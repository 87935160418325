// Move it later somehere else
export class ScanTileLocation {
    zoom = 0;
    col = 0; // Tile column index
    row = 0; // Tile row index

    code = 0; // JS uses 53 bit precision. We can use 20 + 20 + 13 = bits

    clearPos() {
        this.col = this.row = Infinity;
        this.code = Infinity;
    }

    setPos(col: number, row: number) {
        this.col = col;
        this.row = row;
        //this.code = (Math.abs(this.zoom) * 524287 * 524287) + (this.row * 524287) + this.col;
        this.code = Math.abs(this.zoom) * 1000 * 1000 + this.row * 1000 + this.col;
    }

    assign(v: ScanTileLocation) {
        this.zoom = v.zoom;
        this.setPos(v.col, v.row);
    }

    equ(v: ScanTileLocation) {
        return this.code === v.code;
        // return this.zoom === v.zoom && this.col === v.col
        //     && this.row === v.row;
    }
}
