import * as Geo from 'tslib/geo'
import {ApiTileInfo, ApiThumbnailData} from '../ScanData/DataApi'
import {SceneTile} from '../ScanRenderer/ScanScene/SceneTile'
import {ScanTileLocation} from '../ScanTypes/ScanTileLocation'
import {FetchParams} from '../ScanData/DataFetch'
import {ScanDataStatus} from './ScanDataStatus'

//
// Data retrieval and handling
//

// Information about the tile being rendered to texture
export enum SceneImageState {
    Idle, // The item is idle and available
    Pending, // Data is not ready to display
    Ready, // Data is ready to display
    Requested, // Data was requested but waiting
    Received, // The data received but not processed yet
    Processing, // The data received and is processing
    Blank, // The image is blank and is irrelevant
    Error, // Error happened
}

export interface SceneImageTextureSourceSetup {
    id: string
    hash: string
    url: string
}

export interface SceneImageTileSourceSetup {
    loc: ScanTileLocation
    dataOffset: number
    dataSize: number
    hash: string
    url: string
}

export type DataSourceCallback = (ds: ScanDataSource) => void

export interface DataSourceSetup {
    url: string
    id: string
    name: string
    type: string
    hash: string
    index?: number
}

export enum ScanDataSourceStatus {
    Idle = 0,
    Pending,
    Waiting, // Data source is waiting to be fetched
    Fetching, // Fetching data from the server
    Processing, // Processing the data
    Cancelling, // Cancel request was issued
    Cancelled, // Request was cancelled
    Error, // Error happened
    Ready, // Data is ready
}

export class ScanDataSource extends ScanDataStatus {
    // Data location and identification
    //scanId = '';
    url?: string
    name?: string
    hash = ''
    id?: string // unique id
    index = -1 // Just arbitrary index
    type = '' // Resource mime type: json, yaml, jpeg, etc.

    //
    // Correspondning Tile location
    tileLoc = new ScanTileLocation() // location of the tile in the scan
    tileRef?: SceneTile

    // Image source maintanence
    // Every time the image used to age increments
    age = 0
    priorityIndex = 0 // Index in the priority array

    inFetchingList = false
    inPendingList = false

    // Data retrieval options
    updated = false
    cached = false
    useCache = false
    dataRangeOffset = 0
    dataRangeSize = 0
    dataSize = 0

    // Fetching statuses
    fetchState = new FetchParams()

    // Image data information
    imageSize?: Geo.Size
    imageName?: string

    //
    // Data resource destinations
    //
    //destination = DataDestination.None;
    destBlobUrl = false
    blob?: Blob
    blobUrl?: string

    // Destination is bitmap
    destBitmap = false
    bitmap?: ImageBitmap

    // HTML.img destination
    destHtmlImageElement = false
    htmlImageElement?: HTMLImageElement

    // Texture destination
    texRect?: Geo.Rect // Location on the texture
    tileInfo?: ApiTileInfo

    // Callbacks
    onFetch?: (ds: ScanDataSource, err?: Error) => void
    //onError?: (ds: ScanDataSource, err: Error) => void;

    //
    // Initialization
    //
    constructor(setup?: DataSourceSetup) {
        super()

        if (setup) {
            this.url = setup.url
            this.id = setup.id
            this.name = setup.name
            this.hash = setup.hash
            this.type = setup.type
            this.index = setup.index ?? 0
        }
    }

    // initScanImage(scanId: string, url: string) {
    //     this.url = url;
    //     this.cached = true;
    // }

    clone(out: ScanDataSource) {
        out.url = this.url
        out.name = this.name
        out.id = this.id
        out.type = this.type
        out.hash = this.hash

        if (this.imageSize) out.imageSize = Geo.makeSize(this.imageSize.w, this.imageSize.h)
        out.imageName = this.imageName
    }

    // Reset destination
    resetDest() {
        this.destBlobUrl = false
        this.destHtmlImageElement = false
        this.destBitmap = false
    }

    // Releasing data
    release() {
        this.resetDest()
        this.blob = undefined

        if (this.blobUrl) {
            URL.revokeObjectURL(this.blobUrl)
            this.blobUrl = undefined
        }

        this.htmlImageElement = undefined
        this.bitmap = undefined
        this.tileInfo = undefined
    }

    //
    // Setup
    //

    // Texture
    setupTexture(setup: SceneImageTextureSourceSetup) {
        //this.resetDest();
        this.id = setup.id
        this.name = setup.id
        this.hash = setup.hash
        this.url = setup.url
    }

    // Tile setup
    setupTile(setup: SceneImageTileSourceSetup) {
        //this.resetDest();
        let [tz, tr, tc] = [setup.loc.zoom, setup.loc.row, setup.loc.col]
        this.tileLoc.assign(setup.loc)
        this.id = `imgTile_${-tz}_${tr}_${tc}`
        this.name = this.id
        this.hash = setup.hash
        this.dataRangeOffset = setup.dataOffset
        this.dataRangeSize = setup.dataSize
        this.url = setup.url
    }

    // Thumbnails
    setThumbnail(std: ApiThumbnailData) {
        this.imageSize = Geo.arrayToSize(std.size)
        this.imageName = std.name
        this.hash = std.hash
    }
}
