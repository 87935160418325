import * as Geo from 'tslib/geo'
import * as CMath from 'tslib/cmath'

import * as UE from 'tsui/UiElements'

import {scanStainGlasses_, ScanStainGlassInfo} from 'data/ScanStainGlasses'

export class HomePageUploadZone {
    index: number
    col: number
    row: number

    //rect = new Geo.Rect()
    layout = new UE.UiLayout({
        position: 'absolute',
    })

    glass: ScanStainGlassInfo

    constructor(glass: ScanStainGlassInfo, index: number, col: number, row: number) {
        this.glass = glass
        this.index = index
        this.col = col
        this.row = row
    }
}

export class HomePageDarkAiTheme {
    minPageWidth = 0

    stainUploadGridRows = 2
    stainUploadGridSize = new Geo.Size(
        Math.floor(scanStainGlasses_.length / this.stainUploadGridRows),
        this.stainUploadGridRows
    )
    stainUploadDropZones: HomePageUploadZone[] = []

    // stainButtonCols = 6;
    // stainButtonRows = 2;
    //gridXs = 12 / this.stainUploadGridSize.w

    stainButtonSize = new Geo.Size(100, 260)
    stainZoneMinM = new Geo.Size(8, 8)
    stainZoneMaxM = new Geo.Size(48, 32)
    stainZoneMinSize = this.stainButtonSize.clone().add(this.stainZoneMinM).add(this.stainZoneMinM)
    stainZoneMaxSize = this.stainButtonSize.clone().add(this.stainZoneMaxM).add(this.stainZoneMaxM)
    stainAreaMinSize = this.stainZoneMinSize.clone().scaleSize(this.stainUploadGridSize)
    stainAreaMaxSize = this.stainZoneMaxSize.clone().scaleSize(this.stainUploadGridSize)

    stainDropZoneSize = new Geo.Size()

    stainUploadAreaLayout = new UE.UiLayout({
        //position: 'absolute',
        position: 'relative',
    })

    stainButtonLayout = new UE.UiLayout({
        position: 'absolute',
        width: this.stainButtonSize.w,
        height: this.stainButtonSize.h,
        transformAbsoluteCenter: true,
    })

    genericButtonSize = new Geo.Size(120, 340)
    genericZoneMinM = new Geo.Size(8, 8)
    genericZoneMaxM = new Geo.Size(48, 32)

    genericButtonLayout = new UE.UiLayout({
        position: 'absolute',
        width: this.genericButtonSize.w,
        height: this.genericButtonSize.h,
        transformAbsoluteCenter: true,
    })

    genericZoneMinSize = this.genericButtonSize
        .clone()
        .add(this.genericZoneMinM)
        .add(this.genericZoneMinM)

    genericZoneMaxSize = this.genericButtonSize
        .clone()
        .add(this.genericZoneMaxM)
        .add(this.genericZoneMaxM)

    genDropZoneLayout = new UE.UiLayout({
        //position: 'absolute',
        position: 'relative',
    })

    dropZoneBorder = new UE.UiBorder({
        width: 1,
        color: '#80808088',
    })

    dropZoneOverBorder = new UE.UiBorder({
        width: 4,
        color: theme_.whiteSmoke,
        style: 'dashed',
    })

    constructor() {
        let row = 0
        let col = 0
        let index = 0
        for (let glass of scanStainGlasses_) {
            if (col >= this.stainUploadGridSize.w) {
                col = 0
                row++
            }
            this.stainUploadDropZones.push(new HomePageUploadZone(glass, index++, col++, row))
        }
        this.stainUploadGridSize.h = row + 1

        this.onWindowSize()
    }

    onWindowSize() {
        let horzMargin = 0
        let topMargin = 0
        let bottomMargin = 0
        let pageTop = theme_.pageHeaderHeight + topMargin

        let minZoneWidth = this.stainAreaMinSize.w + this.genericZoneMinSize.w
        let maxZoneWidth = this.stainAreaMaxSize.w + this.genericZoneMaxSize.w
        let minZoneHeight = this.stainAreaMinSize.h
        let maxZoneHeight = this.stainAreaMaxSize.h

        let clientWidth = document.documentElement.clientWidth

        if (clientWidth < theme_.pageMinWidth) clientWidth = theme_.pageMinWidth

        let clientHeight = document.documentElement.clientHeight
        if (clientHeight < minZoneHeight + pageTop + bottomMargin) {
            clientHeight = minZoneHeight + pageTop + bottomMargin
        }

        let zoneWidth = clientWidth - horzMargin * 2
        let zoneHeight = clientHeight - pageTop - bottomMargin

        let stainZoneWidth: number
        let genericZoneWidth: number

        if (zoneWidth >= maxZoneWidth) {
            stainZoneWidth = zoneWidth * 0.74

            if (stainZoneWidth < this.stainAreaMaxSize.w) stainZoneWidth = this.stainAreaMaxSize.w

            genericZoneWidth = clientWidth - horzMargin * 2 - stainZoneWidth
        } else {
            // Not enough space for all
            // Try to fit everything equally
            let stainButtonsTotalWidth = this.stainButtonSize.w * this.stainUploadGridSize.w
            let buttonsTotalWidth = stainButtonsTotalWidth + this.genericButtonSize.w
            let totalMargins = (this.stainUploadGridSize.w + 1) * 2

            // Choose optimal horizontal margin
            let zoneMargin = Math.floor((zoneWidth - buttonsTotalWidth) / totalMargins)

            stainZoneWidth = stainButtonsTotalWidth + zoneMargin * 2 * this.stainUploadGridSize.w
            genericZoneWidth = clientWidth - horzMargin * 2 - stainZoneWidth

            /*
            if (stainZoneWidth < this.stainAreaMinSize.w) {

                stainZoneWidth = this.stainAreaMinSize.w;
                genericZoneWidth = this.genericZoneMinSize.w;

                let remaining = clientWidth - (stainZoneWidth + genericZoneWidth);
                if (remaining > 0) {
                    stainZoneWidth += remaining/2;
                    genericZoneWidth += remaining/2;
                }

                //console.debug(stainZoneWidth);
            }

            if (genericZoneWidth < this.genericZoneMinSize.w) {
                genericZoneWidth = this.genericZoneMinSize.w;
            }
            */
        }

        //
        // First calculate the are size
        //
        // this.stainsUploadArea.w = Math.min(stainZoneWidth, this.stainAreaMaxSize.w)
        // this.stainsUploadArea.h = Math.min(zoneHeight, this.stainAreaMaxSize.h)
        this.stainUploadAreaLayout.width = Math.min(stainZoneWidth, this.stainAreaMaxSize.w)
        this.stainUploadAreaLayout.height = Math.min(zoneHeight, this.stainAreaMaxSize.h)

        //console.debug(this.stainDropZoneSize, this.stainsUploadArea);
        this.stainDropZoneSize
            .assign(this.stainUploadAreaLayout.getSize())
            .divSize(this.stainUploadGridSize)
            .chop()
        this.stainUploadAreaLayout.setSize(this.stainDropZoneSize)
        this.stainUploadAreaLayout.scaleSize(this.stainUploadGridSize)

        // Center the stains bottons
        let offset = Math.floor(Math.abs((stainZoneWidth - this.stainUploadAreaLayout.width) * 0.5))
        //this.stainUploadAreaLayout.left = horzMargin + offset

        //
        // Make sure we don't go above the page area
        //
        const availH = clientHeight - theme_.pageHeaderHeight - this.stainUploadAreaLayout.height
        const availMin = theme_.pageHeaderHeight / 2
        const availMax = theme_.pageHeaderHeight
        const t =
            availH <= availMin
                ? 0
                : availH >= availMax
                ? 1
                : (availH - availMin) / (availMax - availMin)

        const yCoeff = availH < 0 ? 0 : CMath.lerp(0.5, 0.3, t)
        //this.stainUploadAreaLayout.top = Math.floor(availH * yCoeff)

        //
        // Do the same with the generic button
        //
        if (genericZoneWidth > this.genericZoneMaxSize.w) {
            this.genDropZoneLayout.width = this.genericZoneMaxSize.w
            // let space = (genericZoneWidth - this.genDropZoneLayout.width) / 2
            // this.genDropZoneLayout.left = clientWidth - genericZoneWidth + space - horzMargin
            // this.genDropZoneLayout.left -= space * 0.8
        } else {
            this.genDropZoneLayout.width = genericZoneWidth
            //this.genDropZoneLayout.left = clientWidth - horzMargin - this.genDropZoneLayout.width
        }

        // if (this.genDropZoneLayout.left < horzMargin + stainZoneWidth)
        //     this.genDropZoneLayout.left = horzMargin + stainZoneWidth

        this.genDropZoneLayout.height = Math.min(this.genericZoneMaxSize.h, zoneHeight)
        //this.genDropZoneLayout.top = this.stainUploadAreaLayout.getTop() + (this.stainUploadAreaLayout.getHeight() - this.genDropZoneLayout.height) / 2

        // Work on horizontal symmetry
        // let rightGap = clientWidth - this.genDropZoneLayout.getRight()
        // let leftGap = this.stainUploadAreaLayout.left
        // if (Math.abs(rightGap - leftGap) > 2) {
        //     let newGap = Math.floor((rightGap + leftGap) / 2)

        //     this.stainUploadAreaLayout.left += newGap - leftGap
        //     //this.genDropZoneLayout.left += rightGap - newGap
        // }

        //
        // Now update all the stain zones
        //
        //console.debug(this.stainsUploadArea, this.stainDropZoneSize.h);
        for (let zone of this.stainUploadDropZones) {
            zone.layout.left = zone.col * this.stainDropZoneSize.w
            zone.layout.top = zone.row * this.stainDropZoneSize.h
            zone.layout.width = this.stainDropZoneSize.w
            zone.layout.height = this.stainDropZoneSize.h
        }

        this.minPageWidth =
            this.stainUploadAreaLayout.getWidth() +
            this.genDropZoneLayout.getWidth() +
            theme_.muiSpacing * 2
    }
}
