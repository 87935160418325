import React from 'react';

import {Typography, SvgIconProps} from '@mui/material';

import {scanTheme_} from '../../ScanTheme/ScanTheme';

import Env from 'env';
import ButtonElement from 'tsui/DomElements/ButtonElement';

interface NavButtonProps {
    label?: string;
    icon?: React.JSXElementConstructor<SvgIconProps>;
    children?: React.ReactNode;

    // Button's position in the panel
    col: number;
    row: number;

    onClick: () => void;
}

function NavPanelButton(props: NavButtonProps, ref: React.Ref<HTMLButtonElement>) {
    const Icon = props.icon;
    const iconSize = scanTheme_.navButtonHeight * 0.55;

    return (
        <ButtonElement
            ref={ref}
            onClick={props.onClick}
            sx={[
                scanTheme_.buttonHover.sx(),
                scanTheme_.navButtonBorder.sx(),
                scanTheme_.navButtonBackground.sx(),
                scanTheme_.navButtonFont.sx(),
                {
                    position: 'absolute',
                    left: scanTheme_.panelPadding + props.col * scanTheme_.navButtonWidth,
                    bottom: scanTheme_.panelPadding + props.row * scanTheme_.navButtonHeight,
                    width: scanTheme_.navButtonWidth,
                    height: scanTheme_.navButtonHeight,

                    cursor: 'pointer',

                    color: scanTheme_.panelToolbarButtonIconColor,
                    backgroundColor: scanTheme_.panelToolbarButtonBackground.getColor(),
                },
            ]}
        >
            {props.label && <>{props.label}</>}

            {Icon && (
                <Icon
                    sx={{
                        width: iconSize,
                        height: iconSize,
                        '& path': {
                            fill: scanTheme_.panelToolbarButtonIconColor,
                        },
                    }}
                />
            )}
            {props.children}
        </ButtonElement>
    );
}

export default React.forwardRef(NavPanelButton);
