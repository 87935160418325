import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, Stack, TextField, Typography} from '@mui/material'

import * as F from 'tsui/Form'
import ShowWrapper from 'tsui/ShowWrapper'

export interface UploadPermissionData {
    stainTag: string
    notLoggedIn?: boolean
    noUploadPermission?: boolean
}

interface PermissionDialogProps {
    params: UploadPermissionData | null
    onClose?: () => void
    onCloseNull?: (p: null) => void
}

export default function ScanUploadPermissionDialog(props: PermissionDialogProps) {
    return <ShowWrapper<PermissionDialogProps> show={props.params} impl={ScanUploadPermissionDialogImpl} props={props}/>
}



function ScanUploadPermissionDialogImpl(props: PermissionDialogProps) {
    const navigate = useNavigate()

    const form = F.useInputForm()

    React.useEffect(() => {}, [])

    const onSubmitLogin = React.useCallback(() => {
        navigate('/login')
    }, [])

    return (
        <>
            <F.PageFormDialog
                title='Please login'
                submitLabel='Login'
                onSubmit={onSubmitLogin}
                onClose={props.onClose}
                onCloseNull={props.onCloseNull}
                form={form}
                size='sm'
            >
                <Typography>You should be logged in to use this feature!</Typography>
            </F.PageFormDialog>
        </>
    )
}
