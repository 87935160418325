import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Button} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Env from 'env'

import * as Geo from 'tslib/geo'

import session_ from 'api/session'
import * as AuthApi from 'api/auth'

import {AuthButton} from '../Buttons/AuthButton'
import PageMainMenu from './PageMainMenu'

export function getAuthHeaderSize() {
    let size = new Geo.Size()

    if (Env.isMobile) {
        return Geo.makeSize(theme_.pageHeaderHeight, theme_.pageHeaderHeight)
    }

    if (session_.isLoggedIn) {
        return Geo.makeSize(theme_.authGreetingWidth, theme_.authButtonSize.h)
    }

    const buttonsCount = 2
    const width = buttonsCount * theme_.authButtonSize.w + (buttonsCount - 1) * theme_.navButtonGap
    return Geo.makeSize(width, theme_.authButtonSize.h)
}

export default function AuthHeader() {
    const navigate = useNavigate()

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const th = theme_.homePageHeaderTheme

    // const onMenuItem = React.useCallback((key: string) => {
    //     setOpenDrawer(false)
    // }, [])

    const onLogout = React.useCallback(() => {
        AuthApi.logout().then(() => {
            navigate('/')
        })
    }, [])

    return (
        <>
            {th.mainMenu.show && (
                <Button onClick={() => setOpenDrawer(!openDrawer)} sx={th.mainMenu.sx()}>
                    <MenuIcon
                        sx={{
                            //color: theme_.navButtonTextColor,
                            width: th.mainMenu.layout!.width,
                            height: th.mainMenu.layout!.height,
                        }}
                    />
                </Button>
            )}

            {/* {th.loginButton.show && (
                <AuthButton label='Login' href='/login' layout={th.loginButton.layout} />
            )} */}
            <AuthButton label='Login' href='/login' show={th.loginButton.show} layout={th.loginButton.layout} />

            {th.joinButton.show && <AuthButton label='Join' href='/join' layout={th.joinButton.layout} />}

            {th.logoutButton.show && (
                <AuthButton label='Logout' onClick={() => onLogout()} layout={th.logoutButton.layout} />
            )}
            {th.greetingButton.show && (
                <AuthButton
                    tlabel={session_.greeting}
                    layout={th.greetingButton.layout}
                    onClick={() => setOpenDrawer(true)}
                />
            )}

            <PageMainMenu open={openDrawer} onClose={setOpenDrawer}/>
        </>
    )
}
