import React from "react";


import { useNavigate } from 'react-router-dom';


import PageContents from "components/PageContents";

import * as AuthApi from 'api/auth'

import * as Form from 'tsui/Form';



export default function LoginForgotPage() {

    const buttonRef = React.useRef<HTMLButtonElement>();
    const form = Form.useInputForm();
    const navigate = useNavigate();

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {

    }, []);

    return <PageContents title='Forgot Login'>
    <Form.PageForm title='Recover Login' onSubmit={onSubmit} form={form} size='sm'>
        <Form.InputText label='Login / Email / Phone Number' id='login' autocomplete='username' validate="email" required form={form} xsMax/>
        

        <Form.SubmitButton form={form} xs={12} label='Send Request'/>

    </Form.PageForm>
</PageContents>;
}

