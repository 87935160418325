import React from 'react'

import {Box, Typography, SvgIconProps} from '@mui/material'

import {scanTheme_} from '../../ScanTheme/ScanTheme'

import {ScanViewStateInstance} from '../../ScanViewStateProvider'

import NavPanelButton from './NavPanelButton'
import {IScanLocation} from '../../ScanTypes/ScanLocation'

interface ZoomButtonProps {
    label: string
    zoomKey: number
    zoomTo: number
    minZoom: number
    maxZoom: number
    zoomColor: string

    // Button's position in the panel
    col: number
    row: number
}

class ZoomButtonState {
    lastZoom = 10
}

export default function NavZoomButton(props: ZoomButtonProps) {
    const scanCtx = React.useContext(ScanViewStateInstance).state
    const stateRef = React.useRef(new ZoomButtonState())
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    //const indicatorRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        //initZoomButton(props.zoomTo, stateRef.current!, props, buttonRef.current!, indicatorRef.current!)

        const onScanZoom = (loc: IScanLocation) => {
            updateZoomButtonOnZoom(loc.zoom, stateRef.current, props, buttonRef.current!)
        }

        scanCtx.pubsub.onScanZoom(onScanZoom)

        return () => {
            scanCtx.pubsub.offScanZoom(onScanZoom)
        }
    }, [])

    const handleClick = React.useCallback(() => {
        scanCtx.easeToZoom(props.zoomTo)
    }, [])

    return (
        <NavPanelButton ref={buttonRef} label={props.label} col={props.col} row={props.row} onClick={handleClick}>
            <Box 
                sx={{
                    position: 'absolute',
                    left: 0,
                    width: 9,
                    top: 0,
                    bottom: 0,
                    backgroundColor: props.zoomColor,
                }}
            />
        </NavPanelButton>
    )
}

function updateZoomButtonOnZoom(zoom: number, st: ZoomButtonState, props: ZoomButtonProps, button: HTMLButtonElement) {

    // console.debug(zoom === st.lastZoom)

    if (zoom === st.lastZoom) return

    let style = button.style

    if (zoom > props.minZoom && zoom <= props.maxZoom) {
        if (zoom === props.zoomTo) {
            style.color = scanTheme_.buttonSelectedTextColor
            style.backgroundColor = scanTheme_.buttonSelectedBackground
        } else {
            // We hit the button
            let lambda = Math.abs(zoom - props.zoomTo)
            let perc = `${lambda * 100}%`

            //style.color = lambda >= 0.5 ? selTextColor : normTextColor; // 'white';
            //style.background = 'linear-gradient(red, orange, yellow, green, blue);';
            //style.backgroundColor = `linear-gradient(to right, ${selTextColor}, ${selTextColor} perc, ${normTextColor});`;
            //console.log(style.backgroundColor);
            //style.backgroundColor = Theme.themeColor;

            style.color = scanTheme_.buttonSelectedTextColor
            style.backgroundColor = scanTheme_.buttonSelectedBackground //'#55dffe'; //selBkColor;
        }
    } else {
        style.color = scanTheme_.buttonTextColor
        style.backgroundColor = scanTheme_.navButtonBackground.getColor()
    }

    st.lastZoom = zoom
}
