
export enum SceneTileStatus {
    Pending = 0,
    Fetching,
    Image,
    Blank,
    Empty,
    Invalid,
    Error,
}
