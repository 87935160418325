type ApiSize = number[];
type ApiPoint = number[];
type ApiRect = number[];

export class ApiImageData {
    name!: string;
    size!: ApiSize;
    type!: string;
    hash!: string;
}


export class ApiThumbnailData extends ApiImageData {
};


//
// General information about all resources
// Summary
//
interface ApiResourceItem {
    name: string;  // acts like id sometimes
    tag: string;
    file: string;
    hash: string;  // Data hash
    type: string; // resource type
    imageSize?: ApiSize;  // In case it's an image - its size
    length: number  // data length
};

export interface ApiScanSummary {
    summary: ApiScanSummaryBody;
};

interface ApiScanSummaryData {
    length: number;  // Total data size
};

interface ApiScanSummaryBody {
    resources: ApiResourceItem[];
    data: ApiScanSummaryData;
};



//
// Main information about the actual specimen
//

export interface ApiScannerData {
    manufacturer: string;
    model: string;
};

// General information about the scecimen
export interface ApiSpecimenData {
    scan: ScanImageData;
    zoomLevels: any;
    thumbnail: ApiThumbnailData;
    thumbnails: ApiThumbnailData[];
    recog?: RecogResultData;

    scanner: ApiScannerData;
};


// Information about the scan image
export interface ScanImageData {
    imageSize: ApiSize;
    tileSize: ApiSize;
    tilesCount: ApiSize;
    pixelSizeMM: number;
    pixelsPerMM: number;
    emptyTileColor?: string;
    blankTileColor?: string;
};


//
// Recognition result
//
export interface RecogResultData {
    status: string;
    aoi?: number;
};

export interface ScanZoomInfoData {
    zoom: number;
    tilesCount: number[];
};

export type ScanZoomInfoContainer = Map<number, ScanZoomInfoData>;





//
// Tile information
//

export type ApiTileInfoData = any[];  // Just an array

export class ApiTileInfo {
    status: string;
    dataOffset: number;
    dataSize: number;
    hash: string;

    isBlank() {  return this.status === 'B'; }
    isEmpty() {  return this.status === 'E'; }

    // Runtime data for speeding things up
    rtStatus = 0;  // 0 - unknown, 1 - ready, 2 - pending
    isRtUnknown() { return this.rtStatus === 0; }
    setRtUnknown() {  this.rtStatus = 0; }
    isRtReady() { return this.rtStatus === 1; }
    setRtReady() {  this.rtStatus = 1; }
    isRtPending() { return this.rtStatus === 2; }
    setRtPending() { this.rtStatus = 2; }


    constructor(apiData: any[]) {
        this.status = apiData[0] as string;
        this.dataOffset = apiData[1] as number;
        this.dataSize = apiData[2] as number;
        this.hash = apiData[3] as string;
    }


};

export interface ApiTileInfoContainer {
    tilesZYX: any;
};

//
// Detected pathogen 
//
export interface PathogenData {
    status: string;
    confidence: number;
    pos: ApiPoint;
    radius: number;
};


//
// Area of interest information
//
export interface AoiData {
    index: number;
    center: ApiPoint;
    status: string;
    radius: number;
    image: ApiImageData;
    confidence: number;
    tile: number[];
    pathogens: PathogenData[];
};

export interface AoiContainerData {
    count: number;
    imageExt: string;
    zoom: number;
    data: AoiData[];
};

export interface AoiDataContents {
    aoi: AoiContainerData;
};
    


