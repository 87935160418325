import {WglRenderer} from '../webgl_renderer';

export function start(this: WglRenderer) {
    this.started = true;
    let launched = false;
    let rendering = false;

    let wgl = this;

    this.requestFrameId = window.requestAnimationFrame(function onFrame(time: number) {
        if (wgl.stopped) return;
        if (!launched) {
            wgl.launch();
            launched = true;
        }

        if (!rendering) {
            rendering = true;
            wgl.renderFrame(time);
            rendering = false;
        }
        window.requestAnimationFrame(onFrame);
    });
}

export function stop(this: WglRenderer) {
    this.stopped = true;
    window.cancelAnimationFrame(this.requestFrameId);
}
