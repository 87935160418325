import {AuthParams, cacheFetchBlob} from '../../ScanData/DataFetch'
import {ScanDataManager} from '../ScanDataManager'
import {ScanDataSource} from '../ScanDataSource'

export async function ScanDataManager_fetchBlobBody(this: ScanDataManager, ds: ScanDataSource) {
    try {
        if (ds.blobUrl) {
            URL.revokeObjectURL(ds.blobUrl)
            ds.blobUrl = undefined
        }

        //this.invalidateBusyCount();  // Indicate that the busy statuses changed

        ds.fetchState.reset({
            url: ds.url!,
            type: ds.type,
            name: ds.name!,
            hash: ds.hash,
            dataOffset: ds.dataRangeOffset,
            dataLength: ds.dataRangeSize,
        })

        let auth: AuthParams = {
            accountId: this.accountId,
            scanId: this.scanId,
        }

        let blob = await cacheFetchBlob(auth, ds.fetchState)

        ds.setReady() // TODO: remove

        this.removeFromFetchingList_(ds)
        //this.fetchingSources_.delete(ds.index);

        // Check if the request was already cancelled
        if (ds.isCancelling) {
            ds.isCancelling = false

            if (ds.isReleasing) {
                this.releaseDataSource(ds)
            } else {
                ds.setIdle()
            }

            ds.isFetching = false
            return
        }

        // We are not fetching data anymore
        // Wr got the data

        ds.updated = true
        ds.blob = blob

        // The target is a blob URL
        if (ds.destBlobUrl) {
            ds.blobUrl = URL.createObjectURL(ds.blob)
        }

        // Target is the HTML Image element
        if (ds.destHtmlImageElement) {
            //console.debug("fetch html:", ds.name);
            ds.htmlImageElement!.src = ds.blobUrl!
        }

        // Target is the bitmap
        if (ds.destBitmap) {
            let bmp = await createImageBitmap(blob)
            ds.setReady()
            this.onImageBitmap_(ds, bmp)
            ds.tileInfo?.setRtReady()
        } else {
            ds.setReady()
            this.processDataResult_(ds)
            ds.tileInfo?.setRtReady()
        }
    } catch (err) {
        this.handleError_(ds, err)
    }
}
