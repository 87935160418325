import {ScanThemeInstance} from './ScanTheme';

export function ScanTheme_setupThumbnail(this: ScanThemeInstance) {
    this.thumbnailImageMax = this.panelMinContentsHeight;

    this.thumbnailPanel.layout.left = this.panelMargin;
    this.thumbnailPanel.layout.bottom = this.panelMargin;
    this.thumbnailPanel.layout.width = this.panelMinHeight;
    this.thumbnailPanel.layout.height = this.panelMinHeight;
}

export function ScanTheme_updateThumbnail(this: ScanThemeInstance) {
    this.thumbnailPanel.visible = this.aoiCount === 0;
}
