
//
// Scan Recognition status
//

export enum RecogStatusCode {
    Positive = 'P',
    Negative = 'N',
    Suspicious = 'S',
}
