import React from 'react'

import {Typography, SvgIconProps} from '@mui/material'

import ScrollLeftIcon from '@mui/icons-material/ArrowBackIosRounded'
import ScrollUpIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import ScrollRightIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ScrollDownIcon from '@mui/icons-material/KeyboardArrowDownRounded'

import {scanTheme_} from '../../ScanTheme/ScanTheme'

import Env from 'env'
import ButtonElement from 'tsui/DomElements/ButtonElement'

interface ScrollButtonProps {
    //direction: 'left' | 'up' | 'right' | 'down'
    visible: boolean
    vertical: boolean
    direction: 'forward' | 'back'
    // icon: React.JSXElementConstructor<SvgIconProps>
    // children?: React.ReactNode

    onClick: () => void
}

function AoiScrollButton(props: ScrollButtonProps, ref: React.Ref<HTMLButtonElement>) {
    //const Icon = props.direction === 'left' ? ScrollLeftIcon : (props.direction === 'up' ? ScrollUpIcon : props.direction === 'right' ? ScrollRightIcon : ScrollDownIcon)

    let buttonLeft: number | undefined = undefined
    let buttonTop: number | undefined = undefined
    let buttonRight: number | undefined = undefined
    let buttonBottom: number | undefined = undefined

    let buttonHeight: number
    let buttonWidth: number

    //let Icon = props.direction === 'forward' ? ScrollRightIcon : ScrollDownIcon
    let Icon: React.JSXElementConstructor<SvgIconProps> // props.direction === 'forward' ? ScrollRightIcon : ScrollDownIcon
    let iconSize: number
    const iconSizeK = 1 //0.9

    if (props.vertical) {
        buttonHeight = scanTheme_.panelToolbarHeight
        buttonWidth = scanTheme_.aoiImageSize
        iconSize = buttonHeight * iconSizeK

        buttonLeft = 0
        if (props.direction === 'forward') {
            Icon = ScrollDownIcon
            buttonBottom = 0
        } else {
            Icon = ScrollUpIcon
            buttonTop = 0
        }
    } else {
        buttonHeight = scanTheme_.aoiImageSize
        buttonWidth = scanTheme_.panelToolbarHeight
        iconSize = buttonWidth * iconSizeK

        buttonBottom = 0
        if (props.direction === 'forward') {
            Icon = ScrollRightIcon
            buttonRight = 0
        } else {
            buttonLeft = 0
            Icon = ScrollLeftIcon
        }
    }

    return (
        <ButtonElement
            ref={ref}
            onClick={props.onClick}
            sx={[
                scanTheme_.buttonBackground.sx(),
                scanTheme_.buttonBorder.sx(),
                scanTheme_.buttonHover.sx(),
                {
                    visibility: props.visible ? 'visible' : 'hidden',

                    position: 'absolute',
                    left: buttonLeft,
                    top: buttonTop,
                    right: buttonRight,
                    bottom: buttonBottom,

                    width: buttonWidth,
                    height: buttonHeight,

                    cursor: 'pointer',

                    p: 0,
                    m: 0,

                    //border: 'none',
                },
            ]}
        >
            <Icon
                //inheritViewBox={true}
                sx={{
                    width: iconSize,
                    height: iconSize,

                    '& path': {
                        fill: scanTheme_.panelToolbarButtonIconColor,
                    },
                }}
            />
            {/* {props.children} */}
        </ButtonElement>
    )
}

export default React.forwardRef(AoiScrollButton)
