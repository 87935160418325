import * as Api from 'api';

import * as ServerApi from 'api/api';

import scanViewConfig_ from '../ScanViewConfig';
import {cacheGet, cachePut} from './DataCache';
import session_ from 'api/session';
import {arrayBufferToBlob, blobToArrayBuffer} from 'tslib/blob';
import {assertThrow} from 'tslib/assert';
import {delay} from 'tslib/delay';

export interface AuthParams {
    accountId: string;
    scanId?: string;
    token?: string;
}

export enum ResourceType {
    Json = 'application/json',
}

export interface IFetchParams {
    url: string;
    name: string;
    type: string;
    hash: string;
    dataOffset?: number;
    dataLength?: number;
}

export class FetchParams implements IFetchParams {
    url: string;
    name: string;
    type: string;
    hash: string;
    dataOffset?: number;
    dataLength?: number;

    abortController?: AbortController;

    cancelled = false;
    retrievedFromCache = false;

    constructor(params?: IFetchParams) {
        this.url = '';
        this.name = '';
        this.type = '';
        this.hash = '';

        if (params) {
            this.reset(params);
        }
    }

    reset(params: IFetchParams) {
        this.url = params.url;
        this.type = params.type;
        this.name = params.name;
        this.hash = params.hash;
        this.dataOffset = params.dataOffset;
        this.dataLength = params.dataLength;
        this.abortController = undefined;
        this.cancelled = false;
    }

    cancel() {
        //this.status = FetchStatus.Cancelled;
        this.cancelled = true;
        if (this.abortController) {
            //console.debug("Aborting...");
            this.abortController.abort();
            this.abortController = undefined;
        }
    }
}

function makeError(res?: Response): Error {
    return new Error(res ? res.statusText : 'Invalid response');
}

function assertResponse(res?: Response) {
    if (!res) throw new Error('Invalid Response');

    if (!res.ok) throw makeError(res);
}

function makeFetchRequest(auth: AuthParams, src: FetchParams): Request {
    assertThrow(session_.isLoggedIn, 'User not logged in');

    let headers = new Headers();

    // TODO: work on the s3 authorization
    if (!src.url.includes('amazonaws.com')) {
        headers.set('Authorization', `Bearer ${session_.token}`);
    }

    if (src.dataOffset && src.dataLength) {
        headers.set('Range', `bytes=${src.dataOffset}-${src.dataOffset + src.dataLength - 1}`);
    }

    //src.abortController = new AbortController();

    let req = new Request(src.url, {
        headers: headers,
        //signal: src.abortController?.signal,
    });

    return req;
}

async function doFetch(req: Request): Promise<Response> {
    let res = await fetch(req);

    assertResponse(res);

    scanViewConfig_.debug_fetchThrottle && delay(200);

    return res;
}

export async function fetchJson<T>(auth: AuthParams, src: FetchParams): Promise<T> {
    src.retrievedFromCache = false;

    let req = makeFetchRequest(auth, src);
    let res = await doFetch(req);

    let json = await res.json();

    return json as T;
}

export async function cacheFetchJson<T>(auth: AuthParams, src: FetchParams): Promise<T> {
    src.retrievedFromCache = false;

    if (!scanViewConfig_.dataCacheEnabled) return fetchJson<T>(auth, src);

    let data = await cacheGet<T>(auth, src);

    if (data) return data;

    data = await fetchJson<T>(auth, src);

    await cachePut<T>(auth, src, data);

    return data;
}

export async function cacheFetchBlob(auth: AuthParams, src: FetchParams): Promise<Blob> {
    src.retrievedFromCache = false;

    let req = makeFetchRequest(auth, src);

    //console.debug("Fetch:", src.name);

    if (!scanViewConfig_.dataCacheEnabled) {
        let res = await doFetch(req);
        // let res = await fetch(req);
        // assertResponse(res);
        //scanViewConfig_.debug_fetchThrottle && delay(200);
        return await res.blob();
    }

    let buff = await cacheGet<ArrayBuffer>(auth, src);
    if (buff) {
        src.retrievedFromCache = true;
        return arrayBufferToBlob(buff, src.type);
    }

    let res = await doFetch(req);
    let blob = await res.blob();

    let data = await blobToArrayBuffer(blob);
    if (data) {
        cachePut<ArrayBuffer>(auth, src, data as ArrayBuffer);
    }

    return blob;
}

//
// Actual resource querying functions
//
export async function queryScanJsonResource<T>(
    accountId: string,
    scanId: string,
    url: string,
    name: string,
    hash: string
): Promise<T> {
    return cacheFetchJson(
        {accountId: accountId, scanId: scanId},
        new FetchParams({
            url: url,
            name: name,
            hash: hash,
            type: ResourceType.Json,
        })
    );
}

export async function fetchScanJsonResource<T>(accountId: string, scanId: string, url: string): Promise<T> {
    return fetchJson(
        {accountId: accountId, scanId: scanId},
        new FetchParams({
            url: url,
            name: '',
            hash: '',
            type: ResourceType.Json,
        })
    );
}

// export async function queryScanState(accountId: string, scanId: string): Promise<Api.ApiScanStateData> {
//     let url = ServerApi.makeEndPoint(ServerApi.makeRootScan(scanId), 'st');
//     return fetchScanJsonResource<Api.ApiScanStateData>(accountId, scanId, url);
// }
