import PageImage from 'tsui/PageImage';

import {Scan} from 'data/Scan';

import {getUrlName} from 'tslib/url';
import ImgElement from 'tsui/DomElements/ImgElement';

interface Props {
    scan?: Scan;
    url?: string;

    table?: boolean; // Thumbnail for table

    width?: number;
    height?: number;
}

export default function ScanThumbnail(props: Props) {
    const url = (props.scan ? props.scan.thumbnailUrl : props.url)!;
    const width = props.width ? props.width : props.height;
    const height = props.height ? props.height : props.width;

    if (props.table === true) {
        let scan = props.scan!;

        return (
            <ImgElement
                src={url}
                alt={scan.scanId}
                draggable='false'
                sx={[
                    theme_.scanTableThumbnailBorder.sx(),
                    {
                        width: 1,
                        height: 1,
                        objectFit: 'contain',
                    },
                ]}
            />
        );
    }

    return (
        <PageImage
            src={url}
            alt={getUrlName(url)}
            width={width}
            height={height}
            sx={[
                theme_.scanThumbnailBorder.sx(),
                {
                    objectFit: 'contain',
                },
            ]}
        />
    );
}
