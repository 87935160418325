import React from "react"

import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"

import { parse as parseYaml } from "yaml"
import { RenderTemplateElement } from "components/RenderTemplateElement"
import jsPDF from "jspdf"
import { ReportEditContext } from "../ReportTypes/ReportAction"

const reportData = {
    fields: {
        patientName: "O.S. Rayskaya",
        birthDate: "11.07.1996",
        ast: "RF15-01-03",
        gender: "Female",
        received: "2015/01/26",
        emc: "2015-00304",
        referring: "S.V. Lishchuk, MD",
        pathologist: "C. A. VanBeek, MD",
    },
}

interface Props {
    ctx: ReportEditContext
}

export default function ReportHeading(props: Props) {

    const [sectionHeight, setSectionHeight] = React.useState(0)

    // const [templateData, setTemplateData] = React.useState<any>(null)
    // const [dataRequested, setDataRequested] = React.useState(false)
    // const [pdfValue, setPdfValue] = React.useState("")

    //console.debug(props.ctx.template)
    const page = props.ctx.page

    const templateData = props.ctx.template.header!
    const setup = templateData.setup
    //console.log(templateData)
    //const state = { row: 0 }

    React.useEffect(() => {
        const state = {row: 0, col: 0, widthCol: 0}

        {templateData.elements.map((item, i) =>
            RenderTemplateElement(props.ctx, state, reportData, item, i, true)
        )}

        //console.debug(state)
        const lineHeight = 23
        setSectionHeight((state.row + 1) * lineHeight)
    }, [])

    const state = {row: 0, col: 0, widthCol: 0}

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    // position: "absolute",
                    // left: page.paddingX,
                    // right: page.paddingX,
                    // top: page.paddingTop,
                    height: sectionHeight,
                    // height: setup.height * page.lineHeight,
                }}
            >
                {templateData.elements.map((item, i) =>
                    RenderTemplateElement(props.ctx, state, reportData, item, i, false)
                )}
            </Box>
        </>
    )

}

