import {ScanThemeInstance} from './ScanTheme';

import scanViewConfig_ from 'scan/ScanViewConfig';

export function ScanTheme_setupNav(this: ScanThemeInstance) {
    let borderWidth = this.panelBorderWidth;

    let numVertButtons = scanViewConfig_.showNavRotateButtons ? 4 : 3;

    this.navButtonWidth = this.panelMinContentsWidth / 2;
    this.navButtonSmallWidth = this.panelMinContentsWidth / 3;

    if (this.styleLens) {
        this.navPanel.layout.right = this.panelMargin;
    } else {
        this.navPanel.layout.left = this.panelMargin;
    }

    this.navPanel.layout.bottom = this.styleLens ? this.panelMargin : this.panelMinHeight - borderWidth;

    this.navPanel.layout.width = this.panelMinWidth;

    this.navPanel.layout.height = this.navButtonHeight * numVertButtons + this.panelPadding * 2;
}

export function ScanTheme_updateNav(this: ScanThemeInstance) {}
