import React from 'react'

import * as F from 'tsui/Form'
import { handleModalClose, ModalCloseProps } from 'tsui/Types/ModalCloseParams'
// import SlateEditor from "./SlateEditor";

interface AddMacrosProps extends ModalCloseProps {
    //onClose: () => void
}

export default function ReportMacroAddDialog(props: AddMacrosProps) {
    const form = F.useInputForm()

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        handleModalClose(props)
        //props.onClose()
    }, [])

    return (
        <>
            <F.PageFormDialog title='Add Macro' onSubmit={onSubmit} form={form} size='sm' {...props}>
                <F.InputText
                    label='Macro Name'
                    id='macrosTite'
                    autocomplete='off'
                    //validate='not-empty'
                    required
                    form={form}
                    xsMax
                />

                {/* <Form.InputText label='Text' id='macrosText' type='text' autocomplete="off" required validate='not-empty' form={form} xsMax/> */}
                <F.InputText
                    label='Macro Contents'
                    id='macrosTextArea'
                    autocomplete='off'
                    required
                    form={form}
                    xsMax
                    multiline={{
                        minRows: 3,
                        maxRows: 6,
                    }}
                />

                {/* <SlateEditor/> */}
            </F.PageFormDialog>
        </>
    )
}
