export class DocItemInfo {
    key: string
    title: string
    md: string

    constructor(key: string, title: string, md: string) {
        this.key = key
        this.title = title
        this.md = md
    }
}

function registerItem(key: string, title: string, md: string) {
    let item = new DocItemInfo(key, title, md)
    //devDocItems_.set(key, new DocItemInfo(key, title, item));
    devDocItemMap_.set(key, devDocItemsArray_.length)
    devDocItemsArray_.push(item)
}

export const devDocItemMap_ = new Map<string, number>()
export const devDocItemsArray_ = new Array<DocItemInfo>()

registerItem('roadmap', 'Roadmap', 'roadmap')
registerItem('daily', 'Daily Cycle', 'daily')
registerItem('data-component', 'Data Provider Component', 'data-component')
