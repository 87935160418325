import React from 'react'

import {ApiUser} from 'api/users'
import * as Api from 'api/api'

import * as F from 'tsui/Form'

import Env from 'env'
import {FormSizeType} from 'tsui/Form'

export default function UserProfileTab() {
    const form = F.useUpdateForm()

    const [data, setData] = React.useState<ApiUser | null>(null)

    const [changePassword, setChangePassword] = React.useState(false)

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return

        console.debug(evt.data)

        form.setBusy()
        Api.requestSession<ApiUser>('user', 'update', evt.data)
            .then((_) => {
                form.setMessageUpdateSuccess()
                //form.reloadData();
            })
            .catch((err) => form.setError(err))
    }, [])

    let modalSize: FormSizeType = 'md'
    let readonly = false
    let mobileWidth
    if (Env.isMobile) {
        modalSize = 'sm'
        readonly = true
        mobileWidth = 12
    }

    return (
        <F.FormDataProvider<ApiUser> apiGroup='user' apiCommand='profile' onData={(d) => setData(d)} form={form}>
            <F.PageForm title='User' form={form} size={modalSize} onSubmit={onSubmit} formContainer='none'>
                <F.Section label='Name' form={form} />
                <F.InputText
                    label='Prefix'
                    id='namePrefix'
                    value={data?.namePrefix}
                    //autocomplete='given-name'
                    form={form}
                    xs={1}
                    //readonly={readonly}
                />
                <F.InputText
                    label='First Name'
                    id='firstName'
                    value={data?.firstName}
                    //autocomplete='given-name'
                    form={form}
                    xs={3}
                    //readonly={readonly}
                />
                <F.InputText
                    label='Middle Name'
                    id='middleName'
                    value={data?.middleName}
                    //autocomplete='additional-name'
                    form={form}
                    xs={3}
                    readonly={readonly}
                />
                <F.InputText
                    label='Last Name'
                    id='lastName'
                    value={data?.lastName}
                    //autocomplete='family-name'
                    form={form}
                    xs={4}
                    //readonly={readonly}
                />
                <F.InputText
                    label='Suffix'
                    id='nameSuffix'
                    value={data?.nameSuffix}
                    //autocomplete='honorific-suffix'
                    form={form}
                    xs={1}
                    readonly={readonly}
                />

                <F.Section label='Contact' optional form={form} />
                <F.InputText
                    label='Area Code'
                    id='phoneAreaCode'
                    autocomplete='tel'
                    validate='tel'
                    form={form}
                    xs={3}
                    readonly={readonly}
                />
                <F.InputText
                    label='Phone'
                    id='phoneNumber'
                    autocomplete='tel'
                    validate='tel'
                    form={form}
                    xs={3}
                    readonly={readonly}
                />

                {!Env.isMobile && <F.SubmitButton label='Update' form={form} xsMax size='lg' align='center' />}
            </F.PageForm>
        </F.FormDataProvider>
    )
}
