import * as Geo from 'tslib/geo';
import {RecogStatusCode} from 'data/RecogStatus';
import * as ServerData from '../ScanData/DataApi';
import {ScanDataSource} from '../ScanDataManager/ScanDataSource';
import {ScanViewContext} from '../ScanViewContext';

export class RecogResult {
    status!: string;
    isPositive() {
        return this.status === RecogStatusCode.Positive;
    }
    isNegative() {
        return this.status === RecogStatusCode.Negative;
    }
    isSuspicious() {
        return this.status === RecogStatusCode.Suspicious;
    }

    name?: string;
    aoiCount = 0;

    style?: string;
}

export class ScannerInfo {
    manufacturer?: string;
    model?: string;

    constructor(si: ServerData.ApiScannerData) {
        this.manufacturer = si.manufacturer;
        this.model = si.model;
    }
}

export class ScanInfo {
    scanSize: Geo.Size;
    tileSize: Geo.Size;
    tilesCount: Geo.Size;
    pixelSizeMM: number;
    pixelsPerMM: number;
    emptyTileColor: string;
    blankTileColor: string;
    zoomLevels = new Map<number, ServerData.ScanZoomInfoData>();
    thumbnailDataSource?: ScanDataSource;
    thumbnailsDataSources: ScanDataSource[] = [];
    result = new RecogResult();
    scanner: ScannerInfo;

    constructor(scanCtx: ScanViewContext) {
        let sid = scanCtx.scanInfoDataState.data!;
        this.scanSize = Geo.apiToSize(sid.scan.imageSize);
        this.tileSize = Geo.apiToSize(sid.scan.tileSize);
        this.tilesCount = Geo.apiToSize(sid.scan.tilesCount);
        this.pixelSizeMM = sid.scan.pixelSizeMM;
        this.pixelsPerMM = sid.scan.pixelsPerMM;

        this.blankTileColor = sid.scan.blankTileColor ?? 'white';

        // TODO: calculate and read from the server
        //this.emptyTileColor = sid.scan.emptyTileColor ?? '#dfdfdf';
        this.emptyTileColor = '#dfdfdf';

        this.scanner = new ScannerInfo(sid.scanner);

        for (let key of Object.keys(sid.zoomLevels)) {
            let zoomLevel = -parseInt(key);
            this.zoomLevels.set(zoomLevel, sid.zoomLevels[key]);
        }

        let dm = scanCtx.dataManager!;

        if (sid.thumbnail) {
            this.thumbnailDataSource = dm.addPermanentDataSource();
            this.thumbnailDataSource?.setThumbnail(sid.thumbnail);
        }

        this.thumbnailsDataSources = [];
        for (let tn of sid.thumbnails) {
            let th = dm.addPermanentDataSource()!;
            th.setThumbnail(tn);
            this.thumbnailsDataSources.push(th);
        }

        if (sid.recog) {
            this.result.status = sid.recog.status;
            this.result.aoiCount = sid.recog.aoi ? sid.recog.aoi : 0;
        } else {
            this.result.status = 'U';
            this.result.aoiCount = 0;
        }
    }

    release() {
        this.thumbnailDataSource = undefined;
        this.thumbnailsDataSources.length = 0;
    }
}
