import React from 'react';

import PageContents from 'components/PageContents';

import ScansPageContents from './ScansPageComponents/ScansPageContents';
import ScansPageTabbed from './ScansPageComponents/ScansPageTabbed';

export default function ScansPage() {
    return <ScansPageTabbed />;

    // if (!session_.isLoggedIn) {
    //     return (
    //         <PageContents title='Scans' current='scans' type='data'>
    //             <p>Please login or register (TODO: buttons)</p>
    //         </PageContents>
    //     )
    // }

    // return (
    //     <PageContents title='Scans' current='scans' type='data'>
    //         <ScansPageContents category='' />
    //     </PageContents>
    // )
}
