import {Container} from '@mui/material';

import * as UE from 'tsui/UiElements';

import PageNavButton from 'components/Buttons/PageNavButton';

interface PageNavProps {
    current: string;
}

export default function PageNav(props: PageNavProps) {
    const th = theme_.homePageHeaderTheme;
    const thNav = th.navBar;

    if (!thNav.show) return <></>;

    const buttonsData = [
        {tag: 'home', label: 'Home', href: '/', show: true},
        {tag: 'scans', label: 'Scans', href: '/scans', show: true}, //session_.isLoggedIn},
        // {tag: 'contact', label: 'Contact Us', href: '/contact', show: true},
        {tag: 'tools', label: 'Tools', href: '/tools', show: true},
    ];

    const buttonWidth = theme_.navButtonSize.w;

    return (
        <Container sx={thNav.sx()}>
            {buttonsData.map((item, index) => {
                return (
                    <PageNavButton
                        key={item.tag}
                        tag={item.tag}
                        label={item.label}
                        //tlabel={item.tlabel}
                        href={item.href}
                        current={props.current}
                        show={item.show}
                        width={theme_.navButtonSize.w}
                        height={theme_.navButtonSize.h}
                        layout={
                            new UE.UiLayout({
                                position: 'absolute',
                                left: index * (buttonWidth + theme_.navButtonGap),
                                top: 0,
                                width: theme_.navButtonSize.w,
                                height: theme_.navButtonSize.h,
                            })
                        }

                        // sx={{
                        //     position: 'absolute',
                        //     left: index * (buttonWidth + theme_.navButtonGap),
                        //     top: 0,
                        //     width: theme_.navButtonSize.w,
                        //     height: theme_.navButtonSize.h,
                        // }}
                    />
                );
            })}
        </Container>
    );
}
