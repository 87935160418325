import React from 'react'

import * as D from 'data'
import * as AD from 'api/data'

import * as Api from 'api'

import ScanNoteDetailsModal from './ScanNoteDetailsModal'
import {ScanDetailsProps} from '../ScanDetailsProps'
import PageDataTable, {PageDataTableColumn} from 'tsui/PageDataTable'


export default function ScanNotesTab(props: ScanDetailsProps) {
    const mounted = React.useRef(false)
    const [dataRequested, setDataRequested] = React.useState(false)
    const [data, setData] = React.useState<D.ScanNote[] | null>(props.scan.notes ?? null)

    const [detailsNote, setDetailsNote] = React.useState<D.ScanNote | null>(null)

    const [error, setError] = React.useState<Error | null>(null)

    React.useEffect(() => {
        mounted.current = true

        //console.debug(props.scan.notes)

        if (!dataRequested) {
            setDataRequested(true)

            if (props.scan.notes === undefined) {
                //console.debug('fetching notes')

                Api.requestSession<AD.ApiNoteData[]>('user', 'fetch_notes', {
                    scanId: props.scan.scanId,
                })
                    .then((sc) => {
                        if (mounted.current) {
                            props.scan.notes = D.processApiNotes(sc)
                            setData(props.scan.notes)
                        }
                    })
                    .catch(setError)
                return
            }
        }

        return () => {
            mounted.current = false
        }
    }, [dataRequested])

    return (
        <>
            <PageDataTable<D.ScanNote>
                getRowId={(row) => row.noteId}
                columns={columns}
                rows={data}
                error={error}
                onDetails={setDetailsNote}
                // detailsComponent={
                //     // <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} />
                //     <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} />
                // }

                // initialState={{
                //     sorting: {
                //         sortModel: [{field: 'noteDate', sort: 'desc'}],
                //     },
                // }}
            />

            <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} />
        </>
    )

}

const columns: PageDataTableColumn<D.ScanNote>[] = [
    {
        field: 'userGreeting',
        label: 'Doctor',
        type: 'greeting',
    },

    {
        field: 'text',
        label: 'Text',
        flex: 10,
    },

    {
        field: 'noteDate',
        label: 'Date',
        type: 'date',
        sortable: true,
        initialSort: 'desc',
    },
]
