import scanViewConfig_ from '../ScanViewConfig';

//import {Debug} from 'tslib/debug'
import {AuthParams, IFetchParams} from './DataFetch';

const productVersion_ = '0.0.2';

//const cacheNameBase_ = "scanview-cache";
//const cacheName_ = `${cacheNameBase_}-${productVersion_}`;
const cacheEnabled_ = scanViewConfig_.dataCacheEnabled;

let db_: IDBDatabase;

const databaseName_ = 'bpf-scan-cache';
const cacheStore_ = 'scan-data';
const cacheLocIndex = 'idx-tile-location';
const cacheDateIndex = 'idx-access-date';

//let debug_ = new Debug({enabled: false, name: 'CacheDB'})

let initPromise_: Promise<void> | undefined = undefined;
let maintenanceStarted_ = false;

export async function cacheInit(): Promise<void> {
    // debug_.check('init()')

    if (!cacheEnabled_ || db_) return Promise.resolve();

    // We are already in the process of initializing the database
    if (initPromise_) {
        // debug_.check('Reentrant init()')
        return initPromise_;
    }

    initPromise_ = new Promise<void>((resolve, reject) => {
        if (!window.indexedDB) return reject("You browser doesn't support indexedDB.");

        let openReq = indexedDB.open(databaseName_);

        openReq.onerror = (event) => {
            console.error('Error loading database.', event);
            reject('CacheDB::Error loading');
        };

        openReq.onsuccess = function (this: IDBRequest<IDBDatabase>) {
            // debug_.check('Open Success.')
            db_ = this.result;
            resolve();
        };

        openReq.onupgradeneeded = function (this: IDBOpenDBRequest, ev: IDBVersionChangeEvent) {
            //  debug_.check('CacheDB::Upgrading.')

            let db = this.result;
            let keyPath = ['accountId', 'scanId', 'name'];

            let store = db.createObjectStore(cacheStore_, {keyPath: keyPath});

            store.transaction.onerror = function (this: IDBTransaction, ev: Event) {
                console.error('CacheDB::upgradeneeded::error', this.error);
                reject(this.error);
            };

            store.transaction.oncomplete = function (this: IDBTransaction, ev: Event) {
                //  debug_.check('CacheDB::upgrade::complete')
            };

            store.createIndex(cacheLocIndex, ['accountId', 'scanId', 'name'], {unique: true});
            store.createIndex(cacheDateIndex, 'date', {unique: false});
        };
    });

    return initPromise_;
}

export async function cacheMaintenance(accountId: string, scanId: string, cutoff: number) {
    return new Promise<void>((resolve, reject) => {
        if (maintenanceStarted_) return resolve();

        maintenanceStarted_ = true;

        let txn = db_.transaction(cacheStore_, 'readwrite');
        let store = txn.objectStore(cacheStore_);
        let idx = store.index(cacheDateIndex);

        // We don't want to be getting every value every time
        // But we can't delete
        let req = idx.openKeyCursor();

        req.onsuccess = function (this: IDBRequest<IDBCursor | null>) {
            let cursor = this.result;

            if (cursor) {
                let pk = cursor.primaryKey as Array<string>;

                let recAcc = pk[0]; //.valueOf(); //[0].toString() !== accountId;
                let recScanId = pk[1];
                let recDate = cursor.key as number;

                let sameAcct = recAcc === accountId;

                // The current scan is ignored
                if (sameAcct && recScanId === scanId) {
                    cursor.continue();
                    return;
                }

                // If different account or old file, delete it
                if (!sameAcct || recDate <= cutoff) {
                    //console.debug("deleting...", pk);
                    store.delete(cursor.primaryKey);
                }

                cursor.continue();
            } else {
                maintenanceStarted_ = false;
                resolve();
            }
        };

        req.onerror = (event) => {
            console.error('Error maintaining database.', event);
            maintenanceStarted_ = false;
            reject('CacheDB::Error Maintainint');
        };
    });
}

export async function cacheGet<T>(auth: AuthParams, src: IFetchParams): Promise<T | null> {
    return new Promise<T | null>((resolve, reject) => {
        if (!src.name || src.name.length === 0) {
            //console.error("Name must not be empty");
            return reject(`cacheGet::Name must not be empty: ${src.url}`);
        }

        let key = [auth.accountId, auth.scanId!, src.name];

        let req = db_.transaction(cacheStore_, 'readonly').objectStore(cacheStore_).get(key);

        req.onerror = function (ev) {
            reject('CacheDB::Error:Get()');
        };

        req.onsuccess = function (this: IDBRequest, ev) {
            if (!this.result) return resolve(null);

            let result = this.result;

            if (src.hash && result.hash !== src.hash) return resolve(null);

            if (scanViewConfig_.debug_cacheThrottle) {
                setTimeout(() => resolve(result.data), 1000);
            } else {
                resolve(result.data);
            }
        };
    });
}

export async function cachePut<T>(auth: AuthParams, src: IFetchParams, data: T) {
    return new Promise<void>((resolve, reject) => {
        let val = {
            accountId: auth.accountId,
            scanId: auth.scanId,
            name: src.name,
            hash: src.hash,
            data: data,
            date: Date.now(),
        };

        let req = db_.transaction(cacheStore_, 'readwrite').objectStore(cacheStore_).put(val);

        req.onerror = function (ev) {
            reject('CacheDB::Error:Put.');
        };

        req.onsuccess = function (ev) {
            resolve();
        };
    });
}
