import * as Geo from 'tslib/geo';

import { IScanLocationParam, ScanLocation } from "../../ScanTypes/ScanLocation";
import { IEasingParam } from "../NavEasing";
import { InertiaStatus } from "../ScanNavInertia";
import { EaseToParams, ScanViewRenderer } from "../ScanViewRenderer";

//
// Easing
//

export function ScanRender_processInertia(this: ScanViewRenderer, time: number): InertiaStatus | undefined
{
    let inertia = this.intertiaHandler_.onMoveEnd(time);

    if (!inertia)
        return undefined;

    let nav = this.nav!;

    // Determine target position
    // Adjust for the rotation
    let rotated = Geo.makePointRotate(inertia.offset, nav.currLoc.rot.sin, nav.currLoc.rot.cos);
    inertia.destPos.assign(rotated);

    inertia.destPos.scale(-nav.zoomMulToScan); // Scale to screen and negate coords

    inertia.destPos.add(nav.currLoc.pos);
    //nav.clampPos(inertia.destPos);

    if (inertia.destPos.equ(nav.currLoc.pos))
        return undefined;

    return inertia;
}

export function setLocation(this: ScanViewRenderer, to: ScanLocation) 
{
    let nav = this.nav!;

    nav.setLocation(to);

    this.processLocationChanged(false);

    this.pubsub?.emitScanLocation(nav.currLoc);
}

export function moveTo(this: ScanViewRenderer, to: IScanLocationParam) 
{
    this.setLocation(this.nav!.processLocationParams(to));
}


export function processEasingFinished(this: ScanViewRenderer) {
    let webgl = this.webgl!;
    webgl.easing = false;
    webgl.easingFinished = true;

    this.setLocation(webgl.getEasingLoc());
}

export function cancelEasing(this: ScanViewRenderer): boolean {
    if (!this.webgl!.easing)
        return false;

    let webgl = this.webgl!;

    webgl.easing = false;
    webgl.easingFinished = true;

    let nav = this.nav!;

    nav.setLocation(webgl.getEasingLoc());
    return true;
}

export function easeTo(this: ScanViewRenderer, to: IScanLocationParam, params?: EaseToParams) 
{
    // TODO: Check if we can just use moveTo (if the positions are too close)

    // TODO: Check if need to switch to the thumbnails scene
    // in case the current scene can't cover the whole transition
    // if (this.currentSceneRef !== this.scenes[0]) {
    //     this.selectSceneByIndex(0);
    // }


    let webgl = this.webgl!;
    let nav = this.nav!;


    // End whatever easing was happening
    if (webgl.easing) {
        this.processEasingFinished();
    }
    let curr = nav.getCurrLoc();


    // Determine the duration
    // For very close positions easing isn't needed at all
    let duration = params?.duration ?? 0;
    let pdist = 0;  // Position distance
    let zdist = 0;  // Zoom distance
    let rdist = 0;  // Rotation distance
    if (to.pos) {
        pdist = Math.hypot(to.pos.x - curr.pos.x, to.pos.y - curr.pos.y);
        pdist *= nav.zoomMulToView;
    }

    if (to.zoom !== undefined) {
        zdist = Math.abs(to.zoom - curr.zoom);
    }

    if (to.rot !== undefined) {
        rdist = Math.abs(to.rot - curr.rot.angle);
    }

    // Small movements on the same screen are done without easing
    let sdiag = Math.hypot(this.surfaceSize.w, this.surfaceSize.h);
    if (zdist === 0 && rdist === 0 && pdist <= sdiag / 8) {
        this.moveTo(to);
        return;
    }

    
    // Medium distance
    if (duration === 0) {
        if (zdist <= 2 && rdist < Math.PI / 2 && pdist <= sdiag * 2)
            duration = 500;
        else  // Long distance
            duration = 1000;
    }

    let easeParams: IEasingParam = {
        from: curr,
        to: to,
        duration: duration,
    };

    webgl.startEasing(easeParams);
    let renderer = this;

    // Updating 
    setTimeout(function onTimeout() {
        if (webgl.easingFinished) {
            renderer.processEasingFinished();
            return;
        }

        renderer.pubsub?.emitScanLocationTrack(webgl.getEasingLoc());
        setTimeout(onTimeout, 100);
    }, 100);
}



