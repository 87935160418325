import React from "react";
import { Grid, Box, Paper, Tabs, Tab } from '@mui/material';
import DevDocItem from "./DevDocItem";
//import { AdminTabSubTitle } from "pages/admin/AdminComponents";
import {devDocItemsArray_} from "./DevDoc";


const startTab = 'roadmap';
//const startTab = 'cod';  // Debug

export default function DevDocTab()
{
    const [selectedTab, setSelectedTab] =  React.useState(startTab);

    const onTabChange = React.useCallback((evt, value) => {
        setSelectedTab(value);
    }, []);

    return <Box display='block' sx={{
        border: 1,
        borderColor: 'divider',
        height: 1,
    }}>
        {/* <AdminTabSubTitle>Developer Documentation</AdminTabSubTitle> */}

        <Grid container spacing={2} sx={{
            height: "90%",
        }}>
            <Grid item xs={2}>
                <Tabs 
                    value={selectedTab}
                    onChange={onTabChange}
                    orientation="vertical"
                    sx={{
                        borderRight: 1, 
                        borderColor: 'divider',
                        height: 1,
                    }}>

                    {devDocItemsArray_.map(item => 
                        <Tab key={item.key} value={item.key} label={item.title}/>
                    )}
                </Tabs>
            </Grid>

            <Grid item xs sx={{
                width: 1,
                height: 1,
                //maxHeight: 0.9, //'90%',
            }}>
                <Paper>
                <DevDocItem docKey={selectedTab}/>
                </Paper>
            </Grid>

        </Grid>
    </Box>;

}

