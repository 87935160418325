import * as Geo from 'tslib/geo';
import { SceneTexture } from './ScanScene/SceneTexture';
import { WglTexture } from "./WebGLRen/webgl_renderer";


export class RenderTexture {
    // The actual texture for rendering
    wglTexture = new WglTexture();
    wglIndex = 0;  // Texture index in webgl 


    texSize = new Geo.Size();  // Texture size

    // References
    sceneTexRef?: SceneTexture;

    reset() {
        this.sceneTexRef = undefined;
    }

    release() {
        this.reset();
    }

};
