import React from 'react'

import OpenInFullIcon from '@mui/icons-material/OpenInFullRounded'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreenRounded'

import * as FullScreen from 'ui/FullScreen'
import CaptionButton from './CaptionButton'


export default function FullScreenButton(props: {fs: boolean}) {
    const Icon = props.fs ? CloseFullscreenIcon : OpenInFullIcon

    return <CaptionButton icon={Icon} onClick={FullScreen.toggle}/>
}
