import * as Geo from 'tslib/geo';
import scanViewConfig_ from 'scan/ScanViewConfig';

import {RenderTexture} from '../RenderTexture';
import {ScanViewRenderer} from '../ScanViewRenderer';

//
// Acquire all resources for the scenes
//
export function ScanRender_initSceneResources(this: ScanViewRenderer) {
    let webgl = this.webgl!;
    let scanCtx = this.scanCtx!;
    let scanInfo = scanCtx.scanInfo;

    // Reserve all textures will be used
    let setup = this.setup;

    setup.tileSize.assign(scanInfo.tileSize);

    // Select optimal texture size
    // Make sure they are divisible by tile size
    let texDim = Math.min(scanViewConfig_.textureSize, webgl.device.maxTextureSize);
    setup.texPerScene.set(scanViewConfig_.texturesPerScene, scanViewConfig_.texturesPerScene);

    texDim = Math.floor(texDim / setup.tileSize.w) * setup.tileSize.w;
    setup.texSize.set(texDim, texDim);

    setup.tilesPerTex.assign(setup.texSize).divSize(setup.tileSize);

    setup.tilesPerScene.assign(setup.tilesPerTex).scaleSize(setup.texPerScene);

    setup.tilesPerScan.assign(scanInfo.scanSize).divSize(scanInfo.tileSize);

    this.sceneTexScanCov.assign(setup.texSize).scaleSize(setup.texPerScene);

    // Add main thumbnail image source
    this.thumbnailDataSource = this.imageDataManager!.addPermanentDataSource();

    //// First comes main thumbnail texture with one image source
    {
        // Setup texture
        let tex = new RenderTexture();
        tex.texSize.assign(setup.texSize);
        this.textures.push(tex);
        this.texThumbRef = tex;
    }

    //
    // Four textures for current view.
    // The quad covers 4096 + 4096 = 8192 by 8192 area
    //
    for (let itex = 0; itex < setup.texPerScene.w * setup.texPerScene.h; ++itex) {
        let tex = new RenderTexture();
        tex.texSize.assign(setup.texSize);

        this.textures.push(tex);

        this.texSceneViewRef.push(tex);
    }

    // Build the WebGL renderer scene
    this.buildWglScene();

    //
    // Create all the textures in WebGL
    //
    let textureIndex = 0;
    for (let tex of this.textures) {
        webgl.createTexture(tex.wglTexture, tex.texSize);
        tex.wglIndex = textureIndex;
        let batch = webgl.scene.tilesBatchesRef[textureIndex];
        batch.texIndex = textureIndex;
        batch.texRef = tex.wglTexture;
        ++textureIndex;
    }
}
