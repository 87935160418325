import * as i18n from 'i18n'
import * as LS from 'tsfront/preferences'
import * as GD from 'tools/GlobalDispatch'

export interface ApiSession {
    token?: string
    userId?: string
    access?: string
    greeting?: string
    lang?: string
    accounts?: string[]
}

class SessionStorage {
    token?: string
    userId?: string
    access?: string
    greeting?: string
    lang?: string
    accounts?: string[]
}

function cleanupSession(ss: SessionStorage | undefined) {
    if (!ss) return ss

    if (!ss.token || !ss.userId) return undefined

    if (!ss.greeting) ss.greeting = ss.userId

    return ss
}

function assignSession(ss: SessionStorage | undefined, api: ApiSession | undefined) {
    if (!api) return undefined

    if (!ss) return undefined

    ss.token = api.token
    ss.userId = api.userId
    ss.access = api.access
    ss.greeting = api.greeting ?? ss.userId
    ss.lang = api.lang
    ss.accounts = api.accounts

    return ss
}

function isSessionEqu(ss: SessionStorage | undefined, sess: ApiSession) {
    if (!ss) return false

    return (
        ss.token === sess.token &&
        ss.userId === sess.userId &&
        ss.access === sess.access &&
        ss.greeting === sess.greeting &&
        ss.lang === sess.lang
    )
}

const anonLangPreference = new LS.LsPreference<string>('string', 'lang-anon', i18n.langDefault)
const userLangPreference = new LS.LsPreference<string>('string', 'lang-user', i18n.langDefault)

const sessionStorage = new LS.LsPreference<SessionStorage>('object', 'session')

export class Session {
    token!: string
    userId!: string
    greeting!: string
    access!: string

    accounts?: string[]

    isLoggedIn = false
    isUser = false
    isAdmin = false
    isDeveloper = false
    canUploadScan = false

    userLang?: string
    anonLang?: string
    lang: string // Selected user language

    constructor() {
        this.anonLang = anonLangPreference.getOpt()
        this.userLang = userLangPreference.getOpt()
        this.lang = i18n.langDefault

        let storedSession = cleanupSession(sessionStorage.getOpt())

        if (storedSession) {
            // Immediately populate the stored object
            this.processSessionObject(storedSession)

            // Now launch the authentication
            this.startProcessSessionObject(storedSession).then((sess) => {
                if (!sess) {
                    this.processLogout()
                    GD.pubsub_.dispatch(GD.authListenerId)
                    return
                }

                // Just update the current user in case some information changed
                if (isSessionEqu(storedSession, sess)) {
                    this.processSessionObject(sess)
                }
            })
        } else {
            // Just deal with the language
            if (this.anonLang !== undefined && this.anonLang !== i18n.langDefault) {
                this.processLang(this.anonLang, {skipStorage: true})
            }
        }
    }

    private startProcessSessionObject(sess: SessionStorage) {
        return new Promise<SessionStorage | null>((resolve, reject) => {
            // Request server for a new token
            resolve(sess)
        })
    }

    private processSessionObject(sess: SessionStorage) {
        this.token = sess.token!
        this.userId = sess.userId!
        this.access = sess.access!
        this.greeting = sess.greeting!
        this.accounts = sess.accounts

        //console.debug(this.greeting);

        this.isLoggedIn = true
        this.isUser = true

        if (this.access) {
            let accessArr = this.access.split(',')
            for (let a of accessArr) {
                switch (a) {
                    case 'R':
                        this.isAdmin = this.isDeveloper = true
                        break
                    case 'A':
                        this.isAdmin = true
                        break
                    case 'Z':
                        this.isDeveloper = true
                        break
                }
            }
        }

        // Read the user preferred language
        //console.debug(sess.lang, this.userLang);

        if (sess.lang) {
            let userLang = i18n.langSanitize(sess.lang)
            this.processLang(userLang, {skipStorage: true})
        } else {
            if (this.userLang) this.processLang(this.userLang, {skipStorage: true})
        }
    }

    private processLogout() {
        this.isLoggedIn = false
        this.isUser = false
        this.isAdmin = false
        this.isDeveloper = false

        this.token = ''
        this.userId = ''
        this.greeting = ''
        this.access = ''
        this.userLang = ''
        this.accounts = undefined

        sessionStorage.remove()
    }

    authenticate(api?: ApiSession) {
        let sess = new SessionStorage()
        let retSess = assignSession(sess, api)
        if (!retSess) {
            this.logout()
            return
        }

        this.processSessionObject(sess)

        sessionStorage.set(sess)

        GD.pubsub_.dispatch(GD.authListenerId)
    }

    logout() {
        let wasLoggedIn = this.isLoggedIn

        this.processLogout()

        if (wasLoggedIn) {
            GD.pubsub_.dispatch(GD.authListenerId)

            // Check the anon language setting
            if (this.anonLang && this.anonLang !== this.lang) {
                this.setLang(this.anonLang)
            }
        }
    }

    private startProcessLang(langParam?: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            let newLang: string

            if (langParam === undefined) {
                if (this.lang === i18n.langDefault) {
                    resolve(null)
                    return
                }
                newLang = i18n.langDefault
            } else {
                if (this.lang === langParam) {
                    resolve(null)
                    return
                }
                newLang = langParam
            }

            let lang = i18n.langSanitize(newLang)

            //console.debug("changing language to: ", lang);

            i18n.changeLanguage(lang, (error, t) => {
                if (error) {
                    resolve(null)
                    return
                }

                //console.debug("changed language to: ", lang, i18n.language);

                document.documentElement.lang = lang
                i18n.i18nFreqLangUpdate()
                resolve(lang)
            })
        })
    }

    private processLang(
        langParam: string | undefined,
        options?: {skipDispath?: boolean; skipStorage?: boolean}
    ) {
        if (!langParam && this.lang === i18n.langDefault) return

        if (langParam === this.lang) return

        this.startProcessLang(langParam ?? i18n.langDefault).then((lang) => {
            if (!lang) return

            this.lang = lang

            let skipDispath = options?.skipDispath === true
            let skipStorage = options?.skipStorage === true

            //console.debug("changed lang: ", lang);

            if (this.isLoggedIn) {
                this.userLang = this.lang

                !skipStorage && userLangPreference.set(this.lang)

                //console.debug(userLangPreference);
            } else {
                this.anonLang = this.lang
                !skipStorage && anonLangPreference.set(this.lang)
            }

            !skipDispath && GD.pubsub_.dispatch(GD.langListenerId)
        })
    }

    setLang(newLang: string) {
        this.processLang(newLang)
    }

    getLang(): string {
        return this.lang
    }
}

const session_ = new Session()

export default session_
