import React, {useState} from 'react'

import {Stack, Box} from '@mui/material'

interface Props {
    children: React.ReactNode
}

export default function PageToolbar(props: Props) {

    return (
        <Stack direction='row' alignItems='center' spacing={1} sx={{
            width: 1,
            height: theme_.pageToolbarHeight,
        }}>
            {props.children}
        </Stack>
    )

}
