//
// Generic structure describing data status
//

export class ScanDataStatus {
    //
    // Data source status
    //
    isAvailable = true // Data source is available
    isIdle = false // Data source is attached, ready and available
    isPending = false // Fetch pending
    isFetching = false // Data is fetching

    isBusy = false
    isError = false
    isReady = false // Data is ready

    isCancelling = false // Data source is in the process of cancelling
    isReleasing = false // Data source is in the process of being released

    protected clearState_() {
        this.isAvailable = false
        this.isIdle = false
        this.isBusy = false
        this.isPending = this.isFetching = false //this.isReceived = false;
        this.isCancelling = this.isReleasing = false
        this.isError = this.isReady = false
    }

    setIdle() {
        this.clearState_()
        this.isIdle = true
    }

    setAvailable(avail = true) {
        this.clearState_()
        this.isAvailable = avail
    }

    setPending() {
        this.clearState_()
        this.isPending = true
        this.isBusy = true
    }

    setFetching() {
        this.clearState_()
        this.isFetching = true
        this.isBusy = true
    }

    // Sets the data ready
    setReady() {
        this.clearState_()
        this.isReady = true
    }

    setError() {
        this.clearState_()
        this.isError = true
    }
}
