import Env from 'env';

export class ScanViewConfig {
    // Main version
    version = '0.0.2';

    // Whether skip empty tiles by default
    skipEmptyTiles = false;

    // Using browser database to cache the data
    dataCacheEnabled = true;
    //dataCacheEnabled = false;

    // Size of the data source pool
    dsPoolSize = 1024;

    // How many bitmaps keep in memory before fetch
    dsBitmapPoolSize = 256;
    //dsBitmapPoolSize = 0;
    dsKeepBitmap = false;
    dsKeepBlob = !this.dsKeepBitmap;

    // Maximum parallel fetch requests
    dsMaxParallelFetch = 8; // TODO: back 200

    // Process timer interval
    dsProcessInterval = 200;

    // Texture dimension
    textureSize = 2048;
    //textureSize = 4096
    //textureSize = 1024

    // Textures Per Scene: NxN
    texturesPerScene = 2;
    //texturesPerScene = 3
    //texturesPerScene = 1

    // User interface setup
    //showCurrentLocation = Env.isMobile ? false : true
    showCurrentLocation = false;
    showTileBusy = true;

    showNavRotateButtons = false;

    //
    // Debugging
    //
    //debug_showPanel = Env.isDev && true;  // Show scan view debug panel
    debug_showPanel = false; // Show scan view debug panel
    debug_showPanelTileFetch = Env.isDev && this.debug_showPanel && true;

    //debug_cacheThrottle = Env.isDev && true;
    debug_cacheThrottle = false;
    //debug_fetchThrottle = Env.isDev && true;
    debug_fetchThrottle = false;
}

var scanViewConfig_ = new ScanViewConfig();

export default scanViewConfig_;
