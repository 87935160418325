import scanViewConfig_ from '../ScanViewConfig'
import {ScanDataSource} from './ScanDataSource'

import * as Methods from './ScanDataManagerMethods'
import {SceneTile} from '../ScanRenderer/ScanScene/SceneTile'
import * as DebugPanel from '../ScanPanels/ScanViewDebugPanel'

export class ScanDataManager {
    //
    // Setup
    //
    accountId: string
    scanId: string
    dataPoolSize: number

    currentAge_ = 1
    timer_?: any

    //
    // Data Source Containers
    //
    dataSourcesPermanent: ScanDataSource[] = [] // Static data source pool

    dataSourcesPool: ScanDataSource[] = [] // Dynamic data source pool

    // Quick access pools
    //optUsePendingArray = true;  // Use separate arrays for pending and fetching sources

    pendingSources_: number[] = []
    fetchingSources_ = new Set<number>()
    bitmapReadySources_: number[] = []

    // State of the data pool
    pendingCount_ = 0
    fetchingCount_ = 0
    busyCount_ = 0

    //
    // Debugging
    //
    debug_stats_?: DebugPanel.DebugPanelField

    //
    // Initialization
    //
    constructor(accountId: string, scanId: string) {
        this.accountId = accountId
        this.scanId = scanId
        this.dataPoolSize = scanViewConfig_.dsPoolSize

        for (let i = 0; i < this.dataPoolSize; ++i) {
            let ds = new ScanDataSource()
            ds.index = i
            this.dataSourcesPool.push(ds)
        }

        this.timer_ = setInterval(() => this.processSchedule_(), scanViewConfig_.dsProcessInterval)

        if (scanViewConfig_.debug_showPanelTileFetch) {
            this.debug_stats_ = DebugPanel.addField('DS.Mgr-stats', {label: 'DS', showCounter: true})
        }
    }

    release = Methods.ScanDataManager_release

    //
    // Setup
    //
    addPermanentDataSource(): ScanDataSource {
        let ds = new ScanDataSource()
        this.dataSourcesPermanent.push(ds)
        return ds
    }

    acquireDataSource(tile: SceneTile): ScanDataSource {
        // First check for the available bitmaps
        if (scanViewConfig_.dsBitmapPoolSize > 0) {
            for (let idx of this.bitmapReadySources_) {
                let ds = this.dataSourcesPool[idx]
                if (ds.tileLoc.code === tile.scanLoc.code) {
                    return ds
                }
            }
        }

        for (let ds of this.dataSourcesPool) {
            if (ds.isAvailable) {
                ds.resetDest()
                ds.setAvailable(false)
                return ds
            }
        }
        throw new Error('Exhausted Scan Data Source Pool')
    }

    releaseDataSource = Methods.ScanDataManager_releaseDataSource

    //
    // Fetching data
    fetchScanHtmlImg(ds: ScanDataSource, img: HTMLImageElement) {
        ds.resetDest()
        ds.destBlobUrl = true
        ds.destHtmlImageElement = true
        ds.htmlImageElement = img

        this.fetchBlob_(ds)
    }

    fetchImageUrl(ds: ScanDataSource) {
        ds.resetDest()
        ds.destBlobUrl = true

        this.fetchBlob_(ds)
    }

    fetchTextureBitmap_(ds: ScanDataSource) {
        ds.resetDest()
        ds.destBitmap = true

        this.fetchBlob_(ds)
    }

    // Return true if fetching performs immediately
    // false if fetching was queued
    fetchTile(ds: ScanDataSource): boolean {
        // TODO: check if there are any tiles in the queue
        // if there aren't then just fetch the tile instead of scheduling it

        // If the data source is not in the pool, then just request right away
        // This is for all the other images except tiles
        if (!this.dataSourcesPool.includes(ds)) {
            ds.setPending()
            this.fetchTextureBitmap_(ds)
            return true
        }

        // Check if the bitmap is already avaiable
        if (scanViewConfig_.dsBitmapPoolSize > 0 && ds.isReady) {
            if (ds.bitmap) {
                this.onImageBitmap_(ds, ds.bitmap)
            } else if (ds.blob) {
                createImageBitmap(ds.blob).then((bmp) => this.onImageBitmap_(ds, bmp))
            }

            return true
        }

        // Check if we can just fetch right away
        if (this.fetchingCount_ < scanViewConfig_.dsMaxParallelFetch) {
            this.fetchTextureBitmap_(ds)
            this.fetchingCount_++
            return true
        }

        ds.setPending()
        this.pushToPendingList_(ds)

        return false
    }

    cancelFetch(ds: ScanDataSource): boolean {
        // If already requested - do nothing
        // Later we'll work in cancelling the fetch request
        if (ds.isFetching) {
            this.removeFromFetchingList_(ds)
            ds.isCancelling = true
            return true
        }

        // If the image is pending, then remove clear it
        if (ds.isPending) {
            this.removeFromPendingList_(ds)
            ds.setIdle()
            return true
        }

        // Coudn't cancel fetching operation
        return false
    }

    processTileReadyData(ds: ScanDataSource) {
        // Target is the bitmap
        if (ds.destBitmap) {
            if (!ds.blob) return false
            createImageBitmap(ds.blob).then((bmp) => {
                ds.setReady()
                this.onImageBitmap_(ds, bmp)
                ds.tileInfo?.setRtReady()
            })
            //let bmp = await createImageBitmap(ds.blob);
        } else {
            ds.setReady()
            this.processDataResult_(ds)
            ds.tileInfo?.setRtReady()
        }
        return true
    }

    fetchBlob_(ds: ScanDataSource) {
        ds.setFetching()
        this.addToFetchingList_(ds)

        if (ds.tileInfo) {
            ds.tileInfo.setRtPending()
        }

        this.fetchBlobBody_(ds)
    }

    fetchBlobBody_ = Methods.ScanDataManager_fetchBlobBody

    onImageBitmap_(ds: ScanDataSource, bitmap: ImageBitmap) {
        ds.bitmap = bitmap
        this.processDataResult_(ds)
    }

    processDataResult_(ds: ScanDataSource) {
        ds.setReady()

        ds.onFetch && ds.onFetch(ds)

        if (scanViewConfig_.dsBitmapPoolSize > 0) {
            if (!scanViewConfig_.dsKeepBitmap) ds.bitmap = undefined
            if (!scanViewConfig_.dsKeepBlob) ds.blob = undefined
        } else {
            ds.bitmap = undefined
            ds.blob = undefined
        }
    }

    //
    // Containers handling
    //
    addToFetchingList_(ds: ScanDataSource) {
        this.fetchingSources_.add(ds.index)
        ds.inFetchingList = true
    }

    removeFromFetchingList_(ds: ScanDataSource) {
        this.fetchingSources_.delete(ds.index)
        ds.inFetchingList = false
    }

    pushToPendingList_(ds: ScanDataSource) {
        let idx = this.pendingSources_.indexOf(ds.index)
        idx >= 0 && this.pendingSources_.splice(idx, 1)

        this.pendingSources_.push(ds.index)
        ds.inPendingList = true
    }

    popFromPendingList_(): ScanDataSource | undefined {
        let index = this.pendingSources_.pop()
        if (index === undefined) return undefined
        let ds = this.dataSourcesPool[index]
        ds.inPendingList = false
        return ds
    }

    removeFromPendingList_(ds: ScanDataSource) {
        ds.inPendingList = false
        let idx = this.pendingSources_.indexOf(ds.index)
        if (idx < 0) return
        this.pendingSources_.splice(idx, 1)
    }

    //
    // Scheduling, maintanence
    //
    processSchedule_ = Methods.ScanDataManager_processSchedule

    //
    // Download Scheduling
    //

    //
    // Error handling
    //
    handleError_(ds: ScanDataSource, err: any) {
        let error: Error

        if (err instanceof Error) {
            error = err
        } else if (typeof err === 'string') {
            error = new Error(err)
        } else {
            error = new Error('Unknown error')
        }

        this.removeFromFetchingList_(ds)
        this.removeFromPendingList_(ds)

        ds.setError()
        ds.onFetch && ds.onFetch(ds, error)
    }

    //
    // Handling busy statuses
    //
    onBusy?: () => void

    getBusyCount(): number {
        return this.busyCount_
    }
}
