import React from 'react'

import * as Api from 'api'
import * as AD from 'api/data'
import {Scan} from 'data/Scan'

import * as F from 'tsui/Form'
import ShowWrapper from 'tsui/ShowWrapper'

interface Props {
    scan: Scan | null
    onClose?: () => void
    onCloseFalse?: (b: boolean) => void
    onCloseNull?: (b: null) => void

    onUpdate?: (note: AD.ApiNoteData) => void
}

export default function AddScanNoteDialog(props: Props) {
    return <ShowWrapper<Props> impl={AddScanNoteDialogImpl} props={props} show={props.scan} />
}

function AddScanNoteDialogImpl(props: Props) {
    const form = F.useForm({
        type: 'input',
    })

    const scan = props.scan!

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        Api.requestSession<AD.ApiNoteData>('user', 'add_note', {
            scanId: scan.scanId,
            content: evt.data.content,
        })
            .then((d) => {
                //console.log(d)
                //props.onClose()
                props.onUpdate && props.onUpdate(d)
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <F.PageFormDialog
            title={{
                name: 'Add Note to Scan',
                tvalue: scan.scanName,
            }}
            form={form}
            size='sm'
            onSubmit={onSubmit}
            onClose={props.onClose}
            onCloseFalse={props.onCloseFalse}
            onCloseNull={props.onCloseNull}
        >
            {/* <F.Section label='Note' form={form} /> */}
            <F.InputText
                id='content'
                label='Text'
                placeholder='Write note...'
                validate='not-empty'
                autofocus
                form={form}
                xsMax
                multiline
            />
        </F.PageFormDialog>
    )
}
