import React from "react";
import { Box } from "@mui/material";


import { ApiUser } from "api/users";
import * as Api from "api/api";

import PageContents from "components/PageContents";
import * as F from 'tsui/Form';

import ChangePasswordDialog from "../auth/ChangePasswordDialog";
import Env from "env";
import { InputFormField } from "tsui/Form/FormElements/FormFieldContext";


export default function UserOptionsTab() {

    const form = F.useForm({
        type: 'update-fields',
    })

    // const form = Form.useUpdateForm();

    const [data, setData] = React.useState<ApiUser | null>(null);

    const [changePassword, setChangePassword] = React.useState(false);

    const onFieldUpdate = React.useCallback((field: InputFormField) => {
        //console.debug("Field Update:", field)
        form.setFieldBusy(field)

        //let val = field.value

        setTimeout(() => {
            //scan.scanName = field.value
            //scan[field.id!] = field.value

            //console.debug(scan)
            form.clearFieldBusy(field)
        }, 2000)
    }, [])


    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
    }, []);

    return (
        <F.PageForm form={form} onFieldUpdate={onFieldUpdate} formContainer='none'>
            <F.InputText label='Language' id='language' value={"English"} form={form} xs={4}/>
            <F.InputText label='Date Format' id='dateFormat' value={"dd/mm/yy"} form={form} xs={4}/>
            <F.InputText label='Theme' id='theme' value={"Dark"} form={form} xs={4}/>
        </F.PageForm>
    )

// return <F.FormDataProvider<ApiUser> apiGroup="user" apiCommand="profile" onData={d => setData(d)} form={form}>
        
// {Env.isMobile ? (
//         <F.PageForm title='Options' form={form} size='sm' onSubmit={onSubmit} >
//             <F.InputText label='Language' id='language' value={"eng"} autocomplete='given-name' form={form} xs={12} readonly />
//             <F.InputText label='Date Format' id='dateFormat' value={"dd/mm/yy"} autocomplete='given-name' form={form} xs={12} readonly />
//         </F.PageForm>

//         ) : (
//             <F.PageForm title='Options' form={form} size='md' onSubmit={onSubmit} >

//                 <F.SelectField label='Language' id='language' value={"eng"} form={form} xsMax required
//                     items={[
//                         { id: "eng", label: "eng" },
//                         { id: "ru", label: "ru" },
//                     ]}
//                 />
//                 <F.SelectField label='Date format' id='dateFormat' value={"dd/mm/yy"} form={form} xsMax required
//                     items={[
//                         { id: "yy/mm/dd", label: "yy/mm/dd" },
//                         { id: "dd/mm/yy", label: "dd/mm/yy" },
//                         { id: "mm/dd/yy", label: "mm/dd/yy" }
//                     ]}
//                 />
//                 <F.SubmitButton label='Update' form={form} xsMax size='lg' align="center" />
//             </F.PageForm>
//         )}
    

// </F.FormDataProvider>

}

