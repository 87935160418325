import React from 'react';

import {SvgIconProps} from '@mui/material';

import {Link, Button, IconButton} from '@mui/material';

import ViewIcon from '@mui/icons-material/PageviewOutlined';
import DetailsIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

import {useTranslation} from 'react-i18next';

import Env from 'env';
import {makeSxProps} from '../Mui/SxPropsUtil';

export type ActionButtonType = 'details' | 'view' | 'edit';

export interface ActionButtonProps {
    icon?: React.JSXElementConstructor<SvgIconProps>;
    type?: ActionButtonType;
    label?: string;
    tlabel?: string; // Translated label
    href?: string; // Link style button
    target?: string;
    onClick?: () => void;
}

export default function TableActionButton(props: ActionButtonProps) {
    //const [t] = useTranslation()

    if (props.icon) {
        return <TableActionButtonElement {...props} icon={props.icon} />;
    }

    switch (props.type) {
        case 'view':
            return <TableActionButtonElement {...props} icon={ViewIcon} />;

        case 'details':
            return <TableActionButtonElement {...props} icon={DetailsIcon} />;

        case 'edit':
            return <TableActionButtonElement {...props} icon={EditIcon} />;
    }

    return <TableActionButtonElement {...props} />;

    /*
    if (props.type === 'view') {
        if (props.href) {
            return (
                <Link
                    variant='button'
                    target={props.target}
                    href={props.href}
                    sx={[theme_.buttonHover.sx(), {
                        width: theme_.tableActionButtonWidth,
                        //height: 1,
                        height: theme_.tableActionButtonWidth,

                        //p: 1,
                    }]}
                >
                    <ViewIcon
                        sx={{
                            width: theme_.tableActionButtonWidth - 16,
                            height: theme_.tableActionButtonWidth,
                        }}
                    />
                </Link>
            )
        }
    }

    if (props.type === 'edit') {
        //console.debug(props)
        if (props.href) {


            return <IconButton
                //onClick={props.onClick}
                color='primary'
                href={props.href}
                target={props.target}
                sx={{
                    width: theme_.tableActionButtonWidth,
                    height: theme_.tableActionButtonWidth,
                    //...theme_.cssButtonHover(),
                }}
            >
                <DetailsIcon />
            </IconButton>

            // return (
            //     <Link
            //         //variant='button'
            //         target={props.target}
            //         href={props.href}
            //         sx={[theme_.buttonHover.sx(), {
            //             width: theme_.tableActionButtonWidth,
            //             height: theme_.tableActionButtonWidth,

            //             //p: 1,
            //         }]}
            //     >
            //         <EditIcon
            //             width={theme_.tableActionButtonWidth - 16}
            //             height={theme_.tableActionButtonWidth}

            //             sx={{
            //                 // width: 26,
            //                 // height: 26,
            //                 // width: theme_.tableActionButtonWidth - 16,
            //                 // height: theme_.tableActionButtonWidth,
            //             }}
            //         />
            //     </Link>
            // )
        }
    }    

    if (props.type === 'details') {
        return (
            <IconButton
                onClick={props.onClick}
                color='primary'
                sx={{
                    width: theme_.tableActionButtonWidth,
                    height: theme_.tableActionButtonWidth,
                    //...theme_.cssButtonHover(),
                }}
            >
                <DetailsIcon />
            </IconButton>
        )
    }

    const label = props.tlabel ?? (props.label !== undefined ? t(props.label) : 'N/A')

    if (props.href) {
        return (
            <Link
                variant='button'
                target={props.target}
                href={props.href}
                sx={{
                    width: theme_.tableActionButtonWidth,
                }}
            >
                <Button
                    variant='outlined'
                    sx={{
                        width: 1,
                    }}
                >
                    {label}
                </Button>
            </Link>
        )
    }

    return (
        <Button
            variant='outlined'
            onClick={props.onClick}
            sx={{
                width: theme_.tableActionButtonWidth,
            }}
        >
            {label}
        </Button>
    )
    */
}

interface ElementProps {
    icon?: React.JSXElementConstructor<SvgIconProps>;
    tlabel?: string;

    onClick?: () => void;
    href?: string;
    target?: string;
}

function TableActionButtonElement(props: ElementProps) {
    if (props.icon) {
        return <TableIconElement {...props} />;
    }

    if (props.href) {
        return (
            <Button
                variant='outlined'
                href={props.href}
                target={props.target}
                sx={{
                    width: theme_.tableActionButtonWidth,
                }}
            >
                {props.tlabel}
            </Button>
        );
    } else {
        return (
            <Button
                variant='outlined'
                onClick={props.onClick}
                sx={{
                    width: theme_.tableActionButtonWidth,
                }}
            >
                {props.tlabel}
            </Button>
        );
    }
}

function TableIconElement(props: ElementProps) {
    const Icon = props.icon!;

    const color = 'primary';
    const sxProps = makeSxProps({
        width: theme_.tableActionButtonWidth,
        height: theme_.tableActionButtonHeight,
        m: 0,
        p: 0,
        // fontSize: 'x-large',

        '& svg': {
            fontSize: theme_.tableActionButtonHeight - 20,
        },

        // "&& $a": {
        //     p: 0,
        // },
    });

    if (props.href) {
        return (
            <IconButton
                href={props.href}
                target={props.target}
                color={color} // 'primary'
                //disableRipple={true}
                disableFocusRipple={true}
                //size='large'
                sx={sxProps}
            >
                <Icon />
            </IconButton>
        );
    } else {
        return (
            <IconButton
                onClick={props.onClick}
                color={color}
                //disableRipple={true}
                disableFocusRipple={true}
                //size='large'
                sx={sxProps}
            >
                <Icon />
            </IconButton>
        );
    }
}
