import React from 'react'
import {DataGrid, GridColDef, GridPanelProps} from '@mui/x-data-grid'
import {Button, Container, Box, Grid, Typography} from '@mui/material'

import * as Api from 'api/api'
import * as UsersApi from 'api/users'

import PageDataTable, {PageDataTableColumn} from 'tsui/PageDataTable'


import ProgressIndicator from 'tsui/ProgressIndicator'
import PageContents from 'components/PageContents'
import ErrorMessage from 'tsui/ErrorMessage'
//import AddUserDialog from 'pages/admin/users/AddUserDialog'
import AddDoctorDialog from './account_details/AddDoctorDialog'
//import UserDetailsTab from 'pages/admin/users/UserDetailsTab'
import DoctorDetailsDialog from './account_details/DoctorDetalsDialog'
import Env from 'env'

export default function AccountDoctorsTab() {
    const mounted = React.useRef(false)
    let [dataRequested, setDataRequested] = React.useState(false)
    let [error, setError] = React.useState<Error | null>(null)
    let [doctors, setDoctors] = React.useState<UsersApi.ApiUser[]>()
    let [doctorDetailsId, setDoctorDetailsId] = React.useState<string | null>(null)

    let [showAddDoctor, setShowAddDoctor] = React.useState(false)

    React.useEffect(() => {
        mounted.current = true
        if (!dataRequested) {
            setDataRequested(true)
            console.log('data requested')

            Api.requestAdmin<UsersApi.ApiUser[]>('user', 'fetch_doctors', {})
                .then((data) => {
                    if (mounted.current) {
                        console.debug(data)
                        //console.log({...doctors})
                        //setDoctors([...data])
                        //console.log({...data})
                        //setDoctors(data)
                        setDoctors([]) // TODO: fix doctors

                        //setDoctors([{userId: '1234', firstName: 'aaa'} as unknown as UsersApi.ApiUser])
                    }
                })
                .catch(setError)
            return
        }
        return () => {
            mounted.current = false
        }
    }, [dataRequested])

    // if (error) {
    //     console.debug(error)
    //     return <ErrorMessage error={error} />
    // }

    // if (!doctors) {
    //     return <ProgressIndicator title='Loading...' />
    // }

    return (
        <>
            <PageDataTable<UsersApi.ApiUser>
                getRowId={(row) => row.userId}
                columns={columns}
                rows={doctors}
                error={error}
                // onDetails={setDetailsNote}
                // detailsComponent={
                //     // <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} />
                //     <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} />
                // }

                // initialState={{
                //     sorting: {
                //         sortModel: [{field: 'noteDate', sort: 'desc'}],
                //     },
                // }}
            />

            {/* <ScanNoteDetailsModal scan={props.scan} note={detailsNote} onCloseDetails={setDetailsNote} /> */}
        </>
    )    


    // return (
    //     <>
    //         {Env.isMobile ? (
    //             <>
    //                 <Grid container height='100%'>
    //                     <Grid item xs height='100%'>
    //                         <DataGrid
    //                             columns={[
    //                                 {field: 'userId', headerName: 'Doctor Id', headerAlign: 'center', width: 120},
    //                                 // { field: 'firstName', headerName: 'First Name', headerAlign: 'center', width: 120 },
    //                                 // { field: 'lastName', headerName: 'Last Name', headerAlign: 'center', width: 120 },
    //                                 {
    //                                     field: 'email',
    //                                     headerName: 'Email',
    //                                     headerAlign: 'center',
    //                                     minWidth: 300,
    //                                     flex: 1,
    //                                 }, // width: 600 },
    //                             ]}
    //                             rows={doctors}
    //                             //pageSize={10}
    //                             //autoHeight
    //                             //rowsPerPageOptions={[10]}

    //                             autoPageSize={true}
    //                             disableSelectionOnClick
    //                             getRowId={(row) => row.userId}
    //                             onRowClick={(parms) => {
    //                                 setDoctorDetailsId(parms.id as string)
    //                             }}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </>
    //         ) : (
    //             <>
    //                 <Grid container height='100%'>
    //                     <Grid item xs={10} md={10}>
    //                         <Typography>Total Doctors:&nbsp;{doctors.length}</Typography>
    //                     </Grid>
    //                     <Grid item md={2} alignItems='right'>
    //                         <Button
    //                             variant='contained'
    //                             onClick={() => {
    //                                 setShowAddDoctor(true)
    //                             }}
    //                         >
    //                             Add Doctor
    //                         </Button>
    //                     </Grid>

    //                     <Grid item xs height='100%'>
    //                         <DataGrid
    //                             columns={[
    //                                 {field: 'userId', headerName: 'Doctor Id', headerAlign: 'center', width: 120},
    //                                 {field: 'firstName', headerName: 'First Name', headerAlign: 'center', width: 120},
    //                                 {field: 'lastName', headerName: 'Last Name', headerAlign: 'center', width: 120},
    //                                 {
    //                                     field: 'email',
    //                                     headerName: 'Email',
    //                                     headerAlign: 'center',
    //                                     minWidth: 300,
    //                                     flex: 1,
    //                                 }, // width: 600 },
    //                                 // {
    //                                 //     field: '',
    //                                 //     type: 'actions',
    //                                 //     headerName: 'Actions',
    //                                 //     width: 100,
    //                                 //     renderCell: (cell) => {
    //                                 //         return (
    //                                 //             <>
    //                                 //                 <Button
    //                                 //                     variant='contained'
    //                                 //                     onClick={() => setDoctorDetailsId(cell.id as string)}
    //                                 //                 >
    //                                 //                     Details
    //                                 //                 </Button>
    //                                 //                 {/* <Button onClick={() => setEditedUserIndex(cell.id as string)}>Edit</Button> */}
    //                                 //             </>
    //                                 //         )
    //                                 //     },
    //                                 // }, // width: 600 },
    //                             ]}
    //                             rows={doctors}
    //                             //pageSize={10}
    //                             //autoHeight
    //                             //rowsPerPageOptions={[10]}

    //                             autoPageSize={true}
    //                             disableSelectionOnClick
    //                             getRowId={(row) => row.userId}
    //                             onRowDoubleClick={(parms) => {
    //                                 setDoctorDetailsId(parms.id as string)
    //                             }}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </>
    //         )}
    //         {showAddDoctor && (
    //             <AddDoctorDialog
    //                 onChange={() => {
    //                     console.log('onChange')
    //                     setDataRequested(false)
    //                 }}
    //                 onClose={() => setShowAddDoctor(false)}
    //             ></AddDoctorDialog>
    //         )}
    //         {doctorDetailsId && (
    //             <DoctorDetailsDialog
    //                 onChange={() => {
    //                     console.log('onChange')
    //                     setDataRequested(false)
    //                 }}
    //                 userId={doctorDetailsId}
    //                 onClose={() => setDoctorDetailsId(null)}
    //             />
    //         )}
    //     </>
    // )
}

const columns: PageDataTableColumn<UsersApi.ApiUser>[] = [
    // {
    //     field: 'userGreeting',
    //     label: 'Doctor',
    //     type: 'greeting',
    // },

    {
        field: 'firstName',
        label: 'First Name',
    },

    {
        field: 'lastName',
        label: 'Last Name',
    },


    {
        field: 'email',
        label: 'Email',
        flex: 10,
    },

    // {
    //     field: 'noteDate',
    //     label: 'Date',
    //     type: 'date',
    //     sortable: true,
    //     initialSort: 'desc',
    // },
]
