import React from 'react';

import {GridColDef, GridRenderCellParams, GridValidRowModel} from '@mui/x-data-grid';
import {GridInitialStateCommunity} from '@mui/x-data-grid/models/gridStateCommunity';

import i18n from 'i18next';


import {DataTableActionsCell} from './DataTableActions';
import {PageDataTableAction, PageDataTableColumn, PageDataTableProps} from '../PageDataTable';
import { formatDate } from '../UiUtil/DateFormat';

interface MakeColumnParams<RT extends GridValidRowModel> {
    actions?: PageDataTableAction<RT>[];
}

export function makeDataGridColumn<RT extends GridValidRowModel>(
    props: PageDataTableProps<RT>,
    pc: PageDataTableColumn<RT>,
    params?: MakeColumnParams<RT>
) {
    let col: GridColDef<RT> = {
        field: pc.field ?? '',
        headerAlign: 'center',
        sortable: pc.sortable === true ? true : false,
        disableColumnMenu: true,
        filterable: false,
    };

    if (pc.minWidth) col.minWidth = pc.minWidth;

    if (pc.width) col.width = pc.width;

    col.flex = pc.flex;

    col.renderCell = pc.renderCell;
    col.valueGetter = pc.valueGetter;

    let actions = pc.type === 'actions' ? pc.actions : params?.actions ?? undefined;

    if (actions) {
        col.type = 'actions';
        col.width = theme_.tableActionButtonWidth * actions.length;

        col.renderCell = (cell: GridRenderCellParams<RT>) => (
            <DataTableActionsCell<RT> cell={cell} col={col} actions={actions!} />
        );
    }

    return col;
}

export interface GridState<Row extends GridValidRowModel> {
    columns: GridColDef<Row>[];
    initialState?: GridInitialStateCommunity;
}

export function makeDataGridColumns<Row extends GridValidRowModel>(props: PageDataTableProps<Row>): GridState<Row> {
    let cols: GridColDef<Row>[] = [];

    let wasActions = false;
    let initialSortDirection: string | undefined = undefined;
    let initialSortColumn: string | undefined = undefined;

    for (let pc of props.columns) {
        let col = makeDataGridColumn(props, pc);

        if (pc.label) col.headerName = i18n.t(pc.label) ?? pc.label;
        if (pc.tlabel) col.headerName = pc.tlabel;

        switch (pc.type) {
            case 'image':
                //col.sortable = false
                // col.hideSortIcons = true
                // col.filterable = false
                // col.disableColumnMenu = true
                break;

            case 'date':
            case 'time':
            case 'datetime':
                col.type = pc.type === 'datetime' ? 'dateTime' : 'date';
                col.align = 'right';
                col.width = pc.width ?? (pc.type === 'datetime' ? 200 : 120);


                col.valueGetter = (cell) => cell.value;

                // col.valueGetter = (cell) =>
                //     formatDate(cell.value, {
                //         type: pc.type,
                //     });

                // col.valueGetter = (cell) =>
                //     formatDate(cell.value, {
                //         type: pc.type,
                //     });



                //col.valueGetter = (cell) => 'aaa'

                // if (!col.valueGetter) {
                //     col.valueGetter = (cell) => cell.value
                // }

                // col.valueFormatter = (params: GridValueFormatterParams<Date>) =>
                //     params.value.toLocaleDateString()
                // (`${params.value}`)
                break;

            case 'actions':
                // col.type = 'actions'
                // col.width = theme_.tableActionButtonWidth * pc.actions!.length

                // col.renderCell = (cell: GridValueGetterParams<RT>) => (
                //     <ActionsCell<RT> cell={cell} col={col} cparm={pc} />
                // )

                wasActions = true;
                break;

            case 'greeting':
                col.width = pc.width ?? 120;
                break;

            case 'id':
                col.width = pc.width ?? 90;
                break;

            case 'boolean':
                col.width = pc.width ?? 40;
                col.valueGetter = (cell) => (cell.value ? 'YES' : 'NO');
                break;

            default:
                break;
        }

        if (pc.initialSort) {
            initialSortColumn = col.field;
            initialSortDirection = pc.initialSort;
        }

        cols.push(col);
    }

    if (!wasActions && props.actions !== undefined) {
        let col = makeDataGridColumn(props, {
            type: 'actions',
            actions: props.actions,
        });

        cols.push(col);

        wasActions = true;
    }

    if (!wasActions) {
        if (props.onDetails) {
            let col = makeDataGridColumn(props, {
                type: 'actions',
                actions: [
                    {
                        getDetailsHandler: props.onDetails,
                    },
                ],
            });

            cols.push(col);

            wasActions = true;
        }

        if (props.onView) {
            //let viewAction

            let col = makeDataGridColumn(props, {
                type: 'actions',
                actions: [
                    {
                        getDetailsHandler: props.onDetails,
                    },
                ],
            });

            cols.push(col);

            wasActions = true;
        }
    }

    //let initialState =

    let state: GridState<Row> = {
        columns: cols,
    };

    if (props.initialState) state.initialState = props.initialState;

    if (initialSortColumn) {
        state.initialState = {
            sorting: {
                sortModel: [{field: initialSortColumn, sort: initialSortDirection === 'desc' ? 'desc' : 'asc'}],
            },
        };
    }

    return state;
}
