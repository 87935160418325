import React from 'react';

import {PageContainer} from 'components/Page/PageContainer';
import PageHeader from './Page/PageHeader';
import PageContentsLayout from './Page/PageContentsLayout';
import {PageProps} from './Page/PageProps';

interface Props extends PageProps {
    children?: React.ReactNode;
}

export default function PageContents(props: Props) {
    return (
        <PageContainer {...props}>
            <PageHeader {...props} />
            <PageContentsLayout {...props}>{props.children}</PageContentsLayout>
        </PageContainer>
    );
}
