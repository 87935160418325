import {Mtx4} from 'tslib/mtx'
import * as Vertex from './webgl_vertex'

// Program consists of linked shaders
export class WglShaderProgram {
    // Pointers to View, Proj, Model Matrices
    modlMtx!: Mtx4
    viewMtx!: Mtx4

    projMtxChanged = false
    viewMtxChanged = false
    viewportChanged = false

    //
    // Configuration
    //
    haveVertexColor = false
    haveTexture = false
    haveNormal = false

    haveElement = false
    haveCenter = false
    haveSize = false

    haveCheckers = false
    haveBusy = false
    haveCircle = false

    haveProgress = false

    //
    // Vertex data and buffers
    //
    verticesChanged = false
    numVertices = 0
    vertexData = new Float32Array(Vertex.Components)

    numIndices = 0
    indexData = new Uint16Array(1)

    //
    // Initalization
    //
    vertShaderSource?: string
    fragShaderSource?: string
    vertShader?: WebGLShader
    fragShader?: WebGLShader
    glProg?: WebGLProgram

    //
    // Rendering data
    //
    vtxPosAttrib = 0
    vtxColAttrib = 0
    vtxTexAttrib = 0
    vtxNrmAttrib = 0

    // Project/View matrices
    unViewMtx!: WebGLUniformLocation
    unProjMtx!: WebGLUniformLocation
    unModlMtx!: WebGLUniformLocation

    // Viewport/scale, etc
    unViewport: WebGLUniformLocation | null = null

    // Texture support
    unTexIndex: WebGLUniformLocation | null = null

    // General element support
    unElSize: WebGLUniformLocation | null = null
    unElColor1: WebGLUniformLocation | null = null
    unElColor2: WebGLUniformLocation | null = null

    // Scope lens support
    unLensCenter: WebGLUniformLocation | null = null
    unLensRadius: WebGLUniformLocation | null = null
    unLensColor: WebGLUniformLocation | null = null

    // Progress
    progressUniform: WebGLUniformLocation | null = null

    // For debugging purposes
    signature?: string

    constructor(signature: string) {
        this.signature = signature
    }
}
