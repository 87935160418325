import React from 'react';

import {DataGrid, GridRowId, GridRowParams, GridValidRowModel, GridValueFormatterParams} from '@mui/x-data-grid';
import {GridValueGetterParams, GridRenderCellParams} from '@mui/x-data-grid';
import {GridInitialStateCommunity} from '@mui/x-data-grid/models/gridStateCommunity';

import {ActionButtonProps, ActionButtonType} from './Buttons/TableActionButton';
import ProgressIndicator from './ProgressIndicator';

import ErrorMessage from './ErrorMessage';
import {ComponentViewAction} from './Types/ComponentActions';
import {GridState, makeDataGridColumns} from './DataTable/DataTableColumns';
import {SvgIconProps, Typography} from '@mui/material';

export type DataTableFieldType =
    | 'text'
    | 'date'
    | 'time'
    | 'datetime'
    | 'actions'
    | 'image'
    | 'greeting'
    | 'boolean'
    | 'id';

export interface PageDataTableAction<Row extends GridValidRowModel> extends ActionButtonProps {
    icon?: React.JSXElementConstructor<SvgIconProps>;
    getTypeHandler?: (row: Row) => ActionButtonType;

    getHandler?: (row: Row) => void;

    getDetailsHandler?: (row: Row) => void;
    getViewHandler?: (row: Row) => {target: string; href: string};
    getEditHandler?: (row: Row) => {target: string; href: string};
    //navigate?: PageActionNavigateProps
}

export interface PageDataTableColumn<RT extends GridValidRowModel> {
    type?: DataTableFieldType;

    field?: string;

    label?: string;
    tlabel?: string;

    width?: number;
    minWidth?: number;
    flex?: number;

    sortable?: boolean;
    initialSort?: string;

    actions?: PageDataTableAction<RT>[];

    renderCell?: (params: GridRenderCellParams<RT>) => React.ReactNode;
    valueGetter?: (params: GridValueGetterParams<RT, any>) => any;
    valueFormatter?: (params: GridValueFormatterParams<any>) => any;
}

type DetailsComponent = React.FC<{
    onCloseDetails: (d: null) => void;
}>;

// interface DetailsComponent extends React.ReactElement {
//     onCloseDetails: (d: null) => void
// }

export interface PageDataTableProps<Row extends GridValidRowModel = any> {
    columns: PageDataTableColumn<Row>[];
    rows: Row[] | undefined | null;
    actions?: PageDataTableAction<Row>[];
    noRowsOverlay?: React.JSXElementConstructor<any>;

    getRowId: (row: Row) => GridRowId;
    initialState?: GridInitialStateCommunity;

    onDetails?: (row: Row) => void;
    // detailsComponent?: DetailsComponent
    detailsComponent?: React.ReactNode;

    onView?: ComponentViewAction<Row>;

    error?: Error | null;

    columnHeaderHeight?: number;
}

export default function PageDataTable<RT extends GridValidRowModel = any>(props: PageDataTableProps<RT>) {
    if (props.error) return <ErrorMessage error={props.error} />;

    if (props.rows === undefined || props.rows === null) {
        return <ProgressIndicator title='Loading...' />;
    }

    if (props.detailsComponent) return <DataTableGridDetails table={props} />;

    return <DataTableGrid table={props} />;
}

function DataTableGridDetails<Row extends GridValidRowModel>(props: DataGridProps<Row>) {
    const [detailsRow, setDetailsRow] = React.useState<Row | null>(null);

    const DetailsComponent = props.table.detailsComponent!;

    // console.debug(detailsRow, props.table.detailsComponent)
    //console.debug('details component', DetailsComponent);

    return (
        <>
            <DataTableGrid table={props.table} onDetails={setDetailsRow} />

            {/* {detailsRow && <>{DetailsComponent}</>} */}
            {/* {detailsRow && <DetailsComponent onCloseDetails={setDetailsRow}/>} */}
            {/* {detailsRow && <TableDetailsComponent<Row> onCloseDetails={setDetailsRow} component={DetailsComponent}/>} */}

            {/* {detailsRow && <>{props.table.detailsComponent}</>} */}
            {/* {detailsRow && <DetailsComponent/>} */}
        </>
    );
}

interface TableDetailsComponentProps<Row> {
    onCloseDetails: (d: null) => void;

    component: React.FC<{
        onCloseDetails: (d: null) => void;
    }>;
    // type DetailsComponent = React.FC<{
    //     onCloseDetails: (d: null) => void,
    // }>
}

function TableDetailsComponent<Row>(props: TableDetailsComponentProps<Row>) {
    const Component = props.component;
    return <Component onCloseDetails={props.onCloseDetails} />;
}

interface DataGridProps<Row extends GridValidRowModel> {
    table: PageDataTableProps<Row>;
    onDetails?: (row: Row) => void;
}

function DataTableGrid<Row extends GridValidRowModel>(props: DataGridProps<Row>) {
    const [state, setState] = React.useState<GridState<Row>>();

    React.useEffect(() => {
        setState(makeDataGridColumns<Row>(props.table));
    }, [props.table]);

    const handleDoubleClick = React.useCallback((parm: GridRowParams<Row>) => {
        let onDetails = props.onDetails ?? props.table.onDetails;
        onDetails && onDetails(parm.row);
        //props.table.onDetails && props.table.onDetails(parm.row)
    }, []);

    if (!state) return <></>;

    return (
        <DataGrid
            columns={state.columns}
            rows={props.table.rows!}
            getRowId={props.table.getRowId}
            // Layout
            rowHeight={theme_.tableRowHeight}
            autoPageSize={true}
            // Behavious
            disableRowSelectionOnClick={true}
            //disableMultipleSelection={true}

            // showCellRightBorder={true}
            // showColumnRightBorder={true}
            onRowDoubleClick={handleDoubleClick}
            initialState={state.initialState}
            components={{
                NoRowsOverlay: props.table.noRowsOverlay
                    ? props.table.noRowsOverlay
                    : () => <Typography align='center'>No Data</Typography>,
            }}

            columnHeaderHeight={props.table.columnHeaderHeight ?? 56}
            sx={{
                width: 1,
                height: 1,
                //backgroundColor: theme_.dialogBackgroundColor,

                //.Mui-odd
                '& .MuiDataGrid-row': {
                    '&:nth-of-type(2n+1)': {
                        backgroundColor: theme_.tableOddRowColor,
                        '&:hover': {
                            backgroundColor: '#000000ee',
                        },
                    },
                },
            }}
        />
    );
}
