import * as BrowserCaps from "tsfront/caps";
import { Color } from "tslib/color";
import * as Preferences from "tools/LocalPreferences";
import { ScanViewRenderer } from "../ScanViewRenderer";

export function ScanRender_init(this: ScanViewRenderer) 
{
   // this.debug_.check("init");

    let scanCtx = this.scanCtx!;

    // Load all the options
    this.skipEmptyTiles = Preferences.skipEmptyTiles.get();


    // First create canvas we will be rendering to
    let canvas = this.createCanvas();

    // Initialize data manager
    // Restart the data manager
    this.imageDataManager = scanCtx.dataManager!;

    // Now initialize the view components
    this.initSceneResources();

    // Handle the tile colors
    this.blankTileColor = new Color(scanCtx.scanInfo.blankTileColor);
    this.emptyTileColor = new Color(scanCtx.scanInfo.emptyTileColor);
    this.errorTileColor = new Color('red');
    this.invalidTileColor = new Color('yellow');



    // Now create all scenes
    this.buildScanScenes();

    // Choose the main thumbnail scene
    this.selectSceneByIndex(0);



    this.domSub.addWindowListener('resize', () => this.onWindowResize());


    this.domSub.addElementListener('mousedown', canvas, (ev: MouseEvent) => this.onMouseDown(ev));
    this.domSub.addElementListener('mouseup', canvas, (ev: MouseEvent) => this.onMouseUp(ev));
    this.domSub.addElementListener('mousemove', canvas, (ev: MouseEvent) => this.onMouseMove(ev));
    this.domSub.addElementListener('mouseleave', canvas, (ev: MouseEvent) => this.onMouseLeave(ev));
    this.domSub.addElementListener('mouseenter', canvas, (ev: MouseEvent) => this.onMouseEnter(ev));


    let supportsPassive = BrowserCaps.isListenerPassiveSupported();
    let passiveParm = supportsPassive ? { passive: false } : false;

    this.domSub.addElementListener('wheel', canvas, (ev: WheelEvent) => this.onWheel(ev), passiveParm);


    this.domSub.addElementListener('touchstart', canvas, (ev: TouchEvent) => this.onTouchStart(ev), passiveParm);
    this.domSub.addElementListener('touchmove', canvas, (ev: TouchEvent) => this.onTouchMove(ev), passiveParm);
    this.domSub.addElementListener('touchend', canvas, (ev: TouchEvent) => this.onTouchEnd(ev));
    this.domSub.addElementListener('touchcancel', canvas, (ev: TouchEvent) => this.onTouchCancel(ev));

}

export function ScanRender_release(this: ScanViewRenderer) 
{
  //  this.debug_.check("release()");

    this.stop();

    this.domSub.release();

    // Release all scenes and resources
    this.currentSceneRef_ = undefined;
    this.scenes.forEach(scene => scene.release());
    this.scenes.length = 0;


    //
    // Textures. Including references
    //
    this.texThumbRef = undefined;
    this.texSceneViewRef.length = 0;

    for (let tex of this.textures) {
        this.webgl!.releaseTexture(tex.wglTexture);
        tex.release();
    }
    this.textures.length = 0;

    this.imageDataManager = undefined;

    this.webgl!.release();
    this.webgl = undefined;

    this.nav = undefined;

    this.canvas?.remove();
    this.canvas = undefined;
    this.canvasParent = undefined;

    this.scanCtx = undefined;
    this.pubsub = undefined;

}
