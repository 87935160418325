
export const ReportEditorElement = ({attributes, children, element}) => {
    const style = {textAlign: element.align, margin: 0}

    //console.debug(element)

    // console.log(attributes)
    switch (element.type) {
        case 'block-quote':
            return (
                <>
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                    <hr />
                </>
            )

        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'paragraph-title':
            return (
                <p style={style} {...attributes}>
                    <strong>{children}</strong>
                </p>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}
