import React from 'react';

import {SvgIconProps} from '@mui/material';

import {scanTheme_} from '../../ScanTheme/ScanTheme';

import ButtonElement from 'tsui/DomElements/ButtonElement';

export interface ScanViewCaptionButtonProps {
    icon: React.JSXElementConstructor<SvgIconProps>;
    onClick: () => void;

    // optional stuff
    backgroundColor?: string;
}

function ScanViewCaptionButton(props: ScanViewCaptionButtonProps, ref: React.Ref<HTMLButtonElement>) {
    const Icon = props.icon;

    const buttonWidth = scanTheme_.windowCaptionHeight;
    const buttonHeight = scanTheme_.windowCaptionHeight;

    const iconSize = buttonHeight * 0.8;

    return (
        <ButtonElement
            ref={ref}
            onClick={props.onClick}
            sx={[
                scanTheme_.buttonHover.sx(),
                scanTheme_.captionButtonBorder.sx(),
                {
                    p: 0,
                    m: 0,

                    width: buttonWidth,
                    height: buttonHeight,

                    cursor: 'pointer',

                    //border: 'none',

                    color: scanTheme_.panelToolbarButtonIconColor,
                    backgroundColor: props.backgroundColor ?? scanTheme_.captionButtonBackground.getColor(),

                    '& path': {
                        fill: scanTheme_.captionButtonIconColor,
                    },
                },
            ]}
        >
            <Icon
                sx={{
                    p: 0,
                    m: 0,
                    width: iconSize,
                    height: iconSize,
                }}
            />
        </ButtonElement>
    );
}

export default React.forwardRef(ScanViewCaptionButton);
