import React from 'react'

import {styled} from '@mui/material'

import {Grid, Tabs, Tab, Button, Stack, Box, Paper} from '@mui/material'

import * as GD from 'tools/GlobalDispatch'
import MacrosTable from './MacrosTable'
import AccMacrosTable from './AccMacrosTable'
// import { macroCopyInsertSlateId } from '../SlateEditor';
import ReportMacroAddDialog from './ReportMacroAddDialog'
import {ReportEditProps} from '../ReportTypes/ReportEditProps'
import PageToolbar from 'tsui/PageToolbar'
import ToolbarButton from 'components/Buttons/ToolbarButton'
import {ReportSectionContents} from '../ReportComponents/ReportSectionContents'
import {ReportSectionContainer} from '../ReportComponents/ReportSectionContainer'
import {ReportMacrosAcc, ReportMacrosMy} from '../ReportTypes/ReportMacros'
import ReportMacrosTable from './ReportMacrosTable'
import { ReportEditContext } from '../ReportTypes/ReportAction'

let startTab = 'my_macros'

interface Props {
    ctx: ReportEditContext
}

export default function ReportMacrosTab(props: Props) {
    const [addMacroDialog, setAddMacroDialog] = React.useState(false)

    const [macroCat, setMacroCat] = React.useState(startTab)

    const onTabChange = React.useCallback((evt, value) => {
        setMacroCat(value)
    }, [])

    return (
        <>
            <ReportSectionContainer>
                <PageToolbar>
                    <PageToolbarTabs value={macroCat} onChange={onTabChange} orientation='horizontal'>
                        <PageToolbarTab value={ReportMacrosMy} label='My Macros' />
                        <PageToolbarTab value={ReportMacrosAcc} label='Account Macros' />
                    </PageToolbarTabs>

                    <Box flex={10} />

                    <ToolbarButton
                        label='Add Macro'
                        show={macroCat === ReportMacrosMy}
                        onClick={() => setAddMacroDialog(true)}
                    />
                </PageToolbar>

                <ReportSectionContents>
                    <ReportMacrosTable ctx={props.ctx} cat={macroCat} />
                </ReportSectionContents>
            </ReportSectionContainer>

            {addMacroDialog && <ReportMacroAddDialog onCloseFalse={setAddMacroDialog} />}
        </>
    )
}

const PageToolbarTab = styled(Tab)({
    minHeight: 'auto',
    //padding: 0,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
})

const PageToolbarTabs = styled(Tabs)({
    minHeight: 'auto',
    padding: 0,
})
