import React from 'react';

import {Box, Paper} from '@mui/material';

import FormPageTitleImpl from './FormPageTitleImpl';
import FormImplBody from './FormImplBody';
import {computeFormAtts, FormImplProps} from './FormImpl';
import FormAlertSection from './FormAlertSection';

function updateFormContext(props: FormImplProps) {
    let form = props.form;
    form.formContainer = props.formContainer ?? 'frame';
    form.formElement =
        props.formElement ?? (form.formType === 'update-fields' || form.formType === 'display' ? 'none' : 'form');
    form.layoutElement = props.layoutElement ?? 'grid';

    return props.form;
}

export default function FormImplPage(props: FormImplProps) {
    const [form] = React.useState(updateFormContext(props));

    const atts = computeFormAtts(props);

    if (props.form.formContainer === 'none') {
        return (
            <FormImplBody formAtts={atts} {...props}>
                {props.children}
            </FormImplBody>
        );
    }

    return (
        // <Box display='flex' alignItems='center' justifyContent='center' height={atts.formHeight}>
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
                height: atts.formHeight,
            }}
        >
            <Paper
                sx={[
                    theme_.dialog.sx(),
                    {
                        p: props.form.formPadding,
                        width: atts.gridWidth,
                    },
                    props.formSx ?? {},
                ]}
            >
                <FormPageTitleImpl {...props} />

                <FormAlertSection {...props} />

                <FormImplBody formAtts={atts} {...props}>
                    {props.children}
                </FormImplBody>
            </Paper>
        </Box>
    );
}
