import React from 'react'
import {useNavigate} from 'react-router-dom'

import Env from 'env'
import session_ from 'api/session'

import { HomePageProps } from '../HomePageProps'



export default function HomePageAiMobile(props: HomePageProps) {

    const navigate = useNavigate()

    React.useEffect(() => {
        if (session_.isLoggedIn) {
            navigate('/scans')
        } else {
            navigate('/login')
        }
        return
    }, [])

    return <></>
}
