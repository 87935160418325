import * as Geo from 'tslib/geo';
import {WglRenderer} from '../webgl_renderer';

export function Wgl_onWindowResize(this: WglRenderer, surfaceSize: Geo.ISize, scanCenter: Geo.IPoint) {
    let gl = this.gl!;

    // Remember all the importand information
    this.surfaceSize.assign(surfaceSize);
    this.surfaceCenter.assign(scanCenter);
    this.navZoomPending = true;

    // Set the viewport and update matrices
    gl.viewport(0, 0, this.surfaceSize.w, this.surfaceSize.h);

    // Update the view/projection etc matrices
    //mat4.ortho(wglst.projMtx, 0, wglst.surfaceSize.w, 0, wglst.surfaceSize.h, 1, -1)
    //mat4.ortho(wglst.projMtx, -wglst.surfaceSize.w/2, wglst.surfaceSize.w/2, wglst.surfaceSize.h/2, -wglst.surfaceSize.h/2, 1, -1)
    this.projMtx.ortho(0, this.surfaceSize.w, this.surfaceSize.h, 0, 1, -1);
    this.invalidateProjMtx();

    // Notify all the shader about viewport change
    for (let shader of this.shaderPrograms!) {
        shader.viewportChanged = true;
    }

    // Reflect the change in the background batch
    {
        let batch = this.scene.backgroundBatchRef!;
        let left = 0,
            bottom = 0,
            right = surfaceSize.w,
            top = surfaceSize.h;

        batch.vertices.set(0, {x: left, y: bottom});
        batch.vertices.set(1, {x: left, y: top});
        batch.vertices.set(2, {x: right, y: top});
        batch.vertices.set(3, {x: right, y: bottom});
    }
}
