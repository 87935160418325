import React from'react';
import {Box, Container, Grid, Typography} from '@mui/material';
import * as FT from './FormTypes';

import {useTranslation} from 'react-i18next';

interface Props extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
    label: string;
    type?: 'text' | 'required' | 'optional';
    required?: boolean;
    optional?: boolean;
}


export function Section(props: Props) {
    const [t] = useTranslation();

    //const [label, setLabel] = React.useState('');

    const gridAtts = FT.getFormGridAtts(props, {xs: 12});
    const gridSx = FT.getFormSxParams(props, {mt: 1});

    //React.useEffect(() => {
        let text = t(props.label);

        if (props.type === 'required' || props.required) {
            text += ' (' + t('required') + ')';
        } else if (props.type === 'optional' || props.optional) {
            text += ' (' + t('optional') + ')';
        }

        text += ':';
        const label = text;
        //setLabel(text);

        //const sx = gridSx; //FormT.getFormSxParams(props);

        //console.debug(sx);
    
        // if (sx.m === undefined && sx.mt === undefined)
        //     sx.mt = 2;
    
        // if (sx.m === undefined && sx.mb === undefined)
        //     sx.mb = 1;

        //setGridSx(sx);

        //console.debug(gridSx);
        //console.debug(gridParms);


  
        //let gridParms = FormT.getFormGridParams(props);
        // if (gridAtts.xs === undefined)
        //     gridAtts.xs = 12;

    //}, []);



    return <Grid item {...gridAtts} sx={gridSx}>
         <Typography>{label}</Typography>
    </Grid>;

}
