import * as Algo from 'tslib/algorithm';
import * as Geo from 'tslib/geo';
import {IScanLocationParam, ScanLocation} from './ScanLocation';

export class NavState {
    // displayZoomIndex = 0;  // Index to the DisplayZoomLevel structure
    // dzl?: DisplayZoomLevel;

    // levelZoomIndex = 0; // current zoom index ScanViewZoomLevel
    // zl?: ScanViewZoomLevel;

    scanSize = new Geo.Size();

    //
    // Current state
    //
    currLoc = new ScanLocation();
    zoomedLoc = new ScanLocation(); // keep zoomed for speed

    homePos = new ScanLocation();

    // Home image information
    homeImageRect = new Geo.Rect();

    readonly pi = Math.PI;
    readonly _2pi = Math.PI * 2;

    // Boundaries
    minX = 0;
    minY = 0;
    maxX = 0;
    maxY = 0;
    minZ = 0;
    maxZ = 0;

    zoomMulToView = 1; // Scale coefficient. current scan image size = image-size * zoomScale
    zoomMulToScan = 1; // Multiplayer recip

    //
    // Initialization
    //
    setScanSize(size: Geo.Size) {
        this.scanSize.assign(size);
        this.minX = 0;
        this.maxX = this.scanSize.w;
        this.minY = 0;
        this.maxY = this.scanSize.h;
    }

    processLocationParams(to: IScanLocationParam): ScanLocation {
        let loc = new ScanLocation();
        loc.zoom = to.zoom ?? this.currLoc.zoom;

        if (to.rot !== undefined) loc.rot.set(to.rot);
        else loc.rot.assign(this.currLoc.rot);

        if (to.pos !== undefined) loc.pos.assign(to.pos);
        else loc.pos.assign(this.currLoc.pos);

        return loc;
    }

    setLocation(loc: ScanLocation) {
        this.currLoc.assign(loc);
        this.updateCurrLocation();
    }

    offsetPosXY(offx: number, offy: number): Geo.Point {
        this.currLoc.pos.x += offx;
        this.currLoc.pos.y += offy;
        return this.setPos(this.currLoc.pos);
    }

    offsetPos(off: Geo.IPoint): Geo.Point {
        return this.offsetPosXY(off.x, off.y);
    }

    clampPos(p: Geo.Point): Geo.Point {
        p.x = Algo.clamp(p.x, this.minX, this.maxX);
        p.y = Algo.clamp(p.y, this.minY, this.maxY);
        return p;
    }

    checkPos(p: Geo.IPoint): Geo.Point {
        return Geo.makePoint(Algo.clamp(p.x, this.minX, this.maxX), Algo.clamp(p.y, this.minY, this.maxY));
    }

    setPos(p: Geo.IPoint): Geo.Point {
        this.clampPos(this.currLoc.pos);
        this.updateCurrPos();
        return this.currLoc.pos;
    }
    getPos(): Geo.Point {
        return this.currLoc.pos;
    }
    getCurrLoc() {
        return this.currLoc;
    }

    getZoomedPos(): Geo.Point {
        return this.zoomedLoc.pos;
    }

    setCurrPosZoomed(p: Geo.IPoint): Geo.Point {
        this.setPos(this.zoomedToScanPos(p));
        return this.getZoomedPos();
    }

    zoomedToScanPos(z: Geo.IPoint): Geo.Point {
        return Geo.makePoint(
            Algo.clamp(z.x * this.zoomMulToScan, this.minX, this.maxX),
            Algo.clamp(z.y * this.zoomMulToScan, this.minY, this.maxY)
        );
    }

    rotateViewRad(delta: number): Geo.Angle {
        return this.setRotationRad(this.currLoc.rot.angle + delta);
    }

    rotateView(delta: Geo.IAngle): Geo.Angle {
        return this.setRotationRad(this.currLoc.rot.angle + delta.angle);
    }

    setRotationRad(theta: number): Geo.Angle {
        let alpha = this.checkAngle(theta);
        this.currLoc.rot.set(alpha);
        return this.currLoc.rot;
    }

    setRotation(theta: Geo.IAngle): Geo.Angle {
        return this.setRotationRad(theta.angle);
    }

    checkAngle(a: number): number {
        while (a > Math.PI * 2) a -= Math.PI * 2;
        while (a <= -Math.PI * 2) a += Math.PI * 2;

        return a;
    }

    // Returns true if value changed
    setZoomIndex(zoom: number): boolean {
        if (zoom < this.minZ) zoom = this.minZ;

        if (zoom > this.maxZ) zoom = this.maxZ;

        if (zoom === this.currLoc.zoom) return false;

        this.currLoc.zoom = zoom;
        this.updateCurrLocation();
        return true;
    }

    addZoomIndex(delta: number): boolean {
        return this.setZoomIndex(this.currLoc.zoom + delta);
    }

    private updateCurrLocation() {
        this.zoomMulToScan = 2 ** -this.currLoc.zoom;
        this.zoomMulToView = 1 / this.zoomMulToScan;
        this.updateCurrPos();
    }

    private updateCurrPos() {
        this.zoomedLoc.assign(this.currLoc);
        this.zoomedLoc.pos.scale(this.zoomMulToView);
    }

    //
    // Various access functions
    //

    getZoomedScanSize(): Geo.Size {
        return Geo.makeSize(this.scanSize.w * this.zoomMulToView, this.scanSize.h * this.zoomMulToView);
    }
}
