import * as Geo from "tslib/geo";
import { ScanDataSource } from "../../ScanDataManager/ScanDataSource";
import { ScanScene } from "./ScanScene";
import { RenderTexture } from "../RenderTexture";
import { SceneTile } from "./SceneTile";

//
// Texture and containing tiles
//
export class SceneTexture {

    renTexture!: RenderTexture; // The rendering texture this texture refers to

    // Index position in the scene
    sceneIndex = new Geo.Point();

    // The scan area this texture covers in scan coordinates
    scanRect = new Geo.Rect();

    // Rectangle given by 4 vertices
    //scanRectVtx = new Geo.RectVtx();
    // The screen area this texture covers
    //screenRectVtx = new Geo.RectVtx();
    screenVisible = false;

    // Containing tiles
    tiles: SceneTile[] = [];

    addTile() {
        let tile = new SceneTile();
        tile.texRef = this;
        this.tiles.push(tile);
        return tile;
    }

    setSize(size: Geo.ISize) {
        this.renTexture.texSize.assign(size);
    }


    setTileImageSource(index: number, ds: ScanDataSource) {
        let tile = this.tiles[index];
        if (tile.ds) {
            tile.ds.tileRef = undefined;
        }

        tile.ds = ds;
        ds.tileRef = tile;
    }

    // Back references
    sceneRef?: ScanScene; // the scene this texture belongs to now

    reset() {
        this.sceneRef = undefined;
    }

    release() {
        this.reset();
        this.tiles.forEach(tile => tile.release());
        this.tiles.length = 0;
    }
};

