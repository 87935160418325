import * as cmath from 'tslib/cmath'
import {BezierEasing} from 'tslib/bezier'
import {EasingSetup, IEasingParam} from '../NavEasing'
import {ScanLocation} from 'scan/ScanTypes/ScanLocation'

export class WglEasingState {
    param = new EasingSetup()
    loc = new ScanLocation() // Current location

    private startTime = 0
    //private duration = 0;

    private bezier = new BezierEasing()

    start(parm: IEasingParam) {
        this.param.setParams(parm)

        if (!this.param.easePos) {
            this.loc.pos.assign(this.param.from.pos)
        }

        if (!this.param.easeRot) {
            this.loc.rot.assign(this.param.from.rot)
        }

        if (!this.param.easeZoom) {
            this.loc.zoom = this.param.from.zoom
        }

        this.startTime = window.performance.now()

        this.bezier.set(0, 0, 0.3, 1)
    }

    tick(time: number): boolean {
        // Check for end condition
        let delta = time - this.startTime
        let lambda = delta / this.param.duration
        let from = this.param.from
        let to = this.param.to
        let curr = this.loc

        // Easing
        //lambda = this.easingCubicInOut(lambda);
        lambda = this.bezier.solve(lambda)

        // Check end condition
        if (lambda >= 1) {
            curr.assign(to)
            return true
        }

        if (this.param.easePos) {
            curr.pos.lerp(from.pos, to.pos, lambda)
        }

        if (this.param.easeRot) {
            curr.rot.set(cmath.lerp(from.rot.angle, to.rot.angle, lambda))
        }

        if (this.param.easeZoom) {
            curr.zoom = cmath.lerp(from.zoom, to.zoom, lambda)
        }

        return false
    }

    //private updateLocation(lambda: number) {
    //if (lambda >= 1) {  // Finish
    //    this.finished(
    //    return;
    //}
    //}

    private easingCubicInOut(t: number): number {
        if (t <= 0) return 0
        if (t >= 1) return 1
        let t2 = t * t
        let t3 = t2 * t
        return 4 * (t < 0.5 ? t3 : 3 * (t - t2) + t3 - 0.75)
    }
}
