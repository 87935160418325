import React from 'react';
import {useNavigate} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

import * as UE from 'tsui/UiElements';

import {DesignNavButton} from './DesignNavButton';

interface ButtonProps {
    show?: boolean;
    tag: string;
    label?: string;
    tlabel?: string;

    width: number;
    height: number;
    layout?: UE.UiLayout;

    href?: string;
    current?: string; // Current page tag
    onSelected?: (tag: string) => void;

    //sx?: SxPropsParam
}

export default function PageNavButton(props: ButtonProps) {
    const [t] = useTranslation();
    //const [label] = React.useState(props.label ? t(props.label) : props.tlabel ?? 'Error');
    const navigate = useNavigate();

    const handleClick = React.useCallback(() => {
        if (props.href) {
            navigate(props.href);
        }
    }, []);

    if (props.show === false) return <></>;

    // Designed component
    return (
        <DesignNavButton
            label={props.label}
            tlabel={props.tlabel}
            width={props.width}
            height={props.height}
            paperWidth={props.height}
            fontSize={theme_.navButtonFontSize}
            layout={props.layout}
            onClick={handleClick}
            //sx={props.sx}
        />
    );

    //return <ButtonElement onClick={handleClick}>{label}</ButtonElement>
}
