import React from 'react';

import {Checkbox, FormControlLabel} from '@mui/material';

import * as FT from '../FormTypes';

import FormFieldContainer from '../FormElements/FormFieldContainer';
import {useFormField} from '../FormElements/FormFieldHook';
import {getFormBoolValue} from '../FormElements/FormValue';

interface Props extends FT.FieldProps {
    size?: 'sm' | 'md' | 'lg';
}

export function InputCheckbox(props: Props) {
    const field = useFormField(props, {
        fieldType: 'checkbox',
    });

    const [checked, setChecked] = React.useState(getFormBoolValue(props.value));

    const handleChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        let ch = evt.target.checked;
        field.setValue(ch);
        setChecked(ch);
    }, []);

    return (
        <FormFieldContainer {...props}>
            <FormControlLabel
                label={field.tlabel}
                control={<Checkbox id={props.id} checked={checked} onChange={handleChange} />}
            />
        </FormFieldContainer>
    );
}
