export class ScanStainGlassInfo {
    tag: string
    stain: string
    pathogen: string
    tissueImage?: string

    constructor(item: StainDataItem) {
        this.tag = item.tag
        this.stain = item.stain
        this.pathogen = item.pathogen


        //this.tag = (this.stain + '-' + this.pathogen).toLowerCase()

        let imgsRoot = '/images/home_ai_dark/'
        if (item.tissueImage) this.tissueImage = imgsRoot + item.tissueImage
        else this.tissueImage = imgsRoot + 'dark_stain_button_tissue.png'
    }
}

interface StainDataItem {
    tag: string
    stain: string
    pathogen: string
    tissueImage?: string
}

const scansData: StainDataItem[] = [
    {tag: 'gms-fungi', stain: 'GMS', pathogen: 'Fungi'},
    {tag: 'pas-fungi_mac', stain: 'PAS', pathogen: 'Fungi / MAC'},
    {tag: 'pasf-fungi', stain: 'PAS-F', pathogen: 'Fungi'},

    {tag: 'afb-myco', stain: 'AFB', pathogen: 'Myco', tissueImage: 'dark_stain_button_afb_afb.png'},  // Mycobacterium
    {tag: 'fite-myco_mac', stain: 'Fite', pathogen: 'Myco / MAC', tissueImage: 'dark_stain_button_afb_afb.png'},
    //{stain: 'W-S', pathogen: 'Bacteria'},  // Warthin-Starry 

    {tag: 'gram-bacteria', stain: 'Gram', pathogen: 'Bacteria'},
    {tag: 'giemsa-pylory', stain: 'Giemsa', pathogen: 'H. pylori'},
    {tag: 'alcain_yello-pylory', stain: 'Alcian Yellow', pathogen: 'H. pylory'},  // Alcian Yellow
]

function makeScanStainGlasses() {
    let glasses: ScanStainGlassInfo[] = []
    for (let glass of scansData) {
        glasses.push(new ScanStainGlassInfo(glass))
    }
    return glasses
}

export var scanStainGlasses_ = makeScanStainGlasses()

export function findScanInfoByTag(tag: string): ScanStainGlassInfo | undefined {
    // TODO: implement a better method
    // use map
    for (let si of scanStainGlasses_) {
        if (si.tag === tag)
            return si
    }
    return undefined
}


export const ScanGenericUploadTag = 'generic'

const genericScanData: StainDataItem = {
    tag: ScanGenericUploadTag, stain: 'UPLOAD', pathogen: 'SCAN', tissueImage: 'dark_generic_button_tissue.png'
}

export const scanGenericGlass_ = new ScanStainGlassInfo(genericScanData)
