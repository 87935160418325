import React from 'react'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import * as Path from 'tslib/path'
import {devDocItemMap_, devDocItemsArray_} from './DevDoc'
import {assertThrow} from 'tslib/assert'
import { makeFilePathExt } from 'tslib/filename'

interface DocItemProps {
    docKey: string
}

//const markdown = `Just a link: https://reactjs.com.`

const docsDir = '/dev/doc'

export default function DevDocItem(props: DocItemProps) {
    const [itemKey, setItemKey] = React.useState<string>('')
    const [markdown, setMarkdown] = React.useState<string>('')

    const updateDocument = React.useCallback((docKey: string) => {
        //console.debug(docKey);

        setItemKey(docKey)

        let itemIndex = devDocItemMap_.get(docKey)

        assertThrow(itemIndex !== undefined, 'Dev Doc internal error')

        // if (itemIndex === undefined) {
        //     setMarkdown(errorMarkdown);
        //     return;
        // }

        let item = devDocItemsArray_[itemIndex!]

        let docPath = makeFilePathExt(docsDir, item.md, 'md')

        console.debug(docPath)

        let req = new Request(docPath, {})
        fetch(req)
            .then((res) => res.text().then((contents) => setMarkdown(contents)))
            .catch((err) => setMarkdown(`${err}`))
            .catch((err) => setMarkdown(`${err}`))

        //console.debug(docPath);

        //setMarkdown(dailyDocSource);

        // import(docPath)
        //     .then(contents => setMarkdown(contents))
        //     .catch(err => setMarkdown(`${err}`));
    }, [])

    if (props.docKey !== itemKey) {
        updateDocument(props.docKey)
    }

    // //Re
    // console.debug(itemKey);

    // React.useEffect(() => {

    //     setItemKey(props.docKey);

    //     //console.debug(props.docKey, itemKey);

    //     let itemIndex = devDocItemMap_.get(props.docKey);

    //     if (itemIndex === undefined) {
    //         setMarkdown(errorMarkdown);
    //         return;
    //     }

    //     let item = devDocItemsArray_[itemIndex];

    //     let docPath = Path.makeFilePathExt(docsDir, item.md, 'md');

    //     // if (path.extname(docPath) === '')
    //     //     docPath += '.md';

    //     console.debug(docPath);

    // }, [itemKey]);

    return <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
}
