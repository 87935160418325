import React, {useMemo} from 'react'

import {BaseEditor, createEditor, Descendant, Editor, Transforms, Element as SlateElement} from 'slate'

import {Slate, Editable, withReact, ReactEditor} from 'slate-react'
import {HistoryEditor, withHistory} from 'slate-history'

import * as GD from 'tools/GlobalDispatch'
import * as Api from 'api/api'
import * as D from 'data'
import * as RA from '../ReportTypes/ReportAction'


import {ReportEditProps} from '../ReportTypes/ReportEditProps'

import {ReportEditorLeaf} from '../ReportComponents/ReportEditorLeaf'
import {ReportEditorElement} from '../ReportComponents/ReportEditorElement'
import {getReportEditInitialValue} from '../ReportComponents/ReportEditorInitialText'
import {ReportEditorState} from './ReportEditTab'

type TextTypes = 'paragraph' | 'quote' | 'block-quote' | 'list-item'

//type CustomElement = {type: TextTypes; children: CustomText[]; style?: any}
interface CustomElement {
    type: TextTypes
    children: CustomText[]
    align?: string
    style?: any
}

type CustomText = {type?: string; text: string; bold?: boolean; italic?: boolean; code?: boolean}

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: CustomElement
        Text: CustomText
    }
}



interface SlateEditorProps {
    ctx: RA.ReportEditContext
    state: ReportEditorState
    onTextChange: (value: Descendant[]) => void
}

class SlateEditorState {
    isBold = false
    isItalic = false

    // constructor(props: SlateEditorProps) {
    // }

    // styles = new Map<string, boolean>()
    // constructor(props: SlateEditorProps) {
    // }
}

export default function SlateEditor(props: SlateEditorProps) {
    const stateRef = React.useRef(new SlateEditorState())

    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    const renderElement = React.useCallback((props) => <ReportEditorElement {...props} />, [])
    const renderLeaf = React.useCallback((props) => <ReportEditorLeaf {...props} />, [])

    const [textValue, setTextValue] = React.useState<Descendant[]>(getReportEditInitialValue(props.ctx))

    React.useEffect(() => {
        const onMacrosInsert = (param) => {
            let macro = param.macro as D.ReportMacro
            let text = macro.content

            editor.insertSoftBreak()
            editor.insertText(text)
            editor.insertSoftBreak()

            //setText(text);
        }

        const onStyleToggeMark = (param) => onEditorToggleMark(editor, param.key)

        GD.pubsub_.addListener(RA.editorToggleFormat, onStyleToggeMark)
        GD.pubsub_.addListener(RA.editorInsertMacro, onMacrosInsert)


        props.onTextChange(textValue)

        return () => {
            GD.pubsub_.removeListener(RA.editorToggleFormat, onStyleToggeMark)
            GD.pubsub_.removeListener(RA.editorInsertMacro, onMacrosInsert)
        }
    }, [])

    const onValue = React.useCallback((value: Descendant[]) => {
        //console.debug(editor.operations)

        const st = stateRef.current

        let isSelection = false
        let isText = false
        let isNode = false

        for (let op of editor.operations) {
            if (op.type === 'set_selection') 
                isSelection = true
            else if (op.type === 'set_node' || op.type === 'merge_node')
                isNode = true
            else
                isText = true
        }

        //const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)

        setTextValue(value)

        if (isText) {
            props.onTextChange(value)
        }

        if (isSelection) {
            //let marks = editor.marks
            //if (marks
            let key = 'bold'
            let active = isMarkActive(editor, key)
            if (st.isBold !== active) {
                st.isBold = active
                GD.pubsub_.dispatch(RA.onEditorFormat, {key: key, active: active})
            }

            key = 'italic'
            active = isMarkActive(editor, key)
            if (st.isItalic !== active) {
                st.isItalic = active
                GD.pubsub_.dispatch(RA.onEditorFormat, {key: key, active: active})
            }


            //let isItalic = isMarkActive(editor, 'bold')

            //if (

            // for (let key of RA.editorTextStyles) {
            //     let val = props.state.textStyles.get(key)
            //     let active = isMarkActive(editor, key)

            //     //console.debug(key, val, active)

            //     if (val !== active) {
            //         GD.pubsub_.dispatch(RA.onEditorFormat, {key: key, active: active})
            //     }
            // }

        }

        if (isNode) {
            for (let key of RA.editorBlockStyles) {
                let val = props.state.textStyles.get(key)
                let active = isBlockActive(editor, key, 'align')

                //console.debug(
                //console.debug(key, val, active)

                if (val !== active) {
                    GD.pubsub_.dispatch(RA.onEditorFormat, {key: key, active: active})
                }
            }
        }

        // const st = props.state

    }, [])

    // React.useEffect(() => {
    //     getReport(scan.scanId)
    //         .then((d) => {
    //             console.log(d)
    //             setTextValue(JSON.parse(d.text))
    //         })
    //         .catch((err) => console.log(err))
    // }, [])

    // if (!textValue) {
    //     console.log(`no value`)
    //     return <></>
    // }

    const ctx = props.ctx
    const page = ctx.page
    const template = ctx.template
    // const top = template.header!.setup.height * page.lineHeight + page.paddingTop
    // const bottom = props.ctx.template.footer!.setup.height * page.lineHeight + + page.paddingBottom

    return (
        <Slate editor={editor} initialValue={textValue} onChange={onValue}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                autoFocus
                style={{
                    position: 'absolute',
                    overflow: 'auto',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
            />
        </Slate>
    )

    // return (
    //     <Grid sx={{position: 'relative'}} container columnSpacing={1}>
    //         <Grid item xs={12}>
    //             <Slate
    //                 editor={editor}
    //                 value={value}
    //                 onChange={(value) => {
    //                     slateGlobalValue = value
    //                     setValue(value)
    //                 }}
    //             >
    //                 {/* <Editable renderElement={renderElement} /> */}

    //                 <Editable
    //                     renderElement={renderElement}
    //                     renderLeaf={renderLeaf}
    //                     spellCheck
    //                     autoFocus
    //                     style={{
    //                         overflow: 'auto',
    //                         height: '380px',
    //                         padding: '0 10px',
    //                     }}
    //                 />
    //             </Slate>
    //         </Grid>

    //     </Grid>
    // )
}

export function getPdf(scanId) {
    // let textLines = [
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: '//to do', italic: true }
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: '//to do', bold: true },
    //             { text: '1111111111 2222222222 3333333333 4444444444' },
    //             { text: '5555555555 6666666666 7777777777 8888888888' }
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: '//to do bold italic aaa', bold: true, italic: true },
    //             { text: '1111111111 2222222222 3333333333 4444444444 5555555555 6666666666 7777777777 8888888888', italic: true }
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'aaa' }
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'CLINICAL INFORMATION:', bold: true }
    //         ],
    //     },
    //     // {
    //     //     type: 'paragraph',
    //     //     children: [
    //     //         { text: ' ' }
    //     //     ],
    //     // },
    //     // {
    //     //     type: 'paragraph',
    //     //     children: [
    //     //         { text: ' ' }
    //     //     ],
    //     // },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'FINAL DIAGNOSIS', bold: true },
    //         ],
    //     },
    //     // {
    //     //     type: 'paragraph',
    //     //     children: [
    //     //         { text: ' ' }
    //     //     ],
    //     // },
    //     // {
    //     //     type: 'paragraph',
    //     //     children: [
    //     //         { text: ' ' }
    //     //     ],
    //     // },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'MICROSCOPIC EXAM:', bold: true },
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: '' },
    //         ],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'CONSULTATION SPECIMEN DESCRIPTION:', bold: true },
    //         ],
    //     },
    // ]//?
    // Api.requestSession<any>('report', 'finalize_report', {scanId: scanId}, {textLines: slateGlobalValue}).then((d) => {
    //     console.log(d)
    //     window.open(d.pdfLink, '_blank')
    //     return d
    // })
}
export function getReport(scanId) {
    // console.log(reportId)
    // let data:Descendant[] = []
    // if (reportId) {
    //     Api.requestSession<any>('report', 'get_report', { reportId: reportId }, { contents: JSON.stringify(slateGlobalValue) }).then(d => {
    //         // console.log(`Report ${reportId} is`)
    //         data = JSON.parse(d.text)
    //         console.log(data)

    //     }).catch(err => console.log(err));
    // }
    // return data
    return Api.requestSession<any>('report', 'write_report', {scanId: scanId})
}

const isMarkActive = (editor: Editor, key: string) => {
    const marks = Editor.marks(editor)
    let active = marks ? marks[key] === true : false
    return active
}

const onEditorToggleMark = (editor: Editor, key: string) => {

    const isBlock = RA.editorBlockStyles.includes(key)

    //console.debug(RA.editorBlockStyles, key, isBlock)

    if (isBlock) {
        return toggleBlock(editor, key)
    }

    const isActive = isMarkActive(editor, key)
    //console.debug('mark active', key, isActive)

    if (isActive) {
        Editor.removeMark(editor, key)
    } else {
        Editor.addMark(editor, key, true)
    }

    //Editor.setFo

    //GD.pubsub_.dispatch(RA.onEditorFormat, {key: key, active: !isActive})
}

//let slateGlobalValue: Descendant[]


//const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const LIST_TYPES = ['numbered-list', 'bulleted-list']

const isBlockActive = (editor, format, blockType = 'type') => {
    const {selection} = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                //Element.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const toggleBlock = (editor, format) => {
    const isAlignKey = RA.editorBlockStyles.includes(format)
    const isActive = isBlockActive(editor, format, isAlignKey ? 'align' : 'type')
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            // Element.isElement(n) &&
            // LIST_TYPES.includes(n.type) &&
            !RA.editorBlockStyles.includes(format),
        split: true,
    })

    let newProperties: Partial<SlateElement>
    if (RA.editorBlockStyles.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = {type: format, children: []}
        Transforms.wrapNodes(editor, block)
    }
}
