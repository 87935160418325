import React from 'react'

import {Container, Box, Stack, Grid} from '@mui/material'

import * as UE from 'tsui/UiElements'
import {HomePageDarkAiTheme} from './HomePageDarkAiTheme'

interface DropZoneProps {
    stainTag: string
    layout: UE.UiLayout
    theme: HomePageDarkAiTheme

    onDrop: (files: File[], tag: string) => void
    children?: React.ReactNode
}

class DropZoneState {
    //domsub = new DomSub()
    dragging = false
    dragCounter = 0

    cleanup() {
        //this.domsub.release()
        this.dragging = false
        this.dragCounter = 0
    }
}

export default function HomePageDropZone(props: DropZoneProps) {
    const state = React.useRef(new DropZoneState())
    const st_ = state.current

    const dropRef = React.useRef<HTMLDivElement>(null)

    const onDragEnter = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
            st_.dragging = true
        }

        st_.dragCounter++

        if (st_.dragCounter > 1) return

        if (!dropRef.current) return

        props.theme.dropZoneOverBorder.apply(dropRef)
    }, [])

    const onDragLeave = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        st_.dragCounter--

        if (st_.dragCounter < 0)
            st_.dragCounter = 0

        if (st_.dragCounter > 0) return

        st_.dragging = false

        props.theme.dropZoneBorder.apply(dropRef)
    }, [])



    const onDragOver = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const onDragDrop = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        st_.cleanup()

        if (!e.dataTransfer || e.dataTransfer.items.length === 0) return

        let files: File[] = []

        for (let item of e.dataTransfer.items) {
            let file = item.getAsFile()
            if (file) files.push(file)
        }

        if (files.length === 0) return

        // let item = e.dataTransfer.items[0]
        // let file = item.getAsFile()!
        props.theme.dropZoneBorder.apply(dropRef)

        props.onDrop(files, props.stainTag)
    }, [])



    return (
        <Box
            ref={dropRef}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDragDrop}
            sx={[props.theme.dropZoneBorder.sx(), props.layout.sx()]}
        >
            {props.children}
        </Box>
    )
}
