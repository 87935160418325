import {Link} from 'react-router-dom';

import PageImage from 'tsui/PageImage';

export default function PageBanner() {
    const th = theme_.homePageHeaderTheme;

    return (
        <>
            {th.titleBanner.show && (
                <Link to='/'>
                    <PageImage src={th.titleBanner.src} sx={th.titleBanner.sx()} />
                </Link>
            )}

            {th.titleBannerShort.show && <PageImage src={th.titleBannerShort.src} sx={th.titleBannerShort.sx()} />}
        </>
    );
}
