import React from 'react';

import {InputFormContext} from './FormContext';
import {FormValue, setFormValue} from './FormElements/FormValue';
import {InputFormField} from './FormElements/FormFieldContext';
import {PageDialogTitle} from '../PageDialog';
import {ModalCloseProps} from '../Types/ModalCloseParams';
import {SxObject, SxPropsParam} from 'tsui/Mui/SxPropsUtil';

export type FormSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type FormAlignType = 'left' | 'center' | 'right';

export type AutoCompleteType =
    | 'off'
    | 'username'
    | 'email'
    | 'new-password'
    | 'current-password'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'tel'
    | 'street-address'
    | 'address-line1' | 'address-line2' | 'address-line3'
    | 'address-level1' | 'address-level2' | 'address-level3' | 'address-level4'
    | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name';
    


export interface FormPropsBase {
    form: InputFormContext;
    size?: FormSizeType;
    align?: FormAlignType;
}

export interface FormInputGridProps {
    xs?: number | 'auto' | true; // Grid xs parameter

    xsMax?: boolean;
    xsHalf?: boolean;
    xsThird?: boolean;
    xsThird2?: boolean;
    xsQuarter?: boolean;

    md?: number;
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
}

export interface FieldProps extends FormPropsBase, FormInputGridProps, InputSxProps, DataFieldsProps {
    show?: boolean;
    id?: string;
    
    label?: string;
    tlabel?: string;

    fieldType?: InputFieldType;
    autofocus?: boolean;
    attention?: boolean;
}

export interface FieldParams {
    fieldType: InputFieldType;
    value?: FormValue;
}

export function getFormGridAtts(props: FormInputGridProps, defaultProps?: FormInputGridProps) {
    let xs = defaultProps?.xs;

    if (props.xs) xs = props.xs;
    else if (props.xsMax) xs = 12;
    else if (props.xsHalf) xs = 6;
    else if (props.xsThird) xs = 4;
    else if (props.xsThird2) xs = 8;
    else if (props.xsQuarter) xs = 3;

    return {
        xs: xs,
        md: props.md ?? defaultProps?.md,
    };
}

// xs parameters
export interface InputSxProps {
    m?: number; // margin
    mt?: number; // margin top
    ml?: number; // margin left
    mr?: number;
    mb?: number;
    //xs?: number | 'auto' | true;  // Grid xs parameter

    width?: number;
    height?: number;
}

export function getFormSxParams(props: InputSxProps, defaultProps?: InputSxProps) {
    return {
        m: props.m ?? defaultProps?.m,
        mt: props.mt ?? defaultProps?.mt,
        ml: props.ml ?? defaultProps?.ml,
        mr: props.mr ?? defaultProps?.mr,
        mb: props.mb ?? defaultProps?.mb,
        height: props.height ?? defaultProps?.height,
    };
}

export interface DataFieldsProps {
    required?: boolean;
    validate?: InputValidateType;
    data?: boolean;
    readonly?: boolean;
    displayonly?: boolean;
    value?: FormValue;
    ngHidden?: boolean;
}

export type InputFormDataItem = Record<string, string>;

export class InputFormEvent {
    data: InputFormDataItem = {};
    notData: InputFormDataItem = {};
    fields: Record<string, InputFormField> = {};
}

export type InputFieldType = 'text' | 'checkbox' | 'password' | 'date' | 'time' | 'datetime';

export type InputValidateType = 'off' | 'not-empty' | 'integer' | 'email' | 'password' | 'new-password' | 'tel';

export type FormTypeT = 'input' | 'update' | 'update-fields' | 'display';
export type FormContainerType = 'frame' | 'none';
export type FormElementType = 'form' | 'none';
export type FormLayoutType = 'grid' | 'stack' | 'box' | 'none';

export interface FormProps extends FormPropsBase, ModalCloseProps {
    show?: boolean;

    title?: string | PageDialogTitle;
    ttitle?: string;

    fromComp?: boolean;

    onSubmit?: (evt: InputFormEvent) => void;
    onFieldUpdate?: (field: InputFormField) => void;

    submitLabel?: string;

    // Options
    formContainer?: FormContainerType;
    formElement?: FormElementType;
    formSx?: SxObject;

    layoutElement?: FormLayoutType;

    error?: Error | null;
    children?: React.ReactNode;
}
