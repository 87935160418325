import React from 'react'

import {Dialog, DialogActions, DialogTitle, DialogContent} from '@mui/material'
import {Button, Typography, Divider, Paper} from '@mui/material'

import ErrorIcon from '@mui/icons-material/ErrorOutline'

import {useTranslation} from 'react-i18next'
import ScanViewContainer from './ScanViewContainer'

import DialogCloseButton from 'tsui/Buttons/DialogCloseButton'

export class ScanErrorMessageData {
    title?: string
    message?: string
    description?: string
    stack?: string

    constructor(err: string | Error, title?: string) {
        if (typeof err === 'string') {
            this.setMessage(err)
            this.title = title
        } else if (err instanceof Error) {
            this.setError(err)
        } else {
            this.message = `Unknown error type: ${typeof err}`
        }
    }

    setMessage(text: string) {
        let m = text.split('%')
        this.message = m[0]
        this.description = m[1] ?? undefined
    }

    setError(e: Error) {
        this.title = e.name
        this.message = e.message
        this.stack = e.stack
    }
}

interface ErrorMessageProps {
    error: ScanErrorMessageData | null
    onClose?: () => void
}

export default function ScanErrorMessage(props: ErrorMessageProps) {
    const [t] = useTranslation()

    const handleClose = React.useCallback((_, reason) => {
        if (reason !== 'backdropClick') {
            props.onClose && props.onClose()
        }
    }, [])

    if (!props.error) return <></>

    return (
        <ScanViewContainer>
            <Dialog
                open={true}
                onClose={handleClose}
                PaperComponent={Paper}
                fullWidth={true}
                maxWidth='sm'
                PaperProps={{
                    //color: 'error',
                    sx: {
                        ...theme_.dialogBorder.sx(),
                        borderColor: 'error.main',
                        transform: theme_.dialogMessageTransform,
                    },
                }}
            >
                <DialogTitle id='scan-alert-dialog-title'>
                    <ErrorIcon
                        color='error'
                        sx={{
                            verticalAlign: 'middle',
                        }}
                    />
                    <Typography
                        color='error'
                        display='inline'
                        variant='inherit'
                        sx={{
                            marginLeft: 1,
                            verticalAlign: 'middle',
                        }}
                    >
                        {t(props.error.title ?? 'Error')}
                    </Typography>

                    <DialogCloseButton onClose={props.onClose} />
                </DialogTitle>

                <DialogContent dividers>
                    {/* <DialogContentText  variant='body1' gutterBottom>{props.error.message}</DialogContentText> */}
                    <Typography variant='body1' gutterBottom>
                        {t(props.error.message ?? 'Unknown Error')}
                    </Typography>

                    {props.error.description && (
                        <>
                            <Divider />
                            <Typography variant='body1'>{t(props.error.description)}</Typography>
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' autoFocus onClick={props.onClose}>
                        {t('Dismiss')}
                    </Button>
                </DialogActions>
            </Dialog>
        </ScanViewContainer>
    )
}
