import React from 'react'

import {scanTheme_} from '../ScanTheme/ScanTheme'

import * as Geo from 'tslib/geo'

import CanvasElement from 'tsui/DomElements/CanvasElement'
import ImgElement from 'tsui/DomElements/ImgElement'


import {ScanViewStateInstance} from '../ScanViewStateProvider'
import {ScanViewContext} from '../ScanViewContext'

import {IScanLocation} from '../ScanTypes/ScanLocation'
import {ScanDataSource} from '../ScanDataManager/ScanDataSource'

import ScanViewPanel from '../ScanComponents/ScanViewPanel'
import { onCanvasClick, updateLocationDisplay } from './ThumbnailPanel/ThumbnailCoords'


class ThumbnailState {
    imageSource?: ScanDataSource

    release() {
        this.imageSource = undefined
    }
}

export interface ScanThumbnailImageProps {}


export default function ScanViewThumbnailPanel(props: ScanThumbnailImageProps) {
    const scanCtx = React.useContext(ScanViewStateInstance).state
    const stateRef = React.useRef(new ThumbnailState())

    const canvasRef = React.useRef<HTMLCanvasElement>(null)
    const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null)
    const thImgRef = React.useRef<HTMLImageElement>(null)
    const [imgRect, setImgRect] = React.useState<Geo.Rect>(new Geo.Rect())

    React.useEffect(() => {
        if (!scanTheme_.thumbnailPanel.visible) {
            return
        }

        let st = stateRef.current!

        if (!st.imageSource) {
            st.imageSource = scanCtx.scanInfo.thumbnailDataSource
            scanCtx.dataManager!.fetchScanHtmlImg(st.imageSource!, thImgRef.current!)
        }

        let thRect = scanCtx.gui!.thumbRect

        setImgRect(Geo.makeRect({
            x: scanTheme_.panelPadding + (scanTheme_.thumbnailImageMax - thRect.w)/2,
            y: scanTheme_.panelPadding + (scanTheme_.thumbnailImageMax - thRect.h)/2,
            w: thRect.w,
            h: thRect.h,
        }))

        //console.debug("thumb", scanTheme_.thumbnailImageSize, scanCtx.gui!.thumbRect)

        // Dynamically assign the width and height to canvas
        const canvas = canvasRef.current!
        canvas.width = canvas.height = scanTheme_.thumbnailImageMax

        // Get the canvas context
        let canvasCtx = canvas.getContext('2d')!
        canvasCtxRef.current = canvasCtx
        canvasCtx.lineWidth = scanTheme_.thumbnailCrosshairWidth
        canvasCtx.strokeStyle = scanTheme_.thumbnailCrosshairColor

        updateLocationDisplay(scanCtx, canvas, canvasCtx, scanCtx.getNav().getPos())

        const onPosTrack = (loc: IScanLocation) =>
            updateLocationDisplay(scanCtx, canvas, canvasCtx, loc.pos)
        const onPosChange = (loc: IScanLocation) =>
            updateLocationDisplay(scanCtx, canvas, canvasCtx, loc.pos)

        scanCtx.pubsub.onScanLocationTrack(onPosTrack)
        scanCtx.pubsub.onScanLocation(onPosChange)

        return () => {
            st.release()
            scanCtx.pubsub.offScanLocationTrack(onPosTrack)
            scanCtx.pubsub.offScanLocation(onPosChange)
        }
    }, [scanCtx, thImgRef])

    return (
        <ScanViewPanel panel={scanTheme_.thumbnailPanel}>
            <ImgElement
                ref={thImgRef}
                alt='Thumbnail...'
                sx={{
                    position: 'absolute',
                    left: imgRect.x,
                    bottom: imgRect.y,

                    width: imgRect.w,
                    height: imgRect.h,
                }}
            />
            <CanvasElement
                ref={canvasRef}
                onClick={(e) => onCanvasClick(scanCtx, canvasRef, e)}
                sx={{
                    position: 'absolute',
                    left: scanTheme_.panelPadding,
                    top: scanTheme_.panelPadding,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                }}
            />
        </ScanViewPanel>
    )
}
