import { Typography } from "@mui/material"
import PageContents from "components/PageContents"

export default function NoMatch() {
    return <PageContents>
        <Typography color='#ff0000' textAlign='center' sx={{
            fontSize: 'x-large',
        }}>Invalid Page</Typography>
    </PageContents>
}
