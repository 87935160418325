import React from 'react'

import {Stack} from '@mui/material'

import * as UE from 'tsui/UiElements'

import {scanTheme_} from '../ScanTheme/ScanTheme'
import {ScanViewPanelState} from './ScanViewPanel'

interface Props {
    panel: ScanViewPanelState
    children?: React.ReactNode
}

export default function ScanViewPanelToolbar(props: Props) {
    return (
        <Stack
            direction='row'
            alignItems='center'
            //spacing={1}
            sx={[scanTheme_.panelToolbarBorder.sx(), props.panel.getToolbarLayout().sx(), {}]}
        >
            {props.children}
        </Stack>
    )
}
