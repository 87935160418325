import React from 'react'

import {Stack, Checkbox, Typography} from '@mui/material'

import {scanTheme_} from '../ScanTheme/ScanTheme'


export interface ScanViewPanelToolbarCheckboxProps {
    label?: string
    defaultChecked?: boolean

    onChange: (boolean) => void
}

function ScanViewPanelToolbarCheckbox(
    props: ScanViewPanelToolbarCheckboxProps,
    ref: React.Ref<HTMLButtonElement>
) {
    return (
        <Stack direction='row' alignItems='center'>
            <Typography
                sx={{
                    color: scanTheme_.infoTextColor,
                    fontSize: 'normal',
                }}
            >
                {props.label}:
            </Typography>

            <Checkbox
                ref={ref}
                defaultChecked={props.defaultChecked}
                onChange={(evt) => props.onChange(evt.target.checked)}
                color='default'
                sx={{
                    m: 0,
                    p: 0,


                    // '&.Mui-checked': {
                    //     //color: scanTheme_.themeColor,
                    //     color: scanTheme_.infoTextColor,
                    // },
                    // '& .MuiSvgIcon-root': { 
                    //     fontSize: scanTheme_.panelToolbarHeight - 4,
                    // },
                }}
            />
        </Stack>
    )
}

export default React.forwardRef(ScanViewPanelToolbarCheckbox)
