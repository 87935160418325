import glslVert from '../shaders/scanview_vert.glsl';
import glslFrag from '../shaders/scanview_frag.glsl';

import {WglRenderer} from '../webgl_renderer';
import * as Vertex from '../webgl_vertex';
import {WglShaderProgram} from '../webgl_program';

import {scanTheme_} from '../../../ScanTheme/ScanTheme';

export function compileShader(this: WglRenderer, shader: WebGLShader, source: string) {
    this.gl!.shaderSource(shader, source);
    this.gl!.compileShader(shader);
}

export function checkShaderStatus(this: WglRenderer, shader: WebGLShader, source?: string) {
    let gl = this.gl!;
    let success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
        let log = gl.getShaderInfoLog(shader);
        console.error('Shader Error:', log);
        console.error('Shader Source:', source);

        //console.debug(shader.);
        gl.deleteShader(shader);
        throw new Error(`WebGL::Cannot compile shader: ${log}`);
    }
}

export function requireAttribLoc(this: WglRenderer, glProg: WebGLProgram, name: string) {
    let loc = this.gl!.getAttribLocation(glProg, name);
    if (loc < 0) throw new Error('WebGL::Cannot find attribute: ' + name);
    return loc;
}

export function requireUniformLoc(this: WglRenderer, glProg: WebGLProgram, name: string) {
    let loc = this.gl!.getUniformLocation(glProg, name);
    if (!loc) throw new Error(`WebGL::Cannot find uniform: ${name}`);
    return loc;
}

export function getUniformLoc(this: WglRenderer, glProg: WebGLProgram, name: string): WebGLUniformLocation | null {
    let loc = this.gl!.getUniformLocation(glProg, name);
    if (!loc) {
        // show some message in case
        //throw new Error(`WebGL::Cannot find uniform: ${name}`);
    }
    return loc;
}

function preprocessShaderSource(prog: WglShaderProgram, source: string, defines: Map<string, string>): string {
    let processed = '';

    // For debugging only
    processed += `\n// signature: ${prog.signature}\n\n`;

    for (let [key, val] of defines) {
        processed += `#define ${key} ${val}\n`;
    }

    processed += '\n';
    processed += '#line 0\n';
    processed += '\n';

    processed += source;
    return processed;
}

export function createShader(this: WglRenderer, type: number): WebGLShader {
    let shader = this.gl!.createShader(type);
    if (!shader) throw new Error('WebGL::Cannot create shader.');

    return shader;
}

export function deleteProgram(this: WglRenderer, program?: WglShaderProgram) {
    if (program && program.glProg) {
        let gl = this.gl!;
        if (program.vertShader) {
            gl.deleteShader(program.vertShader);
            program.vertShader = undefined;
        }

        if (program.fragShader) {
            gl.deleteShader(program.fragShader);
            program.vertShader = undefined;
        }

        gl.deleteProgram(program.glProg);
        program.glProg = undefined;
    }

    return undefined;
}

export function glCreateProgram(this: WglRenderer, prog: WglShaderProgram) {
    let gl = this.gl!;
    let glProg = gl.createProgram();
    if (!glProg) throw new Error('WebGL::Cannot create shader program');

    gl.attachShader(glProg, prog.vertShader!);
    gl.attachShader(glProg, prog.fragShader!);

    gl.bindAttribLocation(glProg, 0, 'vtx_pos');
    gl.bindAttribLocation(glProg, 1, 'vtx_col');
    gl.bindAttribLocation(glProg, 2, 'vtx_tex');
    gl.bindAttribLocation(glProg, 3, 'vtx_nrm');

    gl.linkProgram(glProg);

    if (!gl.getProgramParameter(glProg, gl.LINK_STATUS)) {
        this.checkShaderStatus(prog.vertShader!, prog.vertShaderSource);
        this.checkShaderStatus(prog.fragShader!, prog.fragShaderSource);

        //alert(gl.getProgramInfoLog(program));
        let log = gl.getProgramInfoLog(glProg);
        console.error('Shader Error:', log);
        gl.deleteProgram(glProg);
        throw new Error(`WebGL::Cannot link shader: ${log}`);
    }

    prog.glProg = glProg;
}

export function initShaders(this: WglRenderer) {
    const gl = this.gl!;

    //
    // Configure all shader programs
    //

    // Background shader
    {
        let prog = (this.backgroundShaderRef = new WglShaderProgram('background'));
        this.shaderPrograms.push(prog);

        prog.haveElement = true;
        prog.haveCheckers = true;
        prog.modlMtx = this.mtxI;
        prog.viewMtx = this.mtxI;
    }

    // Tile shader
    {
        let prog = (this.tileShaderRef = new WglShaderProgram('tile_tex'));
        this.shaderPrograms.push(prog);

        prog.modlMtx = this.mtxNavModl;
        prog.viewMtx = this.mtxNavView;

        prog.haveTexture = true;
    }

    // Blank/empty tile shader
    {
        let prog = (this.blankShaderRef = new WglShaderProgram('tile_blank'));
        this.shaderPrograms.push(prog);

        prog.modlMtx = this.mtxNavModl;
        prog.viewMtx = this.mtxNavView;

        prog.haveVertexColor = true;
    }

    // Pathogen markers
    {
        let prog = (this.markerShaderRef = new WglShaderProgram('pathogen_marker'));
        this.shaderPrograms.push(prog);

        prog.modlMtx = this.mtxNavModl;
        prog.viewMtx = this.mtxNavView;

        prog.haveVertexColor = true;
        prog.haveCircle = true;
        prog.haveCenter = true;
        prog.haveSize = true;
    }

    // Busy shader
    {
        let prog = (this.busyShaderRef = new WglShaderProgram('busy'));
        this.shaderPrograms.push(prog);

        //prog.modlMtx = this.mtxNavModlInv; // we don't rotate elements
        prog.modlMtx = this.mtxNavModl;
        prog.viewMtx = this.mtxNavView;

        prog.haveNormal = true;
        prog.haveElement = true;
        prog.haveCenter = true;
        prog.haveBusy = true;
        prog.haveProgress = true;
    }

    // Sample shader
    /*
    {
        let prog = this.sampleProgram = new WglProgram("sample");
        this.shaderPrograms.push(prog);
        //this.shaderPrograms[RenderWGL.ShaderIndex.Sample] = prog;

        prog.modlMtx = this.mtxNavModl;
        prog.viewMtx = this.mtxNavView;
    }
    */

    //
    // Now compile all the shaders.
    // Parallelize their compilation
    //
    for (let prog of this.shaderPrograms) {
        let shaderOptions = new Map();

        prog.haveVertexColor && shaderOptions.set('HAVE_VTX_COLOR', '1');

        prog.haveElement && shaderOptions.set('HAVE_ELEMENT', '1');
        prog.haveCenter && shaderOptions.set('HAVE_CENTER', '1');
        prog.haveSize && shaderOptions.set('HAVE_SIZE', '1');

        prog.haveTexture && shaderOptions.set('HAVE_TEXTURE', '1');
        prog.haveProgress && shaderOptions.set('HAVE_PROGRESS', '1');

        prog.haveCheckers && shaderOptions.set('HAVE_CHECKERS', '1');
        prog.haveBusy && shaderOptions.set('HAVE_BUSY', '1');
        prog.haveCircle && shaderOptions.set('HAVE_CIRCLE', '1');

        scanTheme_.styleLens && shaderOptions.set('HAVE_LENS', '1');

        prog.vertShaderSource = preprocessShaderSource(prog, glslVert, shaderOptions);
        prog.fragShaderSource = preprocessShaderSource(prog, glslFrag, shaderOptions);
    }

    for (let prog of this.shaderPrograms) {
        prog.vertShader = this.createShader(gl.VERTEX_SHADER);
        prog.fragShader = this.createShader(gl.FRAGMENT_SHADER);
    }

    for (let prog of this.shaderPrograms) {
        this.compileShader(prog.vertShader!, prog.vertShaderSource!);
        this.compileShader(prog.fragShader!, prog.fragShaderSource!);
    }

    for (let prog of this.shaderPrograms) {
        this.glCreateProgram(prog);
        // Release the actual sourse strings. Save some mem
        prog.vertShaderSource = undefined;
        prog.fragShaderSource = undefined;
    }

    // Initializa all the uniforms, attributes, etx
    for (let prog of this.shaderPrograms) {
        let glprog = prog.glProg!;

        prog.vtxPosAttrib = this.requireAttribLoc(glprog, 'vtx_pos');
        if (prog.haveVertexColor) prog.vtxColAttrib = this.requireAttribLoc(glprog, 'vtx_col');
        if (prog.haveTexture) prog.vtxTexAttrib = this.requireAttribLoc(glprog, 'vtx_tex');
        if (prog.haveNormal || prog.haveCenter) prog.vtxNrmAttrib = this.requireAttribLoc(glprog, 'vtx_nrm');

        if (prog.haveTexture) {
            prog.unTexIndex = this.requireUniformLoc(glprog, 'un_tex_sampler');
        }

        prog.unProjMtx = this.requireUniformLoc(glprog, 'proj_mtx');
        prog.unViewMtx = this.requireUniformLoc(glprog, 'view_mtx');
        prog.unModlMtx = this.requireUniformLoc(glprog, 'modl_mtx');

        prog.unViewport = this.getUniformLoc(glprog, 'un_viewport');

        if (prog.haveProgress) {
            prog.progressUniform = this.getUniformLoc(glprog, 'un_progress');
        }

        if (prog.haveElement) {
            prog.unElSize = this.getUniformLoc(glprog, 'un_el_size');
            prog.unElColor1 = this.getUniformLoc(glprog, 'un_el_color_1');
            prog.unElColor2 = this.getUniformLoc(glprog, 'un_el_color_2');
        }

        if (scanTheme_.styleLens) {
            prog.unLensCenter = this.getUniformLoc(glprog, 'un_lens_center');
            prog.unLensRadius = this.getUniformLoc(glprog, 'un_lens_radius');
            prog.unLensColor = this.getUniformLoc(glprog, 'un_lens_color');
        }
    }

    // Setup attribute pointers
    for (let prog of this.shaderPrograms) {
        gl.useProgram(prog.glProg!);

        let stride = Vertex.VertexSizeBytes;

        // Vertex Position attribute
        let offset = Vertex.PositionOffset * Vertex.FloatBytes;
        gl.vertexAttribPointer(prog.vtxPosAttrib, Vertex.PositionComponents, gl.FLOAT, false, stride, offset);
        gl.enableVertexAttribArray(prog.vtxPosAttrib);

        // Color attribute
        if (prog.haveVertexColor) {
            offset = Vertex.ColorOffset * Vertex.FloatBytes;
            gl.vertexAttribPointer(prog.vtxColAttrib, Vertex.ColorComponents, gl.FLOAT, false, stride, offset);
            gl.enableVertexAttribArray(prog.vtxColAttrib);
        }

        // Texture attribute
        if (prog.haveTexture) {
            offset = Vertex.TextureOffset * Vertex.FloatBytes;
            gl.vertexAttribPointer(prog.vtxTexAttrib, Vertex.TextureComponents, gl.FLOAT, false, stride, offset);
            gl.enableVertexAttribArray(prog.vtxTexAttrib);
        }

        // Normal attributes or various parameters
        if (prog.haveNormal) {
            offset = Vertex.NormalOffset * Vertex.FloatBytes;
            gl.vertexAttribPointer(prog.vtxNrmAttrib, Vertex.NormalComponents, gl.FLOAT, false, stride, offset);
            gl.enableVertexAttribArray(prog.vtxNrmAttrib);
        }
    }
}
