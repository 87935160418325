import React from 'react';

import {Alert} from '@mui/material';

import {FormMessage} from '../FormContext';

interface FormAlertProps {
    message: FormMessage;
}

export default function FormAlert(props: FormAlertProps) {
    return (
        <Alert
            severity={props.message.severity}
            sx={{
                mt: 1,
                mb: 2,
            }}
        >
            {props.message.text}
        </Alert>
    );
}
