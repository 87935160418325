import * as Geo from "tslib/geo";
import { NavControllerContext } from "../NavController";
import { ScanViewRenderer } from "../ScanViewRenderer";



export function assignTouchToPoint(out: Geo.Point, t: Touch) {
    out.set(t.clientX, t.clientY);
}




function setPointRotation(out: Geo.IPoint, p: Geo.IPoint, a: Geo.IAngle) {
    out.x = a.cos * p.x - a.sin * p.y;
    out.y = a.sin * p.x + a.cos * p.y;
}
    
// Called when the user uses mouse to drag the scan on the screen
// We need to adjust the position based on the rotation
export function onPositionDrag(this: ScanViewRenderer, ctx: NavControllerContext, ev: Geo.IPoint) 
{
    let nav = this.nav!;
    let st = this.controlState;

    ctx.setDragCoords(ev, this.surfaceCenter);

    // Calculate dragging relative to the center of the screen
    setPointRotation(st.tempPos1, ctx.evScreenCordAxis, nav.currLoc.rot);
    setPointRotation(st.tempPos2, ctx.lastScreenCordAxis, nav.currLoc.rot);

    // This is the real change
    st.tempDelta.set(st.tempPos2.x - st.tempPos1.x, st.tempPos2.y - st.tempPos1.y)
        .scale(nav.zoomMulToScan);  // Scan coordinates

    nav.offsetPos(st.tempDelta);  // update the position

    ctx.stashPositionLast();

    // Reflect the changes
    this.updateWebGL();
}


// Called when the user dragged the mouse across the screen during rotation
export function navProcessRotationDragged(this: ScanViewRenderer, ctx: NavControllerContext, ev: Geo.IPoint) {
    let nav = this.nav!;
    let st = this.controlState;

    ctx.setDragCoords(ev, this.surfaceCenter);
    ctx.calcPosAngle();

    st.tempTheta.assign(ctx.lastRotAxis);
    st.tempTheta.sub(ctx.evRotAxis);

    nav.rotateView(st.tempTheta);

    ctx.stashRotationLast();

    // Reflect the changes
    this.updateWebGL();
}


