import React from 'react'


import {Stack} from '@mui/material'

export const ReportSectionContainer = (props) => <Stack direction='column' spacing={1} sx={{
    //positoi
    p: 1,
    width: 1,
}}>{props.children}</Stack>
