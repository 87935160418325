import React from 'react'

import {Box, Stack, Typography} from '@mui/material'

import {useTranslation} from 'react-i18next'

import * as UE from 'tsui/UiElements'

import PageImage from 'tsui/PageImage'
import ButtonElement from 'tsui/DomElements/ButtonElement'

interface ButtonProps {
    stainTag: string // The stain and pathogen to be analyzed
    layout: UE.UiLayout

    width?: number
    height?: number
    paperWidth?: number
    fontSize?: number | string

    stain?: string
    pathogen?: string
    label?: string | string[]
    tlabel?: string

    tissueImage?: string

    onClick?: () => void
    onUpload?: (tag: string) => void
}

class ButtonState {
    buttonWidth: number
    buttonHeight: number

    paperWidth: number
    paperHeight: number

    glassHeight: number
    tissueCoverage: number

    labelLayout: UE.UiLayout //({position: 'absolute'})

    constructor(props: ButtonProps) {
        this.buttonWidth = props.layout.getWidth()
        this.buttonHeight = props.layout.getHeight()

        this.paperWidth = this.buttonWidth
        this.paperHeight = this.buttonWidth * 0.8

        this.glassHeight = this.buttonHeight - this.paperHeight
        this.tissueCoverage = this.buttonWidth * 0.8

        const labelPadding = 4

        this.labelLayout = new UE.UiLayout({
            position: 'absolute',
            left: 0,
            top: labelPadding,
            width: this.paperWidth,
            height: this.paperHeight - labelPadding*2,
        })
    }
}

export function ScanUploadButton(props: ButtonProps) {
    const [t] = useTranslation()

    const [labelText, setLabelText] = React.useState<string[]>([])
    const stateRef = React.useRef(new ButtonState(props))

    React.useEffect(() => {
        let st = stateRef.current

        let text: string[] = []

        if (props.label) {
            if (typeof props.label === 'string') text.push(t(props.label))
            else for (let c of props.label) text.push(t(c))
        }

        if (props.tlabel) {
            //let comps = props.tlabel
            text.push(props.tlabel)
        }

        if (props.stain) text.push(props.stain)

        if (props.pathogen) text.push(props.pathogen)

        setLabelText(text)
    }, [])

    const handleClick = React.useCallback(() => {
        props.onUpload && props.onUpload(props.stainTag)
        props.onClick && props.onClick()
    }, [])

    const st_ = stateRef.current

    return (
        <Box sx={props.layout.sx()}>
            <PageImage
                width={st_.buttonWidth}
                height={st_.glassHeight}
                src='/images/upload_button_glass.png'
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: st_.paperHeight,
                }}
            />

            {props.tissueImage && (
                <PageImage
                    width={st_.tissueCoverage}
                    //height={glassHeight}
                    src={props.tissueImage}
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: st_.paperHeight + st_.glassHeight / 2,
                        transform: 'translate(-50%, -50%)',
                        objectFit: 'contain',
                        maxWidth: st_.buttonWidth,
                        maxHeight: st_.glassHeight,
                    }}
                />
            )}

            <PageImage
                width={st_.paperWidth}
                height={st_.paperHeight}
                src='/images/upload_button_paper.png'
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            />

            <ScanLabel text={labelText} layout={st_.labelLayout} />

            <ButtonElement
                onClick={handleClick}
                sx={[
                    theme_.buttonHover.sx(),
                    {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,

                        borderStyle: 'none',
                        backgroundColor: 'transparent',
                        padding: 0,
                        margin: 0,

                        cursor: 'pointer',
                    },
                ]}
            />
        </Box>
    )
}

interface ScanLabelTextProps {
    text: string[]
    layout: UE.UiLayout
}

const ScanLabel = (props: ScanLabelTextProps) => {
    const textLineHeight = props.layout.getHeight() / props.text.length

    return (
        <Stack
            direction='column'
            // alignItems='center'
            sx={[
                props.layout.sx(),
                // {
                //     py: 1,
                // },
            ]}
        >
            {props.text.map((text, index) => (
                // <ScanLabelText key={index} text={text} line={index} height={textLineHeight}/>
                <Typography
                    key={index}
                    flex={10}
                    align='center'
                    sx={{
                        fontSize: 20,
                        lineHeight: 1,
                        fontWeight: 600,
                        color: '#404040',

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
           
                    }}
                >
                    {text}
                </Typography>
            ))}
        </Stack>
    )
}

const ScanLabelText = (props: {text: string; line: number; height: number}) => (
    <Typography
        align='center'
        //sx={[props.layout.sx(), {
        sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            //bottom: 0,
            top: props.line * props.height,
            height: props.height,

            // display: 'block',
            // verticalAlign: 'middle',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            // top: '50%',
            // transform: 'translateY(-50%)',

            // //top: `${props.top * 100}%`,
            // left: 0,
            // right: 0,

            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            color: '#404040',
            //fontFamily: 'Arial',
        }}
    >
        {props.text}
    </Typography>
)

/*
const ScanLabel = (props: ScanLabelTextProps) => {
    const textLineHeight = props.layout.getHeight() / props.text.length

    return (
        <Box
            sx={[
                props.layout.sx(),
                {
                    py: 1,
                },
            ]}
        >
            {props.text.map((text, index) => (
                <ScanLabelText key={index} text={text} line={index} height={textLineHeight}/>
            ))}
        </Box>
    )
}

const ScanLabelText = (props: {text: string, line: number, height: number}) => (
    <Typography
        align='center'
        //sx={[props.layout.sx(), {
        sx={{

            position: 'absolute',
            left: 0,
            right: 0,
            //bottom: 0,
            top: props.line * props.height,
            height: props.height,

            // display: 'block',
            // verticalAlign: 'middle',

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",            

            // top: '50%',
            // transform: 'translateY(-50%)',

            // //top: `${props.top * 100}%`,
            // left: 0,
            // right: 0,

            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            color: '#404040',
            //fontFamily: 'Arial',
        }}
    >
        {props.text}
    </Typography>
)
*/
