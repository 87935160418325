import React from 'react';

import {Box, Drawer, Divider, ListItem, ListItemButton, ListItemText} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as AuthApi from 'api/auth';
import session_ from 'api/session';
import Env from 'env';
import LangSelector from './LangSelector';

interface MenuProps {
    open: boolean;
    //onSelected: (key: string) => void
    onClose: (flag: boolean) => void;
}

export default function PageMainMenu(props: MenuProps) {
    const drawerWidth = Env.isMobile ? 200 : 250;

    return (
        <Drawer open={props.open} anchor='right' onClose={() => props.onClose(false)}>
            <Box sx={{width: drawerWidth}}>
                <PageMenuItems onClose={props.onClose} />
            </Box>
        </Drawer>
    );
}

interface MenuItemsProps {
    onClose: (flag: boolean) => void;
}

function PageMenuItems(props: MenuItemsProps) {
    const navigate = useNavigate();

    const [t] = useTranslation();

    const [showLang, setShowLang] = React.useState(false);
    //const langItemRef = React.useRef(null);
    const langItemRef = React.createRef();

    const th = theme_.homePageHeaderTheme;

    const onClose = React.useCallback(() => {
        setShowLang(false);
        props.onClose(false);
    }, []);

    const onItem = React.useCallback((item: MenuItemProps) => {
        setShowLang(false);

        const key = item.itemKey; //  ?? item.itemId

        switch (key) {
            case 'logout':
                //props.onSelected(key)
                onClose();

                AuthApi.logout().then(() => {
                    navigate('/');
                });
                break;

            case 'lang':
                setShowLang(true);
                break;

            default:
                onClose();
                item.href && navigate(item.href);
                //props.onSelected(key)
                //setShowLang(false);
                break;
        }
    }, []);

    // When the user is not logged in there isn't much to show
    if (!session_.isLoggedIn) {
        return (
            <>
                <PageMenuItem label='Login' onItem={onItem} href='/login' />
                <PageMenuItem label='Tools' onItem={onItem} href='/tools' divider />
                <PageMenuItem itemKey='contact' label='Contact Us' onItem={onItem} divider />
            </>
        );
    }

    return (
        <>
            <PageMenuItem label='Profile' href='/profile' onItem={onItem} />

            {Env.isMobile && (
                <>
                    <PageMenuItem label='Scans' href='/scans' onItem={onItem} divider />

                    {th.langSelector.show && (
                        <PageMenuItem
                            itemId='menuLangId'
                            anchorRef={langItemRef}
                            itemKey='lang'
                            label='Language'
                            onItem={onItem}
                        />
                    )}

                    <LangSelector type='popper' show={showLang} anchorRef={langItemRef} anchorId='menuLangId' />
                </>
            )}

            {/* <PageMenuItem label='Patients' href='/patients' onItem={onItem} /> */}

            {(session_.isAdmin || session_.isDeveloper) && (
                <>
                    {session_.isAdmin && <PageMenuItem label='Admin' href='/admin' onItem={onItem} divider />}
                    {session_.isDeveloper && <PageMenuItem label='Dev' href='/dev' onItem={onItem} />}
                </>
            )}

            <PageMenuItem label='Contact Us' href='/contact' onItem={onItem} divider />

            <PageMenuItem itemKey='logout' label='Logout' onItem={onItem} divider />
        </>
    );
}

interface MenuItemProps {
    show?: boolean;
    itemKey?: string;
    label: string;
    onItem: (item: MenuItemProps) => void;
    href?: string;
    anchorRef?: any;
    itemId?: string;
    divider?: boolean;
}

function PageMenuItem(props: MenuItemProps) {
    const [key] = React.useState(props.itemKey ?? props.label);

    //const navigate = useNavigate()

    const [t] = useTranslation();

    //const [label, setLabel] = React.useState(t(props.label) as string)

    // const onSelected = React.useCallback(() => {
    //     props.onSelected(props)

    //     props.href && navigate(props.href)
    // }, [])

    if (props.show === false) return <></>;

    return (
        <>
            {props.divider && <Divider />}

            <ListItem key={key} id={props.itemId} ref={props.anchorRef} button>
                <ListItemButton onClick={() => props.onItem(props)}>
                    <ListItemText>{t(props.label)}</ListItemText>
                    {/* TODO: Fix new React type error */}
                </ListItemButton>
            </ListItem>
        </>
    );
}

/*
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [showLang, setShowLang] = React.useState(false)
    //const langItemRef = React.useRef(null);
    const langItemRef = React.createRef()

    const onClose = React.useCallback(() => {
        setShowLang(false)
        props.onClose(false)
    }, [])

    const onItemSelected = React.useCallback((item: MenuItemProps) => {
        setShowLang(false)

        const key = item.itemKey //  ?? item.itemId

        switch (key) {
            case 'logout':
                //props.onSelected(key)
                onClose()

                AuthApi.logout().then(() => {
                    navigate('/')
                })
                break

            case 'lang':
                setShowLang(true)
                break

            default:
                
                onClose()
                item.href && navigate(item.href)
                //props.onSelected(key)
                //setShowLang(false);
                break
        }
    }, [])

    const drawerWidth = Env.isMobile ? 200 : 250

    // When the user is not logged in there isn't much to show
    if (!session_.isLoggedIn) {
        return (
            <Drawer open={props.open} anchor='right' onClose={onClose}>
                <Box sx={{width: drawerWidth}}>
                    <PageMenuItem
                        itemKey='login'
                        label='Login'
                        onSelected={onItemSelected}
                        href='/login'
                    />

                    <Divider />

                    <PageMenuItem
                        //itemKey='tools'
                        label='Tools'
                        onSelected={onItemSelected}
                        href='/tools'
                    />

                    <Divider />

                    <PageMenuItem itemKey='contact' label='Contact Us' onSelected={onItemSelected} />
                </Box>
            </Drawer>
        )
    }

                        // {<PageMenuItem label='Contact Us' href='/contact' />

    return (
        <Drawer open={props.open} anchor='right' onClose={onClose}>
            <Box sx={{width: drawerWidth}}>
                <PageMenuItem label='Profile' href='/profile' onSelected={onItemSelected} />

                {Env.isMobile && (
                    <>
                        <Divider />
                        <PageMenuItem label='Scans' href='/scans' onSelected={onItemSelected} />

                        <PageMenuItem
                            itemId='menuLangId'
                            anchorRef={langItemRef}
                            itemKey='lang'
                            label='Language'
                            onSelected={onItemSelected}
                        />
                        <LangSelector type='popper' show={showLang} anchorRef={langItemRef} anchorId='menuLangId' />
                    </>
                )}

                <PageMenuItem label='Patients' href='/patients' onSelected={onItemSelected} />


                {(session_.isAdmin || session_.isDeveloper) && (
                    <>
                        <Divider />
                        {session_.isAdmin && <PageMenuItem label='Admin' href='/admin' onSelected={onItemSelected} />}
                        {session_.isDeveloper && <PageMenuItem label='Dev' href='/dev' onSelected={onItemSelected} />}
                    </>
                )}

                <Divider />
                <PageMenuItem label='Contact Us' href='/contact' onSelected={onItemSelected} />

                <Divider />
                <PageMenuItem itemKey='logout' label='Logout' onSelected={onItemSelected} />
            </Box>
        </Drawer>
    )
    */
