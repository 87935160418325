import React from 'react'

import {Stack} from '@mui/material'
import {Divider} from '@mui/material'

import {parse as parseYaml} from 'yaml'


import * as D from 'data'
import * as AD from 'api/data'
import * as Api from 'api'

import ProgressIndicator from 'tsui/ProgressIndicator'

import ReportMacrosTab from '../ReportMacros/ReportMacrosTab'
import ReportEditTab from '../ReportEditor/ReportEditTab'
import ErrorMessage from 'tsui/ErrorMessage'
import {ReportActionType, ReportEditContext} from '../ReportTypes/ReportAction'

interface Props {
    action: ReportActionType
    scanId?: string
    reportId?: string
    //children: React.ReactNode
}

export default function ReportEditorContainer(props: Props) {
    const [scanId, setScanId] = React.useState(props.scanId)
    const [reportId, setReportId] = React.useState(props.reportId)

    const [report, setReport] = React.useState<D.PatientReport | null>(null)
    const [reportRequested, setReportRequested] = React.useState(false)

    const [scan, setScan] = React.useState<D.Scan | null>(null)
    const [scanRequested, setScanRequested] = React.useState(false)

    const [template, setTemplate] = React.useState<D.PatientReportTemplate | null>(null)
    const [templateRequested, setTemplateRequested] = React.useState(false)

    const [editCtx, setEditCtx] = React.useState<ReportEditContext | null>(null)

    const [error, setError] = React.useState<Error | null>(null)

    React.useEffect(() => {
        if (!scanId && !reportId) {
            return setError(new Error('Scan or report Id is missing'))
        }
    }, [])

    React.useEffect(() => {
        if (!reportId) return
        //if (!reportId

        // if (!reportId) {
        //     if (action
        // }

        if (!report && !reportRequested) {
            setReportRequested(true)

            //console.debug(reportId)

            Api.requestSession<AD.ApiReportData>('report', 'get_report', {reportId: reportId})
                .then((r) => {
                    let report = D.processApiReport(r)

                    //console.debug(report)

                    setReport(report)

                    if (!scanId) setScanId(report.scanId)
                })
                .catch(setError)
        }
    }, [reportId, report, reportRequested, scanId, scan])

    React.useEffect(() => {
        if (!scanId) return

        if (!scan && !scanRequested) {
            setScanRequested(true)
            Api.requestSession<AD.ApiScan>('user', 'fetch_scan', {scanId: scanId})
                .then((r) => {
                    //console.debug(r)
                    let scan = D.handleScanFetch(r)
                    setScan(scan)

                    if (props.action === 'write') {
                        Api.requestSession<AD.ApiReportData>('report', 'begin_report', {scanId: scanId})
                            .then((r) => {
                                let report = D.processApiReport(r)
                                setReport(report)
                                setReportId(report.reportId)
                            })
                            .catch(setError)
                    } else {
                        if (!reportId && scan.reportId) setReportId(scan.reportId)
                    }
                })
                .catch(setError)
            return
        }
    }, [scanId, scanRequested, reportId])

    React.useEffect(() => {
        if (!scan || !report) return

        if (!templateRequested) {
            setTemplateRequested(true)

            Api.request<D.PatientReportTemplate>({
                group: 'report',
                command: 'get_template',
                params: {reportId: report.reportId},
                resType: 'yaml',
            })
                .then((r) => setTemplate(r))
                .catch(setError)
            return
        }
    }, [scan, report])

    React.useEffect(() => {
        if (!scan || !report || !template) return

        //console.debug(template)

        setEditCtx({
            action: props.action,
            report: report,
            scan: scan,
            template: template,
            page: {
                paddingX: 8,
                paddingTop: 8,
                paddingBottom: 8,
                lineHeight: 18,
                fontHeightK: 1.4,
            },
        })
    }, [scan, report, template])

    if (error) return <ErrorMessage error={error} />

    //if (!scan || !report || !template) return <ProgressIndicator />
    if (!editCtx) return <ProgressIndicator />

    return (
        <Stack
            direction='row'
            spacing={0}
            sx={{
                width: 1,
                height: 1,
            }}
        >
            <ReportMacrosTab ctx={editCtx} />
            <Divider orientation='vertical' flexItem color={theme_.pageDataBorder.color} />
            <ReportEditTab ctx={editCtx}/>
        </Stack>
    )
}
