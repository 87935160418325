import {WglRenderBatch} from './webgl_batch'
import {WglShaderProgram} from './webgl_program'

export class RenderQueueWebGL {
    batches: WglRenderBatch[] = []

    clearBatches() {
        this.batches.length = 0
    }

    addBatch(shader: WglShaderProgram): WglRenderBatch {
        let batch = new WglRenderBatch()
        batch.shader = shader
        this.batches.push(batch)
        return batch
    }

    release() {
        this.batches.forEach((batch) => batch.release())
        this.batches.length = 0
    }
}
