import * as AD from "api/data"
import * as Api from 'api'


export interface ScanNote {
    noteId: string
    
    userId: string
    userGreeting: string

    noteDate: Date

    text: string
    previewText: string
}

export function processApiNote(api: AD.ApiNoteData) {
    
    let note: ScanNote = {
        noteId: api.noteId,
        userId: api.userId,
        userGreeting: api.userId,
        noteDate: Api.apiToDate(api.createDate),
        text: api.content,
        previewText: api.content,
    }

    return note
}

export function processApiNotes(api: AD.ApiNoteData[]) {
    let notes: ScanNote[] = []

    for (let an of api) {
        notes.push(processApiNote(an))
    }

    return notes
}
