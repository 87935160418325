import { ZoomLevelValue4 } from "scan/ScanViewContext";
import { ScanViewRenderer } from "../ScanViewRenderer";
import { ScanSceneSetup } from "../ScanScene/ScanScene";
import { ScanSceneLevel } from "../ScanScene/ScanSceneLevel";
import { ScanSceneThumb } from "../ScanScene/ScanSceneThumb";

//
// Builds all scenes to render all scan leves
//
export function renBuildScanScenes(this: ScanViewRenderer)
{
    let nav = this.nav!;

    // let levelScenesSetup: ScanSceneSetup[] = [
    //     {zoom:  0, minZoom: -2, maxZoom: Infinity},
    //     {zoom: -1, minZoom: -3, maxZoom: -2},
    //     {zoom: -2, minZoom: -4, maxZoom: -3},
    //     //{zoom: -2, minZoom: -7, maxZoom: ZoomLevelValue4},
    //     //{zoom: -4, minZoom: -6, maxZoom: ZoomLevelValue4},
    // ]
    let levelScenesSetup: ScanSceneSetup[] = [
        {zoom:  0, minZoom: -1, maxZoom: Infinity},
        {zoom: -1, minZoom: -2, maxZoom: -1},
        {zoom: -2, minZoom: ZoomLevelValue4, maxZoom: -2},
        {zoom: -4, minZoom: -6, maxZoom: ZoomLevelValue4},
    ]

    let thumbSceneSetup: ScanSceneSetup = {
        zoom: nav.homePos.zoom,
        minZoom: Infinity,
        maxZoom: levelScenesSetup[levelScenesSetup.length-1].minZoom
    };


    // First build the main thumbnails scene
    {
        let scene = new ScanSceneThumb(this, thumbSceneSetup);

        scene.build();

        this.thumbSceneRef = scene;  // Remember the reference

        this.scenes.push(scene);
    }

    //
    // Build the zoom level scenes
    //
    for (let setup of levelScenesSetup) {
        let scene = new ScanSceneLevel(this, setup);

        scene.build();
        
        this.scenes.push(scene);
    }
}
