import React from "react";

import { useNavigate } from "react-router-dom";

import * as AuthApi from 'api/auth';

import * as Form from 'tsui/Form';
import PageContents from "components/PageContents";


export default function JoinPage() {
    const form = Form.useInputForm();
    const navigate = useNavigate();

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {
        if (form.error)
            return;

        let password1 = evt.notData['password1'];
        let password2 = evt.notData['password2'];


        const email = evt.data['email']
        const encodeEmail = encodeURIComponent(email)
        const verificationCode = 1234
        const url = `/confirm?email=${encodeEmail}&code=${verificationCode}`

        



        if (password1 !== password2)
            return form.setError('Passwords don\'t match');
        evt.data['password'] = password1;

        //form.clearError(); //setError(null);
        form.setBusy();
        console.log(evt.data)
        AuthApi.join(evt.data).then((sess) => {
            form.clearBusy();
            navigate(url);
        }).catch(err => form.setError(err));
    }, []);

    return <PageContents title='Join'>
        <Form.PageForm title='Join' onSubmit={onSubmit} form={form} size='md' >
            <Form.InputGroup label='Authentication' required form={form} />
            
            <Form.InputText form={form} xs={8} id='email' label='Email' placeholder='Enter Email' autocomplete='email' required validate="email"/>
            {/* <Form.InputText form={form} xs={8} id='login' label='Email' placeholder='Enter Email' autocomplete='email' required validate="email"/> //id='ligin' not working */}
            <Form.FormNote form={form} xs={4} text='(required)' type='required' align='center'/>

            <Form.InputText form={form} xs={8} id='password1' label='Enter Password' placeholder='Enter Password' type='password' autocomplete="new-password" required validate='password' data={false}/>
            <Form.FormNote form={form} xs={4} text='(required)' type='required' align='center'/>

            <Form.InputText form={form} xs={8} id='password2' label='Confirm Password' placeholder='Confirm Password' type='password' autocomplete="new-password" required validate='password' data={false}/>
            <Form.FormNote form={form} xs={4} text='(required)' type='required' align='center'/>


            <Form.InputGroup form={form} label='Name'/>
            {/* <Form.InputText form={form} xs={2} size='sm' id='namePrefix' label='Prefix' placeholder='Prefix' autocomplete='honorific-prefix'  /> */}
            <Form.InputText form={form} xsHalf id='firstName' label='First Name' placeholder='First Name' autocomplete='given-name'/>
            {/* <Form.InputText form={form} xs={5} id='middleName' label='Middle Name' placeholder='Middle Name' autocomplete='additional-name'/> */}

            <Form.InputText form={form} xsHalf id='lastName' label='Last Name' placeholder='Last Name' autocomplete='family-name'/>
            {/* <Form.InputText form={form} xs={2} size='sm' id='nameSuffix' label='Suffix' placeholder='Suffix' autocomplete='honorific-suffix'/> */}

            <Form.InputGroup form={form} label='Contact' optional/>
            <Form.InputText form={form} xs={4} id='phoneAreaCode' label='Area Code' placeholder='Area Code' autocomplete='tel' validate='tel'/>
            <Form.InputText form={form} xs={8} id='phoneNumber' label='Phone' placeholder='Phone' autocomplete='tel' validate='tel'/>

            
            <Form.SubmitButton label='Submit' size='lg' form={form} xsMax align="center" />
            <Form.NavigateLink label='Already have an account? Login' href='/login' form={form} xsMax mt={2} mb={2} align="center" />

        </Form.PageForm>
    </PageContents>

}

