import scanViewConfig_ from '../../ScanViewConfig'
import {ScanDataManager} from '../ScanDataManager'

export function ScanDataManager_processSchedule(this: ScanDataManager) {
    let pendingCount = 0
    let fetchingCount = 0
    let invisibleCount = 0

    pendingCount = this.pendingSources_.length
    fetchingCount = this.fetchingSources_.size

    while (fetchingCount < scanViewConfig_.dsMaxParallelFetch && pendingCount > 0) {
        let ds = this.popFromPendingList_()
        if (!ds) break

        pendingCount = this.pendingSources_.length

        this.fetchTextureBitmap_(ds)

        fetchingCount = this.fetchingSources_.size
    }

    this.pendingCount_ = pendingCount
    this.fetchingCount_ = fetchingCount

    this.debug_stats_ && this.debug_stats_.setText(`pen: ${pendingCount}, fet: ${fetchingCount}`)

    let busyCount = pendingCount + fetchingCount

    if (busyCount !== this.busyCount_) {
        this.busyCount_ = busyCount
        this.onBusy && this.onBusy()
    }
}
