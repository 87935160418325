import * as Geo from 'tslib/geo';
import {Color} from 'tslib/color';

import {DomSub} from 'tsui/Dom/DomLib';
import {ScreenCaps} from 'ui/ScreenCaps';

import scanViewConfig_ from '../ScanViewConfig';
import {ScanDataManager} from '../ScanDataManager/ScanDataManager';
import {ScanDataSource} from '../ScanDataManager/ScanDataSource';
import {ScanViewContext} from '../ScanViewContext';
import {ScanViewPubSub} from '../ScanLib/ScanViewPubSub';
import {WglRenderer} from './WebGLRen/webgl_renderer';

// All the class methods
import {ScanScene} from './ScanScene/ScanScene';
import {RenderTexture} from './RenderTexture';

import * as Methods from './Methods';
import {NavState} from '../ScanTypes/ScanNav';
import {ScanLocation} from '../ScanTypes/ScanLocation';
import {NavControllerContext, NavControllersState} from './NavController';
import {InertiaHandler} from './ScanNavInertia';
import {scanTheme_} from '../ScanTheme/ScanTheme';

export class RenderLevelData {
    tiles = new Map<Geo.Point, ScanDataSource>();
}

export interface EaseToParams {
    duration?: number;
    offset?: Geo.Point;
}

//
// Setup parameters for rendering
export class ScanViewRendererSetup {
    texSize = new Geo.Size(); // Selected texture size
    tileSize = new Geo.Size();
    tilesPerTex = new Geo.Size();
    texPerScene = new Geo.Size(); // Textures used for rendering center of the scene
    tilesPerScene = new Geo.Size();
    tilesPerScan = new Geo.Size(); // How many tiles required to cover the scan
}

export class ScanViewRenderer {
    // Some essential scan information
    scanCtx?: ScanViewContext; // needs to be weak
    skipEmptyTiles = scanViewConfig_.skipEmptyTiles;

    scanId: string;
    scanSize = new Geo.Size();

    pubsub?: ScanViewPubSub; // better weak

    screenCaps = new ScreenCaps();

    // Rendering related stuff
    canvas?: HTMLCanvasElement;
    canvasParent?: HTMLElement;

    zoomLevels = new Map<number, RenderLevelData>();

    webgl?: WglRenderer;
    nav?: NavState;

    // Setup
    setup = new ScanViewRendererSetup();
    //optReuseBitmap = false;

    //
    // Textures management
    //
    textures: RenderTexture[] = [];
    // Texture reference containers for quick access
    texThumbRef?: RenderTexture; // Main thumbnail texture
    texSceneViewRef: RenderTexture[] = []; // Four textures for the central view

    // How much scan area one texture covers
    sceneTexScanCov = new Geo.Size();

    // Various Graphics related stuff
    surfaceSize = new Geo.Size();
    surfaceCenter = new Geo.Point();

    domSub = new DomSub();

    // Scenes corresponding to zoom levels
    scenes: ScanScene[] = []; // All scenes
    thumbSceneRef?: ScanScene; // Main thumbnail scene
    //levelScenes: ScanScene[] = [];  // Container for all the level scenes
    currentSceneRef_?: ScanScene; // Current selected scene

    // Tile rendering related stuff
    emptyTileColor!: Color;
    blankTileColor!: Color;
    errorTileColor!: Color;
    invalidTileColor!: Color;

    controlState = new NavControllersState();

    // debug_ = new Debug({enabled: false, name: 'ScanRenderer'})

    constructor(scanCtx: ScanViewContext) {
        //this.scanCtxRef = new WeakRef(scanCtx);
        this.scanCtx = scanCtx;
        this.pubsub = scanCtx.pubsub;

        this.nav = scanCtx.getNav();
        this.scanId = scanCtx.scanId;
        this.scanSize.assign(scanCtx.scanInfo.scanSize);

        this.webgl = new WglRenderer(scanCtx);
        this.webgl.sceneUpdateCallback = () => this.updateScene();
    }

    // Initialization
    init = Methods.ScanRender_init;
    release = Methods.ScanRender_release;

    // Dealing with HTML canvas element
    createCanvas = Methods.ScanRender_createCanvas;
    attachCanvas = Methods.ScanRender_attachCanvas;

    // Rendering start and stopping
    start() {
        // this.debug_.check('start()')

        this.onWindowResize();
        this.webgl!.start();

        this.updateScene();
    }

    stop() {
        // this.debug_.check('stop()')
        this.webgl!.stop();
    }

    //
    // Everything having to do with scene
    //
    initSceneResources = Methods.ScanRender_initSceneResources;
    buildWglScene = Methods.ScanRender_buildWglScene;

    updateScene = Methods.ScanRender_updateScene;
    //updateSceneTexture = Methods.updateSceneTexture;
    updateTilesBatch = Methods.ScanRender_updateTilesBatch;
    updatePatBatch = Methods.ScanRender_updatePatBatch;
    //positionScene = Methods.positionScene;

    //sceneUpdateRunning_ = false;
    //sceneUpdatePending_ = false;  // More updates were scheduled
    scheduleSceneUpdateOnData(scene?: ScanScene) {
        // If the scene is provided, then check if it's a current scene
        if (scene) {
            scene.dataChanged = true;
            if (scene !== this.currentSceneRef_) return;
        }

        this.currentSceneRef_!.dataChanged = true;
        this.webgl!.scheduleSceneUpdate();
    }

    buildScanScenes = Methods.renBuildScanScenes;

    selectSceneByIndex(index: number) {
        this.selectScene(this.scenes[index]);
    }

    selectScene(scene: ScanScene) {
        this.currentSceneRef_ && this.currentSceneRef_.deactivate();
        this.currentSceneRef_ = scene;
        this.currentSceneRef_.activate();

        //console.debug("select scene", this.currentSceneRef_);

        this.scheduleSceneUpdateOnData();
    }

    //
    // Image source and Data management
    //
    imageDataManager?: ScanDataManager;

    //
    // Image data source management
    //
    // Central thumbnail image source
    thumbnailDataSource?: ScanDataSource;

    releaseTileImageSource = Methods.ScanRender_releaseTileImageSource;
    acquireTileImageSource = Methods.ScanRender_acquireTileImageSource;
    attachTileImageSource = Methods.attachTileImageSource;
    respondEmptyTile = Methods.respondEmptyTile;
    respondBlankTile = Methods.respondBlankTile;
    respondInvalidTile = Methods.respondInvalidTile;
    fetchTileImageSource = Methods.ScanRender_fetchTileImageSource;
    cancelTileImageSourceFetch = Methods.ScanRender_cancelTileImageFetch;

    // Quick
    processSceneImageFetch = Methods.processSceneImageFetch;
    onTileFetch = Methods.ScanRender_onTileFetch;

    //
    // Coordinates
    //

    // Converts screen to scan coordinates
    convertScreenToScanFromTo(from: Geo.IPoint, to: Geo.IPoint) {
        this.webgl!.convertScreenToScanFromTo(from, to);
    }
    screenToScan(p: Geo.IPoint): Geo.Point {
        let out = new Geo.Point();
        this.convertScreenToScanFromTo(p, out);
        return out;
    }

    // Converts scan to screen coordinates
    convertScanToScreenFromTo(from: Geo.IPoint, to: Geo.IPoint) {
        this.webgl!.convertScanToScreenFromTo(from, to);
    }
    scanToScreen(p: Geo.IPoint): Geo.Point {
        let out = new Geo.Point();
        this.convertScanToScreenFromTo(p, out);
        return out;
    }

    convertScanRectVtxToScreenFromTo(from: Geo.RectVtx, to: Geo.RectVtx) {
        this.webgl!.convertScanToScreenFromTo(from.lb, to.lb);
        this.webgl!.convertScanToScreenFromTo(from.lt, to.lt);
        this.webgl!.convertScanToScreenFromTo(from.rt, to.rt);
        this.webgl!.convertScanToScreenFromTo(from.rb, to.rb);
    }

    isScanScreenPointVisible(p: Geo.IPoint): boolean {
        if (scanTheme_.styleLens) {
            let radius2 = scanTheme_.scanLensRadius * scanTheme_.scanLensRadius;
            let dx = p.x - this.surfaceCenter.x;
            let dy = p.y - this.surfaceCenter.y;
            return dx * dx + dy * dy <= radius2;
        }

        return (p.x >= 0 && p.x < this.surfaceSize.w) || (p.y >= 0 && p.y < this.surfaceSize.h);
    }

    isScanScreenRectVisible(rect: Geo.RectVtx): boolean {
        return true;
    }

    //
    // Displaying mathogen markers
    //
    setPathogenMarkersVisible(visible: boolean) {
        this.webgl!.scene.markersBatchRef!.visible = visible;
        this.webgl!.scene.markersBatchRef!.vertices.changed = true;
    }

    //
    // Handling user navigation
    //
    intertiaHandler_ = new InertiaHandler();
    processInertia = Methods.ScanRender_processInertia;

    onMouseDown = Methods.onMouseDown;
    onMouseUp = Methods.onMouseUp;
    onMouseMove = Methods.onMouseMove;
    onMouseLeave = Methods.onMouseLeave;
    onMouseEnter = Methods.onMouseEnter;

    setMouseStyleDefault = Methods.ScanRender_setMouseStyleDefault;
    setMouseStyleDragging = Methods.ScanRender_setMouseStyleDragging;

    onWheel = Methods.onWheel;

    onTouchStart = Methods.ScanRender_onTouchStart;
    onTouchEnd = Methods.ScanRender_onTouchEnd;
    onTouchMove = Methods.ScanRender_onTouchMove;
    onTouchCancel = Methods.ScanRender_onTouchCancel;

    onMouseDragBegin(ctx: NavControllerContext, ev: Geo.IPoint) {
        ctx.setDragCoords(ev, this.surfaceCenter);
        ctx.stashPositionLast();
    }

    onMouseRotationBegin(ctx: NavControllerContext, ev: Geo.IPoint) {
        ctx.setDragCoords(ev, this.surfaceCenter);
        ctx.stashRotationLast();
    }

    onPositionDrag = Methods.onPositionDrag;
    navProcessRotationDragged = Methods.navProcessRotationDragged;

    setLocation = Methods.setLocation;
    moveTo = Methods.moveTo;
    easeTo = Methods.easeTo;

    processEasingFinished = Methods.processEasingFinished;
    cancelEasing = Methods.cancelEasing;

    //locationChangeTimer_: any | undefined = undefined;
    processLocationChanged = Methods.ScanRender_processLocationChanged;

    onWindowResize = Methods.ScanRender_onWindowResize;

    updateWebGL() {
        this.webgl!.setScanLocation(this.nav!.currLoc);
    }

    private lastEmitLoc = new ScanLocation();
    emitLocation() {
        let curr = this.nav!.currLoc;
        let last = this.lastEmitLoc;
        if (!curr.pos.equ(last.pos) || curr.zoom !== last.zoom || curr.rot.angle !== last.rot.angle) {
            this.pubsub?.emitScanLocation(curr);
            last.assign(curr);
        }
    }
    emitLocationTrack() {
        this.pubsub!.emitScanLocationTrack(this.nav!.currLoc);
    }
}
