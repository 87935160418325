import React from 'react'

import {useParams} from 'react-router-dom'

import PageContents from 'components/PageContents'
import ReportEditorContainer from './ReportComponents/ReportEditorContainer'


export default function ReportEditPage() {

    const params = useParams()
    const reportId = params.reportId

    return (
        <PageContents title='Report' current='scans' type='data'>
            <ReportEditorContainer action='edit' reportId={reportId}/>
        </PageContents>
    )
}
