import i18n from 'i18next';

import * as FT from '../FormTypes';

import {FormValue, setFormValue} from '../FormElements/FormValue';

export class InputFormField {
    id?: string;
    name?: string;
    fieldType: FT.InputFieldType;
    isData = true; // if false the item won't be submitted in the form data
    required: boolean;
    validate: FT.InputValidateType;

    origValue: string; // | number | boolean;
    value: string; // | number | boolean;
    valueChanged = false;

    // Runtime data
    error?: Error; // field is in error
    isBusy = false;
    tlabel?: string; // translated label

    constructor(props: FT.FieldProps, params: FT.FieldParams) {

        this.id = props.id; //  ?? ''
        this.fieldType = params.fieldType;

        if (params.value !== undefined) {
            this.value = setFormValue(params.value);
        } else {
            this.value = setFormValue(props.value);
        }

        this.origValue = this.value;

        if (props.label) {
            this.name = props.label;
            this.tlabel = i18n.t(props.label);
        } else if (props.tlabel) {
            this.name = props.tlabel;
            this.tlabel = props.tlabel;
        }

        this.required = props.required === true;
        this.validate = props.validate ?? 'off';
        this.isData = props.data === false ? false : true;
    }

    updateParams(params: FT.FieldParams) {
        if (params.value !== undefined) {
            this.value = setFormValue(params.value);
        }
    }

    setValue(val: FormValue | undefined) {
        this.value = setFormValue(val);
    }
}
