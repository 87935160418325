import React from 'react';

import {GridValueGetterParams, GridRenderCellParams, GridRowId} from '@mui/x-data-grid';

import {Typography} from '@mui/material';

import moment from 'moment';

import * as D from 'data';

import {Scan} from 'data/Scan';

import ScanThumbnail from 'components/ScanThumbnail';
import {ScansTableProps} from './ScansTableProps';
import PageDataTable, {PageDataTableAction, PageDataTableColumn} from 'tsui/PageDataTable';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

type GetRowId = (row: D.Scan) => GridRowId;

export default function ScansTableGrid(props: ScansTableProps) {
    const [columns, setColumns] = React.useState<PageDataTableColumn<Scan>[]>();
    const [actions, setActions] = React.useState<PageDataTableAction<Scan>[]>();
    const [getRowId, setGetRowId] = React.useState<GetRowId>();

    React.useEffect(() => {
        if (props.category === 'uploads') {
            setColumns(uploadsColumns);
            setActions([
                {
                    getDetailsHandler: props.onDetails,
                },
            ]);
            setGetRowId(() => (row: D.Scan) => row.uploadId);

        } else {
            setColumns(scanColumns);
            setActions([
                {
                    getDetailsHandler: props.onDetails,
                },

                {
                    getViewHandler: (scan: Scan) => ({
                        href: D.makeScanViewUrl({scan: scan, fs: false, close: true}),
                        target: D.scanViewTarget,
                    }),
                },
            ]);

            setGetRowId(() => (row: D.Scan) => row.scanId);
        }

        //setColumns(props.category === 'uploads' ? uploadsColumns : scanColumns);
    }, [props.category]);

    if (!columns || !getRowId) return <></>;

    return (
        <PageDataTable<Scan> getRowId={getRowId} columns={columns} rows={props.scans} actions={actions} />
    );

    /*
    if (props.category === 'uploads') {
        return (
            <PageDataTable<Scan>
                getRowId={(row) => row.uploadId}
                columns={columns}
                rows={props.scans}
                actions={[
                    {
                        getDetailsHandler: props.onDetails,
                    },
                ]}
            />
        );
    } else {

        console.debug(props.scans);

        return (
            <PageDataTable<Scan>
                getRowId={(row) => row.scanId}
                columns={columns}
                rows={props.scans}
                actions={[
                    {
                        getDetailsHandler: props.onDetails,
                    },

                    {
                        getViewHandler: (scan: Scan) => ({
                            href: D.makeScanViewUrl({scan: scan, fs: false, close: true}),
                            target: D.scanViewTarget,
                        }),
                    },
                ]}
            />
        );
    }
    */
}

const uploadsColumns: PageDataTableColumn<Scan>[] = [
    {
        field: 'fileName',
        label: 'Name',
        minWidth: 150,
        flex: 1,
    },

    {
        field: 'uploadStatus',
        label: 'Status',
        minWidth: 100,
    },

    {
        field: 'processed',
        label: 'Processed',
        minWidth: 100,
        renderCell: (cell) => <UploadProcessed scanUpload={cell.row} />,
    },

    {field: 'stain', label: 'Stain', width: 200},

    {field: 'pathogen', label: 'Pathogen', width: 200},

    {
        field: 'uploadDate',
        label: 'Upload Date',
        type: 'date',
        minWidth: 200,
    },
];

const scanColumns: PageDataTableColumn<Scan>[] = [
    {
        field: 'scanName',
        label: 'Name',
        minWidth: 150,
        flex: 1,
    },

    {
        field: 'status',
        label: 'Status',
        minWidth: 100,
        renderCell: (cell) => renderResultCell(cell),
    },

    {field: 'stain', label: 'Stain', width: 100},

    {field: 'pathogen', label: 'Pathogen', width: 100},

    {
        field: 'scanDate',
        label: 'Scan Date',
        type: 'date',
        sortable: true,
        initialSort: 'desc',
        //valueGetter: (cell: GridValueGetterParams<Scan>) => cell.row.scanDate,
        // valueGetter: (params) => new Date(params.value),
        // valueFormatter: (params) => moment(params?.value).format('MM/DD/YYYY'),
    },

    {
        type: 'image',
        field: 'thumbnailUrl',
        label: 'Thumbnail',
        width: theme_.tableThumbnailWidth,
        renderCell: (cell) => <ScanThumbnail table={true} scan={cell.row} />,
    },
];

type GridCellType = GridValueGetterParams<Scan>;

const renderResultCell = (cell: GridRenderCellParams) => {
    let si = cell.row.guiStatusInfo!;

    // return <StatusCell color={si.cssColor}>{si.text}</StatusCell>
    return <Typography sx={{color: si.cssColor}}>{si.text}</Typography>;
};

interface UploadProcessedProps {
    scanUpload?: Scan;
}

function UploadProcessed(props: UploadProcessedProps) {
    if (props.scanUpload?.processed === true) {
        return <CheckIcon sx={{color: 'green'}} />;
    } else {
        return <ClearIcon sx={{color: 'red'}} />;
    }
}
