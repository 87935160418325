import React from 'react';

import {Box} from '@mui/material';
import { TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Link } from '@mui/material';
import { Typography } from '@mui/material';


import Env from 'env';

import WipHeader from './WipPageHeader';
import wipScansData from './WipScansData.yaml';
import ImgElement from '../tsui/DomElements/ImgElement';



export default function WipScansPage() {
    const [wipData, setWipData] = React.useState<WipData>();

    React.useEffect(() => {
        //console.log(wipData);
        setWipData(getWipDataYaml(wipScansData));
    }, []);

    if (!wipData)
        return <></>;

    return <>
        <WipHeader />
        <Box sx={{
            //mx: 2,
            //px: Env.isMobile ? 0 : 3,
            //backgroundColor: '#ffffff',
            width: '100%',
            height: '100%',
        }}>
            {renderWipData(wipData)}
        </Box>
    </>;
}

class ScanInfo {
    scanId: string;
    comments?: string;

    constructor(yaml: any) {
        this.scanId = yaml.id;
        this.comments = yaml.comments;
    }
};


class ScanGroup {
    name: string;
    method: string;
    scans: ScanInfo[] = [];

    constructor(yaml: any) {
        this.name = yaml.name;
        this.method = yaml.method;

        for (let spec of yaml.specimens) {
            this.scans.push(new ScanInfo(spec));
        }
    }
};

class WipData {
    groups: ScanGroup[] = [];

    constructor(yaml: any) {
        for (let group of yaml.groups) {
            this.groups.push(new ScanGroup(group));
        }
    }
};


function getWipDataYaml(yaml: any): WipData {
    let data = new WipData(yaml.wip);
    //console.log(data);
    return data;
}


function renderWipData(wip?: WipData) {
    if (!wip)
        return;

    return <>{wip.groups.map((group, index) => renderGroup(group, index))}</>;
}


//const TableHeaderCell = (props) => (<TableCell align='center' sx={{fontSize: 'large'}}>{props.children}</TableCell>);

// const ScansTable = styled(Table)({
//     "& th": {
//         textAlign: 'center',
//         fontSize: 'large',
//     }
// });


function renderGroup(group: ScanGroup, index: number) {
    return <Box key={index}>
        <Typography variant='h2'>{group.name} - {group.method}</Typography>
        <TableContainer component={Paper}>
            <Table sx={{
                textAlign: 'center',
                fontSize: 'large',
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Scan</TableCell>
                        {!Env.isMobile && <TableCell>Full Screen</TableCell>}
                        {!Env.isMobile && <TableCell>Comments</TableCell>}
                        <TableCell sx={{width: 100}}>Image</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {group.scans.map((specimen, index) => renderScan(specimen, index))}
                </TableBody>

            </Table>
        </TableContainer>        
    </Box>;
}


function renderScan(scan: ScanInfo, index: number) {
    let scanUrl = `/scan/${scan.scanId}`;

    return <TableRow key={index}>
        <TableCell sx={{
            verticalAlign: 'center',
            fontFamily: 'monospace',
            fontSize: 'large',
            fontWeight: 'bold',
        }}>
            <Link href={scanUrl}>{scan.scanId}</Link>
        </TableCell>

        {!Env.isMobile && <TableCell><Link href={`${scanUrl}?fullscreen=1`}>Open</Link></TableCell> }
        
        {!Env.isMobile && <TableCell sx={{
            verticalAlign: 'middle',
        }}>{scan.comments}</TableCell>}

        <TableCell sx={{
            maxWidth: 300,
        }}>
            <ImgElement src={''} sx={{
                height: 60,
            }}/>
        </TableCell>
    </TableRow>;
}

