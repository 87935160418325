import React from 'react'

import { HookDeps, makeHookDepsParm } from '../React/ReactUtil'
import {FormContextProps, InputFormContext} from './FormContext'

var currentFormId = 1

export function useUpdateForm() {
    return useForm({type: 'update'})
}

export function useInputForm() {
    return useForm({type: 'input'})
}

export function useForm(props: FormContextProps, deps?: HookDeps) {

    //let errorHook  = React.useState<Error | undefined>()
    
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0)


    let ctx = new InputFormContext(props, {
        forceUpdate: forceUpdate,
    })

    // ctx.forceUpdate = forceUpdate

    // ctx.errorH.setup(React.useState(ctx.error))

    // let [errorHookGet, errorHookSet]  = React.useState(ctx.error)
    
    // ctx.errorHook = errorHookGet
    // ctx.errorHookSet = errorHookSet

    // let [errorHookGet, errorHookSet]  = useGetSet<Error | undefined>(undefined)

    // ctx.errorHookGet = errorHookGet
    // ctx.errorHookSet = errorHookSet


    const formRef = React.useRef(ctx)

    // const formRef = React.useRef<InputFormContext | null>(null)

    // if (!formRef.current) {
    //     formRef.current = new InputFormContext(currentFormId++, props)
    // }
    // const form = formRef.current


    // useEffectOnce(() => {
    //     const form = formRef.current

    //     const onRerender = () => {
    //         //setRenderCount(renderCount + 1);
    //         forceUpdate()
    //     }

    //     form.pubsub.addListener(form.rerenderListenerId, onRerender)

    //     return () => {
    //         form.release()
    //     }
    // })


    React.useEffect(() => {

    }, makeHookDepsParm(deps))

    return formRef.current
}
