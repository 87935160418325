import { ScanDataSource } from "../../ScanDataManager/ScanDataSource";
import { ScanViewRenderer } from "../ScanViewRenderer";
import { SceneTileStatus } from "../ScanScene/SceneTileStatus";

export function ScanRender_onTileFetch(this: ScanViewRenderer, ds: ScanDataSource) 
{
    let currentScene = this.currentSceneRef_;

    if (ds.isReady) {

        //imgSrc.setReceived();

        // TODO: some bug
        if (!ds.bitmap) {
            //imgSrc.setPending();
            //debugger
            return;
        }


        let scene = ds.tileRef?.texRef?.sceneRef;

        //console.debug("On Tile Fetch:", scene);
        //console.debug("Current Scene:", currentScene);

        if (scene === currentScene) {
            
            this.processSceneImageFetch(ds);
        } else {
            //console.debug(`scene don't match:`, ds.tileRef, currentScene?.tileZoom);
            //imgSrc.setReady();

            // Remember the location
            //this.tileImgReadyOrphansByTileLoc_.set(imgSrc.loc.code, imgSrc);

            // Also remember by age to we can be reusing the oldest ones
            //this.tileImgReadyOrphansByAge_.set(imgSrc.age, imgSrc);
        }
    } else {
        //imgSrc.setIdle();
    }


    //Continue with requesting the remaining tiles
    
    /*
    if (currentScene) {
        //debugger
        for (let tile of currentScene.orderedTilesCache_) {
            //console.debug(tile.imgSrc?.state);
            if (tile.imgSrc?.isPending) {
                //console.debug("pending found");
                this.scheduleFetchTileImageSource(tile); 
                //this.fetchTileImageSource(tile); 
                break;
            }
        }
    }
    */
   

}


// Called when the main texture is received and available
export function processSceneImageFetch(this: ScanViewRenderer, ds: ScanDataSource)
{
    //imgSrc.setReady();
    let tile = ds.tileRef;
    let tex = tile?.texRef;

    if (tile && tex) {

        // Check if the current zoom level matches the tile
        let currentScene = this.currentSceneRef_;

        if (currentScene && tile.scanLoc.zoom === currentScene.tileZoom) {

            let glTex = tex.renTexture.wglTexture!;

            if (tile.scanRect.w === this.scanCtx!.scanInfo.scanSize.w) {
                this.webgl!.setTextureImage(glTex, ds.bitmap!);
            } else {
                this.webgl!.setTextureSubImage(glTex, tile.texRect.x, tile.texRect.y, ds.bitmap!);
            }

            //console.debug(tile.scanLoc.zoom, tile.scanLoc.row, tile.scanLoc.col);

            tile.status = SceneTileStatus.Image;

            this.scheduleSceneUpdateOnData(currentScene);
        }
    } 
}       
