import scanViewConfig_ from '../../ScanViewConfig'
import {ScanDataManager} from '../ScanDataManager'
import {ScanDataSource} from '../ScanDataSource'

export function ScanDataManager_release(this: ScanDataManager) {
    // We won't be calling 'busy' anymore
    this.onBusy = undefined

    // Disable the timer immediately
    if (this.timer_) {
        clearInterval(this.timer_)
        this.timer_ = undefined
    }

    for (let idx of this.pendingSources_) {
        let ds = this.dataSourcesPool[idx]
        ds.inPendingList = false
    }
    this.pendingSources_.length = 0

    for (let idx of this.fetchingSources_) {
        let ds = this.dataSourcesPool[idx]
        ds.inFetchingList = false
    }
    this.fetchingSources_.clear()

    // Cancel and release all the data sources
    for (let ds of this.dataSourcesPool) {
        this.releaseDataSource(ds)
        ds.release()
    }
    this.dataSourcesPool.length = 0

    for (let ds of this.dataSourcesPermanent) {
        this.releaseDataSource(ds)
        ds.release()
    }
    this.dataSourcesPermanent.length = 0
}

export function ScanDataManager_releaseDataSource(this: ScanDataManager, ds: ScanDataSource) {
    // If the data source is still requesting something,
    // then just remember the choice
    if (ds.isPending) {
        this.removeFromPendingList_(ds)
    }

    if (ds.isFetching) {
        this.cancelFetch(ds)
        ds.isReleasing = true
        return
    }

    //ds.resetDest();
    ds.onFetch = undefined
    //ds.onError = undefined;

    // Notify the tile object that it has no longer Data Source
    if (ds.tileRef) {
        ds.tileRef.ds = undefined
        ds.tileRef = undefined
    }

    ds.tileInfo = undefined

    // See if we need to stash the ready blob
    if (scanViewConfig_.dsBitmapPoolSize > 0 && ds.isReady) {
        let idx = this.bitmapReadySources_.indexOf(ds.index)
        idx >= 0 && this.bitmapReadySources_.splice(idx, 1)
        this.bitmapReadySources_.push(ds.index)
        //console.debug("Push pending:", ds.tileLoc.code, this.bitmapReadySources_.length, ds.isReady);

        while (this.bitmapReadySources_.length > scanViewConfig_.dsBitmapPoolSize) {
            let idx = this.bitmapReadySources_.shift()!
            let dsRlz = this.dataSourcesPool[idx]

            dsRlz.blob = undefined // Release bitmap memory
            dsRlz.bitmap = undefined
            dsRlz.setAvailable(true)
        }

        if (!scanViewConfig_.dsKeepBitmap) ds.bitmap = undefined

        if (!scanViewConfig_.dsKeepBlob) ds.blob = undefined
    } else {
        // Not using bitmap pool
        //console.debug("Set avail:", ds.tileLoc.code);

        ds.setAvailable()
    }
}
