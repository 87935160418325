import React from 'react'

import {Box, Stack, Paper, Grid, Typography, Button, Link, IconButton} from '@mui/material'
import {TextField, InputAdornment} from '@mui/material'
import CopyIcon from '@mui/icons-material/ContentCopy'

import {useTranslation} from 'react-i18next'

import Env from 'env'

import PageContents from 'components/PageContents'

const paperWidth = Env.isMobile ? undefined : 800
const gridNameXS = Env.isMobile ? 6 : 8
const gridInputXS = Env.isMobile ? 6 : 4
const itemLabelFontSize = Env.isMobile ? 16 : 20
const smallInputWidth = Env.isMobile ? 40 : 100
const mediumInputWidth = Env.isMobile ? 120 : 180
const actionButtonWidth = Env.isMobile ? 120 : 200

export default function RcbcPage() {
    const [t] = useTranslation()

    const [bedAreaXState, setBedAreaXState] = React.useState<InputTextState>({value: ''})
    const bedAreaXRef = React.useRef('')

    const [bedAreaYState, setBedAreaYState] = React.useState<InputTextState>({value: ''})
    const bedAreaYRef = React.useRef('')

    const [cellularityState, setCellularityState] = React.useState<InputTextState>({value: ''})
    const cellularityRef = React.useRef('')

    const [cancerInSituState, setCancerInSituState] = React.useState<InputTextState>({value: ''})
    const cancerInSituRef = React.useRef('')

    const [lnState, setLnState] = React.useState<InputTextState>({value: ''})
    const lnRef = React.useRef('')

    const [metastasisDiaState, setMetastasisDiaState] = React.useState<InputTextState>({value: ''})
    const metastasisDiaRef = React.useRef('')

    const [resultRCB, setResultRCB] = React.useState('')
    const resultRcbRef = React.useRef('')

    const [resultRcbClass, setResultRcbClass] = React.useState('')
    const resultRcbClassRef = React.useRef('')

    const handleValueChange = React.useCallback((value: string, setState: any, ref: any) => {
        ref.current = value

        setState({
            value: value,
            color: value !== '' ? 'success' : undefined,
            focused: value !== '' ? true : undefined,
        })
    }, [])

    const onCalculate = React.useCallback(() => {
        let isError = false

        let dxs = bedAreaXRef.current
        let dx = parseInt(dxs)
        if (dxs === '' || dx <= 0) {
            setBedAreaXState({value: dxs, color: 'error', focused: true})
            isError = true
        }

        let dys = bedAreaYRef.current
        let dy = parseInt(dys)
        if (dys === '' || dy <= 0) {
            setBedAreaYState({value: dys, color: 'error', focused: true})
            isError = true
        }

        let cas = cellularityRef.current
        let ca = parseInt(cellularityRef.current)
        if (cas === '' || ca <= 0 || ca > 100) {
            setCellularityState({value: cas, color: 'error', focused: true})
            isError = true
        }

        let ciss = cancerInSituRef.current
        let cis = parseInt(ciss)
        if (ciss === '' || cis <= 0 || cis > 100) {
            setCancerInSituState({value: ciss, color: 'error', focused: true})
            isError = true
        }

        let lns = lnRef.current
        let ln = parseInt(lns)
        if (lns === '' || ln <= 0) {
            setLnState({value: lns, color: 'error', focused: true})
            isError = true
        }

        let dmets = metastasisDiaRef.current
        let dmet = parseInt(dmets)
        if (dmets === '' || dmet <= 0) {
            setMetastasisDiaState({value: dmets, color: 'error', focused: true})
            isError = true
        }

        if (isError) return

        let result = calculateRCB({
            dx: dx,
            dy: dy,
            ca: ca,
            cis: cis,
            ln: ln,
            dmet: dmet,
        })

        let rcbRounded = Math.round(result.rcb * 1000) / 1000
        let rcbRoundedStr = rcbRounded.toString()

        resultRcbRef.current = rcbRoundedStr
        setResultRCB(rcbRoundedStr)

        resultRcbClassRef.current = result.rcbClass
        setResultRcbClass(result.rcbClass)

        //console.log(bedAreaXRef.current)
    }, [])

    const onReset = React.useCallback(() => {
        setBedAreaXState({value: ''})
        bedAreaXRef.current = ''

        setBedAreaYState({value: ''})
        bedAreaYRef.current = ''

        setCellularityState({value: ''})
        cellularityRef.current = ''

        setCancerInSituState({value: ''})
        cancerInSituRef.current = ''

        setLnState({value: ''})
        lnRef.current = ''

        setMetastasisDiaState({value: ''})
        metastasisDiaRef.current = ''
    }, [])

    const onCopyResultRcb = React.useCallback(() => {
        navigator.clipboard.writeText(resultRcbRef.current)
    }, [])

    const onCopyResultRcbClass = React.useCallback(() => {
        navigator.clipboard.writeText(resultRcbClassRef.current)
    }, [])

    return (
        <PageContents title='Tools'>
            <Typography
                //variant='h1'
                align='center'
                color='#eeeeee'
                sx={{
                    my: Env.isMobile ? 0 : 2,
                    fontSize: Env.isMobile ? 22 : 32,
                }}
            >
                {t('rcbc_title')}
            </Typography>
            <Paper
                sx={{
                    width: paperWidth,
                    mx: 'auto',
                    px: Env.isMobile ? 1 : 3,
                    pb: 1,
                }}
            >
                <InputSectionHeader>{t('rcbc_primary_tumor_bed')}</InputSectionHeader>
                <InputSectionGrid>
                    <InputFieldItem label='rcbc_primary_tumor_bed_area'>
                        <ItemInputNumber
                            state={bedAreaXState}
                            onChange={(v) => handleValueChange(v, setBedAreaXState, bedAreaXRef)}
                            //adornment='mm'
                            min={0}
                            minWidth={smallInputWidth}
                        />
                        <Typography sx={{px: 1}}>X</Typography>
                        <ItemInputNumber
                            state={bedAreaYState}
                            onChange={(v) => handleValueChange(v, setBedAreaYState, bedAreaYRef)}
                            //adornment='mm'
                            min={0}
                            minWidth={smallInputWidth}
                        />
                    </InputFieldItem>

                    <InputFieldItem label='rcbc_overall_cancer_cellularity'>
                        <ItemInputNumber
                            state={cellularityState}
                            onChange={(v) =>
                                handleValueChange(v, setCellularityState, cellularityRef)
                            }
                            adornment='%'
                            min={0}
                            max={100}
                            width={mediumInputWidth}
                        />
                    </InputFieldItem>

                    <InputFieldItem label='rcbc_percent_in_situ'>
                        <ItemInputNumber
                            state={cancerInSituState}
                            onChange={(v) =>
                                handleValueChange(v, setCancerInSituState, cancerInSituRef)
                            }
                            adornment='%'
                            min={0}
                            max={100}
                            width={mediumInputWidth}
                        />
                    </InputFieldItem>
                </InputSectionGrid>

                <InputSectionHeader>{t('rcbc_lymph_nodes')}</InputSectionHeader>
                <InputSectionGrid>
                    <InputFieldItem label='rcbc_positive_lymph_nodes'>
                        <ItemInputNumber
                            state={lnState}
                            onChange={(v) => handleValueChange(v, setLnState, lnRef)}
                            min={0}
                            width={mediumInputWidth}
                        />
                    </InputFieldItem>

                    <InputFieldItem label='rcbc_largest_met_diameter'>
                        <ItemInputNumber
                            state={metastasisDiaState}
                            onChange={(v) =>
                                handleValueChange(v, setMetastasisDiaState, metastasisDiaRef)
                            }
                            adornment='mm'
                            min={0}
                            width={mediumInputWidth}
                        />
                    </InputFieldItem>
                </InputSectionGrid>

                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                    spacing={4}
                    sx={{
                        my: 2,
                        mx: 'auto',
                        width: 1,
                    }}
                >
                    <Button
                        variant='outlined'
                        onClick={onReset}
                        sx={{
                            minWidth: actionButtonWidth,
                            height: 50,
                            //fontWeight: 'bold',
                            fontSize: 'large',
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={onCalculate}
                        sx={{
                            minWidth: actionButtonWidth,
                            height: 50,
                            fontSize: 'large',
                        }}
                    >
                        Calculate
                    </Button>
                </Stack>

                <InputSectionGrid>
                    <InputFieldLabel>Residual Cancer Burden</InputFieldLabel>
                    <ItemResult value={resultRCB} />
                    <Grid item xs={gridInputXS/2}>
                        <IconButton
                            onClick={onCopyResultRcb}
                            sx={{
                                fontSize: 'small',
                            }}
                        >
                            <CopyIcon />
                            {t('copy_clipboard')}
                        </IconButton>
                    </Grid>

                    <InputFieldLabel>Residual Cancer Burden Class</InputFieldLabel>
                    <ItemResult value={resultRcbClass} />
                    <Grid item xs={gridInputXS/2}>
                        <IconButton
                            onClick={onCopyResultRcbClass}
                            sx={{
                                fontSize: 'small',
                            }}
                        >
                            <CopyIcon />
                            {t('copy_clipboard')}
                        </IconButton>
                    </Grid>
                </InputSectionGrid>
            </Paper>

            <Paper
                sx={{
                    width: paperWidth,
                    mx: Env.isMobile ? undefined : 'auto',
                    px: Env.isMobile ? 1 : 3,
                    py: 1,
                    mt: 2,
                    //backgroundColor: theme_.pageBackgroundColor,
                }}
            >
                <Typography sx={{fontSize: 'small'}}>References: </Typography>
                <Typography sx={{fontSize: 'x-small'}}>
                    W. Fraser Symmans et al: Measurement of Residual Breast Cancer Burden to Predict
                    Survival After Neoadjuvant Chemotherapy, 2007.
                    <Link
                        sx={{ml: 1}}
                        href='https://ascopubs.org/doi/pdf/10.1200/JCO.2007.10.6823'
                        target='_blank'
                    >
                        Journal of Clinical Oncology
                    </Link>
                </Typography>
            </Paper>
        </PageContents>
    )
}

type InputColorType = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

interface InputTextState {
    value?: string
    color?: InputColorType
    focused?: boolean
}

const InputSectionHeader = (props: {children}) => (
    <Typography
        variant='h4'
        align='center'
        sx={{
            //mt: 1,
            //mb: 1,
            pt: 1,
        }}
    >
        {props.children}
    </Typography>
)

const InputSectionGrid = (props: {children}) => (
    <Grid container spacing={1} alignItems='center' sx={{my: 1}}>
        {props.children}
    </Grid>
)

const InputFieldLabel = (props: {children}) => (
    <Grid item xs={gridNameXS} alignContent='right'>
        <Typography
            align='left'
            sx={{
                fontSize: itemLabelFontSize,
            }}
        >
            {props.children}:
        </Typography>
    </Grid>
)

const InputFieldItem = (props: {label: string; children}) => {
    const [t] = useTranslation()

    return (
        <>
            <InputFieldLabel>{t(props.label)}</InputFieldLabel>
            <Grid item xs={gridInputXS} display='flex' alignItems='center'>
                {props.children}
            </Grid>
        </>
    )
}

const ItemInputNumber = (props: {
    value?: string
    onChange: (string) => void
    state?: InputTextState
    adornment?: string
    min?: number
    max?: number
    width?: number
    minWidth?: number
}) => (
    <TextField
        type='number'
        value={props.state?.value}
        onChange={(evt) => props.onChange(evt.target.value)}
        //variant='filled'
        color={props.state?.color}
        focused={props.state?.focused}
        InputProps={{
            endAdornment: props.adornment ? (
                <InputAdornment position='end'>{props.adornment}</InputAdornment>
            ) : undefined,

            inputProps: {min: props.min, max: props.max},
        }}
        sx={{
            width: props.width,
            minWidth: props.minWidth,
        }}
    ></TextField>
)

const ItemResult = (props: {value: string}) => (
    <Grid item xs={gridInputXS/2}>
        <TextField
            value={props.value}
            color='primary'
            InputProps={{
                readOnly: true,
            }}
        ></TextField>
    </Grid>
)

interface RcbParams {
    // Primary tumor bed area
    dx: number
    dy: number

    ca: number // Cancer Cellularity
    cis: number // Cancer in situ

    ln: number // number of lymph nodes
    dmet: number // diameter of the largest metastasis
}

const rcbCutoff1 = 1.36
const rcbCutoff2 = 3.28

function calculateRCB(p: RcbParams) {
    let d_prim = Math.sqrt(p.dx * p.dy)
    let f_inv = (1 - p.cis / 100) * (p.ca / 100)

    let rcb1 = 1.4 * Math.pow(f_inv * d_prim, 0.17)
    let rcb2 = Math.pow(4 * (1 - Math.pow(0.75, p.ln)) * p.dmet, 0.17)
    let rcb = rcb1 + rcb2

    let rcbClass = rcb < rcbCutoff1 ? 'RCB-I' : rcb < rcbCutoff2 ? 'RCB-II' : 'RCB-III'

    return {
        rcb: rcb,
        rcbClass: rcbClass,
    }
}
