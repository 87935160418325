import React from 'react';

import {Box, Container, Typography} from '@mui/material';

import PageImage from 'tsui/PageImage';

import * as UE from 'tsui/UiElements';

import {useTranslateLabel} from 'tsui/I18n/TranslateLabel';
import ButtonElement from 'tsui/DomElements/ButtonElement';

interface ButtonProps {
    width: number;
    height: number;
    paperWidth: number;
    fontSize?: number | string;

    label?: string;
    tlabel?: string;

    layout?: UE.UiLayout;

    //sx?: SxPropsParam

    onClick: () => void;
}

export function DesignNavButton(props: ButtonProps) {
    const [label, t] = useTranslateLabel(props);

    const width = props.width; // ?? theme_.authButtonSize.w;
    const height = props.height; // ?? theme_.authButtonSize.h;
    const paperWidth = props.paperWidth; //height;
    const glassWidth = width - paperWidth;

    // Images behind the botton
    return (
        // <Container sx={makeSxProps(props.sx)}>
        <Container sx={props.layout ? props.layout.sx() : {}}>
            <PageImage
                width={glassWidth}
                height={height}
                src='/images/nav_button_glass.png'
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            />

            <PageImage
                width={paperWidth}
                height={height}
                src='/images/nav_button_paper.png'
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            />

            <Typography
                textAlign='center'
                sx={{
                    position: 'absolute',
                    left: 0,
                    width: glassWidth,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    fontSize: props.fontSize,
                    //fontSize: props.sx.fontSize ?? props.fontSize,
                    color: theme_.navButtonTextColor,
                }}
            >
                {label as string}
                {/* TODO: Fix new React type error */}
            </Typography>

            <ButtonElement
                onClick={props.onClick}
                sx={[
                    theme_.buttonHover.sx(),
                    {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: width,
                        height: height,
                        borderStyle: 'none',
                        backgroundColor: 'transparent',
                        padding: 0,
                        margin: 0,
                        cursor: 'pointer',
                    },
                ]}
            />
        </Container>
    );
}
