import {ScanViewContext} from './ScanViewContext';

export function processKeyDown(scanCtx: ScanViewContext, ev: KeyboardEvent) {
    if (scanCtx.gui?.dialogActive) {
        return;
    }
}

export function processKeyUp(scanCtx: ScanViewContext, ev: KeyboardEvent) {
    if (scanCtx.gui?.dialogActive) return;

    //console.debug(ev);
    switch (ev.key) {
        case '4':
            scanCtx.easeTo({zoom: 0});
            break;
        case '2':
            scanCtx.easeTo({zoom: -1});
            break;
        case '1':
            scanCtx.easeTo({zoom: -2});
            break;
        case 'h':
            scanCtx.easeHome();
            break;
        case ' ':
            scanCtx.gui?.aoiSelectNext && scanCtx.gui.aoiSelectNext(1);
            break;
        default:
            break;
    }
}
