import React from 'react';

import {keyframes} from '@emotion/react';
import {Box, Stack, Typography, SvgIcon} from '@mui/material';

import BrokenImageIcon from '@mui/icons-material/ErrorOutlineRounded';
//import BrokenImageIcon from '@mui/icons-material/BrokenImageRounded'

import {ReactComponent as BusyIcon} from 'images/iconify_loadingflowcw.svg';

import ImgElement from 'tsui/DomElements/ImgElement';

import {scanTheme_} from '../../ScanTheme/ScanTheme';

import {ScanViewContext} from '../../ScanViewContext';

import {ScanRecogAoi} from '../../ScanTypes/ScanViewStateRecog';
import {AoiPanelState} from '../ScanViewAoiPanel';

import {onAoiSelected} from './AoiImageSelect';
import {makeImageId} from './AoiPanelLib';

interface Props {
    scanCtx: ScanViewContext;
    st: AoiPanelState;
    aoi: ScanRecogAoi;
    index: number;
}

export function AoiGalleryImage(props: Props) {
    let index = props.index;
    let aoi = props.aoi;
    let left = scanTheme_.aoiVert ? 0 : scanTheme_.aoiImageSize * index;
    let top = scanTheme_.aoiVert ? scanTheme_.aoiImageSize * index : 0;

    if (aoi.imageDataError) {
        return (
            <ImageWrapper left={left} top={top}>
                <SvgIcon
                    component={BrokenImageIcon}
                    inheritViewBox={true}
                    // src={scanTheme_.tileErrorImageUrl}
                    // alt={`Zone ${index + 1}`}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: 40,
                        height: 40,

                        '& path': {
                            fill: 'red',
                        },
                    }}
                />

                <AoiInfoDisplay index={props.index} />

                <Typography
                    align='center'
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,

                        top: '50%',
                        transform: 'translateY(-50%)',

                        color: 'red',

                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                    }}
                >
                    {aoi.imageDataError.message}
                </Typography>
            </ImageWrapper>
        );
    }

    if (!aoi.imageDataLoaded) {
        return (
            <ImageWrapper left={left} top={top}>
                <SvgIcon
                    component={BusyIcon}
                    inheritViewBox={true}
                    sx={{
                        position: 'absolute',

                        width: '40%',
                        height: '40%',

                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',

                        '& path': {
                            fill: scanTheme_.themeColor,
                        },

                        animation: `${rotatingKeyframes} 2s linear infinite`,
                    }}
                />
                <AoiInfoDisplay index={props.index} />
                <ZoneImageOverlay />
            </ImageWrapper>
        );
    }

    return (
        <ImageWrapper left={left} top={top}>
            <ImgElement
                src={aoi.imageUrl}
                alt={`Zone ${index + 1}`}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: scanTheme_.aoiImageSize,
                    height: scanTheme_.aoiImageSize,
                }}
            />
            <AoiInfoDisplay index={props.index} aoi={aoi} />
            {/* <DetectedCountLabel count={aoi.pathogens.length} /> */}
            <ZoneImageOverlay id={makeImageId(index)} onClick={() => onAoiSelected(props.scanCtx, props.st, index)} />
        </ImageWrapper>
    );
}

const rotatingKeyframes = keyframes({
    from: {transform: 'translate(-50%, -50%) rotate(0deg)'},
    to: {transform: 'translate(-50%, -50%) rotate(360deg)'},
});

const ImageWrapper = (props: {left: number; top: number; children: any}) => (
    <Box
        sx={{
            position: 'absolute',
            left: props.left,
            top: props.top,
            width: scanTheme_.aoiImageSize,
            height: scanTheme_.aoiImageSize,
            alignItem: 'center',
            justifyContent: 'center',
        }}
    >
        {props.children}
    </Box>
);

const ZoneImageOverlay = (props: {id?: string; onClick?: () => void; children?: any}) => (
    <Box
        id={props.id}
        onClick={props.onClick}
        sx={[
            scanTheme_.aoiImageBorder.sx(),
            {
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                //width: scanTheme_.aoiImageSize,
                //height: scanTheme_.aoiImageSize,

                //border: ${cssPanelBorderVal};

                cursor: 'pointer',
                pointerEvents: 'all',
            },
        ]}
    >
        {props.children}
    </Box>
);

function AoiInfoDisplay(props: {index: number; aoi?: ScanRecogAoi}) {
    const offset = scanTheme_.aoiImageBorder.width ?? 0;

    return (
        <Stack
            direction='row'
            alignItems='center'
            sx={[
                //scanTheme_.buttonBackground.sx(),
                {
                    position: 'absolute',
                    left: offset,
                    //top: offset,
                    bottom: offset,
                    right: offset,

                    height: 18,
                    px: 0.5,
                    //backgroundColor: theme_.whiteSmoke,
                    backgroundColor: scanTheme_.backgroundColor,
                },
            ]}
        >
            <Typography
                sx={{
                    //color: theme_.smokyBlack,
                    color: scanTheme_.infoTextColor,
                    fontSize: 12,
                }}
            >
                {props.index + 1}
            </Typography>

            {props.aoi && (
                <>
                    <Box flex={10} />
                    <Typography
                        sx={{
                            //color: theme_.smokyBlack,
                            color: scanTheme_.infoTextColor,
                            fontSize: 12,
                        }}
                    >
                        Found: {props.aoi.pathogens.length}
                    </Typography>
                </>
            )}
        </Stack>
    );
}

/*
const IndexLabel = (props: {index: number}) => (
    <Typography
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: scanTheme_.styleDark ? scanTheme_.backgroundColor : scanTheme_.infoTextColor,
        }}
    >
        {props.index + 1}
    </Typography>
)

const DetectedCountLabel = (props: {count: number}) => (
    <Typography
        sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            color: scanTheme_.styleDark ? scanTheme_.backgroundColor : scanTheme_.infoTextColor,
        }}
    >
        Found: {props.count}
    </Typography>
)
*/

/*
const ErrorLabel = (props: {error: Error}) => (
    <Typography
        align='center'
        sx={{
            position: 'absolute',
            left: 0,
            right: 0,

            top: '50%',
            transform: 'translateY(-50%)',

            color: 'red',

            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
        }}
    >
        {props.error.message}
    </Typography>
)
*/
