import Env from 'env'

import HomePageAiMobile from './HomePageAiMobile'
import HomePageAiDesktop from './HomePageAiDesktop'
import { HomePageProps } from '../HomePageProps'


export default function HomePageAi(props: HomePageProps) {

    if (Env.isMobile) {
        return <HomePageAiMobile {...props}/>
    }

    return <HomePageAiDesktop {...props}/>
}
