import React from 'react'

import {SvgIconProps} from '@mui/material'

import {Link, Button, IconButton} from '@mui/material'

import {useTranslation} from 'react-i18next'

import {makeSxProps} from 'tsui/Mui/SxPropsUtil'

export type ToolbarButtonType = 'normal' | 'important'

export type ToolbarButtonState = 'normal' | 'active' | 'disabled'

export interface ToolbarButtonProps {
    show?: boolean
    state?: ToolbarButtonState
    disabled?: boolean
    active?: boolean

    type?: ToolbarButtonType
    icon?: React.JSXElementConstructor<SvgIconProps>

    label?: string
    tlabel?: string // Translated label

    href?: string // Link style button
    target?: string

    onClick?: () => void
    //onClickTrue?: (b: boolean) => void
}

export default function ToolbarButton(props: ToolbarButtonProps) {
    if (props.show === false) return <></>

    if (props.icon) return <ToolbarIconButton {...props} />

    return <ToolbarLabelButton {...props} />
}

function makeButtonProps(props: ToolbarButtonProps) {

    const isIcon = props.icon ? true : false
    
    let variant: 'text' | 'outlined' | 'contained' = 'outlined'

    if (props.type === 'important') 
        variant = 'contained'

    if (props.active)
        variant = 'contained'

    const height = theme_.pageToolbarHeight
    //const width = 
    

    return {
        variant: variant,
        disabled: props.disabled,

        sx: makeSxProps({
            minWidth: isIcon ? height : undefined,
            width: isIcon ? height : undefined,
            maxWidth: isIcon ? height : undefined,
    
            minHeight: height,
            height: height,
            maxHeight: height,
    
            // width: theme_.tableActionButtonWidth,
            // height: theme_.tableActionButtonHeight,
            // m: 0,
            // p: 0,
            // // fontSize: 'x-large',
            // "& svg": {
            //     fontSize: theme_.tableActionButtonHeight - 20,
            // },
        })
    }
}

function ToolbarIconButton(props: ToolbarButtonProps) {
    const Icon = props.icon!

    // let variant: 'text' | 'outlined' | 'contained' = 'outlined'

    // if (props.active)
    //     variant = 'contained'

    const buttonProps = makeButtonProps(props)

    const width = theme_.pageToolbarHeight
    const height = theme_.pageToolbarHeight

    const sxProps = makeSxProps({
        minWidth: theme_.pageToolbarHeight,
        width: theme_.pageToolbarHeight,
        maxWidth: theme_.pageToolbarHeight,

        minHeight: theme_.pageToolbarHeight,
        height: theme_.pageToolbarHeight,
        maxHeight: theme_.pageToolbarHeight,

        // width: theme_.tableActionButtonWidth,
        // height: theme_.tableActionButtonHeight,
        // m: 0,
        // p: 0,
        // // fontSize: 'x-large',
        // "& svg": {
        //     fontSize: theme_.tableActionButtonHeight - 20,
        // },
    })

    if (props.href) {
        return (
            // <IconButton
            <Button
                {...buttonProps}
                href={props.href}
                target={props.target}
                // sx={sxProps}
            >
                <Icon />
            </Button>
            // </IconButton>
        )
    } else {
        return (
            // <IconButton
            <Button
                {...buttonProps}
                onClick={props.onClick}
                // sx={sxProps}
            >
                <Icon />
            </Button>
            // </IconButton>
            )
    }
}

function ToolbarLabelButton(props: ToolbarButtonProps) {
    const [t] = useTranslation()
    let [tlabel] = React.useState(props.tlabel ?? props.label ? t(props.label!) : 'N/A')

    // let variant: 'text' | 'outlined' | 'contained' = 'outlined'

    // if (props.type === 'important') variant = 'contained'

    const buttonProps = makeButtonProps(props)


    if (props.href) {
        return (
            <Button {...buttonProps} href={props.href} target={props.target}>
                {tlabel}
            </Button>
        )
    } else {
        return (
            <Button {...buttonProps} onClick={props.onClick}>
                {tlabel}
            </Button>
        )
    }
}
