import React from 'react'

import {Typography, SvgIconProps} from '@mui/material'

import {scanTheme_} from '../ScanTheme/ScanTheme'

import Env from 'env'
import ButtonElement from 'tsui/DomElements/ButtonElement'

export interface ScanViewPanelToolbarButtonProps {
    label?: string
    icon?: React.JSXElementConstructor<SvgIconProps>
    children?: React.ReactNode

    onClick: () => void
}

function ScanViewPanelToolbarButton(
    props: ScanViewPanelToolbarButtonProps,
    ref: React.Ref<HTMLButtonElement>
) {
    const Icon = props.icon

    const buttonHeight = scanTheme_.panelToolbarContentHeight
    const buttonWidth = scanTheme_.panelToolbarContentHeight

    const iconSize = buttonHeight * 0.55

    return (
        <ButtonElement
            ref={ref}
            onClick={props.onClick}
            sx={[
                scanTheme_.panelToolbarButtonBackground.sx(),
                scanTheme_.panelToolbarButtonBorder.sx(),
                scanTheme_.buttonHover.sx(),
                {
                    p: 0,
                    m: 0,
    
                    width: buttonWidth,
                    height: buttonHeight,

                    cursor: 'pointer',

                    color: scanTheme_.panelToolbarButtonTextColor,
                    //color: 'red',
                    //backgroundColor: 'red',
                    backgroundColor: scanTheme_.captionButtonBackground.getColor(),
                },
            ]}
        >
            {props.label && <>{props.label}</>}

            {Icon && (
                <Icon
                    sx={{
                        width: iconSize,
                        height: iconSize,
                    }}
                />
            )}
            {props.children}
        </ButtonElement>
    )
}

export default React.forwardRef(ScanViewPanelToolbarButton)
