import React from 'react';

import {Box, Stack} from '@mui/material';
import {Typography, SvgIcon} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MenuRounded';

import {useTranslation} from 'react-i18next';

import Env from 'env';
import {scanTheme_} from '../ScanTheme/ScanTheme';

import {DomSub} from 'tsui/Dom/DomLib';
import * as FullScreen from 'ui/FullScreen';

import {ScanViewStateInstance} from '../ScanViewStateProvider';

import {ScanInfo} from '../ScanTypes/ScanInfo';
import {IScanLocation} from '../ScanTypes/ScanLocation';

import {ScanStatusInfo} from 'data/ScanStatus';
import scanViewConfig_ from '../ScanViewConfig';
import {BusyIndicator} from './WindowCaption/CaptionBusyIndicator';
import FullScreenButton from './WindowCaption/CaptionFullScreenButton';
import CaptionButton from './WindowCaption/CaptionButton';

export class ScanViewCaptionState {
    domSub = new DomSub();

    busyVisible = true;

    scanStatusInfo?: ScanStatusInfo;

    release() {
        this.domSub.release();
    }

    update(scanInfo: ScanInfo) {
        this.scanStatusInfo?.setCode(scanInfo.result.status);
    }
}

interface ScanViewCaptionProps {
    onMenu: () => void;
    onClose: () => void;
}

export default function ScanViewCaption(props: ScanViewCaptionProps) {
    const scanCtx = React.useContext(ScanViewStateInstance).state;
    const stateRef = React.useRef(new ScanViewCaptionState());
    const st = stateRef.current!;

    const [t] = useTranslation();

    const statusLocTextRef = React.useRef<HTMLDivElement>(null);

    const [fullScreenState, setFullScreenState] = React.useState(FullScreen.isFullScreen());

    const [recogText, setRecogText] = React.useState('');

    React.useEffect(() => {
        // Data
        st.scanStatusInfo = new ScanStatusInfo(scanCtx.scanInfo.result.status);

        const onWindowResize = () => {
            //console.debug("Is Full screen:", FullScreen.isFullScreen());
            setFullScreenState(FullScreen.isFullScreen());
        };

        st.domSub.addWindowListener('resize', onWindowResize);

        const updateLocStatus = (loc: IScanLocation) => {
            if (!statusLocTextRef.current) return;

            // Update status panel
            let sz = scanCtx.levelToZoom(loc.zoom);
            sz = Math.floor(sz * 100) / 100;

            let [x, y] = [Math.floor(loc.pos.x), Math.floor(loc.pos.y)];

            statusLocTextRef.current.innerText = `z: ${sz}, px: ${x}, ${y}`;
        };

        const onScanLocation = (loc: IScanLocation) => updateLocStatus(loc);
        const onLocationTrack = (loc: IScanLocation) => updateLocStatus(loc);

        if (scanViewConfig_.showCurrentLocation) {
            scanCtx.pubsub.onScanLocation(onScanLocation);
            scanCtx.pubsub.onScanLocationTrack(onLocationTrack);
        }

        st.update(scanCtx.scanInfo);
        setRecogText(st.scanStatusInfo!.text); // To trigger rerendering

        return () => {
            st.release();

            if (scanViewConfig_.showCurrentLocation) {
                scanCtx.pubsub.offScanLocation(onScanLocation);
                scanCtx.pubsub.offScanLocationTrack(onLocationTrack);
            }
        };
    }, [scanCtx, st]);

    const captionHeight = scanTheme_.windowCaptionHeight;

    return (
        <Stack
            direction='row'
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                height: captionHeight,
            }}
        >
            <CaptionButton icon={MenuIcon} onClick={props.onMenu} />

            <Stack
                direction='column'
                flex={10}
                sx={{
                    mx: 1,
                    height: '100%',
                }}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                    sx={{
                        height: captionHeight / 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'monospace',
                            fontSize: Env.isMobile ? 'medium' : 'large',
                            fontWeight: 'bold',
                            color: scanTheme_.infoTextColor,
                            pt: 0.5,
                        }}
                    >
                        {scanCtx.scanId}
                    </Typography>

                    <Box flex={10} />

                    {scanViewConfig_.showCurrentLocation && (
                        <Typography
                            ref={statusLocTextRef}
                            sx={{
                                fontSize: 'medium',
                                color: scanTheme_.infoTextColor,
                            }}
                        />
                    )}
                </Stack>

                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        height: captionHeight / 2,
                    }}
                >
                    <Typography
                        sx={{
                            color: (st.scanStatusInfo?.cssColor ?? 'grey') as unknown as string,
                            fontSize: 'large',
                            fontWeight: 'bold',
                            pb: 0.5,
                        }}
                    >
                        {t(recogText)}
                    </Typography>

                    <Box flex={10} />

                    {scanViewConfig_.showTileBusy && <BusyIndicator st={st} scanCtx={scanCtx} />}
                </Stack>
            </Stack>

            {FullScreen.enabled() && <FullScreenButton fs={fullScreenState} />}

            <CaptionButton icon={CloseIcon} onClick={props.onClose} backgroundColor={theme_.darkRed} />
        </Stack>
    );
}
