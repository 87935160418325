import React from 'react'

import {Box, Stack} from '@mui/material'
import {Typography, SvgIcon} from '@mui/material'
import {keyframes} from '@emotion/react'

import {ReactComponent as BusyIcon} from 'images/iconify_loadingflowcw.svg'

import {useTranslation} from 'react-i18next'

import {scanTheme_} from '../../ScanTheme/ScanTheme'

import scanViewConfig_ from '../../ScanViewConfig'
import {ScanViewContext} from '../../ScanViewContext'
import { ScanViewCaptionState } from '../ScanViewCaption'


interface BusyIndicatorProps {
    scanCtx: ScanViewContext
    st: ScanViewCaptionState
}

export const BusyIndicator = (props: BusyIndicatorProps) => {
    const [t] = useTranslation()

    const busyBlockRef = React.useRef<HTMLDivElement>(null)
    const busyCountRef = React.useRef<HTMLDivElement>(null)

    const st = props.st
    const scanCtx = props.scanCtx
    const captionHeight = scanTheme_.windowCaptionHeight

    React.useEffect(() => {

        const onBusyStatus = (count: number) => {
            //if (!busyBlockRef.current) return
            let busyBlock = busyBlockRef.current!

            let visible = count === 0 ? false : true

            if (visible !== st.busyVisible) {
                busyBlock.style.visibility = visible ? 'visible' : 'hidden'
                st.busyVisible = visible
            }

            if (!visible) return

            let countEl = busyCountRef.current!
            countEl.innerHTML = count.toString()
        }

        scanCtx.pubsub.onBusyStatus(onBusyStatus)

        return () => {
            scanCtx.pubsub.removeBusyStatus(onBusyStatus)
        }
    }, [])

    return (
        <Stack
            ref={busyBlockRef}
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{
                visibility: 'hidden',
            }}
        >
            <Typography
                sx={{
                    color: scanTheme_.infoTextColor,
                }}
            >
                {t('Loading') + ':'}
            </Typography>

            <Typography
                ref={busyCountRef}
                sx={{
                    color: scanTheme_.infoTextColor,
                }}
            />

            <SvgIcon
                component={BusyIcon}
                inheritViewBox={true}
                sx={{
                    width: captionHeight / 2 - 4,
                    height: captionHeight / 2 - 4,
                    '& path': {
                        fill: scanTheme_.themeColor,
                    },
                    animation: `${busyKeyframes} 2s linear infinite`,
                }}
            />
        </Stack>
    )
}

const busyKeyframes = keyframes({
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
})
