import * as Api from 'api/api';

import {ScanInfo} from '../ScanTypes/ScanInfo';
import {ScanViewContext} from '../ScanViewContext';
import {ScanViewRenderer} from '../ScanRenderer/ScanViewRenderer';
import {ScanViewGuiState} from '../ScanTypes/ScanViewStateGui';
import {AoiContainer} from '../ScanTypes/ScanViewStateRecog';

export class ScanViewDataState<T> {
    loaded = false;
    requested = false;
    data?: T;

    release() {
        this.loaded = this.requested = false;
        this.data = undefined;
    }

    load(d: T) {
        this.loaded = true;
        this.data = d;
    }
}

export function processScanState(scanCtx: ScanViewContext) {
    let api = scanCtx.scanStateDataState.data!.api!;
    scanCtx.scanInfo = new ScanInfo(scanCtx);

    let scanInfo = scanCtx.scanInfo;

    if (scanCtx.aoiInfoDataState.data) {
        let aoiStat = scanCtx.aoiInfoDataState.data;
        scanInfo.result.aoiCount = aoiStat.aoi.data.length;

        const imageMaskUrl = api.aoiImageMask!.url;
        scanCtx.aoi = new AoiContainer(aoiStat.aoi);

        for (let index = 0; index < scanCtx.aoi.aoiData.length; ++index) {
            let aoi = scanCtx.aoi.aoiData[index];

            let ds = scanCtx.dataManager!.addPermanentDataSource();
            aoi.imageDataSource = ds;
            ds.url = Api.fmtNameEndpoint(imageMaskUrl, aoi.imageName);
            ds.id = `aoi_${index}`;
            ds.name = aoi.imageName;
            ds.hash = aoi.imageHash;
            ds.type = aoi.imageType;
            ds.index = index;

            aoi.imageDataUrl = ds.url;
        }
    }

    // Further process thumbnails
    for (let thumb of scanInfo.thumbnailsDataSources) {
        thumb.id = thumb.imageName;
        thumb.name = thumb.imageName;
        thumb.useCache = true;
        thumb.url = Api.makeImageEndpoint(api.thumbnailsMask!, thumb.imageName!);
    }

    // GUI part
    scanCtx.gui = new ScanViewGuiState(scanCtx);

    // Initialize the renderer
    scanCtx.render = new ScanViewRenderer(scanCtx);
}
