import {ScanThemeInstance, scanTheme_} from './ScanTheme';

export function ScanTheme_setupAoi(this: ScanThemeInstance) {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    this.aoiVert = screenWidth > screenHeight;

    // this.aoiSelectNextButton.position = 'relative'
    // this.aoiSelectPrevButton.position = 'relative'
}

export function ScanTheme_updateAoi(this: ScanThemeInstance) {
    if (this.aoiVert) {
        this.aoiImageSize = this.panelMinContentsWidth;

        this.aoiPanel.layout.left = this.panelMargin;
        this.aoiPanel.layout.width = this.panelMinWidth;
        this.aoiPanel.layout.top = this.windowCaptionHeight + this.panelMargin + this.panelSpacing;

        if (this.thumbnailPanel.visible) {
            this.aoiPanel.layout.bottom = this.thumbnailPanel.getTop() + this.panelSpacing;
        } else {
            this.aoiPanel.layout.bottom = this.panelMargin;
        }
    } else {  // AOI horizontal
        this.aoiImageSize = this.panelMinContentsHeight - this.panelToolbarHeight;

        /* // AOI panel on the bottom 
        if (this.thumbnailPanel.visible) {
            this.aoiPanel.layout.left = this.thumbnailPanel.getRight() + this.panelSpacing;
        } else {
            this.aoiPanel.layout.left = this.panelMargin;
        }
        this.aoiPanel.layout.bottom = this.panelMargin;
        this.aoiPanel.layout.height = this.panelMinHeight;

        this.aoiPanel.layout.right = this.styleLens ? this.navPanel.getLeft() + this.panelSpacing : this.panelMargin;
        */

        // AOI panel at the bottom
        // this.aoiPanel.visible = false;
        // this.aoiPanel.layout.left = this.panelMargin;
        // this.aoiPanel.layout.right = this.panelMargin;
        // this.aoiPanel.layout.bottom = this.panelMargin;
        // this.aoiPanel.layout.height = this.panelMinHeight;

        // AOI panel at the bottom, left to the nav
        this.aoiPanel.layout.left = this.panelMargin;
        this.aoiPanel.layout.right = this.navPanel.layout.getWidth() + this.panelMargin + this.panelPadding;
        this.aoiPanel.layout.bottom = this.panelMargin;
        this.aoiPanel.layout.height = this.panelMinHeight;


        // AOI panel above thumbnail and nav
        // this.aoiPanel.layout.left = this.panelMargin;
        // this.aoiPanel.layout.bottom = this.thumbnailPanel.layout.getTop() + this.panelPadding;
        // this.aoiPanel.layout.height = this.panelMinHeight;
        // this.aoiPanel.layout.right = this.panelMargin;

        // // AOI panel on the top of the screen
        // this.aoiPanel.layout.left = this.panelMargin;
        // this.aoiPanel.layout.top = scanTheme_.windowCaptionHeight + this.panelMargin;
        // this.aoiPanel.layout.height = this.panelMinHeight;
        // this.aoiPanel.layout.right = this.panelMargin;
    }
}
