import React from 'react';

import {Dialog, DialogActions, DialogTitle, DialogContent} from '@mui/material';
import {Slide, SlideProps, Divider, Paper} from '@mui/material';
import {Typography, IconButton, Button, Checkbox} from '@mui/material';
import {Box, FormGroup, FormControlLabel} from '@mui/material';
import {Table, TableHead, TableBody, TableContainer, TableRow, TableCell} from '@mui/material';

import {useTranslation} from 'react-i18next';

import * as Preferences from 'tools/LocalPreferences';

import {ScanViewStateDefValue} from '../ScanViewStateProvider';
import scanViewConfig_ from '../ScanViewConfig';

import DialogCloseButton from 'tsui/Buttons/DialogCloseButton';

interface ScanViewModalProps {
    show?: boolean;
    onClose: () => void;
}

const keyboardShortcuts = [
    {key: 'H', desc: 'Navigate home (original position).'},
    {key: '1, 2, 4', desc: 'Zoom to 10, 20, and 40.'},
    {key: 'Space', desc: 'Move to next detected pathogen.'},
];

const mouseCommands = [{key: 'Double Click', desc: 'Zoom In / Zoom Out.'}];

const Transition = React.forwardRef((props: SlideProps, ref) => <Slide direction='down' ref={ref} {...props} />);

export default function ScanViewMenuDialog(props: ScanViewModalProps) {
    const [scanCtx] = React.useState(ScanViewStateDefValue.state);
    const [optSkipEmpty, setOptSkipEmpty] = React.useState(scanCtx.render?.skipEmptyTiles ?? false);

    const [t] = useTranslation();

    React.useEffect(() => {
        setOptSkipEmpty(Preferences.skipEmptyTiles.get());
    }, []);

    const onSkipEmptyButtonClick = React.useCallback((checked) => {
        //let skip = !scanCtx.render!.skipEmptyTiles;
        let skip = checked;
        scanCtx.render!.skipEmptyTiles = skip;
        Preferences.skipEmptyTiles.set(skip);
        setOptSkipEmpty(skip);
        //console.debug("Opt.skipEmptyTiles:", skip);
    }, []);

    const onClose = React.useCallback(() => {
        scanCtx.gui!.dialogActive = false;
        props.onClose();
    }, []);

    if (props.show === false || !scanCtx.render) return <></>;

    scanCtx.gui!.dialogActive = true;
    //let container = document.getElementById(ScanViewContainerId);

    // return <ScanViewContainer>
    return (
        <Dialog
            //open={props.show !== false}
            open={true}
            onClose={props.onClose}
            container={scanCtx.gui?.mainContainer}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth='sm'
            PaperComponent={Paper}
            aria-labelledby='scanview-menu-dialog-title'
        >
            <DialogTitle id='scanview-menu-dialog-title'>
                <Typography display='inline' variant='inherit'>
                    Bug Path Finder
                </Typography>
                &nbsp;
                <Typography display='inline'>({scanViewConfig_.version})</Typography>
                <DialogCloseButton onClose={onClose} />
                {/* <IconButton onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                ><CloseIcon /></IconButton> */}
            </DialogTitle>

            <DialogContent>
                <Divider sx={{m: 0}} />

                {/* <Box sx={{ marginBottom: 2}}> */}
                <FormControlLabel
                    label='Skip Empty Tiles'
                    control={
                        <Checkbox checked={optSkipEmpty} onChange={(ev, checked) => onSkipEmptyButtonClick(checked)} />
                    }
                />
                {/* </Box> */}

                <Divider sx={{marginTop: 2, marginBottom: 2}} />

                <Typography gutterBottom>Keyboard Shortcuts:</Typography>
                {/* <TableContainer component={Paper}> */}
                <Table>
                    <TableHead>
                        {/* <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow> */}
                    </TableHead>
                    <TableBody>
                        {keyboardShortcuts.map((row) => (
                            <TableRow
                                key={row.key}
                                //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                sx={{border: 0}}
                            >
                                <TableCell sx={{width: 100}}>{row.key}</TableCell>
                                <TableCell>{row.desc}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* </TableContainer> */}

                <Divider sx={{marginTop: 2, marginBottom: 2}} />

                <Typography gutterBottom>Mouse Commands:</Typography>
                {/* <TableContainer component={Paper}> */}
                <Table>
                    <TableBody>
                        {mouseCommands.map((row) => (
                            <TableRow key={row.key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell sx={{width: 100}}>{row.key}</TableCell>
                                <TableCell>{row.desc}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* </TableContainer> */}
            </DialogContent>

            <DialogActions>
                <Divider sx={{marginTop: 2}} />
                <Button variant='contained' autoFocus onClick={onClose}>
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
