import React from 'react';

import Env from 'env';

import {ScansTableProps} from './ScansTableProps';
import ScansTableMobile from './ScansTableMobile';
import ScansTableSimple from './ScansTableSimple';
import ScansTableGrid from './ScansTableGrid';

export default function ScansTable(props: ScansTableProps) {
    if (Env.isMobile) {
        return <ScansTableMobile {...props} />;
    }

    //return <ScansTableSimple {...props}/>
    return <ScansTableGrid {...props} />;
}
