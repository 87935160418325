import {WglShaderProgram} from '../webgl_program';
import {WglRenderer} from '../webgl_renderer';

import * as Vertex from '../webgl_vertex';
import {Color} from 'tslib/color';

import {scanTheme_} from '../../../ScanTheme/ScanTheme';

export function renderFrame(this: WglRenderer, time: number) {
    // Check if the scan scene needs to be updated
    if (this.sceneUpdatePending_) {
        this.sceneUpdatePending_ = false;
        this.sceneUpdateCallback!();
    }

    // Check if there is still something to render
    let scene = this.scene;
    let batches = scene.renderQueue.batches;

    let renderPending = scene.changed;
    let verticesChanged = false;
    let rebuildVertices = false;
    let verticesTotal = 0,
        indicesTotal = 0;

    if (this.viewMtxChanged || this.projMtxChanged || this.easing) renderPending = true;

    // One pass to check the states of the batches
    for (let batch of batches) {
        if (!batch.vertices.numVtx || !batch.vertices.numIdx) continue;

        let vtxData = batch.vertices;

        if (batch.animating || batch.elementChanged) renderPending = true;

        if (batch.vertices.changed) {
            batch.vertices.changed = false;
            renderPending = true;
            verticesChanged = true;

            if (batch.renderVtxCount !== vtxData.numVtx || batch.renderIdxCount !== vtxData.numIdx)
                rebuildVertices = true;
        }

        // Compute total vertices and indices in case we have to build them
        verticesTotal += batch.vertices.numVtx;
        indicesTotal += batch.vertices.numIdx;

        // Check the shader status if we have to
        if (!renderPending) {
            let shader = batch.shader!;
            if (shader.projMtxChanged || shader.viewMtxChanged) renderPending = true;
        }
    }

    if (!renderPending) {
        return;
    }

    // Rebuild vertex buffers
    if (verticesChanged) {
        if (rebuildVertices) {
            let vertexOffset = 0;
            let indexOffset = 0;

            if (verticesTotal * Vertex.Components > this.vertexBufferData.length) {
                this.vertexBufferData = new Float32Array(verticesTotal * Vertex.Components);
            }

            if (indicesTotal > this.indexBufferData.length) {
                this.indexBufferData = new Uint16Array(indicesTotal);
            }

            for (let batch of batches) {
                let vtxData = batch.vertices;

                if (vtxData.numVtx === 0 || vtxData.numIdx === 0) continue;

                // Append current batch vertices
                let dstOffset = vertexOffset * Vertex.Components;
                let srcLength = vtxData.numVtx * Vertex.Components;

                this.vertexBufferData.set(vtxData.vtxData.subarray(0, srcLength), dstOffset);

                // Append current batch indices
                // We have to adjust their indices too
                let di = indexOffset;
                for (let i = 0; i < vtxData.numIdx; ++i) this.indexBufferData[di++] = vtxData.idxData[i] + vertexOffset;

                batch.vtxOffset = vertexOffset;
                batch.idxOffset = indexOffset;

                vertexOffset += vtxData.numVtx;
                indexOffset += vtxData.numIdx;

                //this.debugDumpBatchVertices(batch);
            }
        }
    }

    if (this.easing) {
        this.processEasingFrame(window.performance.now());
    }

    let gl = this.gl!;

    // Rebuilding vertices and updating the buffers
    if (verticesChanged) {
        // Upload vertex and index data
        gl.bufferData(gl.ARRAY_BUFFER, this.vertexBufferData, gl.STATIC_DRAW);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indexBufferData, gl.STATIC_DRAW);
    }

    gl.clear(gl.COLOR_BUFFER_BIT);

    let lastShader: WglShaderProgram | undefined = undefined;
    let lastTexIndex = Infinity;

    // Render each batch
    for (let batch of batches) {
        // Just skip empty batches
        if (!batch.vertices.numVtx || !batch.vertices.numIdx || !batch.visible) continue;

        // Select the current shader
        let prog = batch.shader!;
        if (prog !== lastShader) {
            gl.useProgram(prog.glProg!);
            lastShader = prog;
        }

        // First setup projection matrices, viewports, etc.
        if (prog.projMtxChanged) {
            prog.projMtxChanged = false;
            this.setUniformMtx(prog.unProjMtx, this.projMtx);
        }

        if (prog.viewMtxChanged) {
            prog.viewMtxChanged = false;
            this.setUniformMtx(prog.unViewMtx, prog.viewMtx);
            this.setUniformMtx(prog.unModlMtx, prog.modlMtx);
        }

        if (prog.viewportChanged) {
            prog.viewportChanged = false;
            this.setUniformFloat2(prog.unViewport, this.surfaceSize.w, this.surfaceSize.h);

            if (scanTheme_.styleLens) {
                this.setUniformFloat2(prog.unLensCenter, scanTheme_.scanRenderCenter.x, scanTheme_.scanRenderCenter.y);
                this.setUniformFloat(prog.unLensRadius, scanTheme_.scanLensRadius);
                this.setUniformColor(prog.unLensColor, new Color(scanTheme_.scanLensColor));
            }
        }

        //
        // Setting up and selecting the correct texture
        //
        if (prog.haveTexture && batch.texIndex !== lastTexIndex) {
            gl.activeTexture(gl.TEXTURE0 + batch.texIndex);
            gl.bindTexture(gl.TEXTURE_2D, batch.texRef!.glTexture!);
            this.setUniformInt(prog.unTexIndex, batch.texIndex);
            lastTexIndex = batch.texIndex;
        }

        // Update elements if we have to
        if (batch.elementChanged) {
            batch.elementChanged = false;
            this.setUniformFloat(prog.unElSize, batch.elementSize);
            this.setUniformColor(prog.unElColor1, batch.elementColor1);
            this.setUniformColor(prog.unElColor2, batch.elementColor2);
        }

        if (batch.progressEnabled) {
            if (batch.progressStart === 0) {
                batch.progressStart = time;
                batch.progressValue = 0;
            } else {
                let progressTime = time - batch.progressStart;
                if (progressTime > batch.progressDuration) {
                    batch.progressStart = time;
                    batch.progressValue = 0;
                } else {
                    batch.progressValue = progressTime / batch.progressDuration;
                }
            }
            this.setUniformFloat(prog.progressUniform, batch.progressValue);
        }

        let prim = gl.TRIANGLES;
        let offset = batch.idxOffset * 2; // in bytes. 2 - sizeof(UNSIGNED_SHORT)
        gl.drawElements(prim, batch.vertices.numIdx, gl.UNSIGNED_SHORT, offset);

        batch.vertices.changed = false;
    }

    scene.changed = false;
    //scene.verticesChanged = false;

    this.viewMtxChanged = false;
    this.projMtxChanged = false;
}
