import { Color } from "tslib/color";
import * as Geo from "tslib/geo";

export class ScanScenePatMarker {

    pos: Geo.Point;
    radius: number;
    color: Color;

    constructor(pos: Geo.Point, radius: number, color: Color) {
        this.pos = pos;
        this.radius = radius;
        this.color = color;
    }

};

