import * as LS from 'tsfront/preferences';
import {IErrorHandler} from 'tslib/error';

import * as ServerCache from '../ScanData/DataCache';
import {ScanDataManager} from '../ScanDataManager/ScanDataManager';

import {ScanViewContext} from '../ScanViewContext';

async function databaseMaintenance(accountId: string, scanId: string) {
    await ServerCache.cacheInit();

    const mainTimeKey = 'lastCacheMaintenanceTime';
    const mainInterval = 1000 * 60 * 60 * 24; // 24 hours

    let maintPending = false;
    let now = Date.now();

    //let lastMaintTime = (await KeyVal.keyvalGet<number>(mainTimeKey)) || 0
    let lastMaintTime = LS.lsGetInt(mainTimeKey, 0);

    //console.debug("maint time:", lastMaintTime, (now - lastMaintTime)/1000);

    if (!lastMaintTime || now - lastMaintTime >= mainInterval) {
        maintPending = true;
    }

    if (maintPending) {
        // We update the timestamp immediately
        // Because it might take time, to avoid second call
        //await KeyVal.keyvalSet(mainTimeKey, now)
        LS.lsSetValue(mainTimeKey, now);

        // Run the maintenance, could be long process, but we don't care
        ServerCache.cacheMaintenance(accountId, scanId, now);
    }
}

export function ScanViewCtx_init(this: ScanViewContext, scanId: string, onError: IErrorHandler) {
    if (this.initialized || this.error) return;

    //  this.debug_.check('init()')

    this.clearStatusFlags();
    this.scanId = scanId;

    this.dataManager = new ScanDataManager(this.accountId, this.scanId);

    this.addBusySource(this.dataManager);

    try {
        // check the system
        //scanViewInit(this);
        //if (!BrowserCaps.indexedDBSupported())
        //    throw new Error("IndexedDB is not supported by browser");

        databaseMaintenance(this.accountId, scanId);

        this.initialized = true;
    } catch (err) {
        this.raiseError(err as Error, onError);
    }
}

export function ScanViewCtx_release(this: ScanViewContext) {
    //  this.debug_.check('release()')

    this.stop();
    this.clearStatusFlags();

    this.scanId = '';

    this.pubsub.release();

    this.gui?.release();
    this.gui = undefined;

    this.render?.release();
    this.render = undefined;

    this.zoomLevels = undefined;

    this.scanSummaryDataState = undefined;
    this.scanStateDataState.release();
    this.scanInfoDataState.release();
    this.tileInfoDataState.release();
    this.aoiInfoDataState.release();

    this.aoi?.release();
    this.aoi = undefined;

    this.dataManager?.release();
    this.dataManager = undefined;
}

