import { ScanViewRenderer } from '../ScanViewRenderer';

import { WglRenderBatch } from '../WebGLRen/webgl_batch';

import { scanTheme_ } from '../../ScanTheme/ScanTheme';

//
// Builds the WebGL renderer scene
//
export function ScanRender_buildWglScene(this: ScanViewRenderer)
{
    let webgl = this.webgl!;
    let scene = webgl.scene;
    let queue = scene.renderQueue;

    let batch: WglRenderBatch;

    //
    // Background batch
    //
    batch = scene.backgroundBatchRef = queue.addBatch(webgl.backgroundShaderRef!);

    batch.setupElement(scanTheme_.scanBgCheckerSize, scanTheme_.scanBgCheckerColor1, scanTheme_.scanBgCheckerColor2);
    
    batch.vertices.restart();

    batch.vertices.add([
        {x: 0, y: 0},
        {x: 0, y: 0},
        {x: 0, y: 0},
        {x: 0, y: 0},
    ], [0, 1, 2, 0, 2, 3]);


    //
    // Reserve all the textures we will be using
    //
    //webgl.numTextures = this.textures.length;
    //for (let itex = 0; itex < webgl.numTextures; ++itex) {
    for (let tex of this.textures) {
        batch = queue.addBatch(webgl.tileShaderRef!);
        scene.tilesBatchesRef.push(batch);
    }

    // Blank tile batch
    scene.blankBatchRef = queue.addBatch(webgl.blankShaderRef!);

    // Pathogen indicators
    scene.markersBatchRef = queue.addBatch(webgl.markerShaderRef!);


    //
    // Busy indicators batch
    //
    batch = scene.busyBatchRef = queue.addBatch(webgl.busyShaderRef!);

    batch.setupElement(scanTheme_.scanBusyIndicatorSize, scanTheme_.busyIndicatorColor1, scanTheme_.busyIndicatorColor2);

    batch.progressEnabled = true;
    batch.progressValue = 0;
    batch.progressDuration = 1000;
    batch.progressStart = 0;

}
