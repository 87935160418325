import {ScanScene} from './ScanScene'

import * as Geo from 'tslib/geo'

import * as Methods from './ScanSceneLevelMethods'

export class ScanSceneLevel extends ScanScene {
    zMul_ = Infinity // Zoom multiplier
    zDiv_ = Infinity // 1 / zoom multiplyer
    screenLensK_ = 1.3

    // Scan area this scene covers
    //sceneScanRect_ = new Geo.Rect();

    // Size of the scan area covered
    texScanCov_ = new Geo.Size() // Texture coverage of the scan in scan units
    //texScreenCov_ = new Geo.Size();  // Texture screen coverage (pixels);

    tileScanCov_ = new Geo.Size() // Tile scan coverage in scan unit
    //tileScreenCov_ = new Geo.Size();  // Tile screen coverage (pixels);

    // Current scan coverage area
    scanRenderRect = new Geo.Rect() // Current scan are being rendered
    scanBoundsRect = new Geo.Rect() // Current scan bounds

    // Visibility
    scanVisibleRadius = 0
    scanVisibleRect = new Geo.Rect() // The scan area we render. A little larger than visible area
    //scanSceneBounds = new Geo.RectVtx();

    // Navigation bounds (after which the scene layout must change)
    scanNavBounds = new Geo.Rect() // RectVtx();
    scanNavOffset = 0 // How much we allow the user to navigate to the bounds

    // Tile indices
    tilesIdxTotal_ = new Geo.Size() // Total tiles necessary to cover the scan
    tilesIdxMax_ = new Geo.Size()
    tilesIdxRect_ = new Geo.Rect()

    computeTilesLayout = Methods.SceneLevel_computeTilesLayout
    processZoom = Methods.SceneLevel_processZoom
    processLayout = Methods.SceneLevel_processLayout
    processPosition = Methods.SceneLevel_processPosition

    calcNavBounds = Methods.SceneLevel_calcNavBounds
    calcNavAllowance = Methods.SceneLevel_calcNavAllowance
    calcVisibleScanRect = Methods.SceneLevel_calcVisibleScanRect
    isScanRectVisible = Methods.SceneLevel_isScanRectVisible

    calcLocCoverage = Methods.SceneLevel_calcLocCoverage

    // Overrides
    override onBuild = Methods.SceneLevel_OnBuild

    override onActivate = Methods.SceneLevel_OnActivate
    override onDeactivate = Methods.SceneLevel_OnDeactivate

    override onLayoutChange = Methods.SceneLevel_OnLayoutChange

    override onLocation = Methods.SceneLevel_OnLocation
}
