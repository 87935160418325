import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    LinearProgress,
    LinearProgressProps,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import * as Api from 'api';

import * as F from 'tsui/Form';

import * as SD from 'data/ScanStainGlasses';
import {assertObject} from 'tslib/assert';
import {DialogButton} from 'tsui/Buttons/DialogButton';
import ShowWrapper from 'tsui/ShowWrapper';
import * as UP from 'api/scan_upload';
import DialogCaption from '../../../tsui/Form/DialogCaption';

//
// TODO: check if the user has permission to upload scan
// TODO: Change Submit to Upload (button)
// Rearrange, move browse and path to the upload component
// In place of the file path show "Select scan to upload..."
//

const scanStainFieldId = 'scan-stain';
const scanPathogenFieldId = 'scan-pathogen';

interface ScanUploadDialogProps {
    tag?: string | null;
    files?: File[];

    onUpload: (tag: string, files: File[], stain?: string, pathogen?: string) => void;
    onClose: () => void;
}

class DropZoneState {
    dragging = false;
    dragCounter = 0;

    cleanup() {
        this.dragging = false;
        this.dragCounter = 0;
    }
}

const fileNotSelectedText = 'Scan image file is not selected.';
const dropZoneContentsText = 'Drop scan image file Here...';

export default function ScanUploadDialog(props: ScanUploadDialogProps) {
    return <ShowWrapper<ScanUploadDialogProps> show={props.tag} impl={ScanUploadDialogImpl} props={props} />;
}

function ScanUploadDialogImpl(props: ScanUploadDialogProps) {
    const [stain, setStain] = React.useState<string>('');
    const [pathogen, setPathogen] = React.useState<string>('');

    const [dataRequested, setDataRequested] = React.useState(true);
    let [error, setError] = React.useState<Error | null>(null);
    const mounted = React.useRef(false);
    const [scanUploadId, setScanUploadId] = React.useState<string | null>(null);
    const [fileChunkId, setFileChunkId] = React.useState<string | null>(null);
    const [fileOffset, setFileOffset] = React.useState<number | null>(null);
    const [fileLength, setFileLength] = React.useState<number | null>(null);
    const [uploadIntervalTime, setUploadIntervalTime] = React.useState(0);
    const [timeVariant, setTimeVariant] = React.useState<string>('minute');

    // const [scanUpdateId, setScanUpdateId] = React.useState<string>('WnTLK1wZa4CdTNUMtnApBC')

    const [progress, setProgress] = React.useState(0);
    const [linearProgress, setLinearProgess] = React.useState(false);

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = React.useState(fileNotSelectedText);
    const filesRef = React.useRef<File[]>([]);

    const dropRef = React.useRef<HTMLDivElement>(null);
    const [dropZoneText, setDropZoneText] = React.useState<string>(dropZoneContentsText);

    const dropzoneState = React.useRef(new DropZoneState());
    const st_ = dropzoneState.current;

    const form = F.useForm({
        type: 'input',
        //layoutElement: 'none',
    });

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            if (scanUploadId) {
                let counter = 0;
                let files = filesRef.current;
                let file = files[0];
                if (fileOffset !== null && fileChunkId !== null && fileLength) {
                    const blobChunk = file.slice(fileOffset, fileLength);
                    let startDate = new Date();

                    Api.requestSessionImage<any>(
                        'scan',
                        'upload_chunk_start',
                        {
                            chunkId: fileChunkId,
                            uploadId: scanUploadId,
                        },
                        blobChunk
                    )
                        .then((d) => {
                            if (mounted.current) {
                                if (d.chunksArray) {
                                    if (d.chunksArray.length <= 1) {
                                        setProgress(100);
                                        setLinearProgess(true);
                                        counter++;
                                    } else {
                                        for (let chunk of d.chunksArray) {
                                            if (chunk.isFinished === false) {
                                                let endDate = new Date();
                                                let seconds = (endDate.getTime() - startDate.getTime()) / 1000;

                                                let num =
                                                    (d.chunksArray.length * seconds) / 60 - (counter * seconds) / 60;
                                                if (num < 1) {
                                                    num = d.chunksArray.length * seconds - counter * seconds;
                                                    setTimeVariant('second');
                                                } else {
                                                    setTimeVariant('minute');
                                                }
                                                setUploadIntervalTime(Math.round(num * 100) / 100);

                                                setFileOffset(chunk.offset);
                                                setFileLength(chunk.offset + chunk.length);

                                                setFileChunkId(chunk.chunkId);
                                                let prog = (chunk.offset / file.size) * 100;
                                                if (prog <= 100) {
                                                    setProgress(prog);
                                                    setLinearProgess(true);
                                                }
                                                setDataRequested(false);
                                                break;
                                            } else {
                                                counter++;
                                            }
                                        }
                                    }

                                    if (counter >= d.chunksArray.length) {
                                        Api.requestSession<any>('scan', 'upload_chunk_end', {
                                            uploadId: scanUploadId,
                                        })
                                            .then((d) => {
                                                if (d.processStatus === 1) {
                                                    setProgress(100);
                                                    setTimeout(() => {
                                                        setLinearProgess(false);
                                                    }, 1000);
                                                } else {
                                                    alert('Invalid File');
                                                }
                                            })
                                            .catch((err) => alert(err));
                                    }
                                }
                            }
                        })
                        .catch((err) => alert(err));
                }
            }

            setDataRequested(true);
            return;
        }
        return () => {
            mounted.current = false;
        };
    }, [dataRequested]);

    React.useEffect(() => {
        let tag = props.tag!;
        if (tag === SD.scanGenericGlass_.tag) {
            //setStain(
        } else {
            let scan = assertObject(SD.findScanInfoByTag(tag), 'Internal Error');

            setStain(scan.stain);
            setPathogen(scan.pathogen);
        }

        if (props.files) {
            filesRef.current = props.files;
            processSelectedFiles();
        }
    }, []);

    const processSelectedFiles = React.useCallback(() => {
        let files = filesRef.current;

        if (files.length === 0) {
            setFileName(fileNotSelectedText);
            setDropZoneText(dropZoneContentsText);
            return;
        }

        setDropZoneText(`You selected ${files.length} file(s)`);

        if (files.length === 1) {
            setFileName(files[0].name);
            return;
        }

        let fileNames: string[] = [];

        files.forEach((f) => fileNames.push(f.name));

        setFileName(fileNames.join(', '));
    }, []);

    const onSelectedFileChange = React.useCallback((e) => {
        const files = e.target.files;
        filesRef.current = files;
        processSelectedFiles();
    }, []);

    const onBrowseClick = () => {
        fileInputRef.current!.click();
    };

    const onUpload = React.useCallback((evt: F.InputFormEvent) => {
        //event.preventDefault()

        let files = filesRef.current;
        let file = files[0];
        let stain = evt.data[scanStainFieldId];
        let pathogen = evt.data[scanPathogenFieldId];

        if (files.length === 0) {
            alert('Please select file(s) to upload');
            return;
        }

        async function digestMessage(file: File) {
            // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
            const fileUpl = await file.arrayBuffer();
            const hashBuffer = await crypto.subtle.digest('SHA-256', fileUpl); // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
            return hashHex;
        }

        digestMessage(file)
            .then((digestHex) => {
                // let chunkSize = 6 * 1024 * 1024
                // let chunkSize = 36 * 1024 * 1024
                // let chunkSize = 10 * 1024 * 1024

                // if (file.size <= chunkSize) {
                //     // singleFileUpload(ctx)
                // } else {
                //     // multipartFileUpload(ctx)
                if (digestHex !== null) {
                    Api.requestSession<any>('scan', 'upload', {
                        filename: file.name,
                        stain: stain,
                        pathogen: pathogen,
                        fileSize: file.size.toString(),
                        fileDigestHex: digestHex,
                    })
                        .then((d) => {
                            // let newObject = {
                            //     'lastModified': file.lastModified,
                            //     // 'lastModifiedDate' : file.lastModifiedDate,
                            //     'name': file.name,
                            //     'size': file.size,
                            //     'type': file.type
                            // };

                            // localStorage.setItem(d.uploadId, JSON.stringify(newObject));

                            setScanUploadId(d.uploadId);
                            setFileOffset(d.offset);
                            setFileLength(d.length);
                            setFileChunkId(d.chunkId);
                            setDataRequested(false);
                        })
                        .catch((err) => alert(err));
                }
            })
            .catch((err) => alert(err));
        // props.onUpload(props.tag!, files, stain, pathogen) //TODO COMENT OUT onUpload AWS S3 process
        //simpleFileUpload({file: file})
        //console.log(file)
    }, []);

    // let uploadCtx = UP.scanUploadStart({
    //     stainTag: stainTag,
    //     files: params.files,
    //     stain: params.stain,
    //     pathogen: params.pathogen,
    // },
    //     handleUploadStatus
    // )

    // const handleUploadStatus = React.useCallback((status: UP.ScanUploadStatus) => {
    //     //console.debug(status)

    //     switch (status.reason) {
    //         case 'started':
    //             break

    //         case 'progress':
    //             setProgress(status.progressCurrent! * 100)
    //             break

    //         case 'error':
    //             setUploadError(status.error!) // TODO: properly display
    //             break

    //         case 'finished':
    //             setUploadFinished(true)
    //             break
    //     }
    // }, [])

    // React.useEffect(() => {
    //     //uploadFiles(props.params.files)

    //     let uploadCtx = UP.scanUploadStart(
    //         {
    //             stainTag: params.stainTag,
    //             files: params.files,
    //             stain: params.stain,
    //             pathogen: params.pathogen,
    //         },
    //         handleUploadStatus
    //     )

    //     uploadCtxRef.current = uploadCtx

    //     return () => {
    //         uploadCtx.cleanup()
    //     }
    // }, [])

    //
    // TODO: move drop zone somewhere else, separate component
    //

    const onDragEnter = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
            st_.dragging = true;
        }

        st_.dragCounter++;

        if (st_.dragCounter > 1) return;

        if (!dropRef.current) return;

        theme_.dropZoneOverBorder.apply(dropRef);
    }, []);

    const onDragLeave = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        st_.dragCounter--;

        if (st_.dragCounter < 0) st_.dragCounter = 0;

        if (st_.dragCounter > 0) return;

        st_.dragging = false;

        theme_.dropZoneBorder.apply(dropRef);
    }, []);

    const onDragOver = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onDragDrop = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        st_.cleanup();

        if (!e.dataTransfer || e.dataTransfer.items.length === 0) return;

        let files: File[] = [];

        for (let item of e.dataTransfer.items) {
            let file = item.getAsFile();
            if (file) files.push(file);
        }

        //if (files.length === 0) return

        // let item = e.dataTransfer.items[0]
        // let file = item.getAsFile()!
        theme_.dropZoneBorder.apply(dropRef);

        filesRef.current = files;
        processSelectedFiles();

        //props.onDrop(files, props.tag)
    }, []);

    if (!stain && !pathogen) return <></>;

    return (
        <F.PageFormDialog
            title='Upload Scan'
            onClose={props.onClose}
            form={form}
            size='md'
            onSubmit={onUpload}
            submitLabel='UPLOAD'
            layoutElement='none'
        >
            <Stack direction='column' spacing={2} sx={{width: 1}}>
                <Stack direction='row' spacing={2} pt={2}>
                    <F.InputText id={scanStainFieldId} label='Stain' value={stain} form={form} />
                    <F.InputText id={scanPathogenFieldId} label='Pathogen' value={pathogen} form={form} />
                </Stack>

                {props.files ? (
                    <>
                        <Typography>You are about to upload scan image</Typography>
                    </>
                ) : (
                    <>
                        {/* <DropZone className='dropZone' onDragOver={allowDrop} onDrop={dropHandler}> */}
                        <Box
                            ref={dropRef}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDragOver={onDragOver}
                            onDrop={onDragDrop}
                            sx={[
                                theme_.dropZoneBorder.sx(),
                                {
                                    width: 'auto',
                                    height: 240,

                                    //borderStyle: 'dashed',
                                    //color: 'green',
                                    color: theme_.dialogTextColor,

                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',

                                    p: 2,
                                    //mt: 2,
                                    //my: 4,

                                    fontSize: 'x-large',
                                },
                            ]}
                        >
                            {dropZoneText}
                        </Box>

                        <Stack direction='row' alignItems='center' spacing={1} pr={1}>
                            <input
                                style={{display: 'none'}}
                                ref={fileInputRef}
                                onChange={onSelectedFileChange}
                                type='file'
                            />

                            <DialogButton label='Browse' onClick={onBrowseClick} />
                            <Typography>{fileName}</Typography>
                        </Stack>
                    </>
                )}
                {linearProgress && (
                    // <Dialog onClose={props.onClose} onBackdropClick={() => {props.onClose}} open={progIndicator} PaperProps={{ sx: { width: 200, height: 200 } }}>
                    // <Dialog onClose={() => { setLinearProgess(false) }} open={linearProgress} PaperProps={{ sx: { width: 200, height: 'auto' } }}>
                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        // onClose={() => { setLinearProgess(false) }}
                        onClose={props.onClose}
                        open={linearProgress}
                        PaperProps={{
                            sx: {
                                minHeight: '30vh',
                            },
                        }}
                    >
                        <DialogCaption ttitle='Uploading...' onClose={props.onClose} />
                        <DialogContent dividers>
                            <Stack direction='column' spacing={5}>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        label='File'
                                        defaultValue={filesRef.current[0].name}
                                        InputProps={{readOnly: true}}
                                        variant='standard'
                                    />
                                    <TextField
                                        label='Size'
                                        defaultValue={filesRef.current[0].size}
                                        InputProps={{readOnly: true}}
                                        variant='standard'
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        label='Stain'
                                        defaultValue={stain}
                                        InputProps={{readOnly: true}}
                                        variant='standard'
                                        id={scanStainFieldId}
                                    />
                                    <TextField
                                        label='Pathogen'
                                        defaultValue={pathogen}
                                        InputProps={{readOnly: true}}
                                        variant='standard'
                                        id={scanPathogenFieldId}
                                    />
                                </Stack>

                                <LinearProgressWithLabel
                                    value={progress}
                                    time={uploadIntervalTime}
                                    timename={timeVariant}
                                />
                                {/* <LinearProgress variant="determinate" value={progress} sx={{ height: '25px' }} /> */}
                                {/* <Typography>{Math.round(progress)}%</Typography> */}
                            </Stack>
                        </DialogContent>
                    </Dialog>
                )}
            </Stack>
        </F.PageFormDialog>
    );
}
function requestSessionJson<T>(arg0: string, arg1: string, arg2: {fileOffset: string; chunkId: string; scanId: any}) {
    throw new Error('Function not implemented.');
}

// function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
//     return (
//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ width: '100%', mr: 1 }}>
//           <LinearProgress variant="determinate" {...props} />
//         </Box>
//         <Box sx={{ minWidth: 35 }}>
//           <Typography variant="body2" color="text.secondary">{`${Math.round(
//             props.value,
//           )}%`}</Typography>
//         </Box>
//       </Box>
//     );
//   }

function LinearProgressWithLabel(props: LinearProgressProps & {value: number; time: number; timename: string}) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant='determinate' {...props} sx={{height: '25px'}} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
                <Typography variant='body2' color='text.secondary'>
                    {`${props.time} ${props.timename}`}
                </Typography>
            </Box>
        </Box>
    );
}
