import React from 'react';

import {Box} from '@mui/material';
import {FormControlLabel, Checkbox, Typography} from '@mui/material';

import PrevButtonIcon from '@mui/icons-material/ArrowBackIosRounded';
import NextButtonIcon from '@mui/icons-material/ArrowForwardIosRounded';

//import {Debug} from 'tslib/debug'

import * as Dom from 'tsui/Dom/DomLib';
import {DomSub} from 'tsui/Dom/DomLib';

import {scanTheme_} from '../ScanTheme/ScanTheme';

import {ScanViewContext} from '../ScanViewContext';
import {ScanViewStateInstance} from '../ScanViewStateProvider';

import {ScanDataSource} from '../ScanDataManager/ScanDataSource';

// Styles
import ScanViewPanel from 'scan/ScanComponents/ScanViewPanel';
import {AoiGalleryImage} from './AoiPanel/AoiImageGallery';
import {onAoiSelected} from './AoiPanel/AoiImageSelect';
import ScanViewPanelToolbar from '../ScanComponents/ScanViewPanelToolbar';
import ScanViewPanelToolbarButton from 'scan/ScanComponents/ScanViewPanelToolbarButton';
import AoiScrollButton from './AoiPanel/AoiScrollButton';
import ScanViewPanelToolbarCheckbox from 'scan/ScanComponents/ScanViewPanelToolbarCheckbox';

//let debug_ = new Debug({enabled: false, name: 'ScanAoi'})

export interface ScanViewAoiProps {
    //onShowMarker: (index: number) => void;
}

export class AoiPanelState {
    scrollOffset = 0;
    scrollBackVisible = false;
    scrollForwardVisible = false;

    aoiImagesCount = 0;

    aoiImageItemLength = 0;
    aoiImageGalleryLength = 0;
    aoiPanelLength = 0;

    selectedIndex = Infinity;

    domSub = new DomSub();

    //readyCount = 0

    release() {
        this.domSub.release();
    }
}

const showAllCheckboxId = 'showAllAoiCb';

function checkScrollOffset(st: AoiPanelState, off: number): number {
    if (off > st.aoiImageGalleryLength - st.aoiPanelLength) off = st.aoiImageGalleryLength - st.aoiPanelLength;

    if (off < 0) off = 0;

    return off;
}

function updateShowMarkerAll(id: string, visible: boolean) {
    let el = document.getElementById(id);
    if (!el) return;
    el.style.visibility = visible ? 'visible' : 'hidden';
}

function onShowMarkers(scanCtx: ScanViewContext, id: string) {
    //let gui = scanCtx.getGui();
    //gui.aoiMarkersVisible = !gui.aoiMarkersVisible;

    updateShowMarkerAll(id, scanCtx.togglePathogenMarkers());
    //props.onShowMarker(-1);
}

function onShowMarkersCheckbox(scanCtx: ScanViewContext, visible: boolean) {
    scanCtx.setPathogenMarkersVisible(visible);
    //let gui = scanCtx.getGui();
    //gui.aoiMarkersVisible = !gui.aoiMarkersVisible;

    //updateShowMarkerAll(id, scanCtx.togglePathogenMarkers())
    //props.onShowMarker(-1);
}

// id={makeImageId(index)}

export default function ScanViewAoiPanel(props: ScanViewAoiProps) {
    //debug_.check("Render");

    const scanCtx = React.useContext(ScanViewStateInstance).state;
    const panelStateRef = React.useRef(new AoiPanelState());
    const st_ = panelStateRef.current;

    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

    const titleRef = React.useRef<HTMLDivElement>(null);

    const galleryContainerRef = React.useRef<HTMLDivElement>(null);
    const galleryImagesRef = React.useRef<HTMLDivElement>(null);

    const backButtonRef = React.useRef<HTMLButtonElement>(null);
    const forwardButtonRef = React.useRef<HTMLButtonElement>(null);

    const updateScrollButtons = React.useCallback(() => {
        let st = panelStateRef.current;
        let backVisible = st.scrollOffset > 0;
        let forwardVisible = st.scrollOffset + st.aoiPanelLength < st.aoiImageGalleryLength;

        if (backVisible !== st.scrollBackVisible) {
            st.scrollBackVisible = backVisible;
            Dom.setElementVisible(backButtonRef, backVisible);
        }

        if (forwardVisible !== st.scrollForwardVisible) {
            st.scrollForwardVisible = forwardVisible;
            Dom.setElementVisible(forwardButtonRef, forwardVisible);
        }
    }, [panelStateRef, backButtonRef, forwardButtonRef]);

    const selectNextZone = React.useCallback(
        (offset: number) => {
            if (st_.selectedIndex === Infinity) st_.selectedIndex = -1;

            let nextIdx = st_.selectedIndex + offset;
            if (nextIdx >= st_.aoiImagesCount) nextIdx = st_.aoiImagesCount - 1;
            if (nextIdx < 0) nextIdx = 0;
            if (nextIdx === st_.selectedIndex) return;

            onAoiSelected(scanCtx, st_, nextIdx);

            let scrollOffset = nextIdx * st_.aoiImageItemLength - st_.aoiPanelLength / 2;
            st_.scrollOffset = checkScrollOffset(st_, scrollOffset);

            let axis = scanTheme_.aoiVert ? 'Y' : 'X';
            Dom.animateElementTransform(galleryImagesRef, `translate${axis}(${-st_.scrollOffset}px)`);
            updateScrollButtons();
        },
        [scanCtx, st_, updateScrollButtons]
    );

    scanCtx.gui!.aoiSelectNext = selectNextZone;

    const scrollGallery = React.useCallback(
        (offset: number) => {
            st_.scrollOffset = checkScrollOffset(st_, st_.scrollOffset + offset);

            let axis = scanTheme_.aoiVert ? 'Y' : 'X';
            Dom.animateElementTransform(galleryImagesRef, `translate${axis}(${-st_.scrollOffset}px)`);
            updateScrollButtons();
        },
        [st_, updateScrollButtons]
    );

    /*
    const processKeyUp = React.useCallback((ev: KeyboardEvent) => {
        //console.debug(ev);
        switch(ev.key) {
        case ' ': 
            if (st_.selectedIndex === Infinity) {
                st_.selectedIndex = -1;
            }
            selectNextZone(1);
            break;
        default: break;
        }
    }, []);
    */

    const onFetchImage = React.useCallback(
        (ds: ScanDataSource, err?: Error) => {
            const st = panelStateRef.current!;
            let aoiData = scanCtx.aoi!.aoiData!;
            let aoi = aoiData[ds.index];

            if (err) {
                aoi.imageDataError = err;
            } else {
                // console.debug('remove me')
                // aoi.imageDataError = new Error("some big downloading error")
                aoi.imageDataLoaded = true;
                aoi.imageUrl = ds.blobUrl!;
            }

            forceUpdate();

            // st.readyCount++
            // setReadyCount(st.readyCount)
        },
        [scanCtx.aoi, panelStateRef]
    );

    React.useLayoutEffect(() => {
        let aoiData = scanCtx.aoi!.aoiData!;
        st_.aoiImagesCount = aoiData.length!;
        st_.aoiImageItemLength = scanTheme_.aoiImageSize;
        st_.aoiImageGalleryLength = st_.aoiImageItemLength * st_.aoiImagesCount;

        // Start data loading
        let dm = scanCtx.dataManager!;
        for (let aoi of aoiData) {
            let ds = aoi.imageDataSource!;
            ds.index = aoi.index;
            ds.onFetch = (ds, err) => onFetchImage(ds, err);
            dm.fetchImageUrl(ds);
        }
    }, [scanCtx, st_]);

    // Initialization
    React.useEffect(() => {
        // debug_.check('useEffect()::Init')

        function getGalleryLength() {
            if (!galleryContainerRef.current) return 0;
            let rect = galleryContainerRef.current!.getBoundingClientRect();
            return scanTheme_.aoiVert ? rect.height : rect.width;
        }

        let st = panelStateRef.current;

        st.aoiPanelLength = getGalleryLength();

        function onWindowResize() {
            let st = panelStateRef.current;
            st.aoiPanelLength = getGalleryLength();
            //state.current.scrollOffset = checkScrollOffset(scanCtx, state.current.scrollOffset);
            let offset = checkScrollOffset(st, st.scrollOffset);
            //console.log("on resize: %d => %d", st.scrollOffset, offset);
            if (offset !== st.scrollOffset) {
                st.scrollOffset = offset;
                let axis = scanTheme_.aoiVert ? 'Y' : 'X';
                Dom.setElementTransform(galleryImagesRef, `translate${axis}(${-st.scrollOffset}px)`);
            }
            updateScrollButtons();
        }

        st.domSub.addWindowListener('resize', onWindowResize);

        //st.domSub.addDocumentListener('keyup', processKeyUp);

        updateScrollButtons();

        return () => {
            st.release();
        };
    }, [scanCtx, updateScrollButtons]);

    // // GUI Element updates
    // React.useEffect(() => {
    //     if (!titleRef) return

    //     updateShowMarkerAll(showAllCheckboxId, true)
    //     setRefresh((r) => !r)
    // }, [scanCtx, titleRef])

    return (
        <ScanViewPanel panel={scanTheme_.aoiPanel}>
            <ScanViewPanelToolbar panel={scanTheme_.aoiPanel}>
                <ScanViewPanelToolbarButton icon={PrevButtonIcon} onClick={() => selectNextZone(-1)} />
                <ScanViewPanelToolbarButton icon={NextButtonIcon} onClick={() => selectNextZone(1)} />

                {!scanTheme_.aoiVert && (
                    <Typography
                        sx={{
                            px: 1,
                        }}
                    >
                        Zones: {panelStateRef.current.aoiImagesCount}
                    </Typography>
                )}

                <Box flex={10} />

                <ScanViewPanelToolbarCheckbox
                    label='Show'
                    defaultChecked={scanCtx.pathogenMarkersVisible}
                    onChange={(st) => onShowMarkersCheckbox(scanCtx, st)}
                />

                {/* <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked={scanCtx.pathogenMarkersVisible}
                            onChange={(evt) => onShowMarkersCheckbox(scanCtx, evt.target.checked)}
                        />
                    }
                    label='Show'
                    labelPlacement='start'
                /> */}
            </ScanViewPanelToolbar>

            <Box
                ref={galleryContainerRef}
                sx={[
                    scanTheme_.aoiPanel.getContentsLayout().sx(),
                    {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    },
                ]}
            >
                <Box
                    ref={galleryImagesRef}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        transform: scanTheme_.aoiVert
                            ? `translateY(${st_.scrollOffset}px)`
                            : `translateX(${st_.scrollOffset}px)`,
                    }}
                >
                    {scanCtx.aoi!.aoiData.map((aoi, index) => (
                        <AoiGalleryImage key={index} scanCtx={scanCtx} st={st_} aoi={aoi} index={index} />
                    ))}
                </Box>

                <AoiScrollButton
                    ref={backButtonRef}
                    visible={st_.scrollBackVisible}
                    vertical={scanTheme_.aoiVert}
                    direction='back'
                    onClick={() => scrollGallery(-st_.aoiPanelLength / 2)}
                />

                <AoiScrollButton
                    ref={forwardButtonRef}
                    visible={st_.scrollForwardVisible}
                    vertical={scanTheme_.aoiVert}
                    direction='forward'
                    onClick={() => scrollGallery(st_.aoiPanelLength / 2)}
                />
            </Box>
        </ScanViewPanel>
    );
}
