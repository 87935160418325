import React from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import * as AuthApi from 'api/auth';
import * as F from 'tsui/Form';
import PageContents from 'components/PageContents';

interface Props {
    email: string;
}

export default function JoinConfirmUserEmailPage() {
    const form = F.useForm({type: 'input'});
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [email, setEmail] = React.useState(urlParams.get('email'));
    const [verificationCode, setVerificationCode] = React.useState(urlParams.get('code'));
    const [disabled, setDisabled] = React.useState(false);

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return;

        AuthApi.confirmUserEmail(evt.data)
            .then((sess) => {
                form.clearBusy();
                if (sess) {
                    navigate('/login');
                }
            })
            .catch(form.setError);
    }, []);

    return (
        <PageContents title='Confirming Login'>
            <F.PageForm title='Confirm Email' form={form} size='md' onSubmit={onSubmit}>
                <F.InputText
                    id='email'
                    label='Email'
                    autocomplete='email'
                    value={email ?? ''}
                    form={form}
                    xsMax
                    required
                />
                <F.InputText
                    id='code'
                    label='Verification Code'
                    value={verificationCode ?? ''}
                    form={form}
                    xsMax
                    required
                />
                {<F.SubmitButton disabled={disabled} align='center' label='Submit' form={form} xsMax />}
            </F.PageForm>
        </PageContents>
    );
}