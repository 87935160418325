import React from 'react';

import { Typography } from '@mui/material';

import HtmlHeader from "ui/HtmlHeader";

export default function WipHeader() {
    return <>
        <HtmlHeader title='BPF - WIP'/>
        <Typography variant='h1' align='center'>Work In Progress</Typography>
    </>;
}
