import React from 'react'
//import {useNavigate} from 'react-router-dom'

import {Box} from '@mui/material'

import session_ from 'api/session'

import PageContents from 'components/PageContents'

import {ScanUploadButton} from 'components/Buttons/ScanUploadButton'

import {HomePageDarkAiTheme} from './HomePageDarkAiTheme'
import HomePageDropZone from './HomePageDropZone'
import ScanUploadProcessModal, {ScanUploadProcessParams} from '../components/ScanUploadProcessModal'

import ScanUploadDialog from '../components/ScanUploadDialog'

import {scanGenericGlass_} from 'data/ScanStainGlasses'
import ScanUploadPermissionDialog, {
    UploadPermissionData,
} from '../components/ScanUploadPermissionDialog'
import {HomePageProps} from '../HomePageProps'

//let debug_ = new Debug({enabled: false, name: 'HomePage'})

class HomePageState {
    homeTheme = new HomePageDarkAiTheme()
}

export default function HomePageAiDesktop(props: HomePageProps) {

    const [selectedScanTag, setSelectedScanTag] = React.useState<string | null>(null)

    const [permissionAlert, setPermissionAlert] = React.useState<UploadPermissionData | null>(null)

    const [droppedFiles, setDroppedFiles] = React.useState<File[] | undefined>()
    const [uploadParams, setUploadParams] = React.useState<ScanUploadProcessParams | null>(null)

    const stateRef = React.useRef(new HomePageState())
    //const st_ = stateRef.current
    const homeTheme = stateRef.current.homeTheme

    const handleUploadRequest = React.useCallback((tag: string) => {
        if (!session_.isLoggedIn) {
            setPermissionAlert({
                stainTag: tag,
                notLoggedIn: true,
            })
            return
        }

        setSelectedScanTag(tag)
    }, [])

    //
    // Drag and drop support
    //

    // When the users drops the file on the home screen
    const handleFileDrop = React.useCallback((files: File[], tag: string) => {
        //console.debug('File dropped', files[0].name)

        setDroppedFiles(files)
        handleUploadRequest(tag)
    }, [])

    // When the user clicks on the button on the home screen
    const handleUploadButton = React.useCallback((tag: string) => {
        handleUploadRequest(tag)
    }, [])

    // When the user selects "Upload" from the dialog box
    const handleUploadBegin = React.useCallback(
        (tag: string, files: File[], stain?: string, pathogen?: string) => {
            setUploadParams({
                stainTag: tag,
                files: files,
            })
        },
        []
    )

    const handleUploadFinish = React.useCallback((filesCount: number) => {
        // Close the upload dialog
        if (filesCount > 0) {
            clearUploadState()
        }

        // Close the upload progress
        setUploadParams(null)
    }, [])

    const clearUploadState = React.useCallback(() => {

        setSelectedScanTag(null)
        setDroppedFiles(undefined)
    }, [])

    return (
        <PageContents
            title='Bug Path Finder'
            current='home'
            minWidth={homeTheme.minPageWidth}
            layout={{
                stack: {
                    direction: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <Box flex={2} />

            <Box sx={homeTheme.stainUploadAreaLayout.sx()}>
                {homeTheme.stainUploadDropZones.map((zone) => (
                    <HomePageDropZone
                        key={zone.glass.tag}
                        stainTag={zone.glass.tag}
                        layout={zone.layout}
                        theme={homeTheme}
                        onDrop={handleFileDrop}
                    >
                        <ScanUploadButton
                            stainTag={zone.glass.tag}
                            stain={zone.glass.stain}
                            pathogen={zone.glass.pathogen}
                            tissueImage={zone.glass.tissueImage}
                            layout={homeTheme.stainButtonLayout}
                            onUpload={handleUploadButton}
                        />
                    </HomePageDropZone>
                ))}
            </Box>

            <Box flex={1} />

            <HomePageDropZone
                stainTag={scanGenericGlass_.tag}
                layout={homeTheme.genDropZoneLayout}
                theme={homeTheme}
                onDrop={handleFileDrop}
            >
                <ScanUploadButton
                    stainTag={scanGenericGlass_.tag}
                    layout={homeTheme.genericButtonLayout}
                    label={['UPLOAD', 'SCAN']}
                    tissueImage={scanGenericGlass_.tissueImage}
                    onUpload={handleUploadButton}
                />
            </HomePageDropZone>

            <Box flex={2} />

            <ScanUploadDialog
                tag={selectedScanTag}
                files={droppedFiles}
                onClose={clearUploadState}
                onUpload={handleUploadBegin}
            />

            <ScanUploadPermissionDialog params={permissionAlert} onCloseNull={setPermissionAlert} />

            <ScanUploadProcessModal params={uploadParams} onUploadEnd={handleUploadFinish} />
        </PageContents>
    )
}
