
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import * as FT from './FormTypes';

interface SelectItem {
    id: string
    label: string;
}

export interface SelectProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps, FT.DataFieldsProps {
    label: string;
    id: string;
    type?: string;
    placeholder?: string | boolean;
    value: string;
    items: SelectItem[];
    // autocomplete?: FT.AutoCompleteType;
}


export function SelectField(props: SelectProps) {
    // const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        // setAge(event.target.value as string);
    };


    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.value}
                    label={props.label}
                    onChange={handleChange}
                >
                    {/* <MenuItem key={"aaa"} value={"aaa"}>{"AAA"}</MenuItem>
                        <MenuItem key={"bbb"} value={"bbb"}>{"BBB"}</MenuItem>
                        <MenuItem key={"vvv"} value={"ccc"}>{"CCC"}</MenuItem> */}
                    {props.items.map(item =>
                        <MenuItem key={item.id} value={item.id}>
                            {item.label}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}