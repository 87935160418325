import * as Geo from "tslib/geo";
import { ScanDataSource } from "../../ScanDataManager/ScanDataSource";
import { ScanTileLocation } from "../../ScanTypes/ScanTileLocation";
import { SceneTexture } from "./SceneTexture";
import { SceneTileStatus } from './SceneTileStatus';


export class SceneTile {
    status = SceneTileStatus.Pending;
   
    ds?: ScanDataSource;


    // Rendering destination
    //screenRect = new Geo.Rect();  // The rectangel on the screen
    scanRect = new Geo.Rect();  // The rectangle on the scan
    //scanRectVtx = new Geo.RectVtx();

    //screenRectVtx = new Geo.RectVtx();
    screenVisible = false;  // Tile is visible on the screen

    // Location in the texture
    texRef?: SceneTexture;
    texIndex = new Geo.Point();  // Column, row in the texture
    texRect = new Geo.Rect();  // The coordinates in texture
    texRectNorm = new Geo.Rect();  // Normalized coords in texture

    // Location in the scan
    scanLoc = new ScanTileLocation();
    locDist = 0;  // Distance to the current location at the time of creation

    release() {
        this.texRef = undefined;
        this.ds = undefined;
    }
};
