import * as Api from 'api/api'

const uploadChunkSize = 6 * 1024 * 1024
const debugImitateUpload_ = true


export interface ScanUploadParams {
    stainTag: string
    stain?: string
    pathogen?: string
    files: File[]
}

export interface ScanUploadStatus {
    reason: 'started' | 'finished' | 'progress' | 'error'

    progressTotal?: number
    progressCurrent?: number

    currentFileIndex?: number
    currentFile?: File

    error?: Error
}

type ScanUploadCallback = (status: ScanUploadStatus) => void


class UploadFileContext {
    file: File
    index: number

    multipartUploadData?: MultipartUploadData
    multipartUploadState?: MultipartUploadState
    multipartCurrentChunk = 0

    progressBegin = 0
    progressRange = 0
    progressEnd = 0

    constructor(file: File, index: number) {
        this.file = file
        this.index = index
    }
}

export class ScanUploadContext {
    //status:
    params: ScanUploadParams
    cb: ScanUploadCallback

    uploads: UploadFileContext[] = []
    currentUploadIndex = 0

    progressCurrent = 0
    progressTotal = 0

    finished = false
    cancelled = false
    errored = false

    constructor(params: ScanUploadParams, cb: ScanUploadCallback) {
        this.params = params
        this.cb = cb

        // TODO: better handle chunked uploads progress
        let progressPerFile = 1 / params.files.length
        let index = 0
        let currentProgress = 0

        for (let file of params.files) {
            let up = new UploadFileContext(file, index)
            up.progressBegin = currentProgress
            up.progressRange = progressPerFile
            up.progressEnd = up.progressBegin + up.progressRange

            this.uploads.push(up)
            index++
        }
    }

    reportUploadProgress(progress: number) {
        let fileCtx = this.uploads[this.currentUploadIndex]

        this.progressCurrent = (progress - fileCtx.progressBegin) / fileCtx.progressRange

        //this.progressCurrent += progress
        this.cb({
            reason: 'progress',
            progressCurrent: this.progressCurrent,
            currentFileIndex: this.currentUploadIndex,
            currentFile: fileCtx.file,
        })
    }

    cleanup() {
        if (!this.finished || !this.errored) {
            this.cancelled = true
        }
    }

}


export function scanUploadStart(params: ScanUploadParams, cb: ScanUploadCallback) {
    let ctx = new ScanUploadContext(params, cb)

    ctx.cb({
        reason: 'started',
    })

    uploadCurrentFile(ctx)

    return ctx
}

export function scanUploadCancel(ctx: ScanUploadContext) {
    ctx.cancelled = true
}

function handleUploadError(ctx: ScanUploadContext, err: Error) {
    ctx.errored = true
    ctx.cb({
        reason: 'error',
        error: err,
    })
}

function debugImitateUpload(ctx: ScanUploadContext) {

    let tickCount = 0
    let ticksTotal = 6

    const processTick = () => {
        if (tickCount >= ticksTotal) {
            ctx.finished = true
            ctx.cb({
                reason: 'finished',
            })

            return
        }

        if (ctx.cancelled || ctx.errored || ctx.finished) {
            return
        }

        tickCount++

        ctx.reportUploadProgress(tickCount / ticksTotal)

        setTimeout(processTick, 2000)
    }

    processTick()

    // setTimeout(() => {


    // }, 2000)


}

function uploadCurrentFile(ctx: ScanUploadContext) {
    if (debugImitateUpload_) {
        return debugImitateUpload(ctx)
    }

    let fileCtx = ctx.uploads[ctx.currentUploadIndex]
    let file = fileCtx.file

    if (file.size <= uploadChunkSize) {
        singleFileUpload(ctx)
    } else {
        multipartFileUpload(ctx)
    }
}

function uploadNextFile(ctx: ScanUploadContext) {

    if (ctx.finished || ctx.cancelled || ctx.errored) {
        return
    }


    ctx.currentUploadIndex++

    // Finished Uploading
    if (ctx.currentUploadIndex >= ctx.uploads.length) {
        ctx.finished = true
        ctx.cb({
            reason: 'finished',
        })
        return
    }

    uploadCurrentFile(ctx)    
}


interface MultipartUploadPart {
    partNumber: number
    presignedUrl: string
}

interface MultipartUploadData {
    scanId: string
    uploadId: string
    parts: MultipartUploadPart[]
}

interface MultipartS3PartState {
    ETag: string
    PartNumber: number
}

interface MultipartUploadState {
    currentPart: number
    s3Parts: MultipartS3PartState[]
}



function singleFileUpload(ctx: ScanUploadContext) {
    let fileCtx = ctx.uploads[ctx.currentUploadIndex]

    let file = fileCtx.file

    Api.requestSession('scan', 'upload_request_simple', {
        filename: file.name,
        stain: ctx.params.stain ?? '',
        pathogen: ctx.params.pathogen ?? '',
    })
        .then((d) => {
            //setProgress(30)
            ctx.reportUploadProgress(0.3)

            //let file = props.files[0]
            const reader = new FileReader()

            console.log(d)
            let data = JSON.parse(d.body)

            //reader.read

            // console.log(data.presigned_url)

            //reader.onload = (e: ProgressEvent<FileReader>) => {
            reader.onloadend = (e: ProgressEvent<FileReader>) => {
                ctx.reportUploadProgress(0.4)
                
                if (e.target!.readyState === 2) {

                    let body = reader.result

                    Api.s3UploadFile(data.presigned_url, body)
                        .then((response) => uploadNextFile(ctx))
                        .catch((err) => handleUploadError(ctx, err))
                }
            }

            reader.readAsArrayBuffer(file)
        })
        .catch((err) => handleUploadError(ctx, err))
}

function multipartFileUpload(ctx: ScanUploadContext) {
    let fileCtx = ctx.uploads[ctx.currentUploadIndex]

    let file = fileCtx.file



    // if (debugImitateUpload) {
    //     setProgress(30)
    //     return
    // }

    //let chunksCount = Math.floor((file.size + uploadChunkSize - 1)/ uploadChunkSize)
    let chunksCount = Math.ceil(file.size / uploadChunkSize)

    Api.requestSession<MultipartUploadData>('scan', 'upload_multipart_begin', {
        filename: file.name,
        stain: ctx.params.stain ?? '',
        pathogen: ctx.params.pathogen ?? '',
        chunksCount: chunksCount.toString(),
    })
        .then((d) => {
            fileCtx.multipartUploadData = d
            fileCtx.multipartUploadState = {currentPart: 0, s3Parts: []}
            fileCtx.multipartCurrentChunk = 0
            multipartChunkUpload(ctx)
        })
        .catch((err) => handleUploadError(ctx, err))
}

//function multipartUpload


function multipartChunkUpload(ctx: ScanUploadContext) {

    if (ctx.finished || ctx.cancelled || ctx.errored) {
        return
    }

    let fileCtx = ctx.uploads[ctx.currentUploadIndex]

    let file = fileCtx.file

    let state = fileCtx.multipartUploadState!
    let data = fileCtx.multipartUploadData!
    let partIndex = fileCtx.multipartCurrentChunk

    if (partIndex >= data.parts.length) {
        multipartUploadComplete(ctx)
        return
    }


    let sliceBegin = partIndex * uploadChunkSize
    let sliceEnd = sliceBegin + uploadChunkSize
    let slicedFile = file.slice(sliceBegin, sliceEnd < file.size ? sliceEnd : undefined)

    const reader = new FileReader()

    reader.onloadend = (e: ProgressEvent<FileReader>) => onMultipartFileReaderLoad(ctx, e.target!)

    reader.readAsArrayBuffer(slicedFile)
}


// Processing FileReader load events
function onMultipartFileReaderLoad(ctx: ScanUploadContext, reader: FileReader) {
    let fileCtx = ctx.uploads[ctx.currentUploadIndex]

    let file = fileCtx.file

    let state = fileCtx.multipartUploadState!
    let data = fileCtx.multipartUploadData!
    let partIndex = fileCtx.multipartCurrentChunk
    let part = data.parts[partIndex]


    // Check if data is ready
    if (reader.readyState !== 2)
        return 

    //setProgress(((partIndex + 0.5) * 100) / data.parts.length)
    ctx.reportUploadProgress((partIndex + 0.5) / data.parts.length)

    let body = reader.result
    Api.s3UploadFile(part.presignedUrl, body)
        .then((response) => {
            let etag = response.headers.get('ETag')

            if (!etag) {
                handleUploadError(ctx, new Error('S3: ETag is missing'))
                return
            }

            state.s3Parts.push({
                ETag: etag,
                PartNumber: partIndex + 1,
            })
            ctx.reportUploadProgress((partIndex + 1) / data.parts.length)

            //setProgress(((partIndex + 1) * 100) / data.parts.length)


            fileCtx.multipartCurrentChunk++

            multipartChunkUpload(ctx)

            //}).catch(err => alert(err))
            //setProgress(100)
            //setUploadFinished(true)
        })
        .catch((err) => handleUploadError(ctx, err))

    // console.log(body)
    //uploadScan(data.presigned_url, body)
}



function multipartUploadComplete(ctx: ScanUploadContext) {
    let fileCtx = ctx.uploads[ctx.currentUploadIndex]

    let state = fileCtx.multipartUploadState!
    let data = fileCtx.multipartUploadData!

    Api.requestSession('scan','upload_multipart_complete',
        {
            scanId: data.scanId,
            uploadId: data.uploadId,
        },
        {
            uploadParts: state.s3Parts,
        }
    )
        .then((result) => uploadNextFile(ctx))
        .catch((err) => handleUploadError(ctx, err))

}

