import React from 'react'

import Env from 'env'
import session_ from 'api/session'

import * as Api from 'api/api'

import * as F from 'tsui/Form'

import PageContents from 'components/PageContents'

export default function ContactUsPage() {
    const form = F.useForm({
        type: 'input',
    })

    const onSubmitContactUs = React.useCallback((evt: F.InputFormEvent) => {
        Api.requestSession<any>('user', 'contact_us', evt.data)
            .then((d) => {
                console.log(evt.data)
                console.log(d)
                console.log(`modified count is ${d.modifiedCount}`)
            })
            .catch((err) => console.log(err))
    }, [])

    const onSubmitSendMessage = React.useCallback((evt: F.InputFormEvent) => {
        Api.apiPublic<any>('user', 'send_message', evt.data)
            .then((d) => {
                console.log(evt.data)
                console.log(d)
                console.log(`modified count is ${d.modifiedCount}`)
            })
            .catch((err) => console.log(err))
    }, [])

    // let modalSize
    // if (Env.isMobile) modalSize = 'sm'
    // else modalSize = 'md'

    // let modalSize = 'md'

    return (
        <PageContents title='Contact Us'>
            {session_.isLoggedIn ? (
                <>
                    {/* <Page.PageDialogContents> */}
                    <F.PageForm
                        title='Contact Us'
                        onSubmit={onSubmitContactUs}
                        form={form}
                        size='md'
                    >
                        <F.InputGroup label='Subject' form={form} />
                        <F.InputText label='Subject' id='subject' form={form} xsMax />

                        <F.InputGroup label='Message' form={form} />
                        {/* <Form.InputText label='Message' id='message' form={form} xsMax /> */}
                        <F.InputText
                            type='text'
                            required
                            label='Message'
                            id='content'
                            form={form}
                            xsMax
                            multiline={{
                                minRows: 3,
                                maxRows: 6,
                            }}
                        />

                        {/* sx={{resize:'none', width: '500px'}} */}
                        {/* chi toxnum style grem */}
                        {/* style={resize:'none'} */}

                        <F.SubmitButton label='Submit' form={form} xsMax size='lg' align='center' />
                    </F.PageForm>

                    {/* </Page.PageDialogContents>  */}
                </>
            ) : (
                <>
                    <F.PageForm
                        title='Contact Us'
                        onSubmit={onSubmitSendMessage}
                        form={form}
                        size='md'
                    >
                        {/*             
            <Form.InputGroup label='Email' form={form} />
            <Form.InputText label='Email' id='email' form={form} xsMax/> */}

                        <F.InputGroup label='Email' form={form} />
                        <F.InputText
                            label='Email'
                            id='senderEmail'
                            autocomplete='username'
                            validate='email'
                            required
                            form={form}
                            xsMax
                        />

                        <F.InputGroup label='Subject' form={form} />
                        <F.InputText
                            label='Subject'
                            id='subject'
                            validate='not-empty'
                            required
                            form={form}
                            xsMax
                        />

                        <F.InputGroup label='Message' form={form} />
                        {/* <Form.InputText label='Message' id='content' validate="not-empty" required form={form} xsMax /> */}
                        <F.InputText
                            type='text'
                            required
                            label='Message'
                            id='content'
                            form={form}
                            xsMax
                            multiline={{
                                minRows: 3,
                                maxRows: 6,
                            }}
                        />

                        <F.SubmitButton label='Submit' form={form} xsMax size='lg' align='center' />
                    </F.PageForm>
                </>
            )}
        </PageContents>
    )

    // return <PageContents title="Contact Us">
    //     {/* <Page.PageDialogContents> */}

    //     <Form.PageInputForm title='Contact Us' form={form} size='md' >
    //         {/*
    //         <Form.InputGroup label='Email' form={form} />
    //         <Form.InputText label='Email' id='email' form={form} xsMax/> */}

    //         <Form.InputGroup label='Subject' form={form} />
    //         <Form.InputText label='Subject' id='subject' form={form} xsMax />

    //         <Form.InputGroup label='Message' form={form} />
    //         <Form.InputText label='Message' id='message' form={form} xsMax />

    //         <Form.SubmitButton label='Submit' form={form} xsMax size='lg' align="center" />

    //     </Form.PageInputForm>

    //     {/* </Page.PageDialogContents>  */}
    // </PageContents>
}
