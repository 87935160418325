import React from 'react';

import {Box} from '@mui/material';

import {scanTheme_} from '../ScanTheme/ScanTheme';

import {ScanViewStateDefValue} from '../ScanViewStateProvider';

interface Props {
    children?: React.ReactNode;
}

const ScanViewContainerId = 'ScanViewContainer';

export default function ScanViewContainer(props: Props) {
    const RootRef = React.useRef<HTMLDivElement>(null);
    const scanCtxRef = React.useRef(ScanViewStateDefValue.state);

    React.useLayoutEffect(() => {
        const scanCtx = scanCtxRef.current;
        if (scanCtx?.gui) {
            scanCtx.gui.mainContainer = RootRef.current;
        }
    }, []);

    return (
        <Box
            id={ScanViewContainerId}
            ref={RootRef}
            sx={[
                scanTheme_.windowBorder.sx(),
                {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                },
            ]}
        >
            {props.children}
        </Box>
    );
}
