import { Link, List, ListItem } from '@mui/material';


interface LinkItemProps {
    url: string;
    label: string;
}

function LinkItem(props: LinkItemProps) {
    return <ListItem divider={true}>
        <Link href={props.url} target='_blank'>{props.label}</Link>
    </ListItem>
}

export default function DevLinksTab()
{
    return <List sx={{ width: '100%'}}>
        <LinkItem url='https://bpf.myjetbrains.com/youtrack' label='YouTrack (https://bpf.myjetbrains.com/youtrack)'/>
        <LinkItem url='/wip' label='WIP'/>
        <LinkItem url='http://asotelepathology.org' label='ASO (http://asotelepathology.org)'/>
    </List>
}

