import React from 'react';

import {Box} from '@mui/material';

import PageDecor from './PageDecor';
import {PageProps} from './PageProps';

interface Props extends PageProps {
    children?: React.ReactNode;
}


export function PageContainer(props: Props) {
    //console.debug("page container")

    //const [_, forceUpdate] = React.useReducer((x) => x + 1, 0)

    // React.useEffect(() => {
    //     const onWindowResize = () => {
    //         theme_.processWindowResize()
    //         // if (theme_.processWindowResize()) {
    //         //     forceUpdate()
    //         // }
    //     }

    //     window.addEventListener('resize', onWindowResize)

    //     const sessionListener = () => {
    //         theme_.processWindowResize()
    //         // if (theme_.processWindowResize()) {
    //         //     forceUpdate()
    //         // }
    //     }

    //     GD.pubsub_.addListener(GD.authListenerId, sessionListener)

    //     return () => {
    //         window.removeEventListener('resize', onWindowResize)

    //         GD.pubsub_.removeListener(GD.authListenerId, sessionListener)
    //     }
    // }, [])

    return (
        <Box
            data-name='pg_root'
            sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,

                minWidth: props.minWidth ?? theme_.pageMinWidth,
                minHeight: theme_.pageMinHeight,

                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundAttachment: 'fixed',
                backgroundImage: theme_.homeBackgroundImage ? `url(${theme_.homeBackgroundImage})` : undefined,

                backgroundColor: theme_.pageBackgroundColor,
            }}
        >
            <PageDecor />
            {props.children}
        </Box>
    );
}
