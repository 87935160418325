import * as AD from 'api/data';
import * as Api from 'api';

import {ScanNote} from 'data';
import {PatientReport} from './PatientReport';

import {ScanStatusInfo} from './ScanStatus';

export interface Scan {
    scanId: string;
    scanName: string;
    ownerId?: string;
    status?: string;
    result?: string;
    scanDate: Date;
    objective?: string;
    stain?: string;
    pathogen?: string;

    thumbnailUrl?: string;

    // Reports
    reportId?: string; // Last report (finalized or not)
    reportUrl?: string;
    reportFinalized?: boolean;

    uploadId: string;
    fileDigestHex?: string;
    uploadDate?: Date;
    uploadStatus?: string;
    processed?: boolean;
    fileName?: string;

    // Additional data
    notes?: ScanNote[];
    reports?: PatientReport[];

    // GUI Run-Time stuff
    guiProcessed?: boolean;

    //guiScanName: string

    guiStatusInfo?: ScanStatusInfo;
    // guiScanDate?: Date
    // guiScanDateText?: string
}

export class ScansData {
    scans: Scan[] = [];
    scansMap = new Map<string, Scan>();
}

export function handleScanFetch(api: AD.ApiScan) {
    //console.debug(api);


    let scan: Scan = {
        scanId: api.scanId,
        scanName: api.scanName ?? api.scanId,

        scanDate: Api.apiToDate(api.scanDate),

        thumbnailUrl: api.thumbnailUrl,

        // Report information
        reportId: api.reportId,
        reportFinalized: api.reportFinalized,
        reportUrl: api.reportUrl,

        guiStatusInfo: new ScanStatusInfo(api.status),

        stain: api.stain,
        pathogen: api.pathogen,

        uploadId: api.uploadId,
        uploadDate: Api.apiToDate(api.uploadDate),
        uploadStatus: api.uploadStatus,
        processed: api.processed,
        fileName: api.fileName,

        //guiScanName: api.scanName ?? api.scanId,
    };

    //console.debug(scan);

    return scan;
}

export function handleScansFetch(apiScans: AD.ApiScan[]) {
    console.debug(apiScans);
    return apiScans.map((api) => handleScanFetch(api));
}

export function processScanDataItem(scan: Scan) {
    //if (!scan.guiProcessed) {
    scan.guiProcessed = true;

    //scan.guiScanName = scan.scanName ?? scan.scanId

    scan.guiStatusInfo = new ScanStatusInfo(scan.status);

    // console.debug(scan.scanDate)

    // scan.guiScanDate = new Date(Date.parse(scan.scanDate))
    // scan.guiScanDateText = scan.guiScanDate.toLocaleDateString()
    //}
}

export function processScansData(scans: Scan[]) {
    for (let scan of scans) {
        if (!scan.guiProcessed) {
            processScanDataItem(scan);
        }
    }
}

interface ScanViewProps {
    scan: Scan;
    fs?: boolean; // Open in full screen
    close?: boolean; // Close the windows
}

export const scanViewTarget = 'scanview';

export function makeScanViewTarget() {
    return scanViewTarget;
}

export function makeScanViewUrl(props: ScanViewProps) {
    let scanId = props.scan.scanId;
    let fs = props.fs === true ? '1' : '0';
    let close = props.close === true ? '1' : '0';

    return `/scan/${scanId}?fullscreen=${fs}&close=${close}`;
}
