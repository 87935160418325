import {Box, Stack, Tab, Tabs} from '@mui/material'
import React from 'react'

import {Scan} from 'data/Scan'

import ScanNotesTab from './ScanDetailsNotes/ScanNotesTab'
import ScanPatientTab from './ScanDetailsPatient/ScanPatientTab'
import ScanReportsTab from './ScanDetailsReports/ScanReportsTab'
import ScanDetailsInfoTab from './ScanDetailsInfo/ScanInfoTab'
import ScanDetailsPanel from './ScanDetailsPanel'
import PageTabs from 'tsui/PageTabs'

export type ScanDetailsCategory = 'scan' | 'notes' | 'reports' | 'patient'

interface ScanModalTabbedProps {
    scan: Scan
    onUpdate: (category: ScanDetailsCategory) => void
    onClose: () => void
}

//const startTab: ScanDetailsCategory = 'scan'
//const startTab: ScanDetailsCategory = 'notes'  // DEBUG: 

export default function ScanModalTabbed(props: ScanModalTabbedProps) {
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0)

    const handleUpdate = React.useCallback((category: ScanDetailsCategory) => {
        forceUpdate()
    }, [])

    const scan = props.scan

    return (
        <Stack
            direction='row'
            spacing={2}
            sx={{
                width: 1,
                height: 1,
            }}
        >
            <PageTabs<ScanDetailsCategory>
                tabs={[
                    {
                        value: 'scan',
                        label: 'Scan',
                        component: <ScanDetailsInfoTab scan={scan} />,
                        //initial: true,
                    },
                    {
                        value: 'notes',
                        label: 'Notes',
                        component: <ScanNotesTab scan={scan} />,
                        //initial: true,  // DEBUG:
                    },
                    {
                        value: 'reports',
                        label: 'Reports',
                        component: <ScanReportsTab scan={scan} />,
                        // initial: true,  // DEBUG:
                    },
                    {
                        value: 'patient',
                        label: 'Patient',
                        component: <ScanPatientTab scan={scan} />,
                    },
                ]}
                //start={startTab}
            />

            <ScanDetailsPanel scan={scan} onUpdate={handleUpdate} onClose={props.onClose} />
        </Stack>
    )
}
