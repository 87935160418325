import * as Algo from 'tslib/algorithm';
import * as Geo from 'tslib/geo';

// The User position in the scan
export interface IScanLocation {
    pos: Geo.IPoint;
    zoom: number;
    rot: Geo.IAngle;
}

export interface IScanLocationParam {
    pos?: Geo.IPoint;
    zoom?: number;
    rot?: number;
}

export class ScanLocation implements IScanLocation {
    // Current position in scan coordinates
    pos = new Geo.Point();

    // Zoom level = 0 - actual scan
    // positive - zoom in
    // negative - zoom out (render tiles)
    zoom = 0;

    // Rotation in radians
    rot = new Geo.Angle();

    setPos(p: Geo.IPoint) {
        this.pos.assign(p);
    }

    set(x: number, y: number, zoom?: number, rot?: number) {
        this.pos.x = x;
        this.pos.y = y;
        if (zoom !== undefined) this.zoom = zoom;
        if (rot !== undefined) this.rot.set(rot);
    }

    assign(loc: IScanLocation) {
        this.pos.assign(loc.pos);
        this.zoom = loc.zoom;
        this.rot.assign(loc.rot);
    }
}
