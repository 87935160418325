import './theme'

const globalFonts = [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
]

const codeFonts = ['source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace']

const globalStyles = {
    // "*": {
    //     draggable: false,
    // },

    body: {
        fontFamily: globalFonts.join(','),

        fontSmooth: 'auto',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',

        backgroundColor: '#000000',

        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center center',
        // backgroundImage: theme_.homeBackgroundImage ? `url(${theme_.homeBackgroundImage})` : undefined,

        // backgroundColor: theme_.pageBackgroundColor,

        // // minWidth: theme_.pageMinWidth,
        // // minHeight: theme_.pageMinHeight,
        // width: '100%',
        // height: '100%',
        // overflow: 'auto',
    },

    code: {
        fontFamily: codeFonts.join(','),
    },
}

export default globalStyles
