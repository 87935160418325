import React from 'react'
import {Box, Container, Grid, Typography, Link} from '@mui/material'
import * as FT from './FormTypes'

import {useTranslation} from 'react-i18next'

interface Props extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
    text: string
    type?: 'text' | 'required'
}

export function FormNote(props: Props) {
    const [t] = useTranslation()
    const [gridAtts] = React.useState(FT.getFormGridAtts(props))
    const [gridSx] = React.useState(FT.getFormSxParams(props)) //, {mt: 1}));

    const fontSize = undefined

    return (
        <Grid item {...gridAtts} sx={gridSx}>
            <Box
                sx={{
                    display: 'flex',
                    //float: float,
                    justifyContent: props.align,
                    height: '100%',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontSize: fontSize}}>{t(props.text)}</Typography>
            </Box>
        </Grid>
    )
}
