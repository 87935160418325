import {ScanThemeInstance} from './ScanTheme';

import Env from 'env';

export function ScanTheme_setupScan(this: ScanThemeInstance) {
    this.scanLensRadius = 0;
    this.scanBgCheckerSize = 40;
    this.scanBusyIndicatorSize = 80;
}

export function ScanTheme_updateScan(this: ScanThemeInstance) {
    let centerVertCoeff = 0.5;

    if (Env.isTablet && this.windowSize.h > this.windowSize.w) {
        centerVertCoeff = 0.57;
    }

    if (Env.isMobile) {
        centerVertCoeff = 0.6;
    }

    //console.debug(this.windowSize);

    this.scanRenderCenter.set(this.windowSize.w / 2, this.windowSize.h * centerVertCoeff);
    this.scanLensRadius = Math.min(this.windowSize.w, this.windowSize.h) / 2;

    let navDiag = Math.hypot(this.navPanel.layout.width!, this.navPanel.layout.height!);
    let screenDiag = Math.hypot(this.windowSize.w / 2, this.windowSize.h / 2);

    if (this.scanLensRadius > screenDiag - navDiag) {
        this.scanLensRadius = screenDiag - navDiag;
    }

    if (this.aoiPanel.visible) {
        if (this.aoiVert) {
            if (this.scanRenderCenter.x - this.scanLensRadius < this.panelExtentMinLeft)
                this.scanLensRadius = this.scanRenderCenter.x - this.panelExtentMinLeft;
        } else {  // Horzontal aoi panel

            //console.debug(this.scanRenderCenter.y, this.scanLensRadius, this.panelExtentMinBottom);
            // if (this.scanRenderCenter.y - this.scanLensRadius < this.panelExtentMinBottom)
            //     this.scanLensRadius = this.scanRenderCenter.y - this.panelExtentMinBottom;
        }
    }
}
