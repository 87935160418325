import Env from 'env';

import * as UE from 'tsui/UiElements';

import * as Geo from 'tslib/geo';

import {ScanTheme_setupAoi, ScanTheme_updateAoi} from './ScanThemeAoi';
import {ScanTheme_setupNav, ScanTheme_updateNav} from './ScanThemeNav';
import {ScanTheme_setupThumbnail, ScanTheme_updateThumbnail} from './ScanThemeThumbnail';
import {ScanTheme_setupScan, ScanTheme_updateScan} from './ScanThemeScan';
import {ScanViewPanelState} from 'scan/ScanComponents/ScanViewPanel';

export class ScanThemeInstance {
    //theme: ThemeInstance;

    //styleLens = false;
    styleLens = true; // Lens style
    //styleDark = false;
    styleDark = true;

    windowSize = new Geo.Size();

    //
    // General screen setup
    //
    backgroundColor = this.styleDark ? theme_.vsBackground : theme_.whiteSmoke;

    themeColor = '#25afce';
    themeDarkColor = '#058fae';
    themeBorderColor = '#91cbd8';
    //themeTextColor = 'DarkSlateGray';
    // cccccc : vs code button text
    themeTextColor = this.styleDark ? '#cccccc' : '#1F3F3f';

    //scanBgColor = "#F5F5DC";
    //scanBgColor = '#FFF8E7'  // Cosmic latte

    //
    // Setup viewew window screen
    //
    windowBorderWidth = 0;
    windowBorder = new UE.UiBorder({
        width: this.windowBorderWidth,
        color: theme_.muiPrimaryDark,
    });

    //
    // Setting panels layout
    //
    panelBorderWidth = 1;
    panelBorderColor = this.styleDark ? '#616161' : '#696969'; // theme_.muiPrimaryDark
    panelBackground = new UE.UiBackground({color: this.backgroundColor});

    panelPadding = this.panelBorderWidth; // Padding of the panels contents
    panelMargin = 0; // Offset of the panels from the screen sides
    panelSpacing = 8; // Env.isMobile ? 0 : 8 // The gap between the panels

    // Default panel dimensions
    panelMinContentsWidth = Env.isMobile ? 120 : 180;
    panelMinContentsHeight = this.panelMinContentsWidth;
    panelMinWidth = this.panelMinContentsWidth + this.panelPadding * 2;
    panelMinHeight = this.panelMinContentsHeight + this.panelPadding * 2;
    panelExtentMinLeft = this.panelMargin + this.panelMinWidth + this.panelSpacing;
    panelExtentMinBottom = this.panelMargin + this.panelMinHeight + this.panelSpacing;

    panelToolbarContentHeight = 34;
    panelToolbarHeight = 34;
    panelToolbarBorder = new UE.UiBorder({
        width: 1,
        bottom: true,
        color: this.panelBorderColor,
    });
    panelToolbarButtonIconColor = this.styleDark ? this.themeTextColor : '#505050';
    panelToolbarButtonTextColor = this.panelToolbarButtonIconColor;
    panelToolbarButtonBorder = new UE.UiBorder({width: 1, color: '#616161'});
    //panelToolbarButtonTextSize =
    //panelToolbarButtonBackground = new UE.UiBackground({color: 'yellow'})
    panelToolbarButtonBackground = new UE.UiBackground({color: this.backgroundColor});

    panelCaptionHeight = 20;

    panelBorder = new UE.UiBorder({
        useOutline: true,
        width: this.panelBorderWidth,
        color: this.panelBorderColor,
    });

    //
    // Setup standard button
    //

    buttonBorderColor = this.styleDark ? '#616161' : '#696969'; // 696969 - LightDimGray';
    //buttonBackground = new UE.UiBackground()

    buttonBackground = new UE.UiBackground({color: this.backgroundColor});
    //buttonBorderColor = theme_.muiPrimaryMain

    buttonBorder = new UE.UiBorder({
        width: 1,
        color: this.buttonBorderColor,
    });

    buttonHover = new UE.UiHover({
        // border: new UE.UiBorder({
        //     width: 1,
        //     color: theme_.muiPrimaryDark,
        // }),
        boxShadowColor: this.styleDark ? '#4d4d50' : '#A5ffff',
    });

    //
    // General buttons setup
    //
    //buttonBgColor = this.backgroundColor
    buttonTextColor = this.themeTextColor; //'black'
    buttonIconColor = this.themeTextColor; //'black'

    buttonSelectedTextColor = theme_.whiteSmoke;
    buttonSelectedBackground = this.themeDarkColor;

    //
    // Screen caption stuff
    //
    windowCaptionHeight = Env.isMobile ? 40 : 54;
    infoTextColor = this.styleDark ? this.themeTextColor : 'black';

    captionButtonIconColor = this.buttonIconColor;

    captionButtonBackground = new UE.UiBackground({color: this.backgroundColor});

    //captionButtonBorder = new UE.UiBorder()
    captionButtonBorder = new UE.UiBorder({
        width: 1,
        color: this.buttonBorder.color,
    });

    //
    // Scan thumbnail section
    //
    thumbnailPanel = new ScanViewPanelState({
        visible: !Env.isMobile,
        name: 'Scan Thumbnail',
    });

    //showThumbnail = !Env.isMobile
    thumbnailPanelColor = this.backgroundColor;
    thumbnailCrosshairWidth = 1;
    thumbnailCrosshairColor = theme_.dimGray;

    thumbnailImageMax = 0;

    //
    // Navigation panel
    //
    navButtonHeight = Env.isMobile ? 40 : 60;
    navButtonWidth = 0;
    navButtonSmallWidth = 0;

    navPanel = new ScanViewPanelState({
        name: 'Scan Nav',
    });

    navButtonBackground = new UE.UiBackground();
    navButtonFont = new UE.UiFont({
        fontSize: Env.isMobile ? 'large' : 'x-large',
        fontWeight: Env.isMobile ? 'normal' : 'bold',
    });

    navButtonBorder = new UE.UiBorder({
        width: this.buttonBorder.width,
        color: this.buttonBorder.color,
        //color: theme_.muiPrimaryDark,
    });

    //
    // Zones (area) of interest (AOI)
    //
    aoiCount = 0;
    aoiVert = false; // Vertical or horizontal aoi
    //aoiLeft = true // AOI location (left or right)
    aoiPanel = new ScanViewPanelState({
        visible: false,
        name: 'AOI',
        showToolbar: true,
    });

    aoiImageSize = 0;
    aoiImageBorder = new UE.UiBorder({
        width: 4,
        color: this.buttonBackground.color,
    });
    aoiImageSelectedBorder = new UE.UiBorder({
        width: 4,
        color: '#fedf00', // 'yellow',
    });

    //
    // Various scan rendering sizes
    //
    scanCanvasSize = new Geo.Size();

    // The center of the rendered scan on the screen
    scanRenderCenter = new Geo.Point();

    scanLensRadius = 0;
    scanLensColor = this.backgroundColor;

    scanBgCheckerSize = 0; //40;
    // scanBgCheckerColor1 = this.styleDark ? '#DFD9CA' : 'white';
    // scanBgCheckerColor2 = this.styleDark ? '#807c73' : theme_.cosmicLatte;
    scanBgCheckerColor1 = 'white';
    scanBgCheckerColor2 = theme_.cosmicLatte;

    scanBusyIndicatorSize = 0; //80;

    busyIndicatorColor1 = this.themeColor;
    //busyIndicatorColor2 = '#aaaaaa11';
    busyIndicatorColor2 = '#ffffffff';

    init() {
        this.setupCommon();
    }

    setupCommon() {
        this.setupNavPanel();
        this.setupThumbnail();
        this.setupAoi();
        this.setupScan();
    }

    setupNavPanel = ScanTheme_setupNav;
    updateNavPanel = ScanTheme_updateNav;

    setupThumbnail = ScanTheme_setupThumbnail;
    updateThumbnail = ScanTheme_updateThumbnail;

    setupScan = ScanTheme_setupScan;
    updateScan = ScanTheme_updateScan;

    setAoiState(show: boolean, count: number) {
        this.aoiPanel.visible = show;
        this.aoiCount = count;
        
        this.updateNavPanel();
        this.updateThumbnail();
        this.updateAoi();
        this.updateScan();
    }

    setupAoi = ScanTheme_setupAoi;
    updateAoi = ScanTheme_updateAoi;

    onCanvasSize(canvasSize: Geo.ISize, windowSize: Geo.ISize) {
        this.scanCanvasSize.assign(canvasSize);
        this.onWindowSize(windowSize);
    }

    onWindowSize(size: Geo.ISize) {
        this.windowSize.assign(size);

        this.updateNavPanel();
        this.updateAoi();
        this.updateThumbnail();
        this.updateScan();
    }

    // Debugging
    debugPanelBorder = new UE.UiBorder({width: 2, color: '#ff0000'});
    debugPanelLayout = new UE.UiLayout({
        position: 'absolute',
        right: 0,
        top: this.windowCaptionHeight,
        width: Env.isMobile ? 1 : 200,
        height: Env.isMobile ? 200 : 400,
    });
    debugPanelBackgroundColor = '#ffffff';
    debugPanelColor = '#000000';
}

var instance: ScanThemeInstance | undefined = undefined;
export var scanTheme_!: ScanThemeInstance; // = new ScanThemeInstance()

export function initScanTheme() {
    //console.debug("initializing scan theme");

    instance = new ScanThemeInstance();
    instance.init();
    scanTheme_ = instance;

    scanTheme_.onWindowSize({w: window.innerWidth, h: window.innerHeight});

    return true;
}

export function releaseScanTheme() {
    if (!instance) return;

    instance = undefined;
}

export function scanThemeStart() {
}
