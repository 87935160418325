import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {createRoot} from 'react-dom/client';

import {GlobalStyles} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import globalStyles from 'theme/GlobalStyle';

import '@fontsource/roboto';

import './i18n';
import './api/session';

import * as D from 'data';

import * as BrowserPolifylls from 'tsfront/polifills';

import muiTheme from 'theme/MuiTheme';

import HomePageAi from 'pages/home/HomePageDarkAi/HomePageAi';
import ContactUsPage from 'pages/about/ContactUsPage';
import ScansPage from 'pages/scans/ScansPage';
//import AdminPage from 'pages/admin/AdminPage'
import WipScansPage from 'dev/WipScansPage';
import ScanViewPage from 'scan/ScanViewPage';
import DevPage from 'dev/DevPage';
import LoginForgotPage from 'pages/auth/LoginForgotPage';
import AsoHomePage from 'aso/AsoHomePage';

import LoginPage from 'pages/auth/LoginPage';
import JoinPage from 'pages/auth/JoinPage';
//import JoinVerificationSentPage from 'pages/auth/JoinVerificationSentPage'
import JoinConfirmUserEmailPage from 'pages/auth/JoinConfirmUserEmail';

import NoMatch from 'pages/404';
import ToolsPage from 'pages/tools/ToolsPage';
import RcbcPage from 'pages/tools/RcbcPage';
import ReportWritePage from 'pages/report/ReportWritePage';
import PatientsPage from 'pages/patients/PatientsPage';
import UserProfilePage from 'pages/user/UserProfilePage';
import UserEmailVerificationPage from 'pages/user/UserEmailVerificationPage';
import ReportEditPage from 'pages/report/ReportEditPage';

// Disable right click menu
document.oncontextmenu = function () {
    return false;
};

BrowserPolifylls.fix();

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ThemeProvider theme={muiTheme}>
        {inputGlobalStyles}
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePageAi />} />

                <Route path='/user_verify/:email/:verificationCode' element={<UserEmailVerificationPage />} />

                <Route path='/scans' element={<ScansPage />} />

                <Route path='/tools' element={<ToolsPage />} />
                <Route path='/tools/rcbc' element={<RcbcPage />} />

                {/* <Route path='/report/:scanId' element={<ReportWritePage />} /> */}
                <Route path={`${D.reportWritePath}/:scanId`} element={<ReportWritePage />} />
                <Route path={`${D.reportEditPath}/:reportId`} element={<ReportEditPage />} />

                {/* <Route path='/admin' element={<AdminPage />} /> */}

                <Route path='/patients' element={<PatientsPage />} />

                <Route path='/profile' element={<UserProfilePage />} />
                <Route path='/profile/security' element={<UserProfilePage tab='security' />} />

                <Route path='/login' element={<LoginPage />} />
                <Route path='/forgot' element={<LoginForgotPage />} />
                <Route path='/join' element={<JoinPage />} />
                <Route path='/confirm' element={<JoinConfirmUserEmailPage />} />

                <Route path='/scan/:scanId' element={<ScanViewPage />} />

                <Route path='/wip' element={<WipScansPage />} />
                <Route path='/dev' element={<DevPage />} />
                <Route path='/aso' element={<AsoHomePage />} />
                <Route path='/contact' element={<ContactUsPage />} />

                <Route path='*' element={<NoMatch />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);
