import * as Api from 'api'
import * as ScanApi from '../ScanData/DataApi';
import * as ServerApi from '../ScanData/DataFetch'
import * as Cache from "../ScanData/DataCache";

import { ScanViewContext } from "../ScanViewContext";
import { LoadProgressData } from "../ScanComponents/ScanLoadingProgress";
import { processScanState } from './ScanData';
import { queryScanJsonResource } from '../ScanData/DataFetch';


interface ILoadScanCallback {
    (progress: LoadProgressData): void;
};

interface ILoadScanOnError {
    (e: Error): void;
};


export async function ScanViewCtx_startDataFetch(this: ScanViewContext, onProgress: ILoadScanCallback, onError: ILoadScanOnError) 
{
    if (this.fetched || this.fetchStarted)
        return;
        
    this.fetchStarted = true;


    let scanId = this.scanId;

    let total = 7;
    let current = 0;

    let summaryData: ScanApi.ApiScanSummary | undefined  = undefined;

    const next = () => {
        current++;
        onProgress(new LoadProgressData(current, total));
    };

    const finish = () => {
        this.scanSummaryDataState = summaryData;
        this.fetched = true;
        processScanState(this);
        current = total - 1;
        next();
    }

    function onCatch(e: Error) {
        onError(e);
    }


   
    
    next();

    await Cache.cacheInit();
    next();


    let pendingRequests = new Map<string, boolean>();

    function processReceived(url: string) {
        next();
        pendingRequests.set(url, false);

        let pendingCount = 0;
        for (let [key, val] of pendingRequests) {
            pendingCount += val ? 1 : 0;
        }

        if (pendingCount === 0) {
            // DEBUG: debug progress
            //return;

            finish();
        }

        // TODO: debug
        //return onError(new Error('Test'));
    }

    let scanCtx = this;

    // This function is called when the res.json is received from the server
    function onSummaryReceived(sd: ScanApi.ApiScanSummary, api: Api.ApiScanEndpoints) {
        summaryData = sd;  // We will use it for the final processing
        next();

        // Now download all necessary resources
        for (let res of sd.summary.resources) {
            //console.log(res);
            //debug_.check("received", res.name);

            // Schedule downloads if we have to
            switch (res.name) {
            case 'scanInfo':{
                let url = api.info!.url;
                pendingRequests.set(url, true);
                queryScanJsonResource<ScanApi.ApiSpecimenData>(scanCtx.accountId, scanId, url, 'scanInfo', res.hash).then(sd => {
                    scanCtx.scanInfoDataState.load(sd);
                    processReceived(url);
                }).catch(onCatch);
                break;
            }
            case 'tilesInfo': {
                let url = api.tilesDesc!.url;
                pendingRequests.set(url, true);
                queryScanJsonResource<ScanApi.ApiTileInfoContainer>(scanCtx.accountId, scanId, url, 'tilesInfo', res.hash).then(sd => {
                    scanCtx.tileInfoDataState.load(sd);
                    processReceived(url);
                }).catch(onCatch);
                break;
            }

            case 'aoiInfo': {
                let url = api.aoiData!.url;
                pendingRequests.set(url, true);
                queryScanJsonResource<ScanApi.AoiDataContents>(scanCtx.accountId, scanId, url, 'aoiInfo', res.hash).then(sd => {
                    scanCtx.aoiInfoDataState.load(sd);
                    processReceived(url);
                }).catch(onCatch);
            }
            break;

            default:
                break;
            }
        }


    }

    // Main scan info
    function onScanState(sd: Api.ApiScanStateData) {
        scanCtx.scanStateDataState.load(sd);
        next();

        // Now request the summary: list of the resources
        ServerApi.fetchScanJsonResource<ScanApi.ApiScanSummary>(scanCtx.accountId, scanId, sd.api.res!.url)
            .then(data => onSummaryReceived(data, sd.api)).catch(onCatch);
    }

    // First load scan state to get url locations, etc.
    if (!this.scanStateDataState.requested) {
        this.scanStateDataState.requested = true;
        Api.requestSession('scan', 'st', {scanId: scanId}).then(onScanState).catch(onCatch);
        //ServerApi.queryScanState(scanCtx.accountId, scanId).then(onScanState).catch(onCatch);
    }


    // let total = 10;
    // let current = 0;

    // setTimeout(function onTimeout() {
    //     current++;
    //     callback(new LoadProgressData(current, total));
    //     if (current < total)
    //         setTimeout(onTimeout);
    // }, 1000);
}

