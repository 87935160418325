import * as Geo from "tslib/geo";

import * as NavMethods from './Methods/ScanNavigation';

// Surface controller state (touches, mouse, etc)
// TODO: probably implement inheritance. Some data members are unnessecary
export class NavControllerContext {
    evScreenCord = new Geo.Point();  //  Event screen coordinates
    lastScreenCord = new Geo.Point();  //  Last screen coordinates
    evScreenCordAxis = new Geo.Point();  //  Current screen coordinates relative to the rotation axis
    lastScreenCordAxis = new Geo.Point();  //  Last screen coordinates relative to the rotation axis


    evRotAxis = new Geo.Angle();  // Event angle relative to the axis
    lastRotAxis = new Geo.Angle();  // Last angle relative to the axis

    downTimestamp = 0;
    upTimestamp = 0;
    lastDownTimestamp = 0;
    lastUpTimestamp = 0;

    //currRot = new Geo.Angle();  // Current angle relative to the axis

    // Touches support
    touchDownCord = new Geo.Point();
    
    evTouch2Cord = new Geo.Point();  // For the second touch
    lastTouch2Cord = new Geo.Point();  //  For the second finger
    evTouchAngle = new Geo.Angle();  // The angle between two touches
    lastTouchAngle = new Geo.Angle();  // The angle between two touches
    touchZoomChanged = true;
    touchRotChanged = true;
    touchPosChanged = true;
    evTouchDist = 0;  // Distance between two touches
    lastTouchDist = 0;
    evScanPos1 = new Geo.Point();  // touch scan position
    evScanPos2 = new Geo.Point();  // touch scan position
    lastScanPos1 = new Geo.Point(); // last scan pos
    lastScanPos2 = new Geo.Point(); // last scan pos


    setDragCoords(ev: Geo.IPoint, axis: Geo.IPoint) {
        this.evScreenCord.assign(ev);

        this.evScreenCordAxis.assign(ev);
        this.evScreenCordAxis.sub(axis);
    }

    setTouchDouble(t1: Touch, t2: Touch) {
        NavMethods.assignTouchToPoint(this.evScreenCord, t1);
        NavMethods.assignTouchToPoint(this.evTouch2Cord, t2);
        this.evTouchDist = Geo.pointDist(this.evScreenCord, this.evTouch2Cord);

        this.evTouchAngle.setPos({x: this.evScreenCord.x - this.evTouch2Cord.x, y: this.evScreenCord.y - this.evTouch2Cord.y});
    }

    calcPosAngle() {
        this.evRotAxis.setPos(this.evScreenCordAxis);
    }

    stashPositionLast() {
        this.lastScreenCord.assign(this.evScreenCord);
        this.lastScreenCordAxis.assign(this.evScreenCordAxis);
        this.lastRotAxis.assign(this.evRotAxis);

        //this.lastDownTimestamp = this.downTimestamp;
        //this.lastUpTimestamp = this.upTimestamp;
    }

    stashRotationLast() {
        this.calcPosAngle();
        this.stashPositionLast();
    }

    stashTouchLast() {
        this.stashPositionLast();
        this.lastTouch2Cord.assign(this.evTouch2Cord);
        if (this.touchZoomChanged) {
            this.lastTouchDist = this.evTouchDist;
        }
        if (this.touchRotChanged) {
            this.lastTouchAngle.assign(this.evTouchAngle);
        }
        if (this.touchPosChanged) {
            this.lastScanPos1.assign(this.evScanPos1);
            this.lastScanPos2.assign(this.evScanPos2);
        }
    }
};

// Hold information about the states of all controllers, touches, keyboards, etc.
export class NavControllersState {

    // Handling the mouse
    isMousePositioning = false;  // If the user is dragging the scan
    isMouseRotating = false;
    isMouseResizing = false;

    currTouchesCount = 0; // current handling of the touches
    //isTouch = false;  // Single touch manipulation (moving)
    //isTouchDouble = false;  // Double finger manipulation (zooming, rotating, moving)
   

    // Contexts for different controller types.
    // What if the user is using several: mouse and touching screen
    mouseDragCtx = new NavControllerContext();
    mouseRotCtx = new NavControllerContext();
    touchDragCtx = new NavControllerContext();

    // Temporary stuff used for calculations
    tempTheta = new Geo.Angle();
    tempDelta = new Geo.Point(); // Current delta (difference between points)
    tempPos1 = new Geo.Point();
    tempPos2 = new Geo.Point()
};

