import {mat4, vec4} from 'gl-matrix';

import * as Geo from 'tslib/geo';
import * as Mtx from 'tslib/mtx';
import {Color} from 'tslib/color';

import {WglScene} from './webgl_scene';

import * as Vertex from './webgl_vertex';
import {ScanViewContext} from '../../ScanViewContext';
import {WglRenderDevice} from './webgl_device';
import {WglEasingState} from './webgl_easing';
import {WglShaderProgram} from './webgl_program';
import {IEasingParam} from '../NavEasing';

import * as Methods from './methods';

interface ITileTextureStateInit {
    numTiles: number;
}

export class WglTexture {
    glTexture?: WebGLTexture | null;
}

export class WglRenderer {
    initialized = false;

    device = new WglRenderDevice();

    // Used limitations. They are usually lower than returned by the device caps
    //maxTextureSize = 0;
    //maxTextures = 0;

    // Selected texture size and their number
    //texSize = 0;
    //numTextures = 0;

    // Rendering surface size
    surfaceSize = new Geo.Size();
    surfaceCenter = new Geo.Point(); // For rotation calculations
    scanSize = new Geo.Size();
    scanCenter = new Geo.Point();

    scene = new WglScene();

    gl: WebGLRenderingContext | null = null;

    // All availabel shaders
    shaderPrograms: WglShaderProgram[] = [];

    // References to the shaders
    backgroundShaderRef?: WglShaderProgram; // Shader to render the background
    tileShaderRef?: WglShaderProgram; // Shaders for the actual tiles
    blankShaderRef?: WglShaderProgram; // Shaders for blank and empty tiles
    markerShaderRef?: WglShaderProgram; // Pathogen markers
    busyShaderRef?: WglShaderProgram; // Busy indicator

    //sampleProgram?: WglShaderProgram;

    // WebGL Buffers
    glVertexBuffer: WebGLBuffer | null = null;
    glIndexBuffer: WebGLBuffer | null = null;

    // All the matrices
    projMtx = Mtx.create4();

    mtxI = Mtx.createEye4(); // identity matrix

    // Navigation view matrix (includes rotation)
    mtxNavView = Mtx.createEye4();
    mtxNavViewInv = Mtx.createEye4();

    // Navigation model matrix (includes offset)
    mtxNavModl = Mtx.createEye4();
    mtxNavModlInv = Mtx.createEye4();

    viewMtxChanged = false;
    projMtxChanged = false;

    // Current navigation situation
    navPos = new Geo.Point();
    navRotAngle = new Geo.Angle();
    navRotAngleInv = new Geo.Angle();
    navRotAxis = new Geo.Point();
    navZoomLevel = 0;
    navZoomPending = true;

    // Processed navigation parameters for fast matrix updates
    mtxRotOffset = new Geo.Point();
    mtxViewOffset = new Geo.Point();
    zoomedScanSize = new Geo.Size();
    zoomedScanCenter = new Geo.Point();

    // Zooming and scaling related stuff
    zoomMulToView = 1;
    zoomMulToScan = 1;
    mtxViewOffsetOffset = new Geo.Point();

    numVertices = 0;
    vertexBufferData = new Float32Array(Vertex.Components);

    numIndices = 0;
    indexBufferData = new Uint16Array(1);

    started = false;
    stopped = false;
    requestFrameId = 0;

    constructor(scanCtx: ScanViewContext) {
        this.scanSize.assign(scanCtx.scanInfo.scanSize);
        this.scanCenter.set(this.scanSize.w / 2, this.scanSize.h / 2);
    }

    //
    // Initialization
    init = Methods.WglRenderer_init;
    release = Methods.WglRenderer_release;

    // Setup
    // Scene update callbacks
    sceneUpdatePending_ = false;
    sceneUpdateCallback?: () => void;
    scheduleSceneUpdate() {
        this.sceneUpdatePending_ = true;
    }

    // Launching
    launch = Methods.WglRenderer_launch;
    start = Methods.start;
    stop = Methods.stop;

    //
    // Coordinates calculation
    //
    setZoomLevel = Methods.WglRenderer_setZoomLevel;
    setScanLocation = Methods.WglRenderer_setScanLocation;
    updateViewMatrices = Methods.WglRenderer_updateViewMatrices;
    convertScanToScreenFromTo = Methods.Wgl_cvtScanToScreenFromTo;
    convertScreenToScanFromTo = Methods.Wgl_cvtScreenToScanFromTo;

    invalidateProjMtx() {
        for (let prog of this.shaderPrograms!) {
            prog.projMtxChanged = true;
        }
        this.projMtxChanged = true;
        this.scene.changed = true;
    }

    invalidateViewMtx() {
        for (let prog of this.shaderPrograms!) {
            prog.viewMtxChanged = true;
        }
        this.viewMtxChanged = true;
        this.scene.changed = true;
    }

    onWindowResize = Methods.Wgl_onWindowResize;

    //
    // Rendering
    //
    renderFrame = Methods.renderFrame;

    //
    // Textures
    //
    createTexture = Methods.createTexture;
    releaseTexture = Methods.releaseTexture;
    setTextureImage = Methods.setTextureImage;
    setTextureSubImage = Methods.setTextureSubImage;

    //
    // Shaders
    //
    initShaders = Methods.initShaders;
    compileShader = Methods.compileShader;
    createShader = Methods.createShader;
    deleteProgram = Methods.deleteProgram;
    glCreateProgram = Methods.glCreateProgram;

    checkShaderStatus = Methods.checkShaderStatus;
    requireAttribLoc = Methods.requireAttribLoc;
    getUniformLoc = Methods.getUniformLoc;
    requireUniformLoc = Methods.requireUniformLoc;

    setUniformFloat(uni: WebGLUniformLocation | null, val: number) {
        this.gl!.uniform1f(uni, val);
    }

    setUniformFloat2(uni: WebGLUniformLocation | null, f1: number, f2: number) {
        this.gl!.uniform2f(uni, f1, f2);
    }

    setUniformInt(uni: WebGLUniformLocation | null, val: number) {
        this.gl!.uniform1i(uni, val);
    }

    setUniformVec4(uni: WebGLUniformLocation, vec: vec4) {
        this.gl!.uniform4fv(uni, vec);
    }

    setUniformMtx(uni: WebGLUniformLocation, mtx: Mtx.Mtx4) {
        this.gl!.uniformMatrix4fv(uni, false, mtx.a);
    }

    setUniformColor(uni: WebGLUniformLocation | null, color?: Color) {
        if (color) this.gl!.uniform4f(uni, color.r, color.g, color.b, color.a);
    }

    //
    // Animation
    //

    // Animating general parameters
    easing = false;
    easingFinished = false;
    private easingState = new WglEasingState();

    getEasingLoc() {
        return this.easingState.loc;
    }

    startEasing(params: IEasingParam) {
        this.easing = true;
        this.easingFinished = false;
        this.easingState.start(params);
    }

    processEasingFrame(time: number) {
        let finished = this.easingState.tick(time);

        let clamped = this.clampScanPosition(this.easingState.loc.pos);

        //if (this.easingState.loc.pos.x

        this.setScanLocation(this.easingState.loc);

        if (finished || clamped) {
            this.easingFinished = true;
            this.easing = false;
        }
    }

    clampScanPosition(pos: Geo.IPoint): boolean {
        let clamped = false;
        if (pos.x < 0) {
            pos.x = 0;
            clamped = true;
        } else if (pos.x >= this.scanSize.w) {
            pos.x = this.scanSize.w - 1;
            clamped = true;
        }

        if (pos.y < 0) {
            pos.y = 0;
            clamped = true;
        } else if (pos.y >= this.scanSize.h) {
            pos.y = this.scanSize.h - 1;
            clamped = true;
        }

        return clamped;
    }

    //
    // Device caps Detection
    //
    detectGraphicsCaps() {
        this.device.detect(this.gl!);

        //this.maxTextures = 16;
        //if (this.maxTextures > this.dev.maxTextureUnits)
        //    this.maxTextures = this.dev.maxTextureUnits;

        //this.maxTextureSize = 4096;
        //this.maxTextureSize = 2048;  // TODO: debug
        //if (this.maxTextureSize > this.dev.maxTextureSize)
        //    this.maxTextureSize = this.dev.maxTextureSize;

        //this.texSize = this.maxTextureSize;
    }

    debugDumpDeviceCaps() {
        console.group('Device Caps');
        this.device.debugDump();
        console.groupEnd();
    }

    // Debugging
    debugDumpBatchVertices = Methods.WglRenderer_debugDumpBatchVertices;
}
