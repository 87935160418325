import {ScanSceneLevel} from './ScanSceneLevel';

import * as Geo from 'tslib/geo';
//import {Debug} from 'lib/debug'

import {scanTheme_} from '../../ScanTheme/ScanTheme';

import {SceneTileStatus} from './SceneTileStatus';
import {ScanLocation} from '../../ScanTypes/ScanLocation';

//const debug_ = new Debug({enabled: false, name: 'LevelScene'})

//
// Build zoom level scene
//
export function SceneLevel_OnBuild(this: ScanSceneLevel) {
    // debug_.check(`onBuild: ${this.tileZoom}`)

    let ren = this.renRef_!;
    let setup = ren.setup;

    // Coefficients
    this.zMul_ = Math.abs(2 ** -this.tileZoom);
    this.zDiv_ = Math.abs(1 / this.zMul_);

    let zm = this.zMul_;

    this.texScanCov_.assign(setup.texSize).scale(zm);
    this.tileScanCov_.assign(setup.tileSize).scale(zm);

    this.scanCoverSize.assign(this.texScanCov_).scaleSize(setup.texPerScene);
    this.scanRenderRect.setSize(this.scanCoverSize);

    this.tilesIdxTotal_.set(
        Math.ceil(this.scanSize_.w / this.tileScanCov_.w),
        Math.ceil(this.scanSize_.h / this.tileScanCov_.h)
    );

    this.tilesIdxRect_.setPos({x: Infinity, y: Infinity}).setSize(setup.tilesPerScene);
    this.tilesIdxMax_.set(this.tilesIdxTotal_.w - this.tilesIdxRect_.w, this.tilesIdxTotal_.h - this.tilesIdxRect_.h);

    this.calcNavAllowance();

    // Thumbnail texture for the background
    this.backgroundTexture = ren.thumbSceneRef!.textures[0];

    this.orderedTilesCache_.length = 0;

    // For all textures
    let texIndex = 0;
    for (let texJ = 0; texJ < setup.texPerScene.h; ++texJ) {
        for (let texI = 0; texI < setup.texPerScene.w; ++texI, ++texIndex) {
            let tex = this.addTexture(ren.texSceneViewRef[texIndex]);
            tex.sceneIndex.set(texI, texJ);
            tex.scanRect.setSize(this.texScanCov_);

            // For all tiles in the texture
            for (let tileJ = 0; tileJ < setup.tilesPerTex.h; ++tileJ) {
                for (let tileI = 0; tileI < setup.tilesPerTex.w; ++tileI) {
                    let tile = tex.addTile();
                    tile.scanRect.setSize(this.tileScanCov_);

                    tile.scanLoc.zoom = this.tileZoom;
                    tile.scanLoc.clearPos();
                    tile.texIndex.set(tileI, tileJ);

                    tile.texRect.set(tile.texIndex.x, tile.texIndex.y, 1, 1).scaleSize(setup.tileSize);
                    tile.texRectNorm.assign(tile.texRect).divSize(setup.texSize);

                    this.orderedTilesCache_.push(tile);
                }
            }
        }
    }

    this.buildScenePatMarkers();
}

//
// When the scene is switched to
export function SceneLevel_OnActivate(this: ScanSceneLevel) {
    // debug_.check(`onActivate: ${this.tileZoom}`)

    //console.debug("onActivate");
    for (let tex of this.textures) {
        for (let tile of tex.tiles) {
            tile.scanLoc.clearPos();
        }
    }
}

export function SceneLevel_OnDeactivate(this: ScanSceneLevel) {
    //  debug_.check(`onDeactivate: ${this.tileZoom}`)

    let ren = this.renRef_!;
    for (let tex of this.textures) {
        for (let tile of tex.tiles) {
            if (tile.status === SceneTileStatus.Pending) {
                ren.cancelTileImageSourceFetch(tile);
            }
            ren.releaseTileImageSource(tile);
        }
    }

    //this.lastZoom = Infinity;
}

export function SceneLevel_OnLayoutChange(this: ScanSceneLevel, loc: ScanLocation) {
    // debug_.check(`onLayoutChange: ${this.tileZoom}`)

    this.calcNavAllowance();
    this.calcNavBounds();
    this.calcVisibleScanRect(loc);
}

export function SceneLevel_OnLocation(this: ScanSceneLevel, loc: ScanLocation): boolean {
    // debug_.check(`onLocation: ${this.tileZoom}. loc: ${loc.pos.x},${loc.pos.y}`)

    // Check for the zoom changes first
    if (loc.zoom !== this.lastZoom) {
        return this.processZoom(loc);
    }

    this.calcVisibleScanRect(loc);

    // Check the distance from the current location to the covered area
    let inNavBounds = this.scanNavBounds.containsPoint(loc.pos);

    if (inNavBounds) {
        return this.processPosition(loc);
    }

    // Now check if the tile layout changes
    // If tiles layout changes, process it
    if (this.computeTilesLayout(loc)) {
        return this.processLayout(loc);
    }

    // If we are here then we just update position
    return this.processPosition(loc);
}

//
// Called when the layout changes (zoom changes)
//
export function SceneLevel_processZoom(this: ScanSceneLevel, loc: ScanLocation): boolean {
    // debug_.check(`processZoom: ${this.tileZoom}`)

    this.lastZoom = loc.zoom;

    // Calculate the area size this scene covers
    let ren = this.renRef_!;
    let nav = ren.nav!;

    this.screenToScanK_ = nav.zoomMulToScan;
    this.scanToScreenK_ = nav.zoomMulToView;

    this.computeTilesLayout(loc);

    this.calcNavAllowance();
    this.calcNavBounds();
    this.calcVisibleScanRect(loc);

    this.processLayout(loc);

    return true;
}

//
// Called when the position changed and its out of the scene
//
export function SceneLevel_processLayout(this: ScanSceneLevel, loc: ScanLocation): boolean {
    // debug_.check(`processLayout: ${this.tileZoom}`)

    let ren = this.renRef_!;
    let setup = this.renRef_!.setup;

    //
    // Calculate the size of the current scene on the screen
    //

    // Now we determined the scene location in tiles
    // Organize the textures now and tiles request the data sources
    let tilesLocChanged = false;
    let tileVisibilityChanged = false;

    let texIndex = 0;
    let texY = this.scanRenderRect.y;
    for (let texJ = 0; texJ < setup.texPerScene.h; ++texJ, texY += this.texScanCov_.h) {
        let texX = this.scanRenderRect.x;
        for (let texI = 0; texI < setup.texPerScene.w; ++texI, ++texIndex, texX += this.texScanCov_.w) {
            let tex = this.textures[texIndex];

            [tex.scanRect.x, tex.scanRect.y] = [texX, texY];

            // tex.scanRectVtx.setRect(tex.scanRect);
            // ren.convertScanRectVtxToScreenFromTo(tex.scanRectVtx, tex.screenRectVtx);
            // tex.screenVisible = ren.isScanScreenRectVisible(tex.screenRectVtx);
            tex.screenVisible = this.isScanRectVisible(tex.scanRect);

            //console.debug(`tex visible: ${texI}.${texJ}`, tex.screenBottomLeft, tex.screenTopRight, tex.screenVisible);

            // For all tiles in the texture
            let tileY = tex.scanRect.y;
            let tileRow = this.tilesIdxRect_.y + texJ * setup.tilesPerTex.h;
            let tileIndex = 0;
            for (let tileJ = 0; tileJ < setup.tilesPerTex.h; ++tileJ, ++tileRow, tileY += this.tileScanCov_.h) {
                let tileCol = this.tilesIdxRect_.x + texI * setup.tilesPerTex.w;
                let tileX = tex.scanRect.x;
                for (
                    let tileI = 0;
                    tileI < setup.tilesPerTex.w;
                    ++tileI, ++tileIndex, ++tileCol, tileX += this.tileScanCov_.w
                ) {
                    let tile = tex.tiles[tileIndex];

                    [tile.scanRect.x, tile.scanRect.y] = [tileX, tileY];
                    tile.screenVisible = tex.screenVisible && this.isScanRectVisible(tile.scanRect);

                    // Compare the tile locations first.
                    // If location is the same do not change anything
                    if (tileCol !== tile.scanLoc.col || tileRow !== tile.scanLoc.row) {
                        tilesLocChanged = true; //  Tiles changed, we have to reload them

                        tile.scanLoc.setPos(tileCol, tileRow);

                        ren.releaseTileImageSource(tile);
                        //tile.status = SceneTileStatus.Pending; // status = SceneTileStatus.Unknown;
                    }
                }
            }
        } // Textures along X
    } // Textures along Y

    if (!tilesLocChanged) return false;

    //if (this.orderedTilesCache_.length !== 0) {
    // Sort the images by the distance from the current position
    // and start downloading based on that.
    // So the user will see first images close to position

    for (let tile of this.orderedTilesCache_) {
        let dx = tile.scanRect.x + tile.scanRect.w / 2 - loc.pos.x;
        let dy = tile.scanRect.y + tile.scanRect.h / 2 - loc.pos.y;
        tile.locDist = dx * dx + dy * dy;
        //this.orderedTilesCache_.push(tile);
    }

    //this.orderedTilesCache_.sort((a, b) => a.locDist - b.locDist);  // Ascending
    this.orderedTilesCache_.sort((a, b) => b.locDist - a.locDist); // Descending

    //console.debug("acquiring tiles", this.orderedTilesCache_.length);

    for (let tile of this.orderedTilesCache_) {
        //console.debug("acquiring:", tile.scanLoc);

        let needsFetching = ren.acquireTileImageSource(tile);
        //console.debug(tile.screenVisible);
        if (needsFetching && tile.screenVisible) {
            ren.fetchTileImageSource(tile);
        }
        //if (tile.imgSrc) {
        //    this.pendingImageSources_.delete(tile.imgSrc);
        //} else {  // If the image fetching is pending, then stop it
        // TODO: add simultenaous fetching limit param
        //    break;
        //}
    }
    //}

    // Now cancel all the unused image sources
    // for (let imgSrc of this.pendingImageSources_) {
    //     ren.cancelTileImageSourceFetch(imgSrc);
    // }
    // this.pendingImageSources_.clear();

    return true;
}

export function SceneLevel_processPosition(this: ScanSceneLevel, loc: ScanLocation): boolean {
    // debug_.check(`processPosition: ${this.tileZoom}`)

    let ren = this.renRef_!;

    for (let tex of this.textures) {
        tex.screenVisible = this.isScanRectVisible(tex.scanRect);

        if (!tex.screenVisible) continue;

        for (let tile of tex.tiles) {
            let tileVisible = tex.screenVisible && this.isScanRectVisible(tile.scanRect);

            if (tile.screenVisible !== tileVisible) {
                tile.screenVisible = tileVisible;

                if (tex.screenVisible) {
                    if (tile.status === SceneTileStatus.Pending) {
                        ren.fetchTileImageSource(tile);
                    }
                }
            }
        }
    }

    return false;
}

//
// Computes new bounds the current scene covers
// Returns rectangle in tile indices required to render the scene
//

export function SceneLevel_computeTilesLayout(this: ScanSceneLevel, loc: ScanLocation): boolean {
    let ren = this.renRef_!;
    let setup = this.renRef_!.setup;

    let zk = this.zMul_;

    let boundsLeft = loc.pos.x - this.scanCoverSize.w / 2;
    let boundsRight = loc.pos.x + this.scanCoverSize.w / 2;
    let boundsBottom = loc.pos.y - this.scanCoverSize.h / 2;
    let boundsTop = loc.pos.y + this.scanCoverSize.h / 2;

    // First compute current optimal position of the tiles
    // Determine center tile location
    //let locTileFloat = Geo.makePoint(loc.pos.x / ren.tileSize.w, loc.pos.y / ren.tileSize.h);

    // TODO: check if we need to use "render.scanToView"
    let tileIdxFloat = Geo.makePoint(loc.pos.x / this.tileScanCov_.w, loc.pos.y / this.tileScanCov_.h);
    let tileIdx = Geo.makePoint(Math.trunc(tileIdxFloat.x), Math.trunc(tileIdxFloat.y));
    let tileIdxFrac = Geo.makePointSub(tileIdxFloat, tileIdx);

    // tileIdxFrac.x > 0.5 && tileIdx.x++;
    // tileIdxFrac.y > 0.5 && tileIdx.y++;

    //let [tpsW, tpsH] = [Math.ceil(setup.tilesPerScan.w / zk), Math.ceil(setup.tilesPerScan.h / zk)];

    let tileIdxX = 0;
    let tileIdxY = 0;
    //let tilesIdxRect = Geo.makeRect(0, 0, setup.tilesPerScene.w, setup.tilesPerScene.h);

    // Check border line conditions
    if (boundsLeft <= 0) {
        tileIdxX = 0;
    } else if (boundsRight >= this.scanSize_.w) {
        tileIdxX = this.tilesIdxMax_.w;
    } else {
        tileIdxX = Math.floor(tileIdx.x - this.tilesIdxRect_.w / 2);
    }

    if (boundsBottom <= 0) {
        tileIdxY = 0;
    } else if (boundsTop >= this.scanSize_.h) {
        tileIdxY = this.tilesIdxMax_.h;
    } else {
        tileIdxY = Math.floor(tileIdx.y - this.tilesIdxRect_.h / 2);
    }

    if (tileIdxX < 0) tileIdxX = 0;
    if (tileIdxY < 0) tileIdxY = 0;

    if (tileIdxX === this.tilesIdxRect_.x && tileIdxY === this.tilesIdxRect_.y) {
        return false;
    }

    [this.tilesIdxRect_.x, this.tilesIdxRect_.y] = [tileIdxX, tileIdxY];

    let scanPos = this.tilesIdxRect_.getPoint().scaleSize(this.tileScanCov_);

    this.scanRenderRect.setPos(scanPos);
    this.scanBoundsRect.assign(this.scanRenderRect);
    if (this.scanBoundsRect.getRight() > this.scanSize_.w)
        this.scanBoundsRect.w = this.scanSize_.w - this.scanBoundsRect.x;

    if (this.scanBoundsRect.getTop() > this.scanSize_.h)
        this.scanBoundsRect.h = this.scanSize_.h - this.scanBoundsRect.y;

    // // Now determine the scene boundaries
    // let bl = this.tilesIdxRect_.x * this.tileScanCov_.w;
    // let br = (this.tilesIdxRect_.x + this.tilesIdxRect_.w) * this.tileScanCov_.w;
    // if (br > this.scanSize_.w)
    //     br = this.scanSize_.w;

    // let bb = this.tilesIdxRect_.y * this.tileScanCov_.h;
    // let bt = (this.tilesIdxRect_.y + this.tilesIdxRect_.h) * this.tileScanCov_.h;
    // if (bt > this.scanSize_.h)
    //     bt = this.scanSize_.h;

    // this.scanNavBounds.lb.set(bl, bb);
    // this.scanNavBounds.lt.set(bl, bt);
    // this.scanNavBounds.rt.set(br, bt);
    // this.scanNavBounds.rb.set(br, bb);

    this.calcNavBounds();

    /*

    // TODO: adjust for screen size and for some margin to start loading images
    let navViewSize = this.scanRect.getSize();

    // Compute how much from the center of the screen we want to start updating
    let horzOffset = 0, vertOffset = 0; 
    
    if (navViewSize.w > ren.surfaceSize.w * 4)
        horzOffset = ren.surfaceSize.w * 0.8;
    else if (navViewSize.w > ren.surfaceSize.w * 2)
        horzOffset = ren.surfaceSize.w * 0.5;
    else if (navViewSize.w > ren.surfaceSize.w)
        horzOffset = ren.surfaceSize.w * 0.25;

    if (navViewSize.h > ren.surfaceSize.h * 4)
        vertOffset = ren.surfaceSize.h * 0.8;
    else if (navViewSize.h > ren.surfaceSize.h * 2)
        vertOffset = ren.surfaceSize.h * 0.5;
    else if (navViewSize.w > ren.surfaceSize.w)
        vertOffset = ren.surfaceSize.h * 0.25;


    let scanRect = new Geo.Rect();
    

    if (boundsLeft <= 0) {
        scanRect.x = 0;
    } else if(boundsRight >= this.scanSize_.w) {
        scanRect.x = this.scanSize_.w - navViewSize.w;
    } else {
        scanRect.x = tilesIdxRect.x * this.tileScanCov_.w;

        let offx = Math.ceil(horzOffset / this.tileScanCov_.w) * this.tileScanCov_.w;
        scanRect.x += offx;
        navViewSize.w -= offx * 2;
    }

    if (boundsBottom <= 0) {
        scanRect.y = 0;
    } else if (boundsTop >= this.scanSize_.h) {
        scanRect.y = this.scanSize_.h - navViewSize.h;
    } else {
        scanRect.y = tilesIdxRect.y * this.tileScanCov_.h;

        let offy = Math.ceil(vertOffset / this.tileScanCov_.h) * this.tileScanCov_.h;
        scanRect.y += offy;
        navViewSize.h -= offy * 2;
    }
    
    [scanRect.w, scanRect.h] = [navViewSize.w, navViewSize.h];

    //this.scanRectVtx.setRect(scanRect);
    this.scanRect.setPos(scanRect);

    console.debug(scanRect.getSize(), this.scanRect.getSize());

    //console.debug(this.scanRectVtx);

    // console.debug(navViewSize.w, this.maxScanX - this.minScanX);
    // console.debug(nav.currLoc.pos.x, nav.currLoc.pos.y, " => ", this.minScanX, this.minScanY, this.maxScanX, this.maxScanY);

    this.tilesIdxRect_.assign(tilesIdxRect);    
    */

    return true;
}

export function SceneLevel_calcNavAllowance(this: ScanSceneLevel) {
    // TODO: check the theme.
    let radiusK = 1.6;
    let radiusAllowance = scanTheme_.scanLensRadius * radiusK;

    let visibleRadiusK = 2;
    this.scanVisibleRadius = scanTheme_.scanLensRadius * visibleRadiusK * this.screenToScanK_;

    //console.debug(ScanTheme.scanLensRadius, this.scanVisibleRadius);

    this.scanNavOffset = Math.floor(radiusAllowance * this.screenToScanK_);

    if (this.scanNavOffset > this.scanRenderRect.w / 3) {
        this.scanNavOffset = 0;
    }
}

export function SceneLevel_calcNavBounds(this: ScanSceneLevel) {
    this.scanNavBounds.assign(this.scanBoundsRect);

    if (this.scanNavBounds.getRight() < this.scanSize_.w) {
        this.scanNavBounds.w -= this.scanNavOffset;
    }

    if (this.scanNavBounds.x > 0) {
        this.scanNavBounds.x += this.scanNavOffset;
        this.scanNavBounds.w -= this.scanNavOffset;
    }

    if (this.scanNavBounds.getTop() < this.scanSize_.h) {
        this.scanNavBounds.h -= this.scanNavOffset;
    }

    if (this.scanNavBounds.y > 0) {
        this.scanNavBounds.y += this.scanNavOffset;
        this.scanNavBounds.h -= this.scanNavOffset;
    }
}

export function SceneLevel_calcVisibleScanRect(this: ScanSceneLevel, loc: ScanLocation) {
    let x = loc.pos.x - this.scanVisibleRadius;
    let y = loc.pos.y - this.scanVisibleRadius;
    let w = this.scanVisibleRadius * 2;
    let h = w;
    this.scanVisibleRect.set(x, y, w, h);
}

export function SceneLevel_isScanRectVisible(this: ScanSceneLevel, r: Geo.IRect) {
    if (
        r.x + r.w < this.scanVisibleRect.x ||
        r.x > this.scanVisibleRect.x + this.scanVisibleRect.w ||
        r.y + r.h < this.scanVisibleRect.y ||
        r.y > this.scanVisibleRect.y + this.scanVisibleRect.h
    )
        return false;

    return true;
}

class ScanLocationDistanceResult {
    outside: boolean;
    minDist = Number.MAX_VALUE;
    minDistX = Number.MAX_VALUE;
    minDistXVtx = 0;
    minDistY = Number.MAX_VALUE;
    minDistYVtx = 0;

    constructor(outside: boolean) {
        this.outside = outside;
    }
}

export function SceneLevel_calcLocCoverage(this: ScanSceneLevel, loc: ScanLocation) {
    /*
    if (loc.pos.x < this.scanRect.x || loc.pos.x >= this.scanRect.x + this.scanRect.w
        || loc.pos.y < this.scanRect.y || loc.pos.y >= this.scanRect.y + this.scanRect.h ) {
        return new ScanLocationDistanceResult(true);
    }

    let res = new ScanLocationDistanceResult(false);

    let lDist = loc.pos.x - this.scanRect.x;
    let bDist = loc.pos.y - this.scanRect.y;
    let rDist = this.scanRect.x + this.scanRect.w - loc.pos.x;
    let tDist = this.scanRect.y + this.scanRect.h - loc.pos.y;

    if (lDist < rDist) {
        res.minDistX = lDist;
        res.minDistXVtx = 0;
    } else {
        res.minDistX = rDist;
        res.minDistXVtx = 1;
    }

    if (bDist < tDist) {
        res.minDistY = bDist;
        res.minDistYVtx = 0;
    } else {
        res.minDistY = tDist;
        res.minDistYVtx = 1;
    }

    res.minDist = Math.min(res.minDistX, res.minDistY);

    return res;
    */
}
