import * as Geo from 'tslib/geo';
import {WglRenderer, WglTexture} from '../webgl_renderer';

export function createTexture(this: WglRenderer, tex: WglTexture, size: Geo.ISize) {
    let gl = this.gl!;

    tex.glTexture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, tex.glTexture);
    //gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    // Flip the textures vertically
    //gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);

    // Reserve the actual texture buffer because
    // providing ImageBitmap and the size is somewhat problematic
    const pixels = new Uint8Array(size.w * size.h * 4);
    for (let i = 0, total = size.h * size.h * 4; i < total; i += 4) {
        pixels[i] = 0;
        pixels[i + 1] = 0xff;
        pixels[i + 2] = 0;
        pixels[i + 3] = 0xff;
    }

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size.w, size.h, 0, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
}

export function releaseTexture(this: WglRenderer, tex?: WglTexture): undefined {
    if (!tex) return undefined;

    let gl = this.gl!;
    if (tex.glTexture) {
        gl.deleteTexture(tex.glTexture);
        tex.glTexture = undefined;
    }

    //tex.release();
    return undefined;
}

export function setTextureImage(this: WglRenderer, tex: WglTexture, bitmap: ImageBitmap) {
    let gl = this.gl!;
    gl.bindTexture(gl.TEXTURE_2D, tex.glTexture!);

    //gl.texImage2D(gl.TEXTURE_2D, 0, fmt, image.width, image.height, 0, fmt, gl.UNSIGNED_BYTE, image);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, bitmap);
    //gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, bitmap);
}

export function setTextureSubImage(
    this: WglRenderer,
    tex: WglTexture,
    xoff: number,
    yoff: number,
    bitmap: ImageBitmap
) {
    let gl = this.gl!;
    gl.bindTexture(gl.TEXTURE_2D, tex.glTexture!);

    gl.texSubImage2D(gl.TEXTURE_2D, 0, xoff, yoff, gl.RGBA, gl.UNSIGNED_BYTE, bitmap);
}
