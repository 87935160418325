import * as Geo from "tslib/geo";
import * as ServerData from '../ScanData/DataApi';
import { ScanDataSource } from "../ScanDataManager/ScanDataSource";

export class RecogPathogen {
    status: string;
    pos: Geo.Point;
    radius: number;
    confidence: number;

    constructor(rt: ServerData.PathogenData) {
        this.status = rt.status;
        this.pos = Geo.apiToPoint(rt.pos);
        this.radius = rt.radius;
        this.confidence = rt.confidence;
    }
};


export class ScanRecogAoi {
    index: number;
    center: Geo.Point;
    status: string;
    radius: number;
    imageName: string;
    imageHash: string;
    imageType: string;
    confidence: number;
    tile: Geo.Point;
    pathogens: RecogPathogen[] = [];

    // Runtime stuff
    imageUrl!: string;
    imageDataUrl!: string;
    imageDataLoaded = false;
    imageDataError?: Error;
    imageDataSource?: ScanDataSource;

    constructor(aoid: ServerData.AoiData) {
        this.index = aoid.index;
        this.center = Geo.apiToPoint(aoid.center);
        this.status = aoid.status;
        this.radius = aoid.radius;
        this.imageName = aoid.image.name;
        this.imageHash = aoid.image.hash;
        this.imageType = aoid.image.type;
        this.confidence = aoid.confidence;
        this.tile = Geo.apiToPoint(aoid.tile);

        for (let pat of aoid.pathogens) {
            this.pathogens.push(new RecogPathogen(pat));
        }
    }

    release() {
        // this.imageDataSource?.release();
        this.imageDataSource = undefined;
    }
}

export class AoiContainer {
    count: number;
    imageExt: string;
    zoom: number;
    aoiData: ScanRecogAoi[] = [];

    constructor(aoid: ServerData.AoiContainerData) {
        this.count = aoid.count;
        this.imageExt = aoid.imageExt;
        this.zoom = aoid.zoom;

        for (let aoi of aoid.data) {
            this.aoiData.push(new ScanRecogAoi(aoi));
        }
    }

    release() {
        this.aoiData.forEach((v) => v.release());
        this.aoiData.length = 0;
    }

    getAoiByIndex(idx: number): ScanRecogAoi { return this.aoiData[idx]; }
};


