import React from "react";

import {Box, Link, List, ListItem, Typography} from '@mui/material';

import session_ from "api/session";

import * as Api from "api/api"

class ApiInfo {
    groups!: ApiGroup[];
}

class ApiGroup {
    title!: string;
    name!: string;
    description?: string;
    subGroups?: ApiGroup[];
    endpoints?: ApiEndpoint[]
}

class ApiEndpoint {
    name!: string;
    parameters?: ApiParameter[];
}

class ApiParameter {
    name!: string;
    placeholder!: string;
}


export default function DevApiTab() {

    let [apiInfo, setApiInfo] = React.useState<ApiInfo | null>(null);

    React.useEffect(() => {
        Api.requestSession<ApiInfo>('dev', 'api_info').then(apiInfo => {
            setApiInfo(apiInfo);
            //console.log(apiInfo);
        });
    }, []);


    if (!apiInfo) {
        return <p>Loading...</p>;
    }

            //height: '100%',

    return <>
        <Typography variant='h2' align='center'>Api Info</Typography>
        <Box sx={{
            maxHeight: 1, //'100%',
            overflow: 'auto',
        }}>
        {apiInfo!.groups.map((group, index) => RenderGroup(group, index))}
        </Box>
    </>

}


function RenderGroup(group: ApiGroup, index: number) {
    return <Box key={index}> 
        <Typography key={index} variant='h3' align='center'>{group.title}</Typography>
        <List dense>
            {group.subGroups?.map((sub, index) => RenderSubGroup(sub, index))}

            {group.endpoints?.map((ep, index) => RenderEndPoint(group, ep, index))}
        </List>
    </Box>;
}

function RenderSubGroup(group: ApiGroup, index: number) {
    return <Box key={index}> 
        <Typography key={index} variant='h4'>{group.title}</Typography>
        <List dense>
            {group.subGroups?.map((sub, index) => RenderSubGroup(sub, index))}

            {group.endpoints?.map((ep, index) => RenderEndPoint(group, ep, index))}
        </List>
    </Box>;

}

function RenderEndPoint(group: ApiGroup, ep: ApiEndpoint, index: number) {
    //console.debug("EndPoint groiup", group);

    let params: any = {};
    params["token"] = session_.token;
    if (ep.parameters) {
        for (let param of ep.parameters) {
            params[param.name] = param.placeholder;
        }
    }
    return <></>;

    // let link = Api.makeApiUrl(group.name, ep.name, params);

    // return <ListItem key={index}>
    //     <Link href={link} target='_blank'>{makeEndLinkLabel(link)}</Link>
    // </ListItem>
}

function makeEndLinkLabel(link: string) {
    let comps = link.split('/');
    let cmd = comps[comps.length-1].split('?')[0];
    return `${comps[comps.length-2]}/${cmd}`;
}
