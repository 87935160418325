import React from 'react'

import {Button, Grid, Stack, TextField} from '@mui/material'

import * as Api from 'api'
import {Scan, processScanDataItem} from 'data/Scan'

import * as F from 'tsui/Form'

import ErrorMessage from 'tsui/ErrorMessage'
import Env from 'env'
import {InputFormField} from 'tsui/Form/FormElements/FormFieldContext'
import {ScanDetailsProps} from '../ScanDetailsProps'

export default function ScanDetailsInfoTab(props: ScanDetailsProps) {
    const form = F.useForm({
        type: 'update-fields',
    })

    const [scan] = React.useState(props.scan)
    // const [updatedScan, setUpdatedScan] = React.useState<UpdatedScanParms>()

    // const onSubmit = React.useCallback((data) => {
    //     console.debug(data)
    //     Api.requestSession<Scan>('user', 'update_scan', {...data, scanId: props.scan.scanId})
    //         .then((data) => {
    //             console.log('scan was updated')
    //             console.log(data)
    //         })
    //         .catch((err) => console.log(err))
    // }, [])

    const onFieldUpdate = React.useCallback((field: InputFormField) => {
        //console.debug("Field Update:", field)
        form.setFieldBusy(field)

        //let val = field.value

        setTimeout(() => {
            //scan.scanName = field.value
            scan[field.id!] = field.value

            //console.debug(scan)
            form.clearFieldBusy(field)
        }, 2000)
    }, [])

    // if (errorMessage) return <ErrorMessage message={errorMessage} />

    return (
        <F.PageForm form={form} onFieldUpdate={onFieldUpdate} formContainer='none'>
            {/* <F.InputText id={scan.scanId} label='Scan Id' value={scan.scanId} form={form}/> */}
            <F.InputText id='scanId' label='Scan Id' value={scan.scanId} readonly form={form} />
            <F.InputText id='scanName' label='Scan Name' value={scan.scanName} form={form} />
        </F.PageForm>
    )

    /*
    return (
        <>
            {Env.isMobile ? (
                <Grid container spacing={2} sx={{width: 1}}>
                    <Grid item xs={12}>
                        <TextField
                            id='scanName'
                            label='Scan Name'
                            variant='outlined'
                            defaultValue={updatedScan?.scanName ?? scan.scanName}
                            onChange={(e) =>
                                setUpdatedScan({...updatedScan, scanName: e.target.value})
                            }
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id='reportId'
                            label='Report Id'
                            variant='outlined'
                            defaultValue={scan?.scanId}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='result'
                            label="Doctor's Result"
                            variant='outlined'
                            defaultValue={updatedScan?.result ?? scan?.result}
                            onChange={(e) =>
                                setUpdatedScan({...updatedScan, result: e.target.value})
                            }
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='status'
                            label='AI Result'
                            variant='outlined'
                            defaultValue={scan?.guiStatusInfo?.text}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='stain'
                            label='Stain'
                            variant='outlined'
                            defaultValue={scan?.stain}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='pathogen'
                            label='Pathogen'
                            variant='outlined'
                            defaultValue={scan?.pathogen}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='date'
                            label='Scan Date'
                            variant='outlined'
                            defaultValue={scan?.guiScanDateText}
                            disabled
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{width: 1}}>
                    <Grid item xs={6}>
                        <TextField
                            id='scanName'
                            label='Scan Name'
                            variant='outlined'
                            defaultValue={updatedScan?.scanName ?? scan.scanName}
                            onChange={(e) =>
                                setUpdatedScan({...updatedScan, scanName: e.target.value})
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id='reportId'
                            label='Report Id'
                            variant='outlined'
                            defaultValue={scan?.scanId}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='result'
                            label="Doctor's Result"
                            variant='outlined'
                            defaultValue={updatedScan?.result ?? scan?.result}
                            onChange={(e) =>
                                setUpdatedScan({...updatedScan, result: e.target.value})
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='status'
                            label='AI Result'
                            variant='outlined'
                            defaultValue={scan?.guiStatusInfo?.text}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='stain'
                            label='Stain'
                            variant='outlined'
                            defaultValue={scan?.stain}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='pathogen'
                            label='Pathogen'
                            variant='outlined'
                            defaultValue={scan?.pathogen}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='date'
                            label='Scan Date'
                            variant='outlined'
                            defaultValue={scan?.guiScanDateText}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        <Button variant='contained' onClick={() => onSubmit(updatedScan)}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    )
    */
}

function sendReportBeginReq(scanId) {
    // console.log('navigated to report')
    // navigate('/report', { replace: false });
    console.log(scanId)

    // sendUpdateReq(props)
    if (scanId) {
        Api.requestSession<any>('report', 'update_report', {scanId: scanId})
            .then((d) => {
                // Api.requestSession<any>('report', 'update_report', { scanId: props.scanId, finalized: 'true' }, reqBody).then(d => {
                console.log(d.scanId)
            })
            .catch((err) => console.log(err))
    }
    return
}

function sendReportUpdateReq(scanId) {
    console.log(scanId)
    // sendUpdateReq(props)
    if (scanId) {
        Api.requestSession<any>('report', 'update_report', {scanId: scanId})
            .then((d) => {
                // Api.requestSession<any>('report', 'update_report', { scanId: props.scanId, finalized: 'true' }, reqBody).then(d => {
                console.log(`modified`)
                console.log(d)
            })
            .catch((err) => console.log(err))
    }
    return
}
