import { ScanViewRenderer } from '../ScanViewRenderer';


export function ScanRender_setMouseStyleDefault(this: ScanViewRenderer) {
    this.canvas!.style.cursor = 'grab';
}

export function ScanRender_setMouseStyleDragging(this: ScanViewRenderer) {
    this.canvas!.style.cursor = 'grabbing';
}



export function onMouseDown(this: ScanViewRenderer, ev: MouseEvent) 
{
    let st = this.controlState;
    this.setMouseStyleDragging();

    // TODO: remove
    //let scan = this.screenToScan(ev);
    //let screen = this.getScanToScreen(scan);
    //console.debug("ev:", ev.x, ev.y, "scan:", scan.x, scan.y, "screen:", screen.x, screen.y);

    st.isMouseRotating = st.isMousePositioning = st.isMouseResizing = false;

    this.cancelEasing();

    if (ev.ctrlKey || ev.button === 2) {
        st.isMouseRotating = true;
        this.onMouseRotationBegin(st.mouseRotCtx, ev);
    } else if (ev.shiftKey) {
        st.isMouseResizing = true;
    } else {
        st.isMousePositioning = true;
        let ctx = st.mouseDragCtx;
        ctx.lastDownTimestamp = ctx.downTimestamp;
        ctx.downTimestamp = ev.timeStamp;
        this.intertiaHandler_.onMoveStart(ev.timeStamp, ev);
        this.onMouseDragBegin(st.mouseDragCtx, ev);
    }
}

export function onMouseEnter(this: ScanViewRenderer, ev: MouseEvent) 
{
    let st = this.controlState;

    if (!st.isMousePositioning && !st.isMouseRotating && !st.isMouseResizing)
        return;

    this.cancelEasing();


    // If the user reelase the mouse outside of the area
    if (ev.buttons === 0) {
        st.isMouseRotating = st.isMousePositioning = st.isMouseResizing = false;
        this.setMouseStyleDefault();
        return;
    }

    if (st.isMousePositioning) {
        this.intertiaHandler_.onMoveStart(ev.timeStamp, ev);
        this.onMouseDragBegin(st.mouseDragCtx, ev)
    } else if (st.isMouseRotating) {
        this.onMouseRotationBegin(st.mouseRotCtx, ev);
    }
}



export function onMouseUp(this: ScanViewRenderer, ev: MouseEvent) {
    let st = this.controlState;

    this.setMouseStyleDefault();

    // Last update
    if (st.isMousePositioning) {
        let ctx = st.mouseDragCtx;
        ctx.lastUpTimestamp = ctx.upTimestamp;
        ctx.upTimestamp = ev.timeStamp;

        // Check for double click
        let delta = ctx.upTimestamp - ctx.lastDownTimestamp;
        let dblClick = ctx.lastUpTimestamp > ctx.lastDownTimestamp && delta <= 500;

        if (dblClick) {

            let nav = this.nav!;

            // Determine target zoom 
            let toZoom = nav.currLoc.zoom < -2 ? 0 : nav.homePos.zoom; 

            // Adjust the target location, so the user's mouse location will
            // correspond to the zoomed location
            // TODO: adjust the screen position so it will end up under the mouse

            //let evPos = this.screenToScan(ev);

            //console.debug("evPos: ", evPos.x, evPos.y);

            let currPos = nav.currLoc.pos.clone();
            //let evOff = Geo.makePoint(ev.x - this.surfaceCenter.x, ev.y - this.surfaceCenter.y);
            //let posOff = Geo.makePointSub(evPos, currPos);


            //this.webgl!.setScanLocation(

            // // Calculate the screen coverage ratio between zoom levels
            // let toMul = 1 ** Math.abs(toZoom);
            // let fromMul = 1 ** Math.abs(nav.currLoc.zoom);
            // let ratio = fromMul / toMul;
            // posOff.scale(ratio);
            // let toPos = Geo.makePointAdd(currPos, posOff);
            //toPos = currPos.clone();

            //console.debug(evPos.x, evPos.y, " => ", toPos.x, toPos.y);

            this.easeTo({pos: currPos, zoom: toZoom});
        } else {

            // Check the inertia situation
            let inertia = this.processInertia(ev.timeStamp);
            if (inertia) {
                this.easeTo({pos: inertia.destPos}, {duration: inertia.duration});
            } else {
                this.onPositionDrag(ctx, ev);
                this.processLocationChanged(false);
            }
        }

    } else if (st.isMouseRotating) {
        this.navProcessRotationDragged(st.mouseRotCtx, ev);
    }

    st.isMouseRotating = st.isMousePositioning = st.isMouseResizing = false;

    this.emitLocation();
}


export function onMouseLeave(this: ScanViewRenderer, ev: MouseEvent) 
{
    let st = this.controlState;

    if (!st.isMousePositioning && !st.isMouseRotating && !st.isMouseResizing)
        return;

    if (st.isMousePositioning) {
        this.onPositionDrag(st.mouseDragCtx, ev);
        this.intertiaHandler_.reset();
        this.processLocationChanged(false);
        this.emitLocationTrack();
    } else if (st.isMouseRotating) {
        this.navProcessRotationDragged(st.mouseRotCtx, ev);
        this.emitLocationTrack();
    }

    this.emitLocation();
}



export function onMouseMove(this: ScanViewRenderer, ev: MouseEvent) 
{
    let st = this.controlState;

    if (!st.isMousePositioning && !st.isMouseRotating && !st.isMouseResizing)
        return;

    if (st.isMousePositioning) {
        this.onPositionDrag(st.mouseDragCtx, ev);
        this.intertiaHandler_.onMove(ev.timeStamp, ev);
        //this.processLocationChanged();
        this.emitLocationTrack();
    } else if (st.isMouseRotating) {
        this.navProcessRotationDragged(st.mouseRotCtx, ev);
        this.emitLocationTrack();
    }

    //st.lastMousePos.assign(ev);
}



//
// Wheel
//
//let wheelLocationTimeout_: any | undefined = undefined;

export function onWheel(this: ScanViewRenderer, ev: WheelEvent) 
{
    ev.preventDefault();

    let zdelta = ev.deltaY * 0.01;
    zdelta *= 0.5;
    zdelta = Math.sign(zdelta) * Math.trunc(Math.abs(zdelta) * 100) / 100;
    //zdelta *= 0.499;

    let nav = this.nav!;
    if (!nav.addZoomIndex(-zdelta))
        return;

    // if (wheelLocationTimeout_)
    //     clearTimeout(wheelLocationTimeout_);

    // wheelLocationTimeout_ = setTimeout(() => {
    //     wheelLocationTimeout_ = undefined;
    this.processLocationChanged(true);

    //}, 100);

    this.pubsub!.emitScanLocation(nav.currLoc);
}
