import { DarkAiTheme } from './DarkAiTheme'
import { LiteTheme } from './LiteTheme'
import { ThemeInstance } from './ThemeInstance'

import * as GD from 'tools/GlobalDispatch'




//var theme_ = new LiteTheme();
//var theme_ = new DarkAiTheme();

//export default theme_;

var themeValid = false

globalThis.theme_ = setTheme(new DarkAiTheme())

function onWindowResize() {
    theme_.processWindowResize()
}

window.addEventListener('resize', onWindowResize)

GD.pubsub_.addListener(GD.authListenerId, onWindowResize)
GD.pubsub_.addListener(GD.langListenerId, onWindowResize)


function setTheme(theme: ThemeInstance) {

    if (themeValid) {
        theme_.release()
    }

    theme.create()
    themeValid = true


    //theme_ = theme

    return theme
}


// import { SxProps } from '@mui/system';
// import * as Geo from 'lib/geo';

// import * as UE from 'ui/UiElements'

// export {}

/*
import { makeSxProps, SxObject, SxPropsParam } from 'ui/SxPropsUtil'


export class ThemeMeasure {
    units = 'px';
    val?: number;

    constructor(val?: number) {
        this.val = val;
    }

    set(val?: number) {
        this.val = val;
    }

    get = () => this.val ?? 0;

    css(name?: string): string {
        if (this.val === undefined)
            return '';

        let val = `${this.val}${this.units}`;
        return name ? `${name}: ${val};\n` : val;
    }
};

export class ThemeSize {
    w = new ThemeMeasure();
    h = new ThemeMeasure();

    constructor(w?: number, h?: number) {
        this.set(w, h);
    }

    getWidth = () => this.w.val;
    getHeight = () => this.h.val;

    set(w?: number, h?: number) {
        this.w.set(w);
        this.h.set(h);
    }

    css() {
        return this.w.css('width') + this.h.css('height');
    }
};

export interface CssPosProps {
    right?: boolean;
};


export class ThemeRect {
    x = new ThemeMeasure();
    y = new ThemeMeasure();
    w = new ThemeMeasure();
    h = new ThemeMeasure();

    constructor(x?: number, y?: number, w?: number, h?: number) {
        this.set(x, y, w, h);
    }

    set(x?: number, y?: number, w?: number, h?: number) {
        this.x.set(x);
        this.y.set(y);
        this.w.set(w);
        this.h.set(h);
    }

    css(opts?: CssPosProps): string {

        let css = opts?.right ? `right: ${this.x.css()};` : `left: ${this.x.css()};`;
            
        css += ` bottom: ${this.y.css()};`
    
        if (this.w.val)
            css += ` width: ${this.w.css()};`;
        else
            css += ` right: 0;`;
    
        if (this.h.val)
            css += ` height: ${this.h.css()};`;
        else
            css += ` top: 0;`;
    
        return css;
    }
    
    sx(opts?: CssPosProps) {
        let style: SxPropsParam = {
            right: opts?.right ? this.x.get() : undefined,
        }

        //style.

        if (opts?.right) {
            style.right = this.x.get()
        } else {
            style.left = this.x.get()
        }

        style.bottom = this.y.get()

        if (this.w.val)
            style.width = this.w.get()
        else
            style.right = 0

        if (this.h.val)
            style.height = this.h.get()
        else
            style.top = 0

        //return style

        return makeSxProps(style)
    

        // return makeSxProps({
        //     right: opts?.right ? this.x.get() : undefined, 
        //     left: opts?.right ? undefined : this.x.get(),
        // })
    }

};


interface DomElementProps {
    position?: string;
    width?: number;
    height?: number;

};

export class ThemeDomElement {

    // Element geometry
    position?: string;
    units = 'px';

    left?: number; // = new ThemeMeasure();
    right?: number; // = new ThemeMeasure();
    bottom?: number; // = new ThemeMeasure();
    top?: number; // = new ThemeMeasure();
    width?: number; // = new ThemeMeasure();
    height?: number; // = new ThemeMeasure();

    border?: UE.UiBorder;

    // Colors
    color?: string;
    backgroundColor?: string;

    css() {
        let s = '';

        if (this.position)
            s += `position: ${this.position};\n`;

        if (this.left !== undefined)
            s += `left: ${this.left}${this.units};\n`;
        if (this.right !== undefined)
            s += `right: ${this.right}${this.units};\n`;
        if (this.top !== undefined)
            s += `top: ${this.top}${this.units};\n`;
        if (this.bottom !== undefined)
            s += `bottom: ${this.bottom}${this.units};\n`;
        if (this.width !== undefined)
            s += `width: ${this.width}${this.units};\n`;
        if (this.height !== undefined)
            s += `height: ${this.height}${this.units};\n`;

        // if (this.border)
        //     s += `border: ${this.border.cssVal()};\n`;

        if (this.color)
            s += `color: ${this.color};\n`;

        if (this.backgroundColor)
            s += `background-color: ${this.backgroundColor};\n`;


        return s;
    }
};
*/

/*
interface ThemeBorderProps {
    width?: number;
    color?: string;
    style?: string;
};

export class ThemeBorder {
    width: ThemeMeasure;
    color: string;
    style: string;

    //constructor(width = 1, color = 'red', style = 'solid') {
    constructor(ini?: ThemeBorderProps | ThemeBorder) {
        if (ini instanceof ThemeBorder) {
            this.width = ini.width;
            this.color = ini.color;
            this.style = ini.style;
        } else {
            this.width = new ThemeMeasure(ini?.width ?? 1);
            this.color = ini?.color ?? 'red';
            this.style = ini?.style ?? 'solid';
        }
    }

    getWidth() { return this.width.val ?? 0; }

    cssVal() { 
        return `${this.width.css()} ${this.style} ${this.color}`;
    }

    sx() {
        if (!this.width.val)
            return {}
            
        return {
            borderWidth: this.width.get(),
            borderStyle: this.style,
            borderColor: this.color,
        }
    }
};
*/

// interface ThemeHoverProps {
//     color: string;
// };

// interface ThemeButtonProps {
//     border?: ThemeBorderProps;
//     hover?: ThemeHoverProps;
// };

// export class ThemeHover {
//     color: string;

//     constructor(color: string) {
//         this.color = color;
//     }

//     css() {
//         return `
// :hover {
//     box-shadow: inset 0 0 0 2em ${this.color};
// }
// `;
//     }

//     csss() {
//         return {
//             ":hover": {
//                 boxShadow: `inset 0 0 0 2em ${this.color}`
//             }
//         }
//     }
// };

// export class ThemeButton extends ThemeDomElement {
//     hover?: ThemeHover;
    
//     override css() {
//         let s = super.css();
//         if (this.hover)
//             s += this.hover.css();

//         s += 'cursor: pointer;\n';

//         return s;
//     }
// };

// export class ThemeSvgButton extends ThemeButton {

//     override css() {
//         let s = super.css() + ';\n';
//         return s;

//         // return (`
//         //     position: absolute;
//         //     cursor: pointer;
//         //     width: ${size.w}px;
//         //     height: ${size.h}px;

//         //     border: ${border};

//         //     :hover {
//         //         box-shadow: inset 0 0 0 2em ${this.buttonHoverColor};
//         //     }
//         // `);
//     }

// };

// export class ThemePanel extends ThemeDomElement {
//     caption?: ThemePanel;
//     contents?: ThemePanel;
// };


//
// CSS generation helpers
//
// export function cssSize(s: Geo.ISize, units = 'px') {
//     return `width: ${s.w}${units}; height: ${s.h}${units}`;
// }

// export function cssRect(r: Geo.IRect, opts?: CssPosProps, optUnits?: string): string 
// {
//     let units = optUnits ?? 'px';

//     let css = opts?.right ? `right: ${r.x}${units};` : `left: ${r.x}${units};`;
        
//     css += ` bottom: ${r.y}${units};`

//     if (r.w)
//         css += ` width: ${r.w}${units};`;
//     else
//         css += ` right: 0;`;

//     if (r.h)
//         css += ` height: ${r.h}${units};`;
//     else
//         css += ` top: 0;`;

//     return css;
// }

// export function cssAbsoluteFill() {
//     return `
//         position: absolute;
//         top: 0;
//         right: 0;
//         left: 0;
//         bottom: 0;
//     `;
// }

// TODO: remove temporary

// export const dialogBorderStyle = '1px solid #91cbd8';
// export const themeColor = '#25afce';


