import React from 'react'

import {useNavigate} from 'react-router-dom'


import * as UE from 'tsui/UiElements'

import {DesignNavButton} from './DesignNavButton'

interface AuthButtonProps {
    show?: boolean
    label?: string
    tlabel?: string // Translated label
    href?: string
    onClick?: () => void

    layout?: UE.UiLayout

    width?: number
    height?: number
    //sx?: SxPropsParam
}

export function AuthButton(props: AuthButtonProps) {
    if (props.show === false) return <></>
    return <AuthButtonImpl {...props}/>
}


export function AuthButtonImpl(props: AuthButtonProps) {
    const navigate = useNavigate()

    //const sx = props.sx![0]!
    const width = props.width ?? props.layout?.width ?? theme_.authButtonSize.w
    const height = props.height ?? props.layout?.height ?? theme_.authButtonSize.h

    const onClick = React.useCallback(() => {
        if (props.href) {
            navigate(props.href)
        } else if (props.onClick) {
            props.onClick()
        }
    }, [])

    // Designed component
    return (
        <DesignNavButton
            label={props.label}
            tlabel={props.tlabel}
            width={width}
            height={height}
            paperWidth={height}
            fontSize={theme_.authButtonFontSize}
            layout={props.layout}
            onClick={onClick}
            //sx={props.sx}
        />
    )
}

