import React from 'react';

import * as FullScreen from 'ui/FullScreen';

import {scanTheme_} from './ScanTheme/ScanTheme';
import {DomSub} from 'tsui/Dom/DomLib';

import ScanLoadingProgress, {LoadProgressData} from './ScanComponents/ScanLoadingProgress';
import ScanErrorMessage, {ScanErrorMessageData} from './ScanComponents/ScanErrorMessage';

import ScanViewThumbnailPanel from './ScanPanels/ScanViewThumbnailPanel';
import ScanViewAoiPanel from './ScanPanels/ScanViewAoiPanel';
import ScanViewCaption from './ScanPanels/ScanViewCaption';
import ScanViewMenuDialog from './ScanComponents/ScanViewMenuDialog';
import ScanViewPopup from './ScanComponents/ScanViewPopup';
import {ScanViewStateDefValue, ScanViewStateInstance} from './ScanViewStateProvider';

import {processKeyDown, processKeyUp} from './ScanViewKeyboard';
import ScanViewContainer from './ScanComponents/ScanViewContainer';
import scanViewConfig_ from './ScanViewConfig';
import ScanViewDebugPanel from './ScanPanels/ScanViewDebugPanel';
import ScanViewNavPanel from './ScanPanels/ScanViewNavPanel';

interface ScanViewProps {
    scanId: string;
    fullScreen: boolean;
    onClose: () => void;
}

class ScanViewLocalState {
    lastZoom = Infinity;
    domSub = new DomSub();
}

export default function ScanViewer(props: ScanViewProps) {
    //console.debug("ScanView::Render")

    // Main statuses
    const [scanCtx, setScanCtx] = React.useState(ScanViewStateDefValue.state);
    const stateRef = React.useRef(new ScanViewLocalState());

    const [loaded, setLoaded] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const [error, setError] = React.useState<ScanErrorMessageData | null>(null);

    // Initialization and loading phase
    const [progressData, setProgressData] = React.useState<LoadProgressData | null>(null);

    // User interaction
    const [showMenuDialog, setShowMenuDialog] = React.useState(false);
    const [showPopup, setShowPopup] = React.useState(false);

    // Error handling
    const raiseError = React.useCallback(
        (err: string | Error, title: string = 'Critical Error!') => {
            scanCtx.raiseError();
            setProgressData(null);
            setError(new ScanErrorMessageData(err, title));
        },
        [scanCtx]
    );

    const cleanup = React.useCallback(() => {
        stateRef.current!.domSub.release();
    }, [stateRef]);

    // Closing the whole window
    const onClose = React.useCallback(() => {
        FullScreen.exitFullScreen();
        cleanup();
        scanCtx.release();
        setLoaded(false);
        props.onClose(); // Call the provided callback
    }, [props, scanCtx, cleanup]);

    // Initialization and Fetching the data
    React.useEffect(() => {
        if (scanCtx.error || scanCtx.created) return;

        scanCtx.init(props.scanId, raiseError);

        scanCtx.startDataFetch(
            (progress) => {
                setProgressData(progress);
                if (scanCtx.fetched) setLoaded(true);
            },
            (err) => {
                raiseError(err);
            }
        );

        // DEBUG: Error debugging
        //raiseError("Test");
    }, [props, scanCtx, raiseError]);

    // Continuing the process when the data is loaded
    React.useEffect(() => {
        // Check the final flags before setting the ready flag
        if (scanCtx.error || !scanCtx.fetched || scanCtx.created) return;

        scanCtx.create(raiseError);

        // Update layout
        scanTheme_.setAoiState(scanCtx.aoi ? true : false, scanCtx.aoi?.count ?? 0);
        // Theme.haveAoi = scanCtx.aoi ? true : false;
        // Theme.showAoi = Theme.haveAoi;

        FullScreen.enterFullScreen(props.fullScreen);

        setReady(true);
    }, [props, scanCtx, loaded, raiseError]);

    // Update GUI elements when everythign is ready
    React.useEffect(() => {
        if (!scanCtx.created || !ready)
            // || !mainContainerRef.current)
            return;

        let st = stateRef.current!;

        // Keyboard
        const onKeyUp = (ev: KeyboardEvent) => processKeyUp(scanCtx, ev);
        const onKeyDown = (ev: KeyboardEvent) => processKeyDown(scanCtx, ev);

        st.domSub.addDocumentListener('keyup', onKeyUp);
        st.domSub.addDocumentListener('keydown', onKeyDown);

        // Launch finally
        scanCtx.startInElement(scanCtx.gui!.mainContainer!);

        return () => cleanup();
    }, [props, scanCtx, stateRef, ready, cleanup]);

    //console.debug(document.getElementById(zoomButton10Id));

    // Error handling and progress
    if (error) {
        return <ScanErrorMessage error={error} onClose={onClose} />;
    }

    if (!ready) {
        return <ScanLoadingProgress data={progressData} />;
    }

    return (
        <ScanViewStateInstance.Provider value={{state: scanCtx, setState: setScanCtx}}>
            <ScanViewContainer>
                <ScanViewCaption onClose={onClose} onMenu={() => setShowMenuDialog(true)} />

                <ScanViewNavPanel onPopup={() => setShowPopup(true)} />

                <ScanViewThumbnailPanel />

                {scanTheme_.aoiPanel.visible && <ScanViewAoiPanel />}

                {showMenuDialog && <ScanViewMenuDialog onClose={() => setShowMenuDialog(false)} />}

                {showPopup && <ScanViewPopup onClose={() => setShowPopup(false)} />}

                {scanViewConfig_.debug_showPanel && <ScanViewDebugPanel />}
            </ScanViewContainer>
        </ScanViewStateInstance.Provider>
    );
}
