import React from 'react';

import Env from 'env';

import PageContents from 'components/PageContents';

import ScansPageContents, {ScanSetCategory} from './ScansPageContents';

// let startTab: ScanSetCategory = 'assigned'
let startTab: ScanSetCategory = 'uploads';

//startTab = 'shared'; // DEBUG:
startTab = 'public'; // DEBUG:

export default function ScansPageTabbed() {
    const [selectedTab, setSelectedTab] = React.useState(startTab);

    return (
        <PageContents
            title='Scans'
            current='scans'
            type='data'
            layout={{
                tabbed: Env.isMobile ? undefined : {
                    selected: selectedTab,
                    onChange: setSelectedTab,
                    tabs: [
                        {key: 'assigned', label: 'Assigned Scans'},
                        {key: 'shared', label: 'Shared Scans'},
                        {key: 'account', label: 'Account Scans'},
                        {key: 'public', label: 'Public Scans'},
                        {key: 'uploads', label: 'Upload Scans'},
                    ],
                },

                nav: !Env.isMobile ? undefined : {
                    selected: selectedTab,
                    onChange: setSelectedTab,
                    actions: [
                        {key: 'assigned', label: 'Assigned'},
                        {key: 'shared', label: 'Shared'},
                        {key: 'account', label: 'Account'},
                        {key: 'public', label: 'Public'},
                        {key: 'uploads', label: 'Uploads'},
                    ],
                },
            }}
        >
            <ScansPageContents category={selectedTab} />
        </PageContents>
    );
}
