import React from 'react';

import * as Geo from 'tslib/geo';
import {DomSub} from 'tsui/Dom/DomLib';

import {ScanViewContext, ZoomLevelValue4} from '../ScanViewContext';
import {calcThumbImageRect} from '../ScanLib/ScanViewThumbnailLib';
import {NavState} from './ScanNav';

export class DisplayZoomLevel {
    zoom!: number;
    maxZoom!: number;
    minZoom!: number;

    label!: string; // Display to the user
    color!: string; // The button color

    buttonRef?: React.RefObject<HTMLButtonElement>;
}

// Everything related to use interaction
export class ScanViewGuiState {
    //
    // GUI elements
    //

    // MainContainer
    mainContainer?: HTMLElement | null;

    thumbRect!: Geo.Rect;
    //aoiMarkersVisible = true;

    //
    // Handling dialogs
    //
    dialogActive = false;

    // Navigation state
    nav = new NavState();

    //displayZoomLevels = new Map<number, DisplayZoomLevel>();

    domSub = new DomSub();

    constructor(scanCtx: ScanViewContext) {
        let scanInfo = scanCtx.scanInfo;

        //
        // UI elemets
        //
        this.thumbRect = calcThumbImageRect(scanInfo.scanSize);
        let minDistance = 1000;
        for (let tn of scanInfo.thumbnailsDataSources) {
            let dw = Math.abs(tn.imageSize!.w - this.thumbRect.w);
            let dh = Math.abs(tn.imageSize!.h - this.thumbRect.h);
            let dist = Math.min(dw, dh);
            if (dist < minDistance) {
                minDistance = dist;
                //minImage = tn.imageName;
                scanInfo.thumbnailDataSource = tn;
            }
        }

        //let zl = this.displayZoomLevels;

        // Zoom buttons
        // zl.set(40, {zoom:    0, minZoom:   -1, maxZoom:    0, label: "40", color: "blue"});
        // zl.set(20, {zoom:   -1, minZoom:   -2, maxZoom:   -1, label: "20", color: "green"});
        // zl.set(10, {zoom:   -2, minZoom: ZoomLevelValue4, maxZoom:   -2, label: "10", color: "orange"});
        //zl.set( 4, {zoom: ZoomLevelValue4, minZoom: -4, maxZoom: ZoomLevelValue4, label:  "4", color: "red"});
    }

    release() {
        //this.lastZoom_ = Infinity;
        this.domSub.release();
        //this.displayZoomLevels.clear();

        this.mainContainer = undefined;
    }

    initializeView(scanCtx: ScanViewContext) {
        // let zl = this.displayZoomLevels;
        // let homeZoom = this.nav.homePos.zoom;
        // zl.set(0, {zoom: homeZoom, minZoom: homeZoom, maxZoom: -4.4, label: "0", color: 'white'});
    }

    //
    // Handling of the zoom buttons
    //
    //lastZoom_ = Infinity;

    // setZoomButtonRef(zoom: number, buttonRef: React.RefObject<HTMLButtonElement>) {
    //     let zl = this.displayZoomLevels.get(zoom)!;
    //     zl.buttonRef = buttonRef;

    //     ScanViewUtil.setZoomButtonStyle(buttonRef, zl.color);
    // }

    // updateZoomButtons(zoom: number) {

    //     //console.debug("update zoom buttons");

    //     if (zoom === this.lastZoom_)
    //         return;

    //     // TODO: implement clever updates
    //     this.displayZoomLevels.forEach(zl => {
    //         ScanViewUtil.setZoomButtonSelected(zl.buttonRef, zoom, zl);
    //     });

    //     this.lastZoom_ = zoom;
    // }

    //
    // Handling Area Of Interest
    //
    aoiSelectNext?: (offset: number) => void;
}
