import {Color} from 'tslib/color';
import {scanTheme_} from '../../ScanTheme/ScanTheme';

import {ScanViewRenderer} from '../ScanViewRenderer';
import {ScanScene} from '../ScanScene/ScanScene';
import {ScanScenePatMarker} from '../ScanScene/ScanScenePatMarker';
import {SceneTexture} from '../ScanScene/SceneTexture';
import {SceneTileStatus} from '../ScanScene/SceneTileStatus';

export function ScanRender_updateScene(this: ScanViewRenderer) {
    // Check if anything changed
    //if (!this.currentSceneRef_!.dataChanged)
    //   return;

    //this.debug_.check("Update Scene.");
    //console.debug("updateScene");

    let scene = this.currentSceneRef_!;

    scene.dataChanged = false;

    let wglsc = this.webgl!.scene;

    wglsc.busyBatchRef!.animating = false;
    wglsc.busyBatchRef!.vertices.restart();

    wglsc.blankBatchRef!.vertices.restart();

    wglsc.tilesBatchesRef.forEach((batch) => batch.vertices.restart());

    scene.backgroundTexture && this.updateTilesBatch(scene, scene.backgroundTexture);
    scene.textures.forEach((tex) => this.updateTilesBatch(scene, tex));

    wglsc.markersBatchRef!.vertices.restart();
    this.updatePatBatch(scene.patMarkers);
}

export function ScanRender_updatePatBatch(this: ScanViewRenderer, markers: ScanScenePatMarker[]) {
    let markersBatch = this.webgl!.scene.markersBatchRef!;

    for (let mark of markers) {
        let c = mark.color;

        let [cx, cy] = [mark.pos.x, mark.pos.y];

        let [sx, sy] = [mark.radius, mark.radius];

        let s = this.nav!.zoomMulToScan * 4.4;
        //console.debug(s);

        let [pl, pr] = [cx - sx * s - 2, cx + sx * s + 2];
        let [pb, pt] = [cy - sy * s - 2, cy + sy * s + 2];

        markersBatch.vertices.add(
            [
                {x: pl, y: pb, z: sx, w: sy, r: c.r, g: c.g, b: c.b, a: c.a, p: cx, q: cy},
                {x: pl, y: pt, z: sx, w: sy, r: c.r, g: c.g, b: c.b, a: c.a, p: cx, q: cy},
                {x: pr, y: pt, z: sx, w: sy, r: c.r, g: c.g, b: c.b, a: c.a, p: cx, q: cy},
                {x: pr, y: pb, z: sx, w: sy, r: c.r, g: c.g, b: c.b, a: c.a, p: cx, q: cy},
            ],
            [0, 1, 2, 0, 2, 3]
        );
    }
}

export function ScanRender_updateTilesBatch(this: ScanViewRenderer, scene: ScanScene, tex: SceneTexture) {
    let wglsc = this.webgl!.scene;
    //let busyCount = this.imageDataManager!.getBusyCount();

    let busyBatch = wglsc.busyBatchRef!;
    let blankBatch = wglsc.blankBatchRef!;
    let tilesBatch = wglsc.tilesBatchesRef[tex.renTexture.wglIndex];

    for (let tile of tex.tiles) {
        // Left, right, top, bottom screen coords
        let pl = tile.scanRect.x;
        let pr = pl + tile.scanRect.w;
        let pb = tile.scanRect.y;
        let pt = pb + tile.scanRect.h;

        //console.debug(tile.scanRect);
        switch (tile.status) {
            case SceneTileStatus.Image: {
                // Left, right, top, bottom texture coords
                let tl = tile.texRectNorm.x;
                let tr = tl + tile.texRectNorm.w;
                let tb = tile.texRectNorm.y;
                let tt = tb + tile.texRectNorm.h;

                tilesBatch.vertices.add(
                    [
                        {x: pl, y: pb, u: tl, v: tb},
                        {x: pl, y: pt, u: tl, v: tt},
                        {x: pr, y: pt, u: tr, v: tt},
                        {x: pr, y: pb, u: tr, v: tb},
                    ],
                    [0, 1, 2, 0, 2, 3]
                );
                break;
            }

            case SceneTileStatus.Pending:
                if (this.imageDataManager!.busyCount_ >= 4) {
                    busyBatch.animating = true;

                    let s = this.nav!.zoomMulToScan;

                    // Center
                    let cx = tile.scanRect.x + tile.scanRect.w / 2;
                    let cy = tile.scanRect.y + tile.scanRect.h / 2;

                    // Now set the rendering are
                    let radius = scanTheme_.scanBusyIndicatorSize / 2;
                    radius *= s;

                    let [el, er] = [cx - radius, cx + radius];
                    let [eb, et] = [cy - radius, cy + radius];

                    busyBatch.vertices.add(
                        [
                            {x: el, y: eb, p: cx, q: cy},
                            {x: el, y: et, p: cx, q: cy},
                            {x: er, y: et, p: cx, q: cy},
                            {x: er, y: eb, p: cx, q: cy},
                        ],
                        [0, 1, 2, 0, 2, 3]
                    );
                }
                break;

            case SceneTileStatus.Blank:
            case SceneTileStatus.Empty:
            case SceneTileStatus.Error: {
                // Choose the tile color
                let c: Color;
                switch (tile.status) {
                    case SceneTileStatus.Blank:
                        c = this.blankTileColor;
                        break;
                    //case SceneTileStatus.Invalid: c = this.invalidTileColor; break;
                    case SceneTileStatus.Empty:
                        c = this.emptyTileColor;
                        break;
                    case SceneTileStatus.Error:
                        c = this.errorTileColor;
                        break;
                    //default: c = this.errorTileColor; break;
                }

                blankBatch.vertices.add(
                    [
                        {x: pl, y: pb, r: c.r, g: c.g, b: c.b, a: c.a},
                        {x: pl, y: pt, r: c.r, g: c.g, b: c.b, a: c.a},
                        {x: pr, y: pt, r: c.r, g: c.g, b: c.b, a: c.a},
                        {x: pr, y: pb, r: c.r, g: c.g, b: c.b, a: c.a},
                    ],
                    [0, 1, 2, 0, 2, 3]
                );

                break;
            }
            case SceneTileStatus.Invalid:
                break;
        } // switch
    }
}
