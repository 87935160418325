import Env from 'env';
import * as Geo from 'tslib/geo';

import {ScanViewZoomLevel} from '../ScanRenderer/ZoomState';
import {ScanViewContext} from '../ScanViewContext';

export function ScanViewCtx_start(this: ScanViewContext) {
    if (this.started) return;

    //   this.debug_.check('start()')

    // Determine layout, zoom levels, etc
    // Maybe we shouldn't be doing this here
    // Think later
    this.zoomLevels = []; //new Array<ScanViewZoomLevel>();
    //let webgl = scanCtx.getWebGL();
    //let webgl = this.webgl!;

    // Level zero scale - original size
    // (40) scale
    let currentScale = 1;
    let currentZoomLevel = 0;
    let scanSize = this.scanInfo.scanSize.clone();

    let screen = window.screen;
    let homeSize = Geo.makeSize(screen.availWidth, screen.availHeight);

    //let homeSize = webgl.dev.screenSize.clone();

    if (Env.isMobile) {
        homeSize.scale(0.99);
    } else if (Env.isTablet) {
        homeSize.scale(0.9);
    } else {
        homeSize.scale(0.7);
    }

    //let homeW = webgl.dev.screenWidth / 2;
    //let homeH = webgl.dev.screenHeight / 2;

    let zoomedSize = scanSize.clone();
    //let zoomedW = scanW;
    //let zoomedH = scanH;

    while (zoomedSize.w > 1) {
        // Dummy condition
        let zl = new ScanViewZoomLevel();
        zl.zoomLevel = currentZoomLevel;
        zl.scale = currentScale;
        zl.zoomedSize = zoomedSize.clone();

        this.zoomLevels.push(zl);

        if (zoomedSize.w * 2 < homeSize.w && zoomedSize.h * 2 < homeSize.h) {
            break;
        }

        zoomedSize.scale(0.5);

        currentScale /= 2;
        --currentZoomLevel;
    }

    //console.log("DisplayZoomLevels", scanCtx.zoomLevels);

    // zl = new ScanViewZoomLevel();
    // zl.zoomLevel = currentZoomLevel;
    // zl.scale = currentScale;

    // Center of the scan
    let nav = this.gui!.nav!; //scanCtx.getNav();
    nav.setScanSize(this.scanInfo.scanSize);
    nav.homePos.set(scanSize.w / 2, scanSize.h / 2, currentZoomLevel + 1, 0);
    nav.minZ = nav.homePos.zoom; //currentZoomLevel;// - 1;
    nav.maxZ = 0; //currentZoomLevel - 1 ;

    this.gui!.initializeView(this);

    // Starting position
    nav.setLocation(nav.homePos);

    this.render!.start();
    this.started = true;

    this.pubsub?.emitScanLocation(nav.currLoc);
}
