import React from 'react';
import {ScanViewContext} from './ScanViewContext';

export type ScanViewStateValue = {
    state: ScanViewContext;
    setState: React.Dispatch<React.SetStateAction<ScanViewContext>>;
};

export const ScanViewStateDefValue: ScanViewStateValue = {
    state: new ScanViewContext(),
    setState: (state) => {},
};

export const ScanViewStateInstance = React.createContext(ScanViewStateDefValue);
