import {IScanLocation, IScanLocationParam, ScanLocation} from '../ScanTypes/ScanLocation';

export interface IEasingParam {
    from: IScanLocation;
    to: IScanLocationParam;
    through?: IScanLocationParam;
    duration: number;
}

export class EasingSetup {
    from = new ScanLocation();
    to = new ScanLocation();
    through = new ScanLocation();
    duration = 0; //?: number;

    easeThrough = false;
    throughPos = false;
    throughZoom = false;
    throughRot = false;

    easePos = false;
    easeZoom = false;
    easeRot = false;

    setParams(p: IEasingParam) {
        //console.debug(p);

        this.from.assign(p.from);
        this.easeThrough = false;
        this.duration = p.duration;

        if (p.to.pos) {
            this.to.pos.assign(p.to.pos);
            this.easePos = true;
        } else {
            this.to.pos.assign(p.from.pos);
            this.easePos = false;
        }

        if (p.to.zoom !== undefined && p.to.zoom !== this.from.zoom) {
            this.to.zoom = p.to.zoom;
            this.easeZoom = true;
        } else {
            this.to.zoom = p.from.zoom;
            this.easeZoom = false;
        }

        if (p.to.rot !== undefined && p.to.rot !== this.from.rot.angle) {
            this.to.rot.set(p.to.rot);
            this.easeRot = true;
        } else {
            this.to.rot.assign(p.from.rot);
            this.easeRot = false;
        }
    }
}
