import * as Geo from 'tslib/geo';

import {scanTheme_} from '../ScanTheme/ScanTheme';

export function calcThumbImageRect(scanSize: Geo.Size): Geo.Rect {
    const Layout = scanTheme_; // TODO: later remove

    if (scanSize.h < scanSize.w) {
        let width = Layout.thumbnailImageMax;
        let height = (scanSize.h * width) / scanSize.w;
        //let y = (Layout.thumbnailImageSize.h - height)/2;

        return Geo.makeRect(0, 0, width, height);
    } else {
        let height = Layout.thumbnailImageMax;
        let width = (scanSize.w * height) / scanSize.h;
        //let y = (Layout.thumbnailImageSize.h - height)/2;

        return Geo.makeRect(0, 0, width, height);
    }
}
