import React from 'react';

import {makeError} from 'tslib/error';

import * as Api from 'api/api';
import {InputFormContext} from 'tsui/Form/FormContext';

import ErrorMessage from 'tsui/ErrorMessage';
import ProgressIndicator from 'tsui/ProgressIndicator';
import {HookDeps} from 'tsui/React/ReactUtil';

// DT = Destination Data Type
// AT = Api Data Type

type ApiGroupsType = 'public' | 'user' | 'admin' | 'scans' | 'scan';

type ApiDataHandlerRet<DT> = void | DT;
type ApiDataHandler<AT, DT = AT> = (data: AT) => ApiDataHandlerRet<DT>;

export interface ApiDataParams<ApiDT, DestDT = ApiDT> {
    apiDefer?: boolean; // Whether defer the call of the api
    apiGroup?: ApiGroupsType;
    apiCommand: string;
    apiParams?: Api.ApiParams;

    form?: InputFormContext;

    onData?: ApiDataHandler<ApiDT, DestDT>;

    //onData?: (data: ApiDT) => void  // The api data is the same as target data
}

export class ApiDataState<AT, DT = AT> {
    apiGroup: ApiGroupsType = 'public';
    apiCommand: string = '';
    apiParams?: Api.ApiParams;

    form?: InputFormContext;

    isMounted = false; // Whether the component is mounted
    isDeferred = false; // Whether the api call is deferred
    isRequested = false; // Whether the api was requested
    isLoading = false; // If the data is loading
    isReady = false; // The data is ready

    data?: DT;
    error?: Error;

    //onData?: (data: DT) => void
    onData?: ApiDataHandler<AT, DT>;

    setApiParams(params: ApiDataParams<AT, DT>) {
        this.isDeferred = params.apiDefer === true;

        this.apiGroup = params.apiGroup ?? 'user';
        this.apiCommand = params.apiCommand;
        this.apiParams = params.apiParams;
        this.onData = params.onData;
        this.form = params.form;
        this.setFetchPending();
    }

    release() {
        this.isMounted = false;

        this.form = undefined;
        this.onData = undefined;
        this.data = undefined;
    }

    setFetchPending() {
        this.isDeferred = false;
        this.isRequested = false;
        this.isLoading = true;
        this.isReady = false;
        this.clearError();

        if (this.form) {
            this.form.isLoading = true;
        }
    }

    processData(data: AT) {
        this.clearError();
        this.isLoading = false;
        this.isReady = true;

        if (this.onData) {
            let d = this.onData(data);
            this.data = d ?? (data as unknown as DT);
        } else {
            this.data = data as unknown as DT;
        }

        if (this.form) {
            this.form.clearLoading();
        }
    }

    raiseError(err: any) {
        this.error = makeError(err);
        this.form && this.form.setError(this.error);
    }

    clearError() {
        this.error = undefined;
        this.form && this.form.clearError();
    }
}

export function useApiData<AT, DT = AT>(params: ApiDataParams<AT, DT>, deps?: HookDeps): ApiDataState<AT, DT> {
    const stateRef = React.useRef(new ApiDataState<AT, DT>());

    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

    // Initialization hook
    React.useEffect(() => {
        let state = stateRef.current!;

        state.isMounted = true;

        return () => state.release();
    }, []);

    // Data request hook
    React.useEffect(() => {
        //console.debug('Requesting update');

        let state = stateRef.current!;

        state.isReady = false;
        state.data = undefined;

        state.setApiParams(params);

        if (state.isDeferred) return;

        state.isRequested = true;

        let apiCall: (group: string, command: string, params?: Api.ApiParams) => Promise<AT>;

        apiCall = state.apiGroup === 'admin' ? Api.requestAdmin : Api.requestSession;

        apiCall(state.apiGroup, state.apiCommand, state.apiParams).then(processData).catch(raiseError);
    }, deps ?? []);

    const processData = React.useCallback(
        (data: AT) => {
            stateRef.current!.processData(data);
            forceUpdate();
        },
        [stateRef.current]
    );

    const raiseError = React.useCallback(
        (err: Error) => {
            stateRef.current!.raiseError(err);
            forceUpdate();
            //setData(stateRef.current!.data)
        },
        [stateRef.current]
    );

    return stateRef.current;
}

interface ApiDataProviderProps<DT> {
    dataState: ApiDataState<DT>;
    children: React.ReactNode;
}

export default function ApiDataProvider<DataT>(props: ApiDataProviderProps<DataT>) {
    const dataState = props.dataState;

    if (dataState.error) return <ErrorMessage error={dataState.error} />;

    if (dataState.isLoading) return <ProgressIndicator background='data' />;

    return <>{props.children}</>;
}
