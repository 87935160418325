import React from 'react'

import {Paper, Stack, Box} from '@mui/material'

import ReportHeading from '../ReportContents/ReportHeading'
import SlateEditor from './ReportSlateEditor'
import ReportFooter from '../ReportContents/ReportFooter'
import SlateToolBar from './ReportEditorToolbar'

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import ViewIcon from '@mui/icons-material/PageviewOutlined'

import {BaseEditor, createEditor, Descendant, Editor} from 'slate'

import * as GD from 'tools/GlobalDispatch'
import * as RA from '../ReportTypes/ReportAction'
import * as AD from 'api/data'
import * as D from 'data'
import * as Api from 'api'

import {saveReport} from '../ReportApi/ReportsApi'
import PageToolbar from 'tsui/PageToolbar'

import {ReportEditProps} from '../ReportTypes/ReportEditProps'
import {ReportSectionContents} from '../ReportComponents/ReportSectionContents'
import {ReportSectionContainer} from '../ReportComponents/ReportSectionContainer'
import ToolbarButton, { ToolbarButtonProps } from 'components/Buttons/ToolbarButton'

interface Props {
    ctx: RA.ReportEditContext
}

export class ReportEditorState {
    textValue: Descendant[] = []

    textStyles = new Map<string, boolean>()

    justSaved = false

    constructor() {
        for (let key of RA.editorTextStyles) {
            this.textStyles.set(key, false)
        }

        for (let key of RA.editorBlockStyles) {
            this.textStyles.set(key, false)
        }
    }
}

export default function ReportEditTab(props: Props) {
    //const [textValue, setTextValue] = React.useState()
    const stateRef = React.useRef(new ReportEditorState())
    const st = stateRef.current

    const [saveDisabled, setSaveDisabled] = React.useState(false)
    const [boldActive, setBoldActive] = React.useState(false)
    const [italicActive, setItalicActive] = React.useState(false)

    const [alignLeft, setAlignLeft] = React.useState(false)
    const [alignCenter, setAlignCenter] = React.useState(false)
    const [alignRight, setAlignRight] = React.useState(false)
    const [alignJustify, setAlignJustify] = React.useState(false)

    React.useEffect(() => {
        const onMark = (evt) => {
            const key = evt.key
            const isActive = evt.active as boolean
            updateStyles(key, isActive)
        }

        GD.pubsub_.addListener(RA.onEditorFormat, onMark)

        return () => {
            GD.pubsub_.removeListener(RA.onEditorFormat, onMark)
        }
    }, [])

    const updateStyles = React.useCallback((key: string, active: boolean) => {
        st.textStyles.set(key, active)

        //console.debug(evt)
        switch (key) {
            case 'bold':
                setBoldActive(active)
                break
            case 'italic':
                setItalicActive(active)
                break

            case 'left':
                setAlignLeft(active)
                break
            case 'center':
                setAlignCenter(active)
                break
            case 'right':
                setAlignRight(active)
                break
            case 'justify':
                setAlignJustify(active)
                break
        }
    }, [])

    const onMark = React.useCallback((key: string) => {
        let active = st.textStyles.get(key) ?? false

        updateStyles(key, !active)

        GD.pubsub_.dispatch(RA.editorToggleFormat, {key: key})
    }, [])

    const onTextChange = React.useCallback((text) => {
        //console.debug(text)

        if (!st.justSaved) {
            st.justSaved = false
            setSaveDisabled(false)
        }

        st.textValue = text
    }, [])

    const onSaveReport = React.useCallback(() => {
        st.justSaved = true
        setSaveDisabled(true)
    }, [])

    const onFinalizeReport = React.useCallback(() => {
        st.justSaved = true
        setSaveDisabled(true)

        Api.request<AD.ApiReportData>({
            group: 'report',
            command: 'finalize',
            params: {reportId: props.ctx.report.reportId},
            body: {text: st.textValue},
        })
            .then((d) => {
                let r = D.processApiReport(d)
                //     console.log(d)
                //     window.open(d.pdfLink, '_blank')
                //     return d
            })
            .catch((err) => alert(err))
    }, [])

    const onPreviewReport = React.useCallback(() => {
        st.justSaved = true
        setSaveDisabled(true)

        Api.request<AD.ApiReportData>({
            group: 'report',
            command: 'preview',
            params: {reportId: props.ctx.report.reportId},
            body: {text: st.textValue},
        })
            .then((d) => {
                let r = D.processApiReport(d)
                let ref = D.getReportViewRef(r)

                window.open(ref.href, ref.target)
                //console.debug(ref)

                //     console.log(d)
                //     window.open(d.pdfLink, '_blank')
                //     return d
            })
            .catch((err) => alert(err))
    }, [])

    return (
        <ReportSectionContainer>
            {/* <SlateToolBar {...props} /> */}

            <PageToolbar>
                <ToolbarButton label='Save' type='important' disabled={saveDisabled} onClick={onSaveReport} />

                <Box flex={1} />

                <ToolbarButton icon={FormatBoldIcon} active={boldActive} onClick={() => onMark('bold')} />
                <ToolbarButton icon={FormatItalicIcon} active={italicActive} onClick={() => onMark('italic')} />

                {/* <EditStyleButton icon={FormatItalicIcon} mark='italic' st={st} /> */}

                <Box flex={1} />

                <ToolbarButton icon={FormatAlignLeftIcon} active={alignLeft} onClick={() => onMark('left')} />
                <ToolbarButton icon={FormatAlignCenterIcon} active={alignCenter} onClick={() => onMark('center')} />
                <ToolbarButton icon={FormatAlignRightIcon} active={alignRight} onClick={() => onMark('right')} />
                <ToolbarButton icon={FormatAlignJustifyIcon} active={alignJustify} onClick={() => onMark('justify')} />

                <Box flex={1} />

                <ToolbarButton icon={ViewIcon} onClick={onPreviewReport} />

                <Box flex={20} />

                <ToolbarButton label='Finalize' type='important' onClick={onFinalizeReport} />
            </PageToolbar>

            <ReportSectionContents>
                <Stack direction='column' sx={{position: 'relative', height: 1}}>
                    <ReportHeading ctx={props.ctx} />

                    <Box
                        sx={{
                            position: 'relative',
                            height: 1,
                        }}
                    >
                        <SlateEditor ctx={props.ctx} state={stateRef.current} onTextChange={onTextChange} />
                    </Box>
                </Stack>
                {/* <ReportFooter ctx={props.ctx}/> */}
            </ReportSectionContents>
        </ReportSectionContainer>
    )
}

interface StyleButtonProps extends ToolbarButtonProps {
    mark: string
    st: ReportEditorState
    //onStyle: (mark: string) => void
}

function EditStyleButton(props: StyleButtonProps) {
    const [isActive, setIsActive] = React.useState(props.st.textStyles.get(props.mark))

    React.useEffect(() => {
        const onMark = (evt) => {
            console.debug(evt)

            if (evt.key === props.mark) {
                let active = evt.active as boolean
                //props.st.styles[props.mark] = active
                props.st.textStyles.set(props.mark, active)
                setIsActive(active)

                //console.debug(evt.key, props.st.styles[props.mark])
            }
        }

        GD.pubsub_.addListener(RA.onEditorFormat, onMark)

        // else {
        //     GD.pubsub_.addListener(RA.onEditorMark, onMark)
        // }

        return () => {
            GD.pubsub_.removeListener(RA.onEditorFormat, onMark)
        }
    }, [])

    const onClick = React.useCallback(() => {
        // const isBlockStyle = RA.editorBlockStyles.indexOf(props.mark) >= 0

        // console.debug(isBlockStyle)

        GD.pubsub_.dispatch(RA.editorToggleFormat, {key: props.mark})
    }, [])

    return <ToolbarButton active={isActive} icon={props.icon} onClick={onClick} />
}
