import React from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';

import ErrorMessage from 'tsui/ErrorMessage';

import * as Url from 'tslib/url';

import ScanViewer from './ScanViewer';
import HtmlHeader from 'ui/HtmlHeader';
import { initScanTheme, releaseScanTheme } from './ScanTheme/ScanTheme';

interface ScanPageProps {
    title?: string;
}


interface ScanPageOptions {
    //caller: string;
    fullscreen: boolean;
    close: boolean;
    //constructor
}

export default function ScanViewPage(props?: ScanPageProps) {
    const [error, setError] = React.useState<Error>();

    const params = useParams();
    const [scanId, setScanId] = React.useState<string>();

    const navigate = useNavigate();
    const location = useLocation();

    const [options, setOptions] = React.useState<ScanPageOptions>();


    React.useEffect(() => {
        if (!params.scanId) {
            setError(new Error(`scanId parameter is missing`));
            return;
        }

        setScanId(params.scanId);

        const search = location.search;
        const urlParams = new URLSearchParams(search);

        setOptions({
            fullscreen: Url.getBoolParam(urlParams.get('fullscreen')),
            close: Url.getBoolParam(urlParams.get('close')),
        });

        // const paramFullscreen = Url.getBoolParam(urlParams.get('fullscreen'));
        // const paramCaller = urlParams.get('caller');
        // const paramClose = urlParams.get('close');
    

        initScanTheme();

        return () => {
            releaseScanTheme();
        };
    }, []);

    const onClose = React.useCallback(() => {
        // if (paramCaller) {
        //     navigate(paramCaller);
        // } else {
            if (options?.close) {
                window.close();
            } else {
                navigate(-1);
            }
        //}
    }, [options]);


    if (!scanId || !options) return <></>;

    if (error) return <ErrorMessage error={error} />;

    return (
        <>
            <HtmlHeader title={props?.title ?? scanId} />
            <ScanViewer scanId={scanId} onClose={onClose} fullScreen={options.fullscreen} />
        </>
    );
}
