import {WglRenderBatch} from '../webgl_batch';
import {WglRenderer} from '../webgl_renderer';

import * as Vertex from '../webgl_vertex';

export function WglRenderer_debugDumpBatchVertices(this: WglRenderer, batch: WglRenderBatch) {
    console.debug('-- Vertices:', batch.vertices.numVtx);

    let iv = 0; // Value index
    let v = batch.vertices.vtxData;
    for (let ivtx = 0; ivtx < batch.vertices.numVtx; ++ivtx) {
        console.debug(`  ${ivtx}: p(${v[iv + 0]}, ${v[iv + 1]}), t(${v[iv + 8]}, ${v[iv + 9]})`);

        iv += Vertex.Components;
    }
}
