//import { Debug } from 'tslib/debug';

import { ScanViewRenderer } from '../ScanViewRenderer';
import { assignTouchToPoint } from './ScanNavigation';

//let debug_ = new Debug({enabled: false, name: "Touches"});


export function ScanRender_onTouchStart(this: ScanViewRenderer, ev: TouchEvent) {
   // debug_.printTouches(ev);

    ev.preventDefault();

    let st = this.controlState;
    let count = ev.touches.length;

  //  this.debug_.assert(count !== 0);
    this.cancelEasing();

    //st.currTouchesCount = count;
    // if (!st.isTouch) {
    //     st.isTouch = true;
    // }

    //console.debug("Touch start:", ev.touches.length);

    // If it's one touch, then it acts like a mouse click
    if (count === 1) {
        let ctx = st.touchDragCtx;
        st.currTouchesCount = count;
        assignTouchToPoint(ctx.evScreenCord, ev.touches[0]);
        ctx.lastDownTimestamp = ctx.downTimestamp;
        ctx.downTimestamp = ev.timeStamp;
        assignTouchToPoint(ctx.touchDownCord, ev.touches[0]);
        this.intertiaHandler_.onMoveStart(ev.timeStamp, ctx.evScreenCord);
        this.onMouseDragBegin(st.touchDragCtx, ctx.evScreenCord);
    } else if (count >= 2) {
        let ctx = st.touchDragCtx;
        st.currTouchesCount = 2;  // for now we are handling only 2 touches
        ctx.setTouchDouble(ev.touches[0], ev.touches[1]);
        this.convertScreenToScanFromTo(ctx.evScreenCord, ctx.evScanPos1);
        this.convertScreenToScanFromTo(ctx.evTouch2Cord, ctx.evScanPos2);
        ctx.touchZoomChanged = ctx.touchRotChanged = ctx.touchPosChanged = true;
        ctx.stashTouchLast();
        this.intertiaHandler_.reset();
    }

}

const Log2 = Math.log(2);

export function ScanRender_onTouchMove(this: ScanViewRenderer, ev: TouchEvent) {
   // debug_.printTouches(ev);

    ev.preventDefault();

    let count = ev.touches.length;
    let st = this.controlState;

    if (st.currTouchesCount === 0)
        return;

    if (count < st.currTouchesCount) {
        //console.error("onTouchMove::strange");
        st.currTouchesCount = count;
    }

    // For one finger were just positioning the scan
    if (st.currTouchesCount === 1) {
        let ctx = st.touchDragCtx;
        assignTouchToPoint(ctx.evScreenCord, ev.touches[0]);
        this.onPositionDrag(st.touchDragCtx, ctx.evScreenCord);
        this.intertiaHandler_.onMove(ev.timeStamp, ctx.evScreenCord);

        //this.processLocationChanged();
        this.emitLocationTrack();
        return;
    }

    if (st.currTouchesCount === 2) {
        let ctx = st.touchDragCtx;
        let nav = this.nav!;


        // Fetch the touches data
        ctx.setTouchDouble(ev.touches[0], ev.touches[1]);
        this.convertScreenToScanFromTo(ctx.evScreenCord, ctx.evScanPos1);
        this.convertScreenToScanFromTo(ctx.evTouch2Cord, ctx.evScanPos2);

        // Distance delta between two touches
        // The value of 2 changes the zoom by 1
        // Positive zooms on (zoom level value decreases)
        let distDelta = ctx.evTouchDist - ctx.lastTouchDist; // Touch distance
        let distLambda = ctx.evTouchDist / ctx.lastTouchDist;

        if (distLambda >= 1.01 ||  distLambda <= 0.995) {
            ctx.touchZoomChanged = true;
            //let zoomDelta = (distLambda - 1);
            let zoomDelta = Math.log(distLambda) / Log2;
            nav.addZoomIndex(zoomDelta);
        } else {
            ctx.touchZoomChanged = false;
        }

        // Process rotation
        let thetaDelta = ctx.evTouchAngle.angle - ctx.lastTouchAngle.angle;
        if (Math.abs(thetaDelta) > 0.0001) {
            ctx.touchRotChanged = true;
            nav.rotateViewRad(-thetaDelta);
        } else {
            ctx.touchRotChanged = false;
        }

        // Now process position
        // show the difference. both finger differences should be the same
        let deltaX1 = ctx.evScanPos1.x - ctx.lastScanPos1.x;
        let deltaY1 = ctx.evScanPos1.y - ctx.lastScanPos1.y;
        let deltaX2 = ctx.evScanPos2.x - ctx.lastScanPos2.x;
        let deltaY2 = ctx.evScanPos2.y - ctx.lastScanPos2.y;

        // I don't why I have to divide by 2 again
        let deltaX = -(deltaX1 + deltaX2)/4;
        let deltaY = -(deltaY1 + deltaY2)/4;

        if (Math.abs(deltaX) >= 200 || Math.abs(deltaY)) {
            ctx.touchPosChanged = true;
            nav.offsetPosXY(deltaX, deltaY);
        } else {
            ctx.touchPosChanged = true;
        }

        if (ctx.touchZoomChanged || ctx.touchRotChanged || ctx.touchPosChanged) {
            //this.processLocationChanged();
            ctx.stashTouchLast();
            this.updateWebGL();
        }

        return;
    }

    // we shouldn't be here
    console.error("We shouldn't be here");

    //console.debug("Touch move:", ev.touches.length);
}

export function ScanRender_onTouchEnd(this: ScanViewRenderer, ev: TouchEvent) {
   // debug_.printTouches(ev);

    ev.preventDefault();

    let count = ev.touches.length;
    let st = this.controlState;

    if (st.currTouchesCount === 0)
        return;

    // The user is done manipulating
    if (count === 0) {
        st.currTouchesCount = 0;

        let ctx = st.touchDragCtx;
        ctx.lastUpTimestamp = ctx.upTimestamp;
        ctx.upTimestamp = ev.timeStamp;

        let delta = ctx.upTimestamp - ctx.lastDownTimestamp;

        let dblClick = ctx.lastUpTimestamp > ctx.lastDownTimestamp && delta <= 500;

        if (dblClick) {
            const maxDist = 50;
            if (Math.abs(ctx.touchDownCord.x -  ctx.evScreenCord.x) > maxDist || 
                Math.abs(ctx.touchDownCord.x -  ctx.evScreenCord.x) > maxDist) {
                dblClick = false;
            }
        }

        if (dblClick) {
            let nav = this.nav!;
            // Determine target zoom 
            let toZoom = nav.currLoc.zoom < -2 ? 0 : nav.homePos.zoom; 
            let currPos = nav.currLoc.pos.clone();
            this.easeTo({pos: currPos, zoom: toZoom});
        } else {
            let inertia = this.processInertia(ev.timeStamp);
            if (inertia) {
                this.easeTo({pos: inertia.destPos}, {duration: inertia.duration});
            } else {
                this.processLocationChanged(false);
                this.emitLocation();
            }
        }
        return;
    }

    // The user had multiple fingers
    // now he removed one and he started moving the scan
    if (count === 1) {
        st.currTouchesCount = 1;
        let ctx = st.touchDragCtx;
        assignTouchToPoint(ctx.evScreenCord, ev.touches[0]);
        ctx.setDragCoords(ctx.evScreenCord, this.surfaceCenter);
        ctx.stashTouchLast();
        this.processLocationChanged(false);
        return;
    }

    // If there are still two or more fingers on the screen
    // just do nothing (for now)


    // // One finger is the same as mouse
    // if (count === 1) {
    //     let pos = touchToPoint(ev.touches[0]);
    //     this.onPositionDrag(st.mouseDragCtx, pos);
    //     st.isTouchDragging = false;
    // }


}



export function ScanRender_onTouchCancel(this: ScanViewRenderer, ev: TouchEvent) {
   // debug_.printTouches(ev);

    ev.preventDefault();
    let st = this.controlState;
    st.currTouchesCount = 0;
}

