import React from 'react'

import * as D from 'data'
import * as AD from 'api/data'
import * as Api from 'api'

import PageDataTable, {PageDataTableColumn} from 'tsui/PageDataTable'
import { Typography } from '@mui/material'

interface ModalReportsTabProps {
    scan: D.Scan
}

export default function ScanReportsTab(props: ModalReportsTabProps) {
    const mounted = React.useRef(false)
    const [dataRequested, setDataRequested] = React.useState(false)
    const [data, setData] = React.useState<D.PatientReport[] | null>(props.scan.reports ?? null)

    const [error, setError] = React.useState<Error | null>(null)

    const scan = props.scan

    React.useEffect(() => {
        mounted.current = true

        if (!dataRequested) {
            setDataRequested(true)
            if (props.scan.reports) return

            Api.requestSession<AD.ApiReportData[]>('reports', 'list', {
                scanId: scan.scanId,
            })
                .then((sc) => {
                    if (mounted.current) {
                        const reports = D.processApiReports(sc)
                        props.scan.reports = reports
                        //console.debug(props.scan.reports)
                        // processScanDataItem(sc);
                        setData(reports)
                    }
                })
                .catch(setError)
            return
        }

        return () => {
            mounted.current = false
        }
    }, [dataRequested])

    return (
        <PageDataTable<D.PatientReport>
            getRowId={(row) => row.reportId}
            columns={columns}
            rows={data}
            error={error}
            noRowsOverlay={() => <Typography align='center'>No Reports</Typography>}
            actions={[
                {
                    getTypeHandler: (row) => (row.finalized ? 'view' : 'edit'),
                    getViewHandler: (row) => D.getReportViewRef(row),
                    getEditHandler: (row) => D.getReportEditRef(row),
                },
            ]}
        />
    )
}

const columns: PageDataTableColumn<D.PatientReport>[] = [
    {
        field: 'reportId',
        label: 'Report Id',
        type: 'id',
    },

    {
        field: 'userGreeting',
        label: 'Doctor',
        type: 'greeting',
        flex: 4,
    },

    // {
    //     field: 'finalized',
    //     label: 'Finalized',
    //     type: 'boolean',
    // },

    {
        field: 'status',
        label: 'Status',
        flex: 2,
    },

    // {
    //     field: 'beginDate',
    //     label: 'Date',
    //     type: 'date',
    //     //sortable: true,
    // },

    {
        field: 'lastModified',
        label: 'Modified',
        type: 'date',
        sortable: true,
        initialSort: 'desc',
    },
]
