

import React from 'react'
import * as Api from "api/api"

import Env from 'env';

import * as PatientsApi from 'api/data/patients'


import { Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ProgressIndicator from 'tsui/ProgressIndicator';
import ErrorMessage from 'tsui/ErrorMessage';
// import AddPatientDialog from 'pages/admin/patients/AddPatientDialog';
// import PatientDetails from 'pages/admin/patients/PatientDetails';


export default function AccountPatientsTab() {

    const mounted = React.useRef(false);
    let [dataRequested, setDataRequested] = React.useState(false);
    let [error, setError] = React.useState<Error | null>(null);
    let [patients, setPatients] = React.useState<PatientsApi.ApiPatient[]>();
    let [patientDetailsId, setPatientDetailsId] = React.useState<string | null>(null);

    let [showAddPatient, setShowAddPatient] = React.useState(false);

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            Api.requestSession<PatientsApi.ApiPatient[]>('user', 'fetch_patients', {}).then(patients => {
                if (mounted.current) {
                    setPatients(patients);
                }
            }).catch(setError);
            setDataRequested(true);
            return;
        }
        return () => { mounted.current = false }
    }, [dataRequested]);


    if (error) {
        console.debug(error);
        return <ErrorMessage error={error} />
    }

    if (!patients) {
        return <ProgressIndicator title='Loading...' />
    }


    return <>
        <Grid container height='100%'>
            <Grid item xs={10} md={10}>
                <Typography>Total Patients:&nbsp;{patients.length}</Typography>
            </Grid>
            <Grid item md={2} alignItems='right'>
                <Button variant='contained' onClick={() => { setShowAddPatient(true) }}>Add Patient</Button>
            </Grid>

            <Grid item xs height='100%'>
                <DataGrid
                    columns={[
                        { field: 'accountId', headerName: 'Account Id', headerAlign: 'center', width: 150 },
                        { field: 'firstName', headerName: 'First Name', headerAlign: 'center', width: 150 },
                        { field: 'lastName', headerName: 'Last Name', headerAlign: 'center', width: 150 },
                        { field: 'dateOfBirth', headerName: 'Date of birth', headerAlign: 'center', width: 150 },
                        { field: 'gender', headerName: 'Gender', headerAlign: 'center', width: 150 },
                        { field: 'refId', headerName: 'Ref Id', headerAlign: 'center', width: 150 },
                        { field: 'patientId', headerName: 'Patient Id', headerAlign: 'center', width: 150 },
                        {
                            field: '', type: 'actions', headerName: 'Actions', width: 100, renderCell: (cell) => {
                                return <>
                                    <Button variant='contained' onClick={() => setPatientDetailsId(cell.id as string)}>Details</Button>
                                    {/* <Button onClick={() => setEditedUserIndex(cell.id as string)}>Edit</Button> */}
                                </>;
                            }
                        }, // width: 600 },
                    ]}
                    rows={patients}
                    //pageSize={10}
                    //autoHeight
                    //rowsPerPageOptions={[10]}

                    autoPageSize={true}
                    //disableSelectionOnClick
                    getRowId={row => row.patientId}//?
                    onRowDoubleClick={(parms) => {
                        setPatientDetailsId(parms.id as string)
                    }}
                />
            </Grid>
        </Grid>


        {/* {showAddPatient && <AddPatientDialog onClose={() => setShowAddPatient(false)}></AddPatientDialog>}
        {patientDetailsId && <PatientDetails patientId={patientDetailsId} onClose={() => setPatientDetailsId(null)} />} */}
    </>
}

