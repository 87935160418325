import React from 'react';

import {Box} from '@mui/material';

import {PageProps} from './PageProps';
import ContentsLayout, {makeContainerSxProps} from './ContentsLayout';

interface Props extends PageProps {
    children?: React.ReactNode;
}

export default function PageContentsLayout(props: Props) {
    const [containerSx] = React.useState(
        makeContainerSxProps({
            page: true,
            type: props.type,
            layout: props.layout,
        })
    );

    if (props.layout) {
        return <ContentsLayout {...props}>{props.children}</ContentsLayout>;
    }

    const LayoutElement = Box;

    return (
        <LayoutElement data-name='pg_contents' sx={containerSx}>
            <InnerContainer {...props} />
        </LayoutElement>
    );
}

function InnerContainer(props: Props) {
    if ('data' === props.type) {
        return (
            <Box
                data-name='pg_layout_data'
                sx={[
                    theme_.pageDataBorder.sx(),
                    {
                        width: 1,
                        height: 1,
                        backgroundColor: theme_.pageBackgroundColor,

                        // p: 1,
                        // px: 1,
                    },
                ]}
            >
                {props.children}
            </Box>
        );
    }

    return <>{props.children}</>;
}
