import React from 'react'

import {Grid, Tabs, Tab, Typography, Stack} from '@mui/material'

import {ApiUser} from 'api/users'
import * as Api from 'api/api'

import PageContents from 'components/PageContents'
import * as Form from 'tsui/Form'

import ChangePasswordDialog from '../auth/ChangePasswordDialog'
import AccountDoctorsTab from './userAccountTabs/AccountDoctorsTab'
import AccountBillingTab from './userAccountTabs/AccountBillingTab'
import AccountPatientsTab from './userAccountTabs/AccountPatientsTab'
import Env from 'env'

let startTab = 'doctor'

export default function UserAccountTab() {
    const [selectedTab, setSelectedTab] = React.useState(startTab)

    const onTabChange = React.useCallback((evt, value) => {
        setSelectedTab(value)
    }, [])

    return (
        <Grid container spacing={2} height='100%'>
            {Env.isMobile ? (
                <>
                    <Grid item xs={12}>
                        <Tabs
                            value={selectedTab}
                            onChange={onTabChange}
                            orientation='horizontal'
                            centered
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tab value='doctor' label='Doctors' />
                            <Tab value='billing' label='Billing' />
                            <Tab value='patients' label='Patients' />
                        </Tabs>
                    </Grid>
                    {selectedTab === 'doctor' && <AccountDoctorsTab />}
                    {selectedTab === 'billing' && <AccountBillingTab />}
                    {selectedTab === 'patients' && <AccountPatientsTab />}
                </>
            ) : (
                <>
                    <Grid item xs={2}>
                        <Tabs
                            value={selectedTab}
                            onChange={onTabChange}
                            orientation='vertical'
                            centered
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tab value='doctor' label='Doctors' />
                            <Tab value='billing' label='Billing' />
                            <Tab value='patients' label='Patients' />
                        </Tabs>
                    </Grid>
                    <Grid item xs marginBottom={10}>
                        {selectedTab === 'doctor' && <AccountDoctorsTab />}
                        {selectedTab === 'billing' && <AccountBillingTab />}
                        {selectedTab === 'patients' && <AccountPatientsTab />}
                    </Grid>
                </>
            )}
        </Grid>
    )
}
