import { BezierEasing } from 'tslib/bezier';
import * as Geo from 'tslib/geo';

class InertiaItem {
    time: number;
    x: number;
    y: number;
    //pos: Geo.Point;

    constructor(time: number, p: Geo.IPoint) {
        this.time = time;
        //this.pos = Geo.makePoint(p.x, p.y);
        [this.x, this.y] = [p.x, p.y];
    }
};

export class InertiaStatus {
    //speed = 0;  // Starting speed
    duration = 0;  // Total duration
    distance = 0;  // Total travelling distance
    offset = new Geo.Point();  // Destination offset
    destPos = new Geo.Point();  // Destination position
};


class InertiaHandlerSetup {
    cutoff = 160;  // Buffer cutoff time
    maxSpeed = 1400;
    deceleration = 2500;
    linearity = 0.3;
    minDistance = 50;
    easing = new BezierEasing(0, 0, 0.3, 1);
};

export class InertiaHandler {

    buffer = new Array<InertiaItem>();
    setup = new InertiaHandlerSetup();

    private started_ = false;

    reset() {
        this.buffer.length = 0;
    }

    onMoveStart(time: number, ev: Geo.IPoint) {
        this.reset();
        this.started_ = true;
        this.onMove(time, ev);
    }

    onMove(time: number, ev: Geo.IPoint) {
        if (!this.started_)
            return;
        this.drainBuffer(time);
        this.buffer.push(new InertiaItem(time, ev));
    }

    // When use releases the finger
    onMoveEnd(time: number): InertiaStatus | undefined {
        if (!this.started_)
            return undefined;

        this.drainBuffer(time);
        //this.onMove(time, p);

        this.started_ = false;

        if (this.buffer.length <= 2)
            return undefined;

        let opts = this.setup;

        // How much we moved total
        let deltas = new Geo.Point(0, 0);
        let buff = this.buffer;
        //let prevPos = buff[0].pos;
        let [prevX, prevY] = [buff[0].x, buff[0].y]

        //console.debug(buff);

        // Process each recorded event
        for (let ev of buff) {
            deltas.x += ev.x - prevX;
            deltas.y += ev.y - prevY;
            [prevX, prevY] = [ev.x, ev.y];
        }

        //console.debug("Deltas:", deltas);

        // Distance travelled the finger
        let touchDistance = deltas.norm();

        //console.debug(touchDistance);

        if (touchDistance < this.setup.minDistance)
            return undefined;

        //console.debug("Dist:", touchDistance);

        // Get total duration of user interaction
        const lastEntry = buff[buff.length - 1];
        const touchDuration = (lastEntry.time - buff[0].time);

        let st = new InertiaStatus();

        let speed = touchDistance * opts.linearity / (touchDuration / 1000);
        //console.debug("speed:", speed);

        speed = Math.min(speed, opts.maxSpeed);


        // st.speed = Algo.clamp(
        //     touchDistance * opts.linearity / (touchDuration / 1000),
        //     -this.setup.maxSpeed, 
        //     this.setup.maxSpeed);

        //console.debug(deltas, st.speed);

        // Distance to travel
        let duration = speed / (opts.deceleration * opts.linearity);

        duration *= 3;

        //console.debug("duration:", duration);

        st.distance = speed * duration; // * 0.5;

        //st.distance *= 2;  // increase the speed

        st.duration = duration * 1000;

        //console.debug("sdist:", touchDistance, "dist:", st.distance, st.distance / touchDistance );

        //console.debug("tdur:", touchDuration, "tdist:", touchDistance, "dist:", st.distance);
        // Additional distance

        
        st.offset.assign(deltas).scale(st.distance / touchDistance);

        //console.debug("off:", st.offset.x, st.offset.y);


        // clear all our data
        this.reset();

        return st;
    }



    private drainBuffer(time: number) {
        //let cutoff = this.setup.cutoff; //160; // milliseconds
        let buff = this.buffer;
        let cutoff = time - this.setup.cutoff;
        let count = 0, total = buff.length;
        for (; count < total; ++count) {
            if (buff[count].time >= cutoff)
                break;
        }
        count && buff.splice(0, count);
        //console.debug(count, total, buff.length);
    }



};

