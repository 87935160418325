import {WglRenderBatch} from './webgl_batch'
import {RenderQueueWebGL} from './webgl_queue'

export class WglScene {
    changed = false

    renderQueue = new RenderQueueWebGL()

    //
    // Batch references for quick access
    //
    backgroundBatchRef?: WglRenderBatch
    // Tiles batches. The index corresponds to the texture index
    tilesBatchesRef: WglRenderBatch[] = []
    blankBatchRef?: WglRenderBatch
    markersBatchRef?: WglRenderBatch
    busyBatchRef?: WglRenderBatch

    //sampleBatch!: WglRenderBatch;

    release() {
        this.backgroundBatchRef = undefined
        this.tilesBatchesRef.length = 0
        this.blankBatchRef = undefined
        this.markersBatchRef = undefined
        this.busyBatchRef = undefined

        this.renderQueue.release()
    }
}
