
import * as D from 'data'

export type ReportActionType = 'write' | 'edit' | 'amend'
export const editorInsertMacro = 'EditorInsertMacroId'
export const editorToggleFormat = 'EditorToggleMark'
//export const editorToggleBlock = 'EditorToggleBlock'

export const onEditorFormat = 'OnEditorMark'


export const editorTextStyles = ['bold', 'italic']
export const editorAlignStyles = ['left', 'center', 'right', 'justify']
export const editorBlockStyles = editorAlignStyles

interface ReportEditPageSetup {
    paddingX: number
    paddingTop: number
    paddingBottom: number

    lineHeight: number
    fontHeightK: number
}

export interface ReportEditContext {
    action: ReportActionType
    report: D.PatientReport
    scan: D.Scan
    template: D.PatientReportTemplate
    page: ReportEditPageSetup
}
