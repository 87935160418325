import React from 'react';

import {Box} from '@mui/material'

//import { Debug } from 'tslib/debug';

import * as Dom from "tsui/Dom/DomLib";
import { DomSub } from "tsui/Dom/DomLib";


import { scanTheme_ } from '../../ScanTheme/ScanTheme';


import { ScanViewContext } from '../../ScanViewContext';

import { ScanRecogAoi } from '../../ScanTypes/ScanViewStateRecog';
import { AoiPanelState } from '../ScanViewAoiPanel';
import { makeImageId } from './AoiPanelLib';


export function onAoiSelected(scanCtx: ScanViewContext, st: AoiPanelState, index: number) {
    let prevId = makeImageId(st.selectedIndex)
    let el = document.getElementById(prevId)
    scanTheme_.aoiImageBorder.apply(el)
    // if (el) {
    //     el.style.borderColor = ScanTheme.panelBorder.color;
    //     el.style.borderWidth = `${ScanTheme.panelBorder.width!}px`
    // }


    st.selectedIndex = index;
    let nextId = makeImageId(st.selectedIndex)
    el = document.getElementById(nextId)
    scanTheme_.aoiImageSelectedBorder.apply(el)
    // if (el) {
    //     el.style.borderColor = 'yellow';
    //     el.style.borderWidth = `${ScanTheme.panelBorder.getWidth() * 4}px`;
    // }

    let aoi = scanCtx.getAoiByIndex(index);
    let currZoom = scanCtx.getNav().currLoc.zoom;
    let zoom = currZoom < -2 ? 0 : currZoom;
    scanCtx.easeTo({pos: aoi.center, zoom: zoom});
}
