import {Color} from 'tslib/color'
import * as Geo from 'tslib/geo'

import {ScanLocation} from '../../ScanTypes/ScanLocation'
import {RenderTexture} from '../RenderTexture'
import {ScanViewRenderer} from '../ScanViewRenderer'
import {ScanScenePatMarker} from './ScanScenePatMarker'
import {SceneTexture} from './SceneTexture'
import {SceneTile} from './SceneTile'

// Each scene corresponds to one zoom level

export interface ScanSceneSetup {
    //ren: ScanViewRenderer;  // Renderer for methods
    zoom: number // zoom level this scene corresponds to
    minZoom: number // farthest zoom level this scene covers (neg - no limit)
    maxZoom: number // nearest zoom level this scene covers
}

export class ScanScene {
    // Quick refs
    renRef_?: ScanViewRenderer
    scanSize_ = new Geo.Size() // For quick access

    // Zoom leves this scene corresponds
    tileZoom = Infinity
    maxZoom = Infinity
    minZoom = Infinity

    // The scan area current scene may cover
    scanCoverSize = new Geo.Size()
    //scanRect = new Geo.Rect();
    //scanRectVtx = new Geo.RectVtx();
    //scanRect = new Geo.Rect();

    // The last zoom and position the scene was updated to
    lastZoom = Infinity
    //lastScanPos = new Geo.Point(Infinity, Infinity);
    //layoutChanged = true;  // If the screen was changed

    // State
    active = false
    //dataPending = false;  //
    dataChanged = false // Scene needs to rerender

    // Stuff for fast calculations
    screenToScanK_ = 1 // converting from/to screen to scan coefficients
    scanToScreenK_ = 1

    // Scene textures to render
    textures: SceneTexture[] = []
    backgroundTexture?: SceneTexture // If the scene uses some back tex

    // Various containers for quick action
    // One big array of tiles since we often go through all tiles
    tilesCache_: SceneTile[] = []
    orderedTilesCache_: SceneTile[] = []

    //
    // Pathogen markers support
    //
    patMarkers: ScanScenePatMarker[] = []

    constructor(ren: ScanViewRenderer, setup: ScanSceneSetup) {
        let scanCtx = ren.scanCtx!
        let scanInfo = scanCtx.scanInfo

        this.renRef_ = ren
        this.tileZoom = setup.zoom

        this.minZoom = setup.minZoom
        this.maxZoom = setup.maxZoom

        this.scanSize_.assign(scanInfo.scanSize)
    }

    release() {
        this.renRef_ = undefined

        this.orderedTilesCache_.length = 0
        //this.orderedTilesCache_.clear();

        this.tilesCache_.length = 0
        for (let tex of this.textures) {
            tex.release()
        }
        this.textures.length = 0

        this.patMarkers.length = 0
    }

    //
    // Building the scene
    //
    build() {
        this.onBuild()

        // Stash all the tiles
        for (let tex of this.textures) {
            for (let tile of tex.tiles) {
                this.tilesCache_.push(tile)
            }
        }
    }

    buildScenePatMarkers = buildScenePatMarkers

    updateLayout(loc: ScanLocation) {
        //let nav = this.renRef_!.nav!;
        this.onLayoutChange(loc)

        //this.layoutChanged = true;
        if (this.active) {
            this.onLocation(loc)
        }
    }

    addTexture(renTex: RenderTexture): SceneTexture {
        let tex = new SceneTexture()
        tex.renTexture = renTex
        tex.sceneRef = this

        this.textures.push(tex)
        this.dataChanged = true

        return tex
    }

    //
    // Maintaining and controlling the scene
    // Returns true is the scene needs to be rerendered
    //
    setLocation(loc: ScanLocation): boolean {
        return this.onLocation(loc)
    }

    // Called during scene switching
    deactivate() {
        if (this.active) {
            this.active = false
            this.onDeactivate()
        }
    }

    activate() {
        if (!this.active) {
            this.lastZoom = Infinity
            this.active = true
            this.onActivate()
        }
    }

    //
    // Various utilities
    //

    // Checks if the location is covered by the scene
    // Returns tuple. First element shows if the scene is in the zoom level at all
    // Second shows if the position is inside the current scene position
    // isInLocation(loc: ScanLocation): [boolean, boolean] {
    //     return [this.isInZoom(loc.zoom), this.isInPos(loc.pos)];
    // }

    // Checks if the scene covers the zoom
    isInZoom(zoom: number): boolean {
        if (this.minZoom !== Infinity && zoom <= this.minZoom) return false
        if (this.maxZoom !== Infinity && zoom > this.maxZoom) return false
        return true
    }

    // // Checks if the scene covers current scan position
    // isInPos(pos: Geo.IPoint): boolean {
    //     return pos.x >= this.scanRectVtx.lb.x && pos.x <= this.scanRectVtx.rt.x
    //         && pos.y >= this.scanRectVtx.lb.y && pos.y <= this.scanRectVtx.rt.y;
    // }

    //
    // Virtual methods
    //
    // The life cycle of the scene

    onBuild() {
        throw new Error('ScanScene::onBuild() must be overriden')
    }

    onActivate() {}
    onDeactivate() {}

    onLayoutChange(loc: ScanLocation) {}

    onLocation(loc: ScanLocation): boolean {
        return false
    }
}

function buildScenePatMarkers(this: ScanScene) {
    //console.debug("onBuild Pat Markers");

    let ren = this.renRef_!
    //let webgl = ren.webgl!;
    let scanCtx = ren.scanCtx!
    //let scanInfo = scanCtx.scanInfo;

    let aoiCount = scanCtx.aoi?.count ?? 0
    this.patMarkers.length = aoiCount

    if (aoiCount === 0) return

    let posColor = new Color('red')
    let susColor = new Color('yellow')

    //console.debug(this);

    let radiusK = 1.4
    if (this.minZoom !== Infinity) {
        switch (this.minZoom) {
            case 0:
            case -1:
                radiusK = 6.4
                break
            default:
                radiusK = 4.4
                break
        }
    }

    //this.minZoom === Infinity ? 1.4 : 6.4;

    let aoiIndex = 0
    for (let aoi of scanCtx.aoi!.aoiData) {
        for (let pat of aoi.pathogens) {
            //console.debug(aoi);
            let color = pat.status === 'S' ? susColor : posColor
            let radius = pat.radius * radiusK
            let marker = new ScanScenePatMarker(pat.pos, radius, color)
            this.patMarkers[aoiIndex++] = marker
        }
    }
}
