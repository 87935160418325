import React, {useMemo} from 'react'

import {BaseEditor, createEditor, Descendant, Editor} from 'slate'
import {Slate, Editable, withReact, ReactEditor} from 'slate-react'
import {HistoryEditor, withHistory} from 'slate-history'

import {Box, Button, Divider, Grid, Typography} from '@mui/material'

export const ReportEditorLeaf = ({attributes, children, leaf}) => {

    if (leaf.bold) {
        children = <strong>{children}</strong>
      }
    
      if (leaf.code) {
        children = <code>{children}</code>
      }
    
      if (leaf.italic) {
        children = <em>{children}</em>
      }
    
      if (leaf.underline) {
        children = <u>{children}</u>
      }
    
      return <span {...attributes}>{children}</span>    

    //console.debug(leaf)

    // let textAlign = 'left'

    // if (leaf.align_right) {
    //     textAlign = 'right'
    // }

    // if (leaf.align_center) {
    //     textAlign = 'center'
    // }

    // if (leaf.align_left) {
    //     textAlign = 'left'
    // }

    // return (
    //     <span
    //         {...attributes}
    //         style={{
    //             fontWeight: leaf.bold ? 'bold' : 'normal',
    //             fontStyle: leaf.italic ? 'italic' : 'normal',
    //             //textAlign: textAlign,
    //             //textAlign: 'right',
    //         }}
    //     >
    //         {children}
    //     </span>
    // )

    //console.debug(leaf.align_center)

    // if (leaf.bold) {
    //     children = <strong>{children}</strong>
    // }

    // if (leaf.italic) {
    //     children = <em>{children}</em>
    // }

    // if (leaf.align_center) {
    //     // children = <div style={{ textAlign: 'center' }}>{children}</div>
    //     children = <Typography align='center'>{children}</Typography>
    // }

    // if (leaf.align_right) {
    //     return <div style={{ textAlign: 'right' }} {...attributes}>{children}</div>
    //     // children = <Typography align='right'>{children}</Typography>
    // }

    // if (leaf.align_left) {
    //     // children = <div style={{ textAlign: 'left' }}>{children}</div>
    //     children = <Typography align='left'>{children}</Typography>
    // }
    // // if (leaf.underline) {
    // //   children = <u>{children}</u>
    // // }
    // // if (leaf.code) {
    // //   children = <code>{children}</code>
    // // }

    // return <span {...attributes}>{children}</span>
}

//export default Rep
