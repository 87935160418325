import React from 'react'

import {Dialog, DialogActions, DialogTitle, DialogContent} from '@mui/material'
import {Slide, SlideProps, Divider} from '@mui/material'
import {Typography, IconButton, Button} from '@mui/material'
import {Grid, Box, TextField, Select, MenuItem, InputAdornment, InputLabel} from '@mui/material'
import {FormControl} from '@mui/material'
import {Paper} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {ScanViewStateInstance} from '../ScanViewStateProvider'

interface ScanViewPopupProps {
    show?: boolean
    onClose: () => void
}

const Transition = React.forwardRef((props: SlideProps, ref) => (
    <Slide direction='up' ref={ref} {...props} />
))

export default function ScanViewPopup(props: ScanViewPopupProps) {
    const scanCtx = React.useContext(ScanViewStateInstance).state
    const scanInfo = scanCtx.scanInfo

    const handleClose = React.useCallback((_, reason) => {
        if (reason !== 'backdropClick') {
            scanCtx.gui!.dialogActive = false
            props.onClose()
        }
    }, [])

    if (props.show === false) return <></>

    scanCtx.gui!.dialogActive = true

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            container={scanCtx.gui?.mainContainer}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth='sm'
            PaperComponent={Paper}
            disableEscapeKeyDown
        >
            <DialogTitle id='scanview-popup-dialog-title'>
                <Typography display='inline' variant='inherit'>
                    {scanCtx.scanId}
                </Typography>

                <IconButton
                    aria-label='close'
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={1} marginBottom={1}>
                        <Grid item xs={4} md={3}>
                            <TextField
                                label='Image Width'
                                defaultValue={scanInfo.scanSize.w}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position='start'>px</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={4} md={3}>
                            <TextField
                                label='Image Height'
                                defaultValue={scanInfo.scanSize.h}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position='start'>px</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={4} md={6}>
                            <TextField
                                label='Resolution'
                                defaultValue={'0.0025 x 0.0025'}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position='start'>mm</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={8} md={8}>
                            <TextField
                                label='Scanner'
                                sx={{width: 1}}
                                defaultValue={scanInfo.scanner.model}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>

                        <Grid item xs={4} md={4}>
                            <TextField
                                label='Scan Date'
                                defaultValue={'01/01/01'}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label='Detected'
                                sx={{width: 1}}
                                defaultValue={'NEGATIVE'}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>

                        <Grid item xs={4} md={4}>
                            <FormControl>
                                <InputLabel id='scan-diag-selection'>Diagnosis</InputLabel>
                                <Select labelId='scan-diag-selection' label='Diagnosis' value='U'>
                                    <MenuItem value='U'>
                                        <em>(Not Selected)</em>
                                    </MenuItem>
                                    <MenuItem value='P'>POSITIVE</MenuItem>
                                    <MenuItem value='N'>NEGATIVE</MenuItem>
                                </Select>
                                {/* <FormHelperText>Diagnosis</FormHelperText> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label='Notes'
                                sx={{width: 1}}
                                defaultValue={''}
                                multiline
                                maxRows={8}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Divider />

                <Button variant='contained'>Share Scan</Button>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined'>Undo</Button>
                &nbsp;
                <Button variant='contained' autoFocus onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
