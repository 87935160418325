import * as UE from 'tsui/UiElements';
import {SxObject} from 'tsui/Mui/SxPropsUtil';

interface IThemeDecor {
    show?: boolean;

    layout?: UE.UiLayout;
    font?: UE.UiFont;

    src?: string; // For images
}

export class ThemeDecor implements IThemeDecor {
    show?: boolean = false;

    layout?: UE.UiLayout;
    font?: UE.UiFont;

    src?: string;

    constructor(i?: IThemeDecor) {
        if (i) {
            this.init(i);
        }
    }

    init(i: IThemeDecor) {
        Object.assign(this, i);
        this.show = i.show === false ? false : true;
    }

    sx() {
        let sx: SxObject = {};

        if (this.layout) {
            Object.assign(sx, this.layout.sx());
        }

        if (this.font) {
            Object.assign(sx, this.font.sx());
        }

        return sx;
    }
}

export class PageHeaderTheme {
    backgroundDecor = new Array<ThemeDecor>();
    bkTopLeftDecor = new ThemeDecor();
    bkTopRightDecor = new ThemeDecor();

    logo = new ThemeDecor();
    titleBanner = new ThemeDecor();
    titleBannerShort = new ThemeDecor();

    mainMenu = new ThemeDecor();

    loginButton = new ThemeDecor();
    logoutButton = new ThemeDecor();
    joinButton = new ThemeDecor();
    greetingButton = new ThemeDecor();

    langSelector = new ThemeDecor();

    navBar = new ThemeDecor();
}
