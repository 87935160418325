import {Color} from 'tslib/color'
import * as Vertex from './webgl_vertex'
import {WglTexture} from './webgl_renderer'
import {WglShaderProgram} from './webgl_program'

export class WglRenderBatch {
    shader?: WglShaderProgram

    visible = true

    vtxOffset = 0
    idxOffset = 0

    renderVtxCount = 0
    renderIdxCount = 0
    vertices = new Vertex.VertexContainer()

    // Texture support
    texRef?: WglTexture
    texIndex = -1

    //
    // Contents
    //

    // Flag showing that the batch is animating and the shader needs to be
    // called on every frame
    animating = false

    elementChanged = false
    elementSize = 0
    elementColor1?: Color
    elementColor2?: Color

    setupElement(size: number, color1?: string, color2?: string) {
        this.elementChanged = true
        this.elementSize = size
        this.elementColor1 = color1 ? new Color(color1) : undefined
        this.elementColor2 = color2 ? new Color(color2) : undefined
    }

    progressEnabled = false
    progressValue = 0
    progressStart = 0
    progressDuration = 0

    release() {
        this.shader = undefined
        this.texRef = undefined
        this.texIndex = -1
    }
}
