import React from 'react';

import {Box, Stack, Tabs, Tab, BottomNavigationAction, Paper} from '@mui/material';
import {BottomNavigation} from '@mui/material';

import {SxObject} from 'tsui/Mui/SxPropsUtil';
import {PageProps} from './PageProps';
import {PageLayoutProps} from './ContentsLayoutProps';

interface Props {
    page?: boolean;
    type?: 'data'; // Contents type

    layout?: PageLayoutProps;
    children?: React.ReactNode;
}

export function makeContainerSxProps(props: Props) {
    let sx: SxObject[] = [];

    let left = 0,
        right = 0,
        top = 0,
        bottom = 0;

    let pl = theme_.pageHorzMargin,
        pr = theme_.pageHorzMargin,
        pt = theme_.pageTopMargin,
        pb = theme_.pageBottomMargin;

    if (props.layout) {
        let isTabs = props.layout.type === 'tabs' || props.layout.tabbed !== undefined;
        let isStack = props.layout.type === 'stack' || props.layout.stack !== undefined;

        if (props.type === 'data') {
            if (isTabs || isStack) {
                left = right = theme_.muiSpacing * theme_.pageHorzMargin;
                top = theme_.muiSpacing * theme_.pageTopMargin;
                bottom = theme_.muiSpacing * theme_.pageBottomMargin;

                pl = pr = pt = pb = 0;
                pl = pr = 1;
                pb = 1;

                //sx.push(theme_.dialogBorder.sx())
                sx.push(theme_.pageDataBorder.sx());
                sx.push({backgroundColor: theme_.pageBackgroundColor});
            }
        }
    }

    sx.unshift({
        position: 'absolute',
        left: left,
        right: right,
        top: theme_.pageHeaderHeight + top,
        bottom: bottom,

        paddingLeft: pl,
        paddingRight: pr,
        paddingTop: pt,
        paddingBottom: pb,
    });

    return sx;
}

export default function ContentsLayout(props: Props) {
    const [containerSx] = React.useState(makeContainerSxProps(props));

    if (props.layout) {
        if (props.layout.tabbed) {
            const tabbed = props.layout.tabbed!;
            const horizontal = tabbed.orientation !== 'vertical';
            const direction = horizontal ? 'column' : 'row';
            return (
                <Stack data-name='pg_layout_tabs_stack' direction={direction} sx={containerSx}>
                    <Tabs
                        data-name='pg_layout_tabs'
                        value={tabbed.selected as string}
                        onChange={(evt, val) => tabbed.onChange(val)}
                        orientation={horizontal ? 'horizontal' : 'vertical'}
                        centered={horizontal ? true : false}
                        sx={{
                            borderBottom: horizontal ? 1 : undefined,
                            borderRight: horizontal ? undefined : 1,
                            borderColor: 'divider',
                        }}
                    >
                        {tabbed.tabs.map((item, index) => (
                            <Tab key={index} value={item.key} label={item.label} />
                        ))}
                    </Tabs>
                    {props.children}
                </Stack>
            );
        }

        if (props.layout.nav) {
            const nav = props.layout.nav;
            return (
                <Stack data-name='pg_layout_tabs_nav' direction='column' sx={containerSx}>
                    {props.children}
                    <Paper elevation={3} sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}>
                        <BottomNavigation
                            data-name='pg_layout_bottom_nav'
                            showLabels
                            value={nav.selected as string}
                            onChange={(evt, val) => nav.onChange(val)}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {nav.actions.map((item, index) => (
                                <BottomNavigationAction key={index} value={item.key} label={item.label} />
                            ))}
                        </BottomNavigation>
                    </Paper>
                </Stack>
            );
        }

        if (props.layout.type === 'stack' || props.layout.stack) {
            const stack = props.layout.stack!;
            //const stackSx = containerSx
            return (
                <Stack
                    data-name='pg_layout_stack'
                    direction={stack.direction}
                    //align='center'
                    justifyContent={stack.justifyContent === 'center' ? 'center' : undefined}
                    alignItems={stack.alignItems === 'center' ? 'center' : undefined}
                    spacing={0}
                    sx={containerSx}
                >
                    {props.children}
                </Stack>
            );
        }
    }

    //const isTabs = props.layout && props.layout.type === 'tabs'
    const LayoutElement = Box;

    return (
        <LayoutElement data-name='page_contents' sx={containerSx}>
            <InnerContainer {...props} />
        </LayoutElement>
    );
}

function InnerContainer(props: Props) {
    if (props.type === 'data') {
        return (
            <Box
                data-name='pg_layout_data'
                sx={[
                    //theme_.dialogBorder.sx(),
                    theme_.pageDataBorder.sx(),
                    {
                        width: 1,
                        height: 1,
                        backgroundColor: theme_.pageBackgroundColor,

                        // p: 1,
                        // px: 1,
                    },
                ]}
            >
                {props.children}
            </Box>
        );
    }

    return <>{props.children}</>;
}
