import {Dialog, DialogTitle, DialogContent, Divider, Paper} from '@mui/material';
import {Typography} from '@mui/material';
import {CircularProgress} from '@mui/material';
import {Box} from '@mui/material';
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';

import {useTranslation} from 'react-i18next';

import ScanViewContainer from './ScanViewContainer';

export class LoadProgressData {
    current: number;
    total: number;
    text: string;

    constructor(curr: number, tot: number, text: string = '') {
        this.current = curr;
        this.total = tot;
        this.text = text;
    }
}

interface LoadingProgressProps {
    data?: LoadProgressData | null;
}

export default function ScanLoadingProgress(props: LoadingProgressProps) {
    const [t] = useTranslation();

    //console.log("ScanViewLoadingProgress", props);
    if (!props.data) return <></>;
    // <ScanViewContainer>

    return (
            <Dialog
                open={true}
                onClose={() => {}}
                PaperComponent={Paper}
                fullWidth={true}
                maxWidth='sm'
                PaperProps={{
                    sx: {
                        ...theme_.dialogBorder.sx(),
                        transform: theme_.dialogMessageTransform,
                    },
                }}
            >
                <DialogTitle id='scan-alert-dialog-title'>
                    <CircularProgress
                        sx={{
                            verticalAlign: 'middle',
                        }}
                    />
                    <Typography
                        display='inline'
                        variant='inherit'
                        sx={{
                            marginLeft: 1,
                            verticalAlign: 'middle',
                        }}
                    >
                        {t('Loading') + '...'}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <DialogLinearProgressWithLabel
                        variant='determinate'
                        value={(props.data.current / props.data.total) * 100}
                    />
                </DialogContent>
            </Dialog>
    );
    //</ScanViewContainer>
}

function DialogLinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress
                    variant='determinate'
                    {...props}
                    sx={{
                        height: 28,
                        borderRadius: 2,
                    }}
                />
            </Box>

            <Box sx={{minWidth: 35}}>
                <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
