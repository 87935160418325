import React from "react";

import { useParams } from "react-router-dom";


import { ApiUser } from "api/users";
import * as Api from "api/api";

import PageContents from "components/PageContents";
import * as Form from 'tsui/Form';
import LoginPage from "pages/auth/LoginPage";



export default function UserEmailVerificationPage() {
    const form = Form.useInputForm();
    let [showForm, setshowForm] = React.useState(true);
    const [data, setData] = React.useState<ApiUser | null>(null);

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {
        console.log(evt.data)
        Api.apiPublic<any>('user', 'user_verify', evt.data).then(d => {
            setshowForm(false)
        }).catch(err => console.log(err));
    }, [])

    const params = useParams();
    console.log(params)
    const email = params.email;
    const verificationCode = params.verificationCode;
    console.log(email, verificationCode)

    return <>
        {/* <Form.DialogInputForm title='User' form={form} size='md' onSubmit={onSubmit} >
            <Form.InputText label='Email' id='email' validate="email" autocomplete='email' form={form} xs={7} />
            <Form.InputText label='Verification Code' id='verificationCode' validate="off" autocomplete='off' form={form} xs={7} />
        </Form.DialogInputForm> */}

        {showForm ? <PageContents title='User'>
            <Form.PageFormDialog title='User' form={form} size='md' onSubmit={onSubmit} >
                <Form.InputText label='Email' id='email' value={email} validate="email" autocomplete='email' form={form} xs={7} />
                <Form.InputText label='Verification Code' id='verificationCode' value={verificationCode} validate="off" autocomplete='off' form={form} xs={7} />
            </Form.PageFormDialog>
        </PageContents>
        :
        <LoginPage />}
    </>


}