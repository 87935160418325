import * as Geo from "tslib/geo";

export class ScreenCaps {
    pixelRatio = 1;
    screenSize = new Geo.Size();
    windowSize = new Geo.Size();

    detect() {
        let screen = window.screen;
        this.screenSize.set(screen.availWidth, screen.availHeight);
        this.pixelRatio = window.devicePixelRatio;
        this.windowSize.set(window.innerWidth, window.innerWidth);
    }

    debugDump() {
        console.info("Screen size:", this.screenSize.w, this.screenSize.h);
        console.info("Window devicePixelRatio: ", this.pixelRatio);
        console.info("Window size:", this.windowSize.w, this.windowSize.h);
    }

};
