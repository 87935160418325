import React from 'react'

import * as D from 'data'

import * as F from 'tsui/Form'
import {handleModalClose, ModalCloseProps} from 'tsui/Types/ModalCloseParams'
import ShowWrapper from 'tsui/ShowWrapper'

interface Props extends ModalCloseProps {
    macro: D.ReportMacro | null
}

export default function EditMacrosDialog(props: Props) {
    return <ShowWrapper<Props> impl={DialogImpl} props={props} show={props.macro} />
}

function DialogImpl(props: Props) {
    const macro = props.macro!

    const form = F.useForm({
        type: 'update',
        data: macro,
    })

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        handleModalClose(props)
        //props.onClose()
    }, [])

    return (
        <>
            <F.PageFormDialog title='Edit Macro' onSubmit={onSubmit} form={form} size='sm' {...props}>
                <F.InputText label='Macro Name' id='name' value={macro.name} required form={form} xsMax />
                <F.InputText
                    label='Macro Text'
                    id='content'
                    value={macro.content}
                    required
                    form={form}
                    xsMax
                    multiline={5}
                />
            </F.PageFormDialog>
        </>
    )
}
