import {BaseEditor, createEditor, Descendant, Editor} from 'slate'
import { ReportEditContext } from '../ReportTypes/ReportAction'
import { ReportEditProps } from '../ReportTypes/ReportEditProps'

export function getReportEditInitialValue(ctx: ReportEditContext): Descendant[] {
    return reportEditInitialValue
}

export const reportEditInitialValue: Descendant[] = [
    {
        type: 'paragraph',
        children: [{text: 'CLINICAL INFORMATION:', bold: true}],
    },

    {
        type: 'paragraph',
        children: [{text: ''}],
    },

    {
        type: 'paragraph',
        children: [
            {text: 'Bold Text', bold: true},
            {text: ' '},
            {text: 'Italic Text', italic: true},
            {text: ' '},
            {text: 'Bold Italic Text', bold: true, italic: true},
        ],
    },

    {
        type: 'paragraph',
        align: 'center',
        children: [{text: 'Centered Text'}],
    },

    
    // {
    //     type: 'paragraph',
    //     children: [
    //         { text: ' ' }
    //     ],
    // },
    // {
    //     type: 'paragraph',
    //     children: [
    //         { text: ' ' }
    //     ],
    // },
    {
        type: 'paragraph',
        children: [{text: 'FINAL DIAGNOSIS', bold: true}],
    },
    {
        type: 'paragraph',
        children: [{text: ''}],
    },

    {
        type: 'paragraph',
        children: [{text: 'MICROSCOPIC EXAM:', bold: true}],
    },
    {
        type: 'paragraph',
        children: [{text: ''}],
    },


    {
        type: 'paragraph',
        children: [{text: 'CONSULTATION SPECIMEN DESCRIPTION:', bold: true}],
    },

    {
        type: 'paragraph',
        children: [{text: ''}],
    },

]
