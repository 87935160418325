import React from 'react'

import {Box, Divider, Grid, Stack, Step, Typography} from '@mui/material'
import ImgElement from 'tsui/DomElements/ImgElement'
import {SxObject} from 'tsui/Mui/SxPropsUtil'
import { ReportEditContext } from 'pages/report/ReportTypes/ReportAction'

const fontSizes = new Map<string, number>([
    ['normal', 14],
    ['small', 12],
    ['x-small', 10],
])

function getElementType(el: any) {
    if (el.type) return el.type
    if (el.text) return 'text'
    if (el.field) return 'text'
    if (el.img) return 'img'
    return 'text'
}

// export function calcTemplateElementHeight(ctx: ReportEditContext, state: any, data: any, el: any, index: number) {
//     const rowHeight = ctx.page.lineHeight

// }

export function RenderTemplateElement(ctx: ReportEditContext, state: any, data: any, el: any, index: number, dryrun: boolean) {

    const page = ctx.page

    const type = getElementType(el)

    const style = el.style

    const rowHeight = ctx.page.lineHeight

    let sx: SxObject = {
        position: 'absolute',
        //position: 'relative',

        // left: style?.left,
        // right: style?.right,

        //height: el.height ? rowHeight * el.height : style?.height,
    }

    let nextRow = false

    if (el.row !== undefined) {
        if (el.row === 'next') {
            nextRow = true
        } else {
            state.row = el.row as number
        }
    }

    if (el.newRow) {
        nextRow = true
    }

    if (el.row === undefined && state.col + state.widthCol >= 12) {
        nextRow = true
    }

    if (nextRow) {
        state.row++
    }

    let col: number | undefined = undefined

    if (el.col !== undefined) {
        col = el.col
    } else {
        if (nextRow) {
            col = 0
        } else {
            col = state.col + state.widthCol
        }
    }

    state.col = col

    sx.top = rowHeight * state.row
    sx.left = `${(state.col * 100) / 12}%`

    state.widthCol = el.width ?? (style?.width ? style?.width! : 12 - state.col)

    if (el.width !== undefined) {
        sx.width = `${(state.widthCol * 100) / 12}%`
    } else {
        //sx.width = `${setup.elementWidth * 100 / 12}%`
    }

    if (dryrun) return


    switch (type) {
        case 'text': {
            const sizeName = el.size ?? style?.fontSize ?? 'normal'
            sx.fontSize = fontSizes.get(sizeName)

            // if (sx.fontSize === undefined) {
            //     sx.fontSize = setup.fontSize;
            // }
            sx.textAlign = el.align ?? style?.align ?? style?.textAlign ?? 'left'

            sx.color = el.color ?? style?.color
            sx.backgroundColor = el.background ?? style?.background ?? style?.backgroundColor
            //sx.paddingRight = 20

            // sx.pl = 1
            //sx.mx = 1

            if (el.field) {
                let field = data.fields[el.field]

                if (field === undefined) {
                    return <Typography key={index} sx={sx}>{`Error: field not found ${el.field}`}</Typography>
                }

                if (sx.textAlign === 'left') sx.pl = 1
                else if (sx.textAlign === 'right') sx.pr = 1

                return (
                    <Typography key={index} sx={sx}>
                        {field}
                    </Typography>
                )
                // for (let field of data.fields) {

                //     if (field.id === el.field) {
                //         return <Typography key={index} sx={sx}>{field.text}</Typography>
                //     }
                // }
            }
            if (el.text) {
                return (
                    <Typography key={index} sx={sx}>
                        {el.text}
                    </Typography>
                )
                //return <Typography key={index} align={el.textAlign} sx={el.style}>{el.text}</Typography>
            }
            break
        }
        case 'box': {
            //let height = undefined

            if (typeof el.height === 'string') {
                const sizeName = el.height
                sx.height = fontSizes.get(sizeName)! * page.fontHeightK
            } else {
                if (el.height) {
                    sx.height = rowHeight * el.height
                } else {
                    sx.height = style?.height
                }
            }

            sx.backgroundColor = el.color ?? el.background ?? style?.background ?? style?.backgroundColor
            return <Box key={index} sx={sx} />
            //break;
        }

        case 'img': {
            //console.debug(sx)
            return <ImgElement key={index} src={el.src} sx={sx} />
            // return <img key={index} src={el.src} style={sx} alt="" />//?styleic a

            //break;
        }

        // case "divider": {
        //     for (let field of data.fields) {

        //         if (field.id === el.field) {
        //             return <Divider key={index} sx={el.style}>{field.text}</Divider>
        //         }
        //     }
        //     break;
        // }
        default:
            console.error(`Invalid template element type: ${el.type}`)
            break
    }

    return
}
