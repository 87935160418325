import { ApiTileInfo } from "../../ScanData/DataApi";
import { ScanDataSource } from "../../ScanDataManager/ScanDataSource";
import { ScanViewRenderer } from "../ScanViewRenderer";
import { SceneTile } from "../ScanScene/SceneTile";
import { SceneTileStatus } from "../ScanScene/SceneTileStatus";


export function ScanRender_acquireTileImageSource(this: ScanViewRenderer, tile: SceneTile): boolean
{
    if (tile.ds) {
        console.error("shouldn't happen");
    }
    // if (tile.ds)
    //     this.releaseTileImageSource(tile);

    // 
    // Search for the correct image source
    //
    
    // First check the tile from the database
    let scanCtx = this.scanCtx!;
    let scanInfo = scanCtx.scanInfo;
    let zoomDataIndex = -tile.scanLoc.zoom;

    let zoomData = scanInfo.zoomLevels.get(tile.scanLoc.zoom);

    if (!zoomData) {
        return this.respondBlankTile(tile);
    }

    let [tileCol, tileRow] = [tile.scanLoc.col, tile.scanLoc.row];
    let [numTilesX, numTilesY] = [zoomData.tilesCount[0], zoomData.tilesCount[1]];

    // Verify for the correctness of the data (shouldn't happen though)
    if (tileCol < 0 || tileRow < 0 || tileCol >= numTilesX || tileRow >= numTilesY) {
        return this.respondInvalidTile(tile);  // TODO: add another "empty" status
    }

    let tileInfo = scanCtx.getTileInfo(zoomDataIndex, tileRow, tileCol);
    //console.debug(tileCol, tileRow, tileInfo ? true : false);

    // Missing tiles are empty
    if (!tileInfo) {
        return this.respondBlankTile(tile);
    }

    // No need to download blank tiles either        
    if (this.skipEmptyTiles && tileInfo.isEmpty()) {
        return this.respondEmptyTile(tile);
    }


    let ds = this.imageDataManager!.acquireDataSource(tile);
    this.attachTileImageSource(tile, ds, tileInfo);
    return true;  // Needs fetching
}


// Attaches aquired data source
// Returns false if fetching is not needed. The data is ready
export function attachTileImageSource(this: ScanViewRenderer, tile: SceneTile, ds: ScanDataSource, tileInfo: ApiTileInfo): boolean
{
    ds.tileInfo = tileInfo;
    ds.tileRef = tile;
    ds.onFetch = ds => this.onTileFetch(ds);
    tile.ds = ds;

    tile.status = SceneTileStatus.Pending;


    // Check if this image source is reusable
    if (ds.isReady) {
        // Now check if the current data matches the tile
        // TODO: bring back
        if (ds.tileLoc.equ(tile.scanLoc)) {
            return false;

            // let result = this.imageDataManager!.processTileReadyData(ds);
            // if (result) {
            //     return false;
            // }
        }

        ds.setPending();
    }



    let api = this.scanCtx!.getServerApi();
    let url = api.tilesData!.url;

    ds.setupTile({
        loc: tile.scanLoc,
        dataOffset: tileInfo.dataOffset,
        dataSize: tileInfo.dataSize,
        hash: tileInfo.hash,
        url: url,
    });

    return true;
}


export function ScanRender_fetchTileImageSource(this: ScanViewRenderer, tile: SceneTile) 
{
    let ds = tile.ds;

    if (!ds)
        return;

    // Check if the scene already has pending tiles
    let scene = tile.texRef?.sceneRef;
    if (!scene) {
        //ds.setIdle();
        //imgSrc.setIdle();
        return;
    }

    if (ds.isReady) {
        let result = this.imageDataManager!.processTileReadyData(ds);
        if (result)
            return;
    }

    this.imageDataManager!.fetchTile(ds);
}

export function ScanRender_cancelTileImageFetch(this: ScanViewRenderer, tile: SceneTile)
{
    let ds = tile.ds;
    if (!ds)
        return false;

    if (!ds.isPending || !ds.isFetching) {
        return false;
    }

    return this.imageDataManager!.cancelFetch(ds);
}




//
// Routines for managing the data sources
//
export function respondEmptyTile(this: ScanViewRenderer, tile: SceneTile) {
    tile.status = SceneTileStatus.Empty;
    return false;
}


export function respondBlankTile(this: ScanViewRenderer, tile: SceneTile) {
    tile.status = SceneTileStatus.Blank;
    return false;
}


export function respondInvalidTile(this: ScanViewRenderer, tile: SceneTile) {
    tile.status = SceneTileStatus.Invalid;
    return false;
}


//
// Returns false if fetching isn't required
//





export function ScanRender_releaseTileImageSource(this: ScanViewRenderer, tile: SceneTile) 
{
    // If tile didn't have src do nothing
    tile.status = SceneTileStatus.Pending;
    
    if (!tile.ds)
        return;

    this.imageDataManager?.releaseDataSource(tile.ds);

    tile.ds = undefined;
}


