import React from 'react'

import {useParams} from 'react-router-dom'

import PageContents from 'components/PageContents'

import ReportEditorContainer from './ReportComponents/ReportEditorContainer'

//import ReportEditorContainer from './ReportComponents/ReportEditorContainer'


export default function ReportWritePage() {

    const params = useParams()
    const scanId = params.scanId

    return (
        <PageContents title='Report' current='scans' type='data'>
            <ReportEditorContainer action='write' scanId={scanId}/>
        </PageContents>
    )

    // return (
    //     <PageContents
    //         title='Report'
    //         current='scans'
    //         type='data'
    //         layout={{
    //             stack: {
    //                 direction: 'row',
    //             },
    //         }}
    //     >
    //         <ReportMacrosTab />
    //         <ReportEditTab />
    //     </PageContents>
    // )
}
