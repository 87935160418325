import React from 'react';

import {Box} from '@mui/material';

import * as GD from 'tools/GlobalDispatch';

import HtmlHeader from 'ui/HtmlHeader';

import AuthHeader from '../Header/AuthHeader';
import LangSelector from '../Header/LangSelector';
import PageLogo from '../Header/PageLogo';
import PageBanner from '../Header/PageBanner';
import PageNav from '../Header/PageNavBar';
import {PageProps} from './PageProps';

export default function PageHeader(props: PageProps) {
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

    React.useEffect(() => {
        const layoutListener = () => {
            forceUpdate();
        };

        GD.pubsub_.addListener(GD.PageLayoutChangeId, layoutListener);

        return () => {
            GD.pubsub_.removeListener(GD.PageLayoutChangeId, layoutListener);
        };
    }, []);

    return (
        <Box
            data-name='pghdr_box'
            sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,

                minWidth: theme_.pageMinWidth,
                height: theme_.pageHeaderHeight,
            }}
        >
            <HtmlHeader title={props.title} ttitle='Bug Path Finder' />
            <PageLogo />
            <PageBanner />
            <LangSelector type='button' />
            <AuthHeader />
            <PageNav current={props.current ?? 'home'} />
        </Box>
    );
}
