import React from 'react';

import * as D from 'data';
import * as AD from 'api/data';

import ApiDataProvider, {useApiData} from 'components/ApiDataProvider';

import ScanDetailsModal from '../ScanDetails/ScanDetailsModal';
import ScansTable from '../ScansTableComponents/ScansTable';

export type ScanSetCategory = 'public' | 'assigned' | 'shared' | 'account' | 'uploads' | '';

interface ScansPageContentsProps {
    category: ScanSetCategory;
}

export default function ScansPageContents(props: ScansPageContentsProps) {
    const dataState = useApiData<AD.ApiScan[], D.Scan[]>(
        {
            apiGroup: 'scans',
            //apiCommand: props.category === 'uploads' ? 'fetch_uploads' : 'fetch',
            apiCommand: 'fetch',
            apiParams: {category: props.category as string},
            onData: D.handleScansFetch,
        },
        [props.category]
    );

    const [detailsScan, setDetailsScan] = React.useState<D.Scan | null>(null);

    const handleUpdate = React.useCallback((cat) => {
        // TODO: update refresh
    }, []);

    // // DEBUG: debug
    // if (dataState.data) {
    //     for (let item of dataState.data) {
    //         if (item.scanId === '20210303_110141' && detailsScan === null) {
    //             setDetailsScan(item)
    //         }
    //     }
    // }

    //console.debug(dataState);

    return (
        <ApiDataProvider dataState={dataState}>
            <ScansTable scans={dataState.data} onDetails={setDetailsScan} category={props.category} />

            <ScanDetailsModal
                scan={detailsScan}
                onCloseDetails={setDetailsScan}
                onUpdate={handleUpdate}
                category={props.category}
            />
        </ApiDataProvider>
    );
}
