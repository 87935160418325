import React from 'react';

import PageImage from '../../tsui/PageImage';

export default function PageDecor() {
    return (
        <>
            {theme_.homePageHeaderTheme.backgroundDecor.map((item, index) => {
                if (item.show === false) return <React.Fragment key={index} />;

                return <PageImage key={index} src={item.src} sx={item.sx()} />;
            })}
        </>
    );
}
