import React from "react";
import * as Api from "api/api";

// import * as Form from "components/PageInputFormObsolete";
import { validateEmail } from "tslib/validate";

import * as Form from 'tsui/Form';
import { ApiPatient } from "api/data/patients";
import { InputLabel, Select } from "@mui/material";





interface AddPatientProps {
    onClose: () => void;
}


export default function AddUserPatientDialog(props: AddPatientProps) {
    const form = Form.useInputForm();
    const [data, setData] = React.useState<ApiPatient | null>(null);

    const buttonRef = React.useRef<HTMLButtonElement>();

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {
        Api.requestSession<any>('user', 'add_patient', evt.data).then(d => {
            console.log(`patient ${d.firstName} ${d.lastName} was added`)
            props.onClose();
        }).catch(err => console.log(err));
    }, []);

    return <Form.PageFormDialog title='User' form={form} size='md' onSubmit={onSubmit} onClose={props.onClose}>

        <Form.InputGroup label='Name' form={form} />
        <Form.InputText label='First Name' id='firstName' validate='off' autocomplete='given-name' form={form} xsThird />
        <Form.InputText label='Middle Name' id='middleName' validate='off' autocomplete='given-name' form={form} xsThird />
        <Form.InputText label='Last Name' id='lastName' validate='off' autocomplete='family-name' form={form} xsThird />
        <Form.InputText label='Date of Birth' id='dateOfBirth' validate='off' autocomplete='family-name' form={form} xsThird />
        <Form.SelectField label='Gender' id='gender' items={[{ id: "mail", label: "Mail" },{ id: "femail", label: "Femail" },{ id: "other", label: "Other" }]} value={"mail"} required form={form} xsMax />

        <Form.InputGroup label='account' form={form} />
        <Form.InputText label='Account Id' id='accountId' validate="off" autocomplete='off' form={form} xs={7} />

    </Form.PageFormDialog>

}

