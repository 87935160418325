import {WglRenderer} from '../webgl_renderer';

export function WglRenderer_init(this: WglRenderer, gl: WebGLRenderingContext) {
    //console.log("webglInit: ", st.initialized);

    if (this.initialized) return;

    this.gl = gl;
    //let gl = this.gl!;

    let vertexBuffer = gl.createBuffer();
    if (!vertexBuffer) throw new Error('WebGL::Cannot create vertex buffer');
    this.glVertexBuffer = vertexBuffer;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.glVertexBuffer);

    let indexBuffer = gl.createBuffer();
    if (!indexBuffer) throw new Error('WebGL::Cannot create index buffer.');
    this.glIndexBuffer = indexBuffer;
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.glIndexBuffer);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    this.initShaders();

    gl.clearColor(0, 0, 0, 0);

    // Set identity matrix for each shader
    for (let prog of this.shaderPrograms!) {
        gl.useProgram(prog.glProg!);
        this.setUniformMtx(prog.unProjMtx, this.mtxI);
        this.setUniformMtx(prog.unViewMtx, this.mtxI);
    }

    this.invalidateProjMtx();
    this.invalidateViewMtx();

    this.initialized = true;
}

export function WglRenderer_release(this: WglRenderer) {
    this.scene.release();

    // TODO: release WebGL objects
    this.backgroundShaderRef = undefined;
    this.tileShaderRef = undefined;
    this.blankShaderRef = undefined;
    this.busyShaderRef = undefined;

    this.shaderPrograms.forEach((prog) => this.deleteProgram(prog));
    this.shaderPrograms.length = 0;

    // Release all the WebGL resources
    this.glVertexBuffer = null;
    this.glIndexBuffer = null;

    // We are done
    this.gl = null;
    this.initialized = false;
}
