import React from 'react'

import {Box} from '@mui/material'

import * as UE from 'tsui/UiElements'

import {scanTheme_} from '../ScanTheme/ScanTheme'
import { ThemeInstance } from 'theme/ThemeInstance'

export interface IScanViewPanelState {
    visible?: boolean
    name?: string
    layout?: UE.UiLayoutParams
    showCaption?: boolean
    showToolbar?: boolean
}

export class ScanViewPanelState {
    visible: boolean
    name?: string
    layout: UE.UiLayout

    showCaption: boolean
    showToolbar: boolean

    constructor(ini?: IScanViewPanelState) {
        this.visible = ini?.visible === false ? false : true
        this.showCaption = ini?.showCaption === false ? false : true
        this.showToolbar = ini?.showToolbar === false ? false : true

        this.name = ini?.name

        this.layout = new UE.UiLayout(ini?.layout) // ini.layout
        if (!this.layout.position) {
            this.layout.position = 'absolute'
        }
    }

    setLayout(parm: UE.UiLayoutParams) {
        this.layout.left = parm.left
        this.layout.right = parm.right
        this.layout.top = parm.top
        this.layout.bottom = parm.bottom
        this.layout.width = parm.width
        this.layout.height = parm.height
    }

    getToolbarLayout() {
        return new UE.UiLayout({
            position: this.layout.position,
            left: scanTheme_.panelPadding,
            top: scanTheme_.panelPadding,
            right: scanTheme_.panelPadding,
            height: this.showToolbar ? scanTheme_.panelToolbarHeight : 0,
        })
    }

    getContentsLayout() {
        let top = scanTheme_.panelPadding
        if (this.showToolbar) top += scanTheme_.panelToolbarHeight

        return new UE.UiLayout({
            position: this.layout.position,
            left: scanTheme_.panelPadding,
            top: top,
            right: scanTheme_.panelPadding,
            bottom: scanTheme_.panelPadding,
        })
    }

    getTop = () => this.layout.getTop()

    getRight = () => this.layout.getRight()
    getLeft = () => this.layout.getLeft()
}

interface Props {
    panel: ScanViewPanelState
    children?: React.ReactNode
}

export default function ScanViewPanel(props: Props) {
    const panel = props.panel

    if (!panel.visible) {
        return <></>
    }

    return (
        <Box
            data-name={`Panel: ${panel.name}`}
            sx={[
                panel.layout.sx(),
                scanTheme_.panelBorder.sx(),
                scanTheme_.panelBackground.sx(),
                {},
            ]}
        >
            {props.children}
        </Box>
    )
}
