import React from 'react'

import {Button, Grid, Stack, TextField, Typography} from '@mui/material'


import * as Api from 'api'

import * as F from 'tsui/Form'

import {Scan} from 'data/Scan'
import ProgressIndicator from 'tsui/ProgressIndicator'
import ErrorMessage from 'tsui/ErrorMessage'
import {ApiPatient} from 'api/data/patients'
import Env from 'env'


interface ModalPatientTabProps {
    scan: Scan
}

export default function ScanPatientTab(props: ModalPatientTabProps) {
    const form = F.useForm({
        type: 'update-fields',
    })

    const mounted = React.useRef(false)
    const [dataRequested, setDataRequested] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

    const [patient, setPatientData] = React.useState<ApiPatient | null>(null)

    const scan = props.scan

    React.useEffect(() => {
        mounted.current = true
        if (!dataRequested) {
            setDataRequested(true)
            Api.requestSession<ApiPatient>('user', 'get_patient', {scanId: scan.scanId})
                .then((data) => {
                    if (mounted.current) {
                        setPatientData(data)
                    }
                })
                .catch((err) => setErrorMessage(err.message))
            return
        }
        return () => {
            mounted.current = false
        }
    }, [dataRequested])

    if (errorMessage) return <ErrorMessage message={errorMessage} />

    if (!patient) {
        return <ProgressIndicator title='Loading...' />
    }

    console.log(patient) //if patient doesn't exsist
    if (!Object.keys(patient).length) {
        return <Typography> patient info is unavailable</Typography>
    }

    return <F.PageForm form={form} formContainer='none'>
        <F.InputText id='patientId' label='Patient Id' value={patient.patientId} readonly form={form}/>
        <F.InputText id='firstName' label='First Name' value={patient.firstName} readonly form={form}/>
    </F.PageForm>

/*
    return (
        <>
            <Grid container spacing={2} sx={{width: 1}}>
                {Env.isMobile ? (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                id='firstName'
                                label='Name'
                                variant='outlined'
                                defaultValue={patient?.firstName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='lastName'
                                label='Surname'
                                variant='outlined'
                                defaultValue={patient?.lastName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='patientId'
                                label='Patient Id'
                                variant='outlined'
                                defaultValue={patient?.patientId}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='referanceId'
                                label='AI Result'
                                variant='outlined'
                                defaultValue={patient?.referanceId}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='dateOfBirth'
                                label='Date of Birth'
                                variant='outlined'
                                defaultValue={patient?.dateOfBirth}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='gender'
                                label='Gender'
                                variant='outlined'
                                defaultValue={patient?.gender}
                                disabled
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                id='firstName'
                                label='Name'
                                variant='outlined'
                                defaultValue={patient?.firstName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='lastName'
                                label='Surname'
                                variant='outlined'
                                defaultValue={patient?.lastName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='patientId'
                                label='Patient Id'
                                variant='outlined'
                                defaultValue={patient?.patientId}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='referanceId'
                                label='AI Result'
                                variant='outlined'
                                defaultValue={patient?.referanceId}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='dateOfBirth'
                                label='Date of Birth'
                                variant='outlined'
                                defaultValue={patient?.dateOfBirth}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='gender'
                                label='Gender'
                                variant='outlined'
                                defaultValue={patient?.gender}
                                disabled
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
    */
}

