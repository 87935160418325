import {Box} from '@mui/material'

import DebugPanel from 'components/DebugPanel'
import {scanTheme_} from '../ScanTheme/ScanTheme'

export * from 'components/DebugPanel'

export default function ScanViewDebugPanel() {
    return (
        <Box
            sx={[
                scanTheme_.debugPanelBorder.sx(),
                scanTheme_.debugPanelLayout.sx(),
                {
                    backgroundColor: scanTheme_.debugPanelBackgroundColor,
                    color: scanTheme_.debugPanelColor,
                },
            ]}
        >
            <DebugPanel />
        </Box>
    )
}
