
import * as F from 'tsui/Form'
import * as D from 'data'

import {Scan} from 'data/Scan'

import PageDialog from 'tsui/PageDialog'

import ShowWrapper from 'tsui/ShowWrapper'

interface NoteDetailsProps {
    scan: Scan
    note: D.ScanNote | null
    onCloseDetails: (d: null) => void
}

export default function ScanNoteDetailsModal(props: NoteDetailsProps) {
    return <ShowWrapper<NoteDetailsProps> impl={ScanNoteDetailsModalImpl} props={props} show={props.note} />
}

function ScanNoteDetailsModalImpl(props: NoteDetailsProps) {
    const form = F.useForm({
        type: 'display',
    })

    const note = props.note!

    return (
        <PageDialog
            type='confirm'
            size='sm'
            title={{
                name: 'Note of Scan',
                tvalue: props.scan.scanName,
            }}
            onCloseNull={props.onCloseDetails}
            form={form}
        >
            <F.InputText label='Note Id' value={note.noteId} form={form} xs={3} />
            <F.InputText label='User' value={note.userGreeting} form={form} xs={5} />
            <F.InputText label='Date' type='date' value={note.noteDate} form={form} xs={4} />

            <F.InputText label='Note' value={note.text} form={form} multiline xsMax />
        </PageDialog>
    )
}
