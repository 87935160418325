import {makeError} from "tslib/error";
import {validateEmail} from "tslib/validate";
import {InputFormContext} from "../FormContext";
import * as FT from "../FormTypes";
import {InputFormField} from "./FormFieldContext";

export function prepareUpdateData(formCtx: InputFormContext, ev: FT.InputFormEvent) {
    // for (let [id, field] of formCtx.registeredFields) {
    //     ev.fields[id] = field;
    // }
}

export function validateForm(formCtx: InputFormContext, ev: FT.InputFormEvent) {
    let errors: Error[] = [];
    let dataCount = 0;

    for (let [id, field] of formCtx.registeredFields) {
        ev.fields[id] = field;

        let res = validateFormField(field);

        if (res.error) {
            field.error = res.error; // makeError(err)
            errors.push(res.error);
            //if (!firstError) firstError = field.error
            continue;
        }

        //let value = res.value!
        const value = field.value;
        const origValue = field.origValue;

        //console.debug(`${field.id}: ${value}, ${origValue}`);

        let skipValue = false;

        // If it's an update form and nothing change, do not send the value
        if (formCtx.formType === "update" && !field.valueChanged) {
            skipValue = true;
        }

        if (!skipValue) {
            if (field.isData) {
                ev.data[id] = field.value;
                ++dataCount;
            } else {
                ev.notData[id] = field.value;
            }
        }
    }

    if (errors.length > 0) {
        formCtx.setError(errors[0]);
    }

    return dataCount;
}

interface FormValidateResult {
    value?: string;
    error?: Error;
}

const assertField = (condition: any, message: string, field?: string): FormValidateResult | undefined => {
    if (!condition) {
        let text = field ?? "";
        if (text.length !== 0) text += " ";
        text += message;
        return {
            error: makeError(text),
        };
        //throw new Error(text)
    }

    return undefined;
};

function validateFormField(field: InputFormField): FormValidateResult {
    let errres: FormValidateResult | undefined = undefined;

    let value = field.value;

    switch (field.fieldType) {
        case "text": {
            //value
            //field.inputElement = document.getElementById(field.id) as HTMLInputElement
            //value = field.inputElement.value.trim()
            //field.value = field.inputElement.value.trim();
            //let value = field.value ?? '';

            //console.debug(field.value.length);

            if (field.required) {
                errres = assertField(value.length !== 0, "is required", field.name);
                if (errres) return errres;
            }

            if (value.length !== 0) {
                switch (field.validate) {
                    case "email":
                        errres = assertField(validateEmail(value), "Invalid email format");
                        if (errres) return errres;
                        break;
                    case "off":
                    default:
                        break;
                }
            }

            break;
        }
        default:
            break;
    }

    return {
        value: value,
    };
}
