import { scanTheme_ } from '../../ScanTheme/ScanTheme';
import * as Geo from 'tslib/geo';
import { ScanViewRenderer } from '../ScanViewRenderer';


export function ScanRender_onWindowResize(this: ScanViewRenderer) {
    let canvas = this.canvas;
    if (!canvas)
        return;

    let windowSize = Geo.makeSize(window.innerWidth, window.innerHeight);
    let canvasSize = Geo.makeSize(canvas.clientWidth, canvas.clientHeight);
    //let canvasSize = Geo.makeSize(parent.clientWidth, parent.clientHeight);
    //let canvasSize = Geo.makeSize(window.innerWidth, window.innerHeight);


    // console.debug("Canvas: ", canvasSize);
    // console.debug("Window", {w: window.innerWidth, h: window.innerHeight});
    
    
    scanTheme_.onCanvasSize(canvasSize, windowSize);


    // 
    // First update canvas
    //
    canvas.width = canvasSize.w;
    canvas.height = canvasSize.h;

    this.surfaceSize.assign(canvasSize);
    //this.surfaceCenter.set(canvasSize.w/2, canvasSize.h/2);
    this.surfaceCenter.assign(scanTheme_.scanRenderCenter);
    //console.debug(this.surfaceCenter);

    //
    // Update the scenes' layouts
    //
    let currLoc = this.nav!.currLoc;
    for (let scene of this.scenes) {
        scene.updateLayout(currLoc);
    }


    // Signal the renderer about the change
    this.webgl!.onWindowResize(canvasSize, this.surfaceCenter);

    // Update everything
    this.updateWebGL();
}
