import * as i18n from 'i18n';

export enum ScanStatusCode {
    Positive = 'P',
    Negative = 'N',
    Suspicious = 'S',
    Invalid = 'I',
}

export class ScanStatusInfo {
    code!: ScanStatusCode;
    text!: string;
    cssColor!: string;

    constructor(code: ScanStatusCode | string | undefined) {
        this.setCode(code);
    }

    setCode(code: ScanStatusCode | string | undefined) {
        this.code = code ? (code as ScanStatusCode) : ScanStatusCode.Invalid;

        switch (this.code) {
            case ScanStatusCode.Positive:
                this.text = i18n.i18nFreq_.positive.t;
                this.cssColor = 'red';
                break;

            case ScanStatusCode.Negative:
                this.text = i18n.i18nFreq_.negative.t;
                this.cssColor = 'green';
                break;

            case ScanStatusCode.Suspicious:
                this.text = i18n.i18nFreq_.suspicious.t;
                this.cssColor = 'yellow';
                break;

            default:
                this.text = 'UNKNOWN';
                this.cssColor = 'orange';
                break;
        }
    }
}
