import React from 'react';

import HomeIcon from '@mui/icons-material/TripOrigin';
import PopupIcon from '@mui/icons-material/DisplaySettingsOutlined';

import {ScanViewStateInstance} from '../ScanViewStateProvider';

import {ZoomLevelValue4} from '../ScanViewContext';

import {scanTheme_} from '../ScanTheme/ScanTheme';

import ScanViewPanel from 'scan/ScanComponents/ScanViewPanel';

import NavPanelButton from './NavPanel/NavPanelButton';
import NavZoomButton from './NavPanel/NavZoomButton';

interface Props {
    onPopup: () => void;
}

export default function ScanViewNavPanel(props: Props) {
    const scanCtx = React.useContext(ScanViewStateInstance).state;

    return (
        <ScanViewPanel panel={scanTheme_.navPanel}>
            <NavPanelButton onClick={() => scanCtx.easeHome()} icon={HomeIcon} col={0} row={0} />
            <NavPanelButton onClick={props.onPopup} icon={PopupIcon} col={1} row={0} />

            <NavZoomButton
                label='4'
                zoomKey={ZoomLevelValue4}
                zoomTo={ZoomLevelValue4}
                minZoom={-6}
                maxZoom={ZoomLevelValue4}
                zoomColor='red'
                col={0}
                row={1}
            />

            <NavZoomButton
                label='10'
                zoomKey={-2}
                zoomTo={-2}
                minZoom={ZoomLevelValue4}
                maxZoom={-2}
                zoomColor='orange'
                col={1}
                row={1}
            />

            <NavZoomButton
                label='40'
                zoomKey={0}
                zoomTo={0}
                minZoom={-1}
                maxZoom={0}
                zoomColor='blue'
                col={0}
                row={2}
            />

            <NavZoomButton
                label='20'
                zoomKey={-1}
                zoomTo={-1}
                minZoom={-2}
                maxZoom={-1}
                zoomColor='green'
                col={1}
                row={2}
            />

            {/* {ScanTheme.showNavRotateButtons && (
                    <>
                        <S.RotateButton
                            col={0}
                            row={3}
                            dir={'45'}
                            onClick={() => scanCtx.easeToRotation(-Math.PI / 4)}
                        />
                        <S.RotateButton
                            col={1}
                            row={3}
                            dir={'up'}
                            onClick={() => scanCtx.easeTo({rot: 0})}
                        />
                        <S.RotateButton
                            col={2}
                            row={3}
                            dir={'-45'}
                            onClick={() => scanCtx.easeToRotation(Math.PI / 4)}
                        />
                    </>
                )} */}
        </ScanViewPanel>
    );
}
