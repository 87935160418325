import * as Geo from 'tslib/geo';
import {ScanLocation} from '../../../ScanTypes/ScanLocation';
import {WglRenderer} from '../webgl_renderer';

export function WglRenderer_setZoomLevel(this: WglRenderer, zoom: number) {
    this.navZoomLevel = zoom;
    this.zoomMulToScan = 2 ** -zoom;
    this.zoomMulToView = 1 / this.zoomMulToScan;

    this.zoomedScanSize.assign(this.scanSize).scale(this.zoomMulToView);
    this.zoomedScanCenter.assign(this.scanCenter).scale(this.zoomMulToView);

    // For quicker calculations
    // The position is always at the center of the screen
    // If the scan position is (0, 0), the offset is
    //   ((screen-size - zoomed-scan-size ) / 2) * zoomScale
    // If the scan position is (scan-size.w, scan-size.h) the offset is
    //   - scan-size.w + ((screen-size - zoomed-scan-size ) / 2) * zoomScale
    // this.mtxViewOffsetOffset.x = (this.surfaceSize.w - this.zoomedScanSize.w)/2 * this.zoomMulToScan;
    //this.mtxViewOffsetOffset.y = (this.surfaceSize.h - this.zoomedScanSize.h)/2 * this.zoomMulToScan;
    //this.mtxViewOffsetOffset.assign(this.zoomedScanCenter).scale(this.zoomMulToScan);
    this.mtxViewOffsetOffset.x = (this.surfaceCenter.x - this.zoomedScanCenter.x) * this.zoomMulToScan;
    //this.mtxViewOffsetOffset.y = (this.surfaceCenter.y - this.zoomedScanCenter.y) * this.zoomMulToScan;
    this.mtxViewOffsetOffset.y =
        (this.surfaceSize.h - this.surfaceCenter.y - this.zoomedScanCenter.y) * this.zoomMulToScan;

    //console.debug(this.mtxViewOffsetOffset);

    //     x = (this.surfaceSize.w - this.zoomedScanSize.w)/2 * this.zoomMulToScan;
    // this.mtxViewOffsetOffset.y = (this.surfaceSize.h - this.zoomedScanSize.h)/2 * this.zoomMulToScan;

    this.navZoomPending = false;
}

// pos - view position in zoomed coordinates
// rot - center of rotation (often same as pos)
// sin, cos - rotation sine and cosines
export function WglRenderer_setScanLocation(this: WglRenderer, loc: ScanLocation) {
    //console.debug("Nav params: ", parm);

    //console.debug(parm.pos, this.navRotAxis);

    this.navRotAngle.assign(loc.rot);
    this.navRotAngleInv.assignInv(loc.rot);

    this.navRotAxis.assign(loc.pos);
    this.navPos.assign(loc.pos);

    if (this.navZoomLevel !== loc.zoom || this.navZoomPending) {
        this.setZoomLevel(loc.zoom);
    }

    // The rotation offset needs to be subtracted from the center of the scan
    //this.mtxRotOffset.set(this.navRotAxis.x - this.scanSize.w, this.navRotAxis.y - this.scanSize.h);
    this.mtxRotOffset.set(-this.navRotAxis.x, -this.navRotAxis.y);

    // After we rotated the object around given axis we move back
    this.mtxViewOffset.assign(this.navRotAxis); //x = this.mtxViewOffset.y = 0;

    // bring back from rotation offset
    // This brings the scan image to the 0, 0 position
    //this.mtxViewOffset.x += -this.mtxRotOffset.x;
    //this.mtxViewOffset.y += -this.mtxRotOffset.y;

    // Calculate offset for scan position.
    this.mtxViewOffset.x += -(this.navPos.x - this.scanSize.w / 2) + this.mtxViewOffsetOffset.x;
    this.mtxViewOffset.y += -(this.navPos.y - this.scanSize.h / 2) + this.mtxViewOffsetOffset.y;

    this.updateViewMatrices();
}

export function WglRenderer_updateViewMatrices(this: WglRenderer) {
    // TODO: remove identity making.
    this.mtxNavModl.eye();
    this.mtxNavModlInv.eye();

    // Rotation offset
    this.mtxNavModl.setTranslation(this.mtxRotOffset);
    this.mtxNavModlInv.setTranslation(this.mtxRotOffset);

    // Rotate the inverse model matrix rotation to compensate view matrix rotation
    this.mtxNavModlInv.setRotationAngleInv(this.navRotAngle);

    // View matrix is responsible for rotation and translation
    this.mtxNavView.setRotationAngle(this.navRotAngle);
    this.mtxNavView.setTranslation(this.mtxViewOffset);

    this.mtxNavViewInv.setRotationAngle(this.navRotAngleInv);
    this.mtxNavViewInv.setTranslationXY(-this.mtxViewOffset.x, -this.mtxViewOffset.y);

    // Now scale the view matrix
    this.mtxNavView.setScale(this.zoomMulToView);
    this.mtxNavViewInv.setScale(this.zoomMulToScan);

    this.invalidateViewMtx();
}

export function Wgl_cvtScanToScreenFromTo(this: WglRenderer, from: Geo.IPoint, to: Geo.IPoint) {
    // Mimick the shader code

    // Model matrix multiplication (only translation)
    let m = this.mtxNavModl.a;
    let x = from.x + m[12];
    let y = from.y + m[13];

    // View matrix multiplication
    m = this.mtxNavView.a;
    to.x = m[0] * x + m[4] * y + m[12];
    to.y = m[1] * x + m[5] * y + m[13];

    to.x *= this.zoomMulToView;
    to.y *= this.zoomMulToView;
}

export function Wgl_cvtScreenToScanFromTo(this: WglRenderer, from: Geo.IPoint, to: Geo.IPoint) {
    let m = this.mtxNavViewInv.a;
    let x = from.x + m[12] * this.zoomMulToView;
    let y = from.y + m[13] * this.zoomMulToView;

    // Inverse View matrix multiplication
    to.x = m[0] * x + m[4] * y;
    to.y = m[1] * x + m[5] * y;

    to.x *= this.zoomMulToScan;
    to.y *= this.zoomMulToScan;

    m = this.mtxNavModl.a;
    to.x -= m[12];
    to.y -= m[13];
}
