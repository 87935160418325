import React from 'react'
import * as Api from 'api/api'

import Env from 'env'

import * as PatientsApi from 'api/data/patients'

import PageContents from 'components/PageContents'

import {Box, Button, Grid, Typography} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'
import ProgressIndicator from 'tsui/ProgressIndicator'
import ErrorMessage from 'tsui/ErrorMessage'
//import PatientDetails from 'pages/admin/patients/PatientDetails'
import AddUserPatientDialog from './AddUserPatientDialog'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface PatientsPageProps {
    patients: PatientsApi.ApiPatient[]
    onDetails: (patientId: string) => void
}

function PatientsPageMobile(props: PatientsPageProps) {
    //let [patientDetailsId, setPatientDetailsId] = React.useState<string | null>(null)

    return (
        // <TableContainer component={Paper}>
        <Table sx={{width: '100%'}}>
            <TableHead>
                <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell align='right'>Middle Name</TableCell>
                    <TableCell align='right'>Last Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.patients.map((patient, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        onClick={() => props.onDetails(patient.patientId)}
                    >
                        <TableCell component='th' scope='row'>
                            {patient.firstName}
                        </TableCell>
                        <TableCell align='right'>{patient.middleName}</TableCell>
                        <TableCell align='right'>{patient.lastName}</TableCell>
                        {/* <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        // </TableContainer>
    )
}

export default function PatientsPage() {
    const mounted = React.useRef(false)
    let [dataRequested, setDataRequested] = React.useState(false)
    let [error, setError] = React.useState<Error | null>(null)
    let [patients, setPatients] = React.useState<PatientsApi.ApiPatient[]>()
    let [patientDetailsId, setPatientDetailsId] = React.useState<string | null>(null)

    let [showAddPatient, setShowAddPatient] = React.useState(false)

    React.useEffect(() => {
        mounted.current = true
        if (!dataRequested) {
            Api.requestSession<PatientsApi.ApiPatient[]>('user', 'fetch_patients', {})
                .then((patients) => {
                    if (mounted.current) {
                        setPatients(patients)
                    }
                })
                .catch(setError)
            setDataRequested(true)
            return
        }
        return () => {
            mounted.current = false
        }
    }, [dataRequested])

    if (error) {
        console.debug(error)
        return <ErrorMessage error={error} />
    }

    if (!patients) {
        return <ProgressIndicator title='Loading...' />
    }

    return (
        <PageContents title='Patients' current='patients' type='data'>
            <Grid container height='auto' alignItems='center' sx={{
                width: 1,
            }}>
                <Grid item xs={10} md={10}>
                    <Typography>Total Patients:&nbsp;{patients.length}</Typography>
                </Grid>
                {Env.isMobile ? (
                    <PatientsPageMobile patients={patients} onDetails={(patientId) => setPatientDetailsId(patientId)} />
                ) : (
                    <>
                        <Grid item md={2} alignItems='right'>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setShowAddPatient(true)
                                }}
                            >
                                Add Patient
                            </Button>
                        </Grid>

                        <Grid item height='100%' xs={12}>
                            <DataGrid
                                columns={[
                                    {
                                        field: 'patientId',
                                        headerName: 'Patient Id',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'accountId',
                                        headerName: 'Account Id',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'firstName',
                                        headerName: 'First Name',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'middleName',
                                        headerName: 'Middle Name',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'lastName',
                                        headerName: 'Last Name',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'dateOfBirth',
                                        headerName: 'Date of birth',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'gender',
                                        headerName: 'Gender',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: 'refId',
                                        headerName: 'Ref Id',
                                        headerAlign: 'center',
                                        width: 150,
                                    },
                                    {
                                        field: '',
                                        type: 'actions',
                                        headerName: 'Actions',
                                        width: 100,
                                        renderCell: (cell) => {
                                            return (
                                                <>
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => setPatientDetailsId(cell.id as string)}
                                                    >
                                                        Details
                                                    </Button>
                                                    {/* <Button onClick={() => setEditedUserIndex(cell.id as string)}>Edit</Button> */}
                                                </>
                                            )
                                        },
                                    }, // width: 600 },
                                ]}
                                rows={patients}
                                //pageSize={10}
                                autoHeight
                                //rowsPerPageOptions={[10]}
                                autoPageSize={true}
                                //disableSelectionOnClick
                                getRowId={(row) => row.patientId}
                                onRowDoubleClick={(parms) => {
                                    setPatientDetailsId(parms.row.patientId)
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            {/* {patientDetailsId && (
                <PatientDetails patientId={patientDetailsId} onClose={() => setPatientDetailsId(null)} />
            )} */}
            {showAddPatient && <AddUserPatientDialog onClose={() => setShowAddPatient(false)}></AddUserPatientDialog>}
        </PageContents>
    )
}
