import React from 'react'

import * as Geo from 'tslib/geo'

import {ScanViewContext} from '../../ScanViewContext'

//
// Thumbnail Panel
//

// TODO: move to more general library
type CanvasMouseEvent = React.MouseEvent<HTMLCanvasElement>
type CanvasRef = React.RefObject<HTMLCanvasElement>

// TODO: move to some "ThumbnailLib" module

function thumbnailToScan(scanCtx: ScanViewContext, th: Geo.IPoint): Geo.Point {
    const scanInfo = scanCtx.scanInfo
    const thr = scanCtx.gui!.thumbRect
    return Geo.makePoint((th.x * scanInfo.scanSize.w) / thr.w, (th.y * scanInfo.scanSize.h) / thr.h)
}

function scanToThumbnail(scanCtx: ScanViewContext, s: Geo.IPoint): Geo.Point {
    const scanInfo = scanCtx.scanInfo
    const thr = scanCtx.gui!.thumbRect
    return Geo.makePoint((s.x * thr.w) / scanInfo.scanSize.w, (s.y * thr.h) / scanInfo.scanSize.h)
}

export function updateLocationDisplay(
    scanCtx: ScanViewContext,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    s: Geo.IPoint
) {
    const canvasRect = canvas.getBoundingClientRect()

    const thr = scanCtx.gui!.thumbRect

    let canvasSize = Geo.makeSize(canvasRect.width, canvasRect.height)

    //const [canvasW, canvasH] = [canvasRect.width, canvasRect.height];
    let off = Geo.makePoint((canvasSize.w - thr.w) / 2, (canvasSize.h - thr.h) / 2)

    ctx.clearRect(0, 0, canvasSize.w, canvasSize.h)

    let th = scanToThumbnail(scanCtx, s)
    th.add(off)

    ctx.beginPath()
    ctx.moveTo(0, th.y)
    ctx.lineTo(canvasSize.w, th.y)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(th.x, 0)
    ctx.lineTo(th.x, canvasSize.h)
    ctx.stroke()
}

export function onCanvasClick(scanCtx: ScanViewContext, canvasRef: CanvasRef, event: CanvasMouseEvent) {
    const canvas = canvasRef.current!
    const canvasRect = canvas.getBoundingClientRect()
    const origin = Geo.makePoint(canvasRect.left, canvasRect.top)
    const thr = scanCtx.gui!.thumbRect

    const canvasSize = Geo.makeSize(canvasRect.width, canvasRect.height)
    const off = Geo.makePoint((canvasSize.w - thr.w) / 2, (canvasSize.h - thr.h) / 2)
    let th = Geo.makePoint(event.pageX - origin.x, event.pageY - origin.y)
    th.sub(off)

    const s = thumbnailToScan(scanCtx, th)

    //console.log("Click: %d, %d => %d, %d", thx, thy, sx, sy);

    scanCtx.easeTo({pos: s})
}
