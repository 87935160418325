import fscreen from 'fscreen';

export function isFullScreen(): boolean { 
    return fscreen.fullscreenElement != null; 
}

export function toggle(): boolean {
    enterFullScreen(!isFullScreen());
    return isFullScreen();
}

export function enabled() {
    return fscreen.fullscreenEnabled;
}

export function enterFullScreen(flag = true) { 
    if (!fscreen.fullscreenEnabled)
        return;

    if (flag) {
        let el = document.getElementById("root");
        return fscreen.requestFullscreen(el!);
    } else {
        exitFullScreen();
    }
}

export function exitFullScreen() {
    if (!isFullScreen())
        return;

    return fscreen.exitFullscreen();
}
