import {grey} from '@mui/material/colors';

import {ThemeBase} from 'tsui/ThemeBase';

import Env from 'env';

import * as Preferences from 'tools/LocalPreferences';
import * as Geo from 'tslib/geo';
import * as GD from 'tools/GlobalDispatch';

import * as Form from 'tsui/Form';
import {PageHeaderTheme} from './ThemeElements';

import * as UE from 'tsui/UiElements';

export type ThemeMode = 'dark' | 'light';

export class ThemeInstance extends ThemeBase {
    mode = Preferences.themeMode.get();

    isDark = this.mode === 'dark';

    // Popular colors
    whiteSmoke = '#f5f5f5';
    dimGray = '#696969';
    smokyBlack = '#100c08';
    cosmicLatte = '#fff8e7'; // Biege kinda color
    darkRed = '#aa0000';

    // IDE classical colors
    vsBackground = '#2d2d30'; // Visual Studio dark mode background color

    // MUI standard colors
    muiPrimaryMain = this.isDark ? '#90caf9' : '#29b6f6';
    muiPrimaryLight = this.isDark ? '#e3f2fd' : '#4fc3f7';
    muiPrimaryDark = this.isDark ? '#42a5f5' : '#42a5f5';

    muiInfoMain = this.isDark ? '#29b6f6' : '#ff0000';

    // Pathology related popular colors
    scanTissueColor = '#25afce';

    // Primary colors
    textPrimaryColor = '#000000';

    themeColor = '#ff0000';
    errorColor = '#ff0000';

    //scan!: ScanThemeInstance;

    //
    muiSpacing = 8;

    // Page Layout
    pageMinWidth = Env.isDesktop ? 768 : 320;
    pageMinHeight = Env.isDesktop ? 768 : 480;

    pageHeaderHeight = Env.isMobile ? 60 : 100; //120;

    pageHorzMargin = Env.isDesktop ? 1 : 0;
    pageTopMargin = Env.isDesktop ? 1 : 0;
    pageBottomMargin = Env.isDesktop ? 1 : 0;

    pageContentsHorzMargin = Env.isDesktop ? 2 : 0;
    pageContentsTopMargin = Env.isDesktop ? 2 : 0;
    pageContentsBottomMargin = Env.isDesktop ? 2 : 0;

    pageBackgroundColor = '#ffffff';
    pageTextColor = '#ff0000'; // VS Code dark theme color
    dialogTextColor = '#ff0000';

    // Page styling
    pageDataBorder = new UE.UiBorder({color: this.muiPrimaryMain, width: 1, radius: 2});

    // Toolbar
    //pageToolbarHeight = 40
    pageToolbarHeight = 30;

    //homeBackgroundImage = '/images/home_ai_dark/dark_home_background.png';
    homeBackgroundImage: string | undefined = undefined; // = '/images/home_ai_dark/dark_home_background.png';

    //
    // Button styling
    //
    authButtonSize = new Geo.Size(120, 32);
    authButtonGap = this.muiSpacing;
    authGreetingWidth = 180;
    //authButtonFontSize: CssType.Property.FontSize = '12px';
    authButtonFontSize = 14;

    navButtonSize = new Geo.Size(152, 36);
    navButtonGap = this.muiSpacing * 2;
    navButtonTextColor = '#000000';
    navButtonFontSize = 16;

    smallInputWidth = 80;

    buttonHover = new UE.UiHover({
        border: new UE.UiBorder({width: 1, color: '#25afce66'}),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    });

    //
    // Page header styling
    //
    homePageHeaderTheme = new PageHeaderTheme();

    //
    // Dialog and Form elements
    //
    dialogBorder = new UE.UiBorder({color: this.muiPrimaryMain, width: 1, radius: 4});

    dialogMessageTransform = 'translateY(-8vh)';

    dialogBackgroundColor!: string;
    dialogHeight = '90%';

    //
    // Forms
    //

    //
    // Input elements
    //
    inputTextBackgroundColor = '#ffffff';
    inputReadonlyBackgroundColor = '#eeeeee';

    //
    // Display grid
    //
    tableRowHeight = 52; // 60
    tableThumbnailWidth = 200;
    tableActionButtonWidth = this.tableRowHeight; //Env.isDesktop ? 90 : 80;
    tableActionButtonHeight = this.tableRowHeight;
    //tableOddRowColor = this.isDark ? '#28282e' : '#626262'
    //tableOddRowColor = this.isDark ? '#323236' : '#626262'
    //tableOddRowColor = this.isDark ? '#00000044' : '#626262'
    tableOddRowColor = '#00000022';
    //tableOddRowColor = 'text.primary'

    //
    // Scan thumbnail stuff
    //
    scanThumbnailBorder = new UE.UiBorder({width: 2, color: this.muiPrimaryMain});
    scanTableThumbnailBorder = new UE.UiBorder({
        left: true,
        right: true,
        width: 0,
        color: grey[600], //'primarythis.muiPrimaryDark.main'#757575', // grey 600 //this.muiPrimaryLight,
    });

    //
    // Drop zones
    //
    dropZoneBorder = new UE.UiBorder({width: 4, color: this.dimGray, style: 'dashed'});
    dropZoneOverBorder = new UE.UiBorder({width: 4, color: this.whiteSmoke, style: 'dashed'});

    // Initialization
    create() {
        this.onInit();
        //window.addEventListener('resize', this.processWindowResize)
        this.processWindowResize();
    }

    release() {
        //window.removeEventListener('resize', this.processWindowResize)
    }
    // constructor() {
    //     this.onInit()
    // }

    processWindowResize() {
        let changed = this.onWindowResize();

        if (changed) {
            GD.pubsub_.dispatch(GD.PageLayoutChangeId);
        }
    }

    onInit() {}
    onWindowResize() {
        return false;
    }

    getFormButtonSize(size?: Form.FormSizeType) {
        switch (size) {
            case 'sm':
                return 80;
            case 'md':
                return 180;
            case 'lg':
                return 300;
            case 'xl':
                return 400;
            default:
                return '100%';
        }
    }

    //
    // Debugging
    //
    debugBorder = new UE.UiBorder({width: 1});
    debugBackground = new UE.UiBackground({color: '#ffff0044'});
}
