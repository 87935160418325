import React from 'react'

import {useParams} from 'react-router-dom'


import {Box, Stack, Tabs, Tab} from '@mui/material'

import {ApiUser} from 'api/users'
import * as Api from 'api/api'

import PageContents from 'components/PageContents'
import * as Form from 'tsui/Form'

import ChangePasswordDialog from '../auth/ChangePasswordDialog'
import UserSecurityTab from './UserSecurityTab'
import UserProfileTab from './UserProfileMainTab'
import UserAccountTab from './UserAccountTab'
import UserOptionsTab from './UserOptionsTab'
import UserAboutAccountTab from './UserAboutAccountTab'

const startTab = 'profile'

interface Props {
    tab?: string
}

export default function UserProfilePage(props: Props) {
    const [selectedTab, setSelectedTab] = React.useState(props.tab ?? startTab)

    // const params = useParams()

    // console.debug(params)


    const onTabChange = React.useCallback((evt, value) => {
        setSelectedTab(value)
    }, [])

    return (
        <PageContents title='User' current='security' type='data'>
            {/* <Form.FormDataProvider<ApiUser> apiGroup="user" apiCommand="profile" onData={d => setData(d)} form={form}> */}

            {/* tabs */}
            <Stack direction='column' sx={{height: 1}}>
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    orientation='horizontal'
                    centered
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    {/* <Tab value='about' label="About" /> */}
                    <Tab value='profile' label='Profile' />
                    <Tab value='security' label='Security' />
                    <Tab value='account' label='Account' />
                    <Tab value='options' label='Options' />
                </Tabs>

                {/* {selectedTab === 'about' && <UserAboutAccountTab />} */}

                <Box
                    sx={{
                        p: 2,
                    }}
                >
                    {selectedTab === 'profile' && <UserProfileTab />}
                    {selectedTab === 'security' && <UserSecurityTab />}
                    {selectedTab === 'account' && <UserAccountTab />}
                    {selectedTab === 'options' && <UserOptionsTab />}
                </Box>

                {/* {selectedTab === 'transactions' && <TransactionsTap/>} */}

                {/* <ChangePasswordDialog show={showChangePassword} onClose={() => setShowChangePassword(false)}/> */}
                {/* {changePassword && <ChangePasswordDialog onClose={() => setChangePassword(false)}/> } */}
            </Stack>
        </PageContents>
    )
}



/*
export default function UserProfilePage() {
    const form = F.useUpdateForm();

    const [data, setData] = React.useState<ApiUser | null>(null);

    const [changePassword, setChangePassword] = React.useState(false);

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error)
            return;

        console.debug(evt.data);

        form.setBusy();
        Api.requestSession<ApiUser>('user', 'update', evt.data).then(_ => {
            form.setMessageUpdateSuccess();
            //form.reloadData();
        }).catch(err => form.setError(err));
    
    }, []);

    return <PageContents title='User'>
        <F.FormDataProvider<ApiUser> apiGroup="user" apiCommand="profile" onData={d => setData(d)} form={form}>

        <F.PageForm title='User' form={form} size='md' onSubmit={onSubmit} formContainer='none'>
            <F.Section label='Authentication' form={form} />
            <F.InputText label='Email' id='email' validate="email" autocomplete='email' readonly value={data?.email} form={form} xs={7} data={false}/>
            <F.FormButton label='Change Password' onClick={() => setChangePassword(true)} form={form} xs={5} align='right' />


            <F.Section label='Name' form={form} />
            <F.InputText label='Prefix' id='namePrefix' value={data?.namePrefix} autocomplete='given-name' form={form} xs={3}/>
            <F.InputText label='First Name' id='firstName' value={data?.firstName} autocomplete='given-name' form={form} xs={9}/>
            <F.InputText label='Middle Name' id='middleName' value={data?.middleName} autocomplete='additional-name' form={form} xs={9}/>
            <F.InputText label='Last Name' id='lastName' value={data?.lastName} autocomplete='family-name' form={form} xs={9}/>
            <F.InputText label='Suffix' id='nameSuffix' value={data?.nameSuffix} autocomplete='honorific-suffix' form={form} xs={3}/>

            <F.Section label='Contact' optional form={form} />
            <F.InputText label='Area Code' id='phoneAreaCode' autocomplete='tel' validate='tel' form={form} xsThird />
            <F.InputText label='Phone' id='phoneNumber' autocomplete='tel' validate='tel' form={form} xsThird2 />


            <F.SubmitButton label='Update' form={form} xsMax size='lg' align="center"/>

        </F.PageForm>

        {changePassword && <ChangePasswordDialog onClose={() => setChangePassword(false)}/> }

        </F.FormDataProvider>
    </PageContents>;
   
}

*/
