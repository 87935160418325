import React from 'react'

import {Grid, Tabs, Tab} from '@mui/material'

import {ApiUser} from 'api/users'
import * as Api from 'api/api'

import PageContents from 'components/PageContents'
import * as F from 'tsui/Form'

import ChangePasswordDialog from '../auth/ChangePasswordDialog'
import {FormSizeType} from 'tsui/Form'
import Env from 'env'

export default function UserSecurityTab() {
    const form = F.useUpdateForm()

    const [data, setData] = React.useState<ApiUser | null>(null)

    const [changePassword, setChangePassword] = React.useState(false)

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return

        console.debug(evt.data)

        form.setBusy()
        Api.requestSession<ApiUser>('user', 'update', evt.data)
            .then((_) => {
                form.setMessageUpdateSuccess()
                //form.reloadData();
            })
            .catch((err) => form.setError(err))
    }, [])

    let modalSize: FormSizeType = 'md'
    let mobileWidth
    if (Env.isMobile) {
        modalSize = 'sm'
        mobileWidth = 12
    }

    return (
        <F.FormDataProvider<ApiUser> apiGroup='user' apiCommand='profile' onData={(d) => setData(d)} form={form}>
            <F.PageForm title='User' form={form} size={modalSize} onSubmit={onSubmit} formContainer='none'>
                <F.Section label='Authentication' form={form} />

                <F.InputText
                    label='Email'
                    id='email'
                    validate='email'
                    autocomplete='email'
                    readonly
                    value={data?.email}
                    form={form}
                    xs={3}
                    data={false}
                />

                <F.FormButton
                    label='Change Password'
                    onClick={() => setChangePassword(true)}
                    form={form}
                    xs={3}
                    // align='right'
                />

                {/* <Form.SubmitButton label='Update' form={form} xsMax size='lg' align="center" /> */}
            </F.PageForm>
            {/* <ChangePasswordDialog show={showChangePassword} onClose={() => setShowChangePassword(false)}/> */}
            {changePassword && <ChangePasswordDialog onClose={() => setChangePassword(false)} />}
        </F.FormDataProvider>
    )
}
