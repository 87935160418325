import React from 'react'

import {Button, Link, Stack} from '@mui/material'
import {Divider} from '@mui/material'

import Env from 'env'

import * as Api from 'api'

import * as D from 'data'

import ShareScanDialog from '../ScanActions/ShareScanDialog'
import {makeScanViewTarget, makeScanViewUrl, Scan} from 'data/Scan'
import ScanThumbnail from 'components/ScanThumbnail'
import {DialogButton} from 'tsui/Buttons/DialogButton'
import AddScanNoteDialog from '../ScanActions/AddScanNoteDialog'
import {ScanDetailsCategory} from './ScanDetailsTabbed'

interface Props {
    scan: Scan
    onUpdate: (category: ScanDetailsCategory) => void
    onClose: () => void
}

const panelWidth = 200

export default function ScanDetailsPanel(props: Props) {
    //const [writeNote, setWriteNote] = React.useState(false)
    const [shareScan, setShareScan] = React.useState<Scan | null>(null)
    const [addNoteScan, setAddNoteScan] = React.useState<Scan | null>(null)

    const scan = props.scan

    return (
        <>
            <Stack
                direction='column'
                spacing={2}
                // sx={{
                //     width: panelWidth,
                // }}
            >
                <ScanThumbnail scan={scan} width={panelWidth} />

                <DialogButton
                    label='View Scan'
                    href={makeScanViewUrl({scan: props.scan, close: true})}
                    target={makeScanViewTarget()}
                />

                <DialogButton
                    label='Share Scan'
                    onClickParam={{
                        cb: setShareScan,
                        param: props.scan,
                    }}
                />

                <Divider />

                <ReportButton scan={props.scan} />

                {/* <Divider /> */}

                <DialogButton
                    label='Add Note'
                    onClickParam={{
                        cb: setAddNoteScan,
                        param: props.scan,
                    }}
                />

                {/* <Divider />

                <DialogButton label='Close' onClick={props.onClose} /> */}
            </Stack>

            <AddScanNoteDialog
                scan={addNoteScan}
                onCloseNull={setAddNoteScan}
                onUpdate={() => props.onUpdate('notes')}
            />

            <ShareScanDialog scan={shareScan} onCloseNull={setShareScan} />
        </>
    )
}

export function getReportPdf(scanId) {
    // return data
    return Api.requestSession<any>('report', 'get_last_report_pdf', {scanId: scanId})
}

function ReportButton(props: {scan: Scan}) {
    const scan = props.scan

    if (scan.reportId) {
        if (!scan.reportFinalized) {
            return <DialogButton label='Continue Report' {...D.getReportEditRef(scan.reportId)} />
        }

        return (
            <>
                <DialogButton label='View Report' {...D.getScanReportViewRef(scan)} />

                <DialogButton label='Ammend Report' {...D.getScanReportWriteRef(scan)} />
            </>
        )
    }

    return <DialogButton label='Write Report' {...D.getScanReportWriteRef(scan)} />
}

// function sendReportBeginReq(scanId) {
//     // console.log('navigated to report')
//     // navigate('/report', { replace: false });
//     console.log(scanId)

//     // sendUpdateReq(props)
//     if (scanId) {
//         Api.requestSession<any>('report', 'update_report', { scanId: scanId }).then(d => {

//             // Api.requestSession<any>('report', 'update_report', { scanId: props.scanId, finalized: 'true' }, reqBody).then(d => {
//             console.log(d.scanId)
//         }).catch(err => console.log(err));
//     }
//     return
// }

// function sendReportUpdateReq(scanId) {
//     console.log(scanId)
//     // sendUpdateReq(props)
//     if (scanId) {
//         Api.requestSession<any>('report', 'update_report', { scanId: scanId }).then(d => {
//             // Api.requestSession<any>('report', 'update_report', { scanId: props.scanId, finalized: 'true' }, reqBody).then(d => {
//             console.log(`modified`)
//             console.log(d)
//         }).catch(err => console.log(err));
//     }
//     return
// }
