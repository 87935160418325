import React from 'react'

import {useTranslation} from 'react-i18next'

import * as F from 'tsui/Form'
import * as Api from 'api'
import {Scan} from 'data/Scan'
import ShowWrapper from 'tsui/ShowWrapper'

interface ScanShareProps {
    scan: Scan | null
    onClose?: () => void
    onCloseFalse?: (b: boolean) => void
    onCloseNull?: (b: null) => void
}

export default function ShareScanDialog(props: ScanShareProps) {
    return (
        <ShowWrapper<ScanShareProps> impl={ShareScanDialogImpl} props={props} show={props.scan} />
    )
}

function ShareScanDialogImpl(props: ScanShareProps) {
    const form = F.useInputForm()

    const scan = props.scan!
    const scanName = scan.scanName ?? scan.scanId

    //const [data, setData] = React.useState()

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return

        // form.setBusy();

        // AuthApi.login(evt.data).then((sess) => {
        //     form.clearBusy();
        //     navigate(props.return ?? '/');
        // }).catch(err => {
        //     form.setError(err);
        // });
        //console.log(evt.data)

        Api.requestSession<Api.ApiSession>('user', 'share_scan', {
            scanId: props.scan!.scanId,
            userData: evt.data.userData,
        }).then((sess) => {
            // console.log(sess)

            props.onClose && props.onClose()
            props.onCloseFalse && props.onCloseFalse(false)
            props.onCloseNull && props.onCloseNull(null)
        })

        // alert('Not implemented. Contact support');
    }, [])

    return (
        <F.PageFormDialog
            ttitle={'Sharing Scan: ' + scanName}
            size='sm'
            form={form}
            onClose={props.onClose}
            onCloseFalse={props.onCloseFalse}
            onCloseNull={props.onCloseNull}
            onSubmit={onSubmit}
        >
            <F.FormNote text='Please enter recipient email address:' form={form} />
            <F.InputText label='Email' id='userData' form={form} xsMax />

            {/* <Form.FormNote text="Please enter recipient userId:" form={form}/>
        <Form.InputText label='UserId' id='userId' form={form} xsMax /> */}
        </F.PageFormDialog>
    )
}
