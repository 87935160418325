import PageContents from 'components/PageContents'
import React from 'react'
import * as F from 'tsui/Form'
import * as AuthApi from 'api/auth'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { InputFormContext } from 'tsui/Form/FormContext'
import Env from 'env'
import { useTranslation } from 'react-i18next';

interface LoginPageProps {
    return?: string
}

export default function LoginPage(props: LoginPageProps) {
    const form = F.useForm({ type: 'input' })
    const navigate = useNavigate()
    const [error, setError] = React.useState<Error | string | null>(null);

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return

        form.setBusy()
        let dataKeys = Object.keys(evt.data);
        let emptyFields = 0

        for (let key of dataKeys) {
            if (evt.data[key] === '') {
                emptyFields += 1
                break;
            }
            if (key === 'login') {
                evt.data[key] = evt.data[key].replace(/\s/g, '').toLowerCase()
            }
            evt.data[key] = evt.data[key].replace(/\s/g, '')
        }
        setError('')

        if (emptyFields > 0) {
            setError('You must fill all the fields')
            form.clearBusy();

        } else {
            AuthApi.login(evt.data)
                .then((sess) => {
                    form.clearBusy()
                    navigate(props.return ?? '/')
                })
                .catch((err) => form.setError(err))
        }
    }, [])

    return (
        <PageContents title='Login'>
            {Env.isMobile ? (
                <LoginFormBodyMobile form={form} onSubmit={onSubmit} error={error} />
            ) : (
                <LoginFormBody form={form} onSubmit={onSubmit} error={error} />
            )}
        </PageContents>
    )
}

interface LoginFormBodyProps {
    form: InputFormContext
    onSubmit: (evt: F.InputFormEvent) => void
    error: Error | string | null
}

function LoginFormBody(props: LoginFormBodyProps) {

    const [t] = useTranslation();

    return (
        <F.PageForm
            onSubmit={props.onSubmit}
            form={props.form}
            size='lg'
            layoutElement='none'
            formSx={{
                backgroundColor: '#00000f',
                border: 'none',
                borderRadius: 0,
            }}
        >
            <Stack
                direction='row'
                width='100%'
                spacing={2}
                alignItems='center'
                sx={
                    {
                        //backgroundColor: 'blue',
                        //backgroundImage: 'url(images/back-image-2.jpg)',
                        boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0 , 0.7)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left',
                        backgroundSize: '75% 100%'
                    }
                }
            >
                <Box
                    sx={{
                        //backgroundColor: 'red',
                        width: '55%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            width: '80%',
                            textAlign: 'center',
                            //color: 'black',
                            //backgroundColor: 'white',
                            marginLeft: '30%'
                        }}
                    >
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: 'x-large',
                            textAlign: 'center',
                            width: '100%',
                            //color: 'white',
                            ml: 1
                        }}
                    >
                        {t('Welcome to the "Bug Path Finder" medical web resource. Please Sign In.')}
                    </Typography>
                </Box>
                <Stack
                    direction='column'
                    spacing={1}
                    sx={{
                        width: '65%',
                        //backgroundColor: theme_.themeColor,
                        p: 1,
                    }}
                >
                    {/* <Typography>{t('Login')} */}
                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            mb: 2,
                        }}
                    >
                        {/* {t('Login')} */}
                    </Typography>

                    <F.InputText
                        label="Email"
                        id='login'
                        autocomplete='username'
                        validate='email'
                        required
                        form={props.form}
                        xsMax
                    />
                    <F.InputText
                        label="Password"
                        id='password'
                        type='password'
                        autocomplete='current-password'
                        required
                        validate='not-empty'
                        form={props.form}
                        xsMax
                    />

                    <F.InputCheckbox
                        id='rememberMe'
                        label="Remember Me"
                        value={false}
                        data={false}
                        form={props.form}
                        xsMax
                    />

                    <Stack direction='column' alignItems='center'>
                        <F.SubmitButton label='Login' form={props.form} xsMax />
                    </Stack>

                    <F.NavigateLink label="Register new account" href='/join' form={props.form} xsMax mt={2} />
                    <F.NavigateLink
                        prefix='forgot'
                        label='login or password?'
                        href='/forgot'
                        form={props.form}
                        xsMax
                        size='sm'
                        align='right'
                    />
                </Stack>
            </Stack>
        </F.PageForm>
    )
}

function LoginFormBodyMobile(props: LoginFormBodyProps) {

    return (
        <F.PageForm title="Login" onSubmit={props.onSubmit} form={props.form} size='sm'>

            <F.InputText
                label="Email"
                id='login'
                autocomplete='username'
                validate='email'
                required
                form={props.form}
                xsMax
            />
            <F.InputText
                label="Password"
                id='password'
                type='password'
                autocomplete='current-password'
                required
                validate='not-empty'
                form={props.form}
                xsMax
            />

            <F.SubmitButton label="Login" form={props.form} xsMax />

            <F.NavigateLink label="Register new account" href='/join' form={props.form} xsMax mt={2} />
            <F.NavigateLink
                prefix='forgot'
                label='login or password?'
                href='/forgot'
                form={props.form}
                xsMax
                size='sm'
                align='right'
            />
        </F.PageForm>
    )
}