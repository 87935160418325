import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
// import {TimePicker, LocalizationProvider} from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import i18n from 'i18next';

import { InputTextProps } from '../FormFields/FormInputText';
import { InputFormField } from './FormFieldContext';
import FormFieldEditContainer from './FormFieldEditContainer';
import { formatDate } from '../../UiUtil/DateFormat';

interface Props extends InputTextProps {
    field: InputFormField;
}

type InputColorType = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
type InputSize = 'medium' | 'small';

interface TextComponentState {
    inputType: string;
    readonly: boolean;

    textFieldProps: TextFieldProps;
}

function updateTextFieldState(props: Props): TextComponentState {
    let readonly = props.form.formType === 'display' || props.readonly === true || props.displayonly === true;

    let inputReadonly = readonly || props.form.formType === 'update-fields';

    let changed = readonly ? false : props.field.valueChanged; //props.field.value !== props.field.origValue

    let borderColor: InputColorType | undefined = undefined;
    //let textColor = 'text.primary'
    let textColor: string | undefined;

    if (inputReadonly) {
        borderColor = 'secondary';
        textColor = 'text.secondary'; // 'text.disabled'
    } else {
        if (changed) {
            borderColor = 'success';
        }
    }

    if (props.attention === true) {
        borderColor = 'error';
    }

    let htmlInputType = 'text';

    switch (props.type) {
        case 'checkbox':
            htmlInputType = 'checkbox';
            break;
        case 'password':
            htmlInputType = 'password';
            break;
        default:
            break;
    }

    let size: InputSize = props.size === 'sm' ? 'small' : 'medium';

    let tp: TextFieldProps = {
        id: props.id,
        name: props.id,
        label: props.field.tlabel,

        type: htmlInputType,
        variant: 'outlined',

        fullWidth: true,

        size: size, //cst.height, // TODO: figure out width and heights

        autoComplete: props.autocomplete ?? 'off',

        error: props.attention,

        sx: {
            '& label': {
                paddingLeft: theme_.textField.paddingLeft,
            },

            '& input': {
                //paddingLeft: 3,
            },

            '& fieldset': {
                paddingLeft: theme_.textField.paddingLeft,
                borderRadius: theme_.textField.borderRadius,
            },


        },

        inputProps: {
            readOnly: inputReadonly,
            maxLength: props.maxLength,

            sx: {
                '&:-webkit-autofill': {
                    //'-webkit-box-shadow': '0 0 0 100px #000 inset',
                    //'-webkit-box-shadow': `0 0 0 100px #222 inset`,
                    WebkitBoxShadow: `0 0 0 100px #222 inset`,
                    //'-webkit-text-fill-color': '#fff',
                    WebKitTextFillColor: '#eee',
                    //WebKitTextFillColor: 'red',
                    //backgroundColor: 'red',
                    borderRadius: theme_.textField.borderRadius,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'darkorange',
                },
            },
        },

        InputLabelProps: { shrink: true },

    };

    if (borderColor) tp.color = borderColor;

    if (props.multiline !== undefined) {
        //console.debug(props.multiline)
        const defaultRows = 5;

        switch (typeof props.multiline) {
            case 'number':
                if (props.multiline > 0) {
                    tp.multiline = true;
                    tp.minRows = props.multiline;
                }
                break;
            case 'boolean':
                if (props.multiline === true) {
                    tp.multiline = true;
                    tp.minRows = defaultRows;
                }
                break;
            case 'object':
                tp.multiline = true;
                tp.minRows = props.multiline.minRows ?? defaultRows;
                tp.maxRows = props.multiline.maxRows;
                break;
            default:
                break;
        }
    }

    if (readonly) {
        tp.sx!['& .MuiInputBase-input'] = {
            //color: theme_.muiInfoMain,
            //color: 'text.disabled',
            color: textColor, //'text.secondary',
        };

        //tp.inputProps!.readOnly = true
    } else {
        // if (inputReadonly) {
        //     //if (!tp.inputProps) tp.inputProps = {}
        //     tp.inputProps!.readOnly = true
        // }

        if (!inputReadonly) {
            if (props.autofocus === true) {
                tp.autoFocus = true;
            }
        }

        tp.placeholder = makePlaceholder(props);
        tp.focused = changed || props.autofocus;
    }

    return {
        inputType: htmlInputType,
        readonly: readonly,

        textFieldProps: tp,
    };
}

function makeParamValue(props: Props): string | undefined {
    if (props.value === undefined) return '';

    if (typeof props.value === 'string') return props.value;
    if (typeof props.value === 'boolean') return props.value ? 'Yes' : 'No';
    if (typeof props.value === 'function') return props.value(props.id);

    return formatDate(props.value, {
        type: props.type,
    });
}

function makePlaceholder(props: Props): string | undefined {
    if (props.placeholder === false) return undefined;

    if (props.tplaceholder !== undefined) return props.tplaceholder;

    if (props.placeholder === undefined) return undefined;

    if (props.placeholder === true) {
        let field = props.field;
        let label = field.tlabel;

        return i18n.t('Enter') + ' ' + label;
    }

    return i18n.t(props.placeholder as string) ?? undefined;
}

class State {
    //valueChangeIndicated = false
}

export default function InputTextElement(props: Props) {
    const [value, setValue] = React.useState(makeParamValue(props));
    let cst = updateTextFieldState(props);

    React.useEffect(() => {
        setValue(makeParamValue(props));
    }, [props.value]);

    const onValueChange = React.useCallback((evt) => {
        const v = evt.target.value;
        //const st = stateRef.current;
        const field = props.field;

        field.setValue(v);
        setValue(v);

        props.field.valueChanged = true;
        cst = updateTextFieldState(props);
    }, []);

    const onDateChange = React.useCallback((value) => {
        let v = value as string;
        const field = props.field;

        field.setValue(v);
        setValue(v);

        props.field.valueChanged = true;
        cst = updateTextFieldState(props);
    }, []);

    if (cst.readonly) {
        return <TextField {...cst.textFieldProps} value={value} />;
    }

    if (props.form.formType === 'update-fields') {
        return (
            <FormFieldEditContainer field={props.field} form={props.form}>
                <TextField {...cst.textFieldProps} value={value} />
            </FormFieldEditContainer>
        );
    }

    // if (props.type === 'time') {
    //     return (
    //         <LocalizationProvider dateAdapter={AdapterMoment}>
    //             <TimePicker
    //                 value={value}
    //                 onChange={onDateChange}
    //                 closeOnSelect={true}
    //                 renderInput={(params) => (
    //                     <TextField {...cst.textFieldProps} value={value} onChange={onValueChange} {...params} />
    //                 )}
    //             />
    //         </LocalizationProvider>
    //     );
    // }

    return <TextField {...cst.textFieldProps} value={value} onChange={onValueChange} />;
}
