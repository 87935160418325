import * as Geo from 'tslib/geo'

export class WglRenderDevice {
    // Device caps
    // pixelRatio = 1;
    // screenSize = new Geo.Size();
    // windowSize = new Geo.Size();

    maxTextureUnits = 0
    maxTextureSize = 0
    maxCombinedTextureUnits = 0

    // Extensions
    extensions: string[] | null | undefined = undefined

    detect(gl: WebGLRenderingContext) {
        this.extensions = gl.getSupportedExtensions()
        this.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)
        this.maxTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
        this.maxCombinedTextureUnits = gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)
    }

    debugDump() {
        // console.info("Screen size:", this.screenSize.w, this.screenSize.h);
        // console.info("Window.devicePixelRatio: ", this.pixelRatio);
        // console.info("Window size:", this.windowSize.w, this.windowSize.h);

        //console.group("Extentions");
        //console.info(this.devExtensions);
        //console.groupEnd();

        console.debug('Max Texture Size: ', this.maxTextureSize)
        console.debug('Max Texture Units: ', this.maxTextureUnits)
        console.debug('Max Combined Texture Units: ', this.maxCombinedTextureUnits)
    } // debug_
}
