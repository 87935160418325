import * as Err from 'tslib/error'
//import {Debug} from 'tslib/debug'

import * as Api from 'api'
import * as ApiData from './ScanData/DataApi'

import {ScanViewRenderer} from './ScanRenderer/ScanViewRenderer'
import {ScanInfo} from './ScanTypes/ScanInfo'
import {ScanViewPubSub} from './ScanLib/ScanViewPubSub'
import {ScanViewZoomLevel} from './ScanRenderer/ZoomState'
import {ScanViewDataState} from './ScanContext/ScanData'
import {ScanViewGuiState} from './ScanTypes/ScanViewStateGui'
import {AoiContainer} from './ScanTypes/ScanViewStateRecog'
import {ScanViewCtx_startDataFetch} from './ScanContext/ScanDataFetch'

import * as Methods from './ScanContext/'
import {IScanLocationParam} from './ScanTypes/ScanLocation'
import {ScanDataManager} from './ScanDataManager/ScanDataManager'

// TODO: Temporary, have better zoom level indexation
export const ZoomLevelValue4 = -3.32

export class ScanViewContext {
    // Status flags
    initialized = false
    fetched = false
    fetchStarted = false
    created = false
    started = false
    error = false

    // Authentication data
    accountId = '10001' // Temporary, implement later
    sessionId = '' // Temporary, implement later

    scanId!: string

    // Instantiation data

    // Zoom levels setup
    zoomLevels?: ScanViewZoomLevel[]
    minZoom = Infinity
    maxZoom = Infinity
    
    levelToZoom(level: number) {
        //console.debug(this.minZoom, this.maxZoom);
        let scale = 2 ** level
        //console.debug(level, scale, 1 / scale, 40 * scale);
        return 40 * scale
    }

    // Initialization phase

    // Data loading phase
    // Received scan data
    scanSummaryDataState?: ApiData.ApiScanSummary
    scanStateDataState = new ScanViewDataState<Api.ApiScanStateData>()
    scanInfoDataState = new ScanViewDataState<ApiData.ApiSpecimenData>()
    tileInfoDataState = new ScanViewDataState<ApiData.ApiTileInfoContainer>()
    aoiInfoDataState = new ScanViewDataState<ApiData.AoiDataContents>()

    getServerApi(): Api.ApiScanEndpoints {
        return this.scanStateDataState.data!.api!
    }

    getTileInfo(zoom: number, tileY: number, tileX: number): ApiData.ApiTileInfo | undefined {
        let tilesContainer = this.tileInfoDataState.data!.tilesZYX
        let tileItem = tilesContainer[zoom]?.[tileY]?.[tileX]
        if (!tileItem) return undefined
        return new ApiData.ApiTileInfo(tileItem)
    }

    // Runtime data
    scanInfo!: ScanInfo
    dataManager?: ScanDataManager

    // Dealing with recog data
    aoi?: AoiContainer
    getAoiByIndex(index: number) {
        return this.aoi!.getAoiByIndex(index)
    }

    gui?: ScanViewGuiState
    getGui() {
        return this.gui!
    }
    getNav() {
        return this.gui!.nav!
    }
    currLoc() {
        return this.gui!.nav!.getCurrLoc()
    }

    // Everything related to rendering the actual image
    render?: ScanViewRenderer
    getRender(): ScanViewRenderer {
        return this.render!
    }
    getWebGL() {
        return this.render!.webgl!
    }

    // Events pubsub
    pubsub = new ScanViewPubSub()

    // debugging
   // debug_ = new Debug({enabled: false, name: 'ScanViewContext'})

    clearStatusFlags() {
        // Reset all the flags
        this.initialized = false

        this.fetched = false
        this.fetchStarted = false

        this.error = false
        this.created = false
        this.started = false
    }

    // Initialization
    init = Methods.ScanViewCtx_init
    release = Methods.ScanViewCtx_release

    raiseError(err?: Error, onError?: Err.IErrorHandler) {
        this.clearStatusFlags()

        this.error = true
        this.fetched = true

        if (err && onError) onError(err)
    }

    create(onError: Err.IErrorHandler) {
        if (this.created || this.error) return

       // this.debug_.check('create()')

        try {
            this.render!.init()
            this.created = true
        } catch (e: any) {
            this.raiseError(e, onError)
        }
    }

    startInElement(element: HTMLElement) {
        this.render!.attachCanvas(element)
        this.start()
    }

    start = Methods.ScanViewCtx_start

    stop() {
        if (!this.started) return
        this.render!.stop()
        this.started = false
    }

    // Data fetching
    startDataFetch = ScanViewCtx_startDataFetch

    //
    // Navigation
    //
    moveTo(to: IScanLocationParam) {
        this.render!.moveTo(to)
    }
    easeTo(to: IScanLocationParam) {
        this.render!.easeTo(to)
    }
    easeToRotation(deg: number) {
        this.easeTo({rot: this.currLoc().rot.angle + deg})
    }
    easeToZoom(zoom: number) {
        this.easeTo({zoom: zoom})
    }
    easeHome() {
        let home = this.getNav().homePos
        this.easeTo({pos: home.pos, zoom: home.zoom, rot: home.rot.angle})
    }

    //
    // Showing pathogen markers
    //
    pathogenMarkersVisible = true
    togglePathogenMarkers() {
        return this.setPathogenMarkersVisible(!this.pathogenMarkersVisible)
    }

    setPathogenMarkersVisible(visible: boolean) {
        this.pathogenMarkersVisible = visible
        this.render!.setPathogenMarkersVisible(this.pathogenMarkersVisible)
        return this.pathogenMarkersVisible
    }

    //
    // Busy status handling.
    // TODO: implement a better way
    private busySources_ = new Set<ScanDataManager>()
    private busyRunning_ = false
    private handleBusyChanged() {
        let count = 0
        //console.debug(this.busySources_);
        for (let src of this.busySources_) {
            count += src.getBusyCount()
        }
        this.pubsub.emitBusyStatus(count)
        this.busyRunning_ = false
    }

    private onBusyChanged() {
        // let count = 0; // Compute total busy count
        // this.busySources_.forEach(src => count += src.getBusyCount());
        // this.pubsub.emitBusyStatus(count);

        if (!this.busyRunning_) {
            //console.debug("on busy changed");
            this.busyRunning_ = true
            //console.debug(this.busySources_);
            setTimeout(() => this.handleBusyChanged(), 0)
        }
    }

    addBusySource(src: ScanDataManager) {
        this.busySources_.add(src)
        src.onBusy = () => this.onBusyChanged()
        this.onBusyChanged()
    }

    removeBusySource(src: ScanDataManager | undefined) {
        if (src) {
            src.onBusy = undefined
            this.busySources_.delete(src)
            this.onBusyChanged()
        }
    }
}
