import React from 'react';

import {styled as muiStyled} from '@mui/material';

import {Box} from '@mui/material';
import {Popper} from '@mui/material';
import {Select, InputBase, FormControl, MenuList, MenuItem, SelectChangeEvent} from '@mui/material';

import * as i18n from 'i18n';
import * as GD from 'tools/GlobalDispatch';

import session_ from 'api/session';

interface Props {
    type: 'button' | 'popper';

    show?: boolean;
    anchorRef?: any;
    anchorId?: string;
}

export default function LangSelector(props: Props) {
    const [currentLang, setCurrentLang] = React.useState(session_.lang);
    const th = theme_.homePageHeaderTheme;
    const thLang = th.langSelector;

    React.useEffect(() => {
        const langListener = () => {
            setCurrentLang(session_.lang);
        };

        GD.pubsub_.addListener(GD.langListenerId, langListener);

        return () => GD.pubsub_.removeListener(GD.langListenerId, langListener);
    }, []);

    const handleChange = React.useCallback((event: SelectChangeEvent) => {
        let lang = event.target.value as string;
        session_.setLang(lang);
    }, []);

    if (!thLang.show) return <></>;


    if (props.type === 'popper') {
        let open = props.show === false ? false : true;
        let anchorEl = open ? document.getElementById(props.anchorId!) : null;

        return (
            <Popper open={open} anchorEl={anchorEl} transition>
                <MenuList>
                    {i18n.supportedLangs_.map((lang) => (
                        <MenuItem key={lang.lang} value={lang.lang}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popper>
        );
    }

    return (
        <Box sx={[thLang.sx(), {p: 0}]}>
            <FormControl
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    p: 0,
                }}
            >
                <Select
                    value={currentLang}
                    onChange={handleChange}
                    input={<SelectorInput />}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: 0,
                    }}
                >
                    {i18n.supportedLangs_.map((lang) => (
                        <MenuItem key={lang.lang} value={lang.lang} sx={{}}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

const SelectorInput = muiStyled(InputBase)(({theme}) => ({
    color: theme_.pageTextColor,
    '& .MuiInputBase-input': {
        //theme_.dropZoneBorder.sx()
        //border: '1px solid #ced4da',
        border: `1px solid ${theme_.dropZoneBorder.color}`,
        //border: '1px solid #ff0000',
        boxSizing: 'border-box',

        //pl: 1,
        paddingLeft: 8,
        // paddingTop: 0,
        // paddingBottom: 0,

        //height: '100%',
        //height: '32px',
    },

    //'& .MuiSelectIcon': {
    '& svg': {
        color: theme_.pageTextColor,
    },
}));
