import React from 'react'

import {styled} from '@mui/material'

import {Paper} from '@mui/material'

export const ReportSectionContents = (props) => <Paper elevation={3} sx={{
    position: 'relative',
    p: 1,
    //p: 0,
    height: 1,
}}>{props.children}</Paper>
