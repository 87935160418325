import React from 'react'

import {Box} from '@mui/material'

import Env from 'env'

const containerId_ = 'debug_PanelContainer'

export default function DebugPanel() {
    React.useEffect(() => {
        initContainerNode()

        return () => releaseContainerNode()
    }, [])

    return (
        <Box
            id={containerId_}
            sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                left: 0,
                top: 0,
                '& p': {
                    fontSize: Env.isMobile ? 'large' : 'small',
                },
            }}
        ></Box>
    )
}

export interface DebugPanelFieldOptions {
    label?: string
    showCounter?: boolean
}

export class DebugPanelField {
    elementId: string
    fieldId: string

    label?: string
    value?: string
    nodeText = '' // Current whole text

    node: HTMLParagraphElement | null = null

    counter = 0
    showCounter: boolean // = false;

    constructor(fieldId: string, elementId: string, options?: DebugPanelFieldOptions) {
        this.fieldId = fieldId
        this.elementId = elementId

        this.label = options?.label
        this.showCounter = options?.showCounter ?? false
    }

    setText(value: string | number) {
        setPanelFieldText(this, value)
    }
}

export function addField(fieldId: string, options?: DebugPanelFieldOptions): DebugPanelField {
    let field = fieldData_.get(fieldId)

    if (field) return field

    let elementId = `debug_element_${currentDebugPanelFieldIndex}`
    currentDebugPanelFieldIndex++

    field = new DebugPanelField(fieldId, elementId, options)

    fieldData_.set(fieldId, field)

    if (containerNode_) {
        createFieldNode(field)
    }

    return field
}

export function setPanelFieldText(fieldId: string | DebugPanelField, value: string | number) {
    if (!isNodesValid_) {
        updateFieldNodes()

        if (!isNodesValid_) {
            //console.debug('Should not happen');
            return
        }
    }

    let field: DebugPanelField
    if (fieldId instanceof DebugPanelField) {
        field = fieldId
    } else {
        let f = fieldData_.get(fieldId)
        if (!f) {
            console.debug(`Invalid field id: ${fieldId}`)
            return
        }
        field = f
    }

    if (field.label) {
        field.nodeText = field.label + ': '
        field.nodeText += value
    } else {
        field.nodeText += value
    }

    field.counter++
    if (field.showCounter) {
        field.nodeText += ' (' + field.counter + ')'
    }

    if (field.node) {
        field.node.innerText = field.nodeText
    }
}

var containerNode_: HTMLDivElement | null = null
var currentDebugPanelFieldIndex = 1
var isNodesValid_ = false
var fieldData_ = new Map<string, DebugPanelField>() // | null = null

function initContainerNode() {
    if (containerNode_) releaseContainerNode()

    containerNode_ = document.getElementById(containerId_) as HTMLDivElement

    if (!containerNode_) {
        console.error('Something went wrong')
        return
    }

    updateFieldNodes()
}

function releaseContainerNode() {
    releaseFieldNodes()

    // if (containerNode_) {
    //     while (containerNode_.firstChild) {
    //         containerNode_.removeChild(containerNode_.lastChild!);
    //     }

    containerNode_ = null
    //}
}

function createFieldNode(field: DebugPanelField) {
    field.node = document.createElement('p')!
    field.node.id = field.elementId

    containerNode_!.appendChild(field.node)
}

function updateFieldNodes() {
    if (!containerNode_) return

    //releaseContainerNode();

    //containerNode_ = document.getElementById(containerId_) as HTMLDivElement | null;
    //if (!containerNode_)
    //    return;

    for (let [key, field] of fieldData_) {
        field.node = document.getElementById(field.elementId) as HTMLParagraphElement | null

        if (!field.node) {
            createFieldNode(field)
        }

        if (field.nodeText && field.node) field.node.innerText = field.nodeText
    }

    isNodesValid_ = true
}

function releaseFieldNodes() {
    isNodesValid_ = false

    for (let [key, field] of fieldData_) {
        field.node = null
    }

    //releaseContainerNode();
}

// const Container = styled('div')({
//     position: 'absolute',
//     right: 0,
//     bottom: 0,
//     left: 0,
//     top: 0,

//     // borderColor: '#ff0000',
//     // borderWidth: 2,
//     // borderStyle: 'solid',
//     // backgroundColor: '#ffffff',
//     // color: '#000000',

//     "& p": {
//         //color: 'red',
//         fontSize: Env.isMobile ? 'large' : 'small',
//     }
// });
