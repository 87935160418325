import * as Geo from 'tslib/geo';

import * as Api from 'api/api';
import { ScanScene } from "./ScanScene";


export class ScanSceneThumb extends ScanScene {

    // Overrides
    override onBuild = ScanSceneThumb_onBuild;
};

//
// Build main thumbnail scene
//
function ScanSceneThumb_onBuild(this: ScanSceneThumb)
{
    let ren = this.renRef_!;
    let setup = ren.setup;
    let scanCtx = ren.scanCtx!;
    let scanInfo = scanCtx.scanInfo;

    //this.scanRect.set(0, 0, 0, 0).setSize(scanInfo.scanSize);

    this.scanCoverSize.assign(scanInfo.scanSize);

    //this.scanNavBounds.setRect({x: 0, y: 0, w: scanInfo.scanSize.w, h: scanInfo.scanSize.h});

    // Choose the texture size
    let thumb = scanInfo.thumbnailsDataSources[0];
    let currSize = Geo.makeSize(0, 0);
    for (let th of scanInfo.thumbnailsDataSources) {
        let [iw, ih] = th.imageSize!.tuple();
        if (iw > setup.texSize.w || ih > setup.texSize.h)
            continue;
        if (iw > currSize.w || ih > currSize.h) {
            thumb = th;
            currSize.set(iw, ih);
        }
    }



    // Make the main texture
    let tex = this.addTexture(ren.texThumbRef!);

    //let tex = this.texThumbnail!;
    tex.setSize(currSize);

    // Texture will have one tile
    let tile = tex.addTile();
    tile.screenVisible = true;
    tile.texRect.set(0, 0, currSize.w, currSize.h);
    tile.scanRect.set(0, 0, scanInfo.scanSize.w, scanInfo.scanSize.h);
    tile.texRectNorm.set(0, 0, 1, 1);


    // Setup the image data source
    let api = scanCtx.getServerApi();
    let url = Api.makeImageEndpoint(api.thumbnailsMask!, thumb.imageName!);

    let ds = ren.thumbnailDataSource!;
    ds.setupTexture({
        id: "imgThumb",
        hash: thumb.hash!,
        url: url,
    });

    ds.onFetch = ds => ren.onTileFetch(ds);


    tex.setTileImageSource(0, ds);

    this.buildScenePatMarkers();

    ren.fetchTileImageSource(tile);
}
