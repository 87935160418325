import session_ from 'api/session';
import Env from 'env';
import * as Geo from 'tslib/geo';

import * as UE from 'tsui/UiElements';

import {ThemeInstance} from './ThemeInstance';

export class DarkAiTheme extends ThemeInstance {
    pageLeftDecorWidth = 600;
    pageRightDecorWidth = 600;
    pageTopDecorAllowance = 60;

    titleTop = 10;
    titleLeft = Env.isMobile ? 2 : 120;
    titleWidth = 400;
    titleShortWidth = Env.isMobile ? 120 : 140;

    authTop = 0;
    authRight = 10;
    authGap = 0;

    override onInit() {
        this.mode = 'dark';
        this.textPrimaryColor = '#ffffff';

        this.themeColor = this.scanTissueColor;
        this.errorColor = '#ff0000';

        this.authTop = this.muiSpacing;
        this.authRight = this.muiSpacing;
        this.authGap = this.muiSpacing;

        this.pageBackgroundColor = this.vsBackground;
        //this.tableOddRowColor = '#28282e'
        // this.pageBackgroundColor = '#FFF8DC';  // Corn silk
        //pageTextColor = '#2d2d30';  // VS Code dark theme color
        //this.pageTextColor = '#1F3F3f';  // VS Code dark theme color
        this.pageTextColor = this.whiteSmoke; // VS Code dark theme color

        this.homeBackgroundImage = '/images/home_ai_dark/dark_home_background.png';

        this.navButtonTextColor = this.whiteSmoke;

        //this.dialogBorder.color = this.muiPrimaryMain

        this.dialogBackgroundColor = 'black';
        this.dialogTextColor = this.whiteSmoke;

        // this.dropZoneBorder.color = '#80808088'
        // this.dropZoneOverBorder.color = '#f0f0f0'

        // Initialize theme decors
        let imagesRoot = '/images/home_ai_dark/';
        let th = this.homePageHeaderTheme;

        th.bkTopLeftDecor.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                width: this.pageLeftDecorWidth,
                left: 0,
                top: 0,
            }),
            src: imagesRoot + 'dark_home_top_left_decor.png',
        });
        th.backgroundDecor.push(th.bkTopLeftDecor);

        th.bkTopRightDecor.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                width: this.pageRightDecorWidth,
                right: 0,
                top: 0,
            }),
            src: imagesRoot + 'dark_home_top_right_decor.png',
        });
        th.backgroundDecor.push(th.bkTopRightDecor);

        th.logo.init({
            show: false,
            layout: new UE.UiLayout({
                position: 'absolute',
                left: 0,
                top: 0,
                width: 64,
                height: 64,
            }),
            src: '/images/empty_lens_logo.svg',
        });

        th.titleBanner.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                left: this.titleLeft,
                top: this.titleTop,
                width: this.titleWidth,
            }),
            src: imagesRoot + 'dark_home_title.png',
        });

        th.titleBannerShort.init({
            show: false,
            layout: new UE.UiLayout({
                position: 'absolute',
                left: this.titleLeft,
                top: this.titleTop,
                width: this.titleShortWidth,
            }),
            src: imagesRoot + 'dark_home_title_short.png',
        });

        th.mainMenu.init({
            show: Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                right: 0,
                width: this.pageHeaderHeight,
                height: this.pageHeaderHeight,
            }),
        });

        th.loginButton.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                right: this.authRight,
                top: this.authTop,
                //width: this.authButtonSize.w,
                width: this.authGreetingWidth,
                height: this.authButtonSize.h,
            }),
            //fontSize: this.authButtonFontSize,
        });

        th.logoutButton.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                right: this.authRight,
                top: this.authTop,
                width: this.authButtonSize.w,
                height: this.authButtonSize.h,
            }),
        });

        th.joinButton.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                right: this.authRight + this.authGap + this.authButtonSize.w,
                top: this.authTop,
                width: this.authButtonSize.w,
                height: this.authButtonSize.h,
            }),
        });

        th.greetingButton.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                //right: th.joinButton.right,
                right: th.logoutButton.layout!.right,
                top: this.authTop,
                width: this.authGreetingWidth,
                height: this.authButtonSize.h,
            }),
        });
//true
        //const haveLang = true;
        const haveLang = false;

        const langSelectorSize = haveLang ? new Geo.Size(100, this.authButtonSize.h) : new Geo.Size(0, 0);

        th.langSelector.init({
            show: haveLang,
            layout: new UE.UiLayout({
                position: 'absolute',
                top: this.authTop,
                width: langSelectorSize.w,
                height: langSelectorSize.h,
            }),
        });

        //
        // Navigation Bar
        //
        th.navBar.init({
            show: !Env.isMobile,
            layout: new UE.UiLayout({
                position: 'absolute',
                height: this.navButtonSize.h,
            }),
        });
    }

    override onWindowResize(): boolean {
        return this.calcHeaderLayout();
    }

    private lastSessionStatus = false;

    private lastWindowMin = false;
    private lastWindowNarrow = false;
    private lastWindowWide = false;

    private isBanner = true;
    private isBannerShort = false;

    calcHeaderLayout() {
        //console.debug('calc header layout')

        let changed = false;

        let clientWidth = document.documentElement.clientWidth;
        let windowWidth = Math.max(clientWidth, this.pageMinWidth);

        let isWidthMin = clientWidth <= windowWidth;

        if (this.lastWindowMin !== isWidthMin) {
            this.lastWindowMin = isWidthMin;
            changed = true;
        }

        if (session_.isLoggedIn !== this.lastSessionStatus) {
            this.lastSessionStatus = session_.isLoggedIn;
            changed = true;
        }

        let th = this.homePageHeaderTheme;

        let langLayout = th.langSelector.layout!;

        //
        // User authentication section
        //
        if (!Env.isMobile) {
            let authWidth = 0;

            if (!session_.isLoggedIn) {
                th.loginButton.show = true;
                //authWidth += th.loginButton.width!;
                authWidth += this.authGreetingWidth;

                th.joinButton.show = false;
                //authWidth += this.authGap + th.joinButton.width!;

                th.logoutButton.show = false;
                th.greetingButton.show = false;
            } else {
                th.logoutButton.show = false; // true;
                //authWidth += th.loginButton.width! + this.authGap;

                th.greetingButton.show = true;
                authWidth += this.authGreetingWidth;

                th.loginButton.show = false;
                th.joinButton.show = false;
            }

            //
            // Lang selector
            //
            langLayout.right = this.authRight + authWidth + this.authGap * 2;

        } else {  // If is mobile
            //
            // Lang selector
            //
            langLayout.top = 16;
            langLayout.right = 80;
        }

        //th.navBar.show = !Env.isMobile;

        //
        // Title Banner Layout
        //
        if (!Env.isMobile) {
            let bannerLayout = th.titleBanner.layout!;
            let shortBannerLayout = th.titleBannerShort.layout!;

            let langSelectorLeft = windowWidth - (langLayout.right! + langLayout.width!);

            let bannerRight = bannerLayout.getRight(); // left! + th.titleBanner.width!
            let shortBannerRight = shortBannerLayout.getRight();

            //console.debug(langSelectorLeft, bannerRight)
            let isBannerShort = this.isBannerShort;
            let isBanner = this.isBanner;

            if (langSelectorLeft < bannerRight) {
                isBannerShort = true;
                isBanner = langSelectorLeft >= shortBannerRight;
            } else {
                isBannerShort = false;
            }

            th.titleBanner.show = isBanner && !isBannerShort;
            th.titleBannerShort.show = isBanner && isBannerShort;

            if (isBanner !== this.isBanner || isBannerShort !== this.isBannerShort) {
                this.isBanner = isBanner;
                this.isBannerShort = isBannerShort;
                changed = true;
            }
        } else {  // If mobile
            th.titleBannerShort.show = true;
        }

        //
        // Navigation bar
        //
        if (!Env.isMobile) {
            let navLayout = th.navBar.layout!;

            let nButtons = 3; //session_.isLoggedIn ? 3 : 2;
            let navWidth = this.navButtonSize.w * nButtons + this.navButtonGap * (nButtons - 1);

            let navSpace = this.pageLeftDecorWidth + this.pageRightDecorWidth + this.pageTopDecorAllowance * 2;

            let availSpace = windowWidth - navSpace;

            navLayout.width = navWidth;

            // 3 states:
            // 1. nav bar is less than avail space
            // 2. nav bar smaller bit fits
            // 3. nav bar doesn't fit
            let isWide = availSpace > navWidth;

            if (isWide !== this.lastWindowWide) {
                this.lastWindowWide = isWide;
                changed = true;
            }

            let isNarrow = availSpace <= 0;
            if (isNarrow !== this.lastWindowNarrow) {
                this.lastWindowNarrow = isNarrow;
                changed = true;
            }

            navLayout.top = isWide ? 20 : this.pageHeaderHeight - this.navButtonSize.h - 6;

            if (isNarrow) {
                th.bkTopRightDecor.show = false;

                let minWidth = navWidth + this.pageLeftDecorWidth + this.pageTopDecorAllowance;
                let maxWidth = minWidth + this.pageRightDecorWidth + this.pageTopDecorAllowance;
                //let wrange

                navLayout.setAbsoluteCenterX(false);

                //navLayout.right = this.authRight
                //navLayout.right = `calc(100% - ${minWidth}px + ${this.authRight}px)`
                let posMin = this.authRight;
                let posMax = (this.pageMinWidth - navWidth) / 2;
                let rightRange = posMax - posMin;
                let winMax = navSpace;
                let winMin = this.pageMinWidth;
                let winRange = winMax - winMin;

                navLayout.right = this.authRight;

                //navLayout.left = `calc(100% - ${this.pageMinWidth}px - ${navWidth} - ${this.authRight}px)`
                //navLayout.right = `calc((100% - ${this.pageMinWidth}px) / 2 + ${this.authRight}px)`
                //navLayout.right = `calc(((100% - ${winMin}px) / ${winRange}) * ${rightRange} + ${rightMin}px)`
                //navLayout.right = `calc(${rightRange}px + ${rightMin}px)`
                //navLayout.right = `calc((100vw - ${navWidth}px)/2)`
                //navLayout.right = `calc((100vw - ${navWidth}px)/2)`
                //navLayout.right = `calc((100vw - ${winMin}px)/${winRange})`
                //navLayout.right = `calc((100% - ${winMin}px) / 2 +  ${winRange}px + ${rightMin}px)`

                //console.debug(navLayout.right)

                //console.debug(navLayout.right, minWidth + this.authRight, windowWidth - minWidth + this.authRight)
            } else {
                th.bkTopRightDecor.show = true;
                navLayout.setAbsoluteCenterX(true);
            }
        }

        // if (changed) {
        //     console.debug('calc header layout (changed)')
        // }

        return changed;
    }
}
