export const Components = 12
export const FloatBytes = 4
export const VertexSizeBytes = Components * FloatBytes

export const PositionOffset = 0
export const PositionComponents = 4
export const ColorOffset = 4
export const ColorComponents = 4
export const TextureOffset = 8
export const TextureComponents = 2
export const NormalOffset = 10
export const NormalComponents = 2

export interface IVertex {
    // Position
    x: number
    y: number
    z: number
    w: number // additional parameter

    // Color
    r: number
    g: number
    b: number
    a: number

    // Texture (0-1)
    u: number
    v: number

    // Normal first components or any additional parameter
    p: number
    q: number
}

// Parameter for setting a vertex
export interface VertexParam {
    // Position
    x: number
    y: number
    z?: number
    w?: number // additional parameter

    // Color
    r?: number
    g?: number
    b?: number
    a?: number

    // Texture (0-1)
    u?: number
    v?: number

    // Normal first components or any additional parameter
    p?: number
    q?: number
}

export class VertexContainer {
    //
    // Vertex data and buffers
    //
    changed = false
    numVtx = 0 // Number of vertices
    vtxData = new Float32Array(Components)

    numIdx = 0 // Number of indices
    idxData = new Uint16Array(1)

    restart() {
        this.numVtx = this.numIdx = 0
    }

    addVertex(v: VertexParam): number {
        let vtxBeginIndex = this.numVtx
        if ((this.numVtx + 1) * Components >= this.vtxData.length) {
            this.expandVertexBuffer()
        }
        this.set(this.numVtx++, v)
        return vtxBeginIndex
    }

    add(vertices: ArrayLike<VertexParam>, indices?: ArrayLike<number>): number {
        let vtxBeginIndex = this.numVtx

        if ((this.numVtx + vertices.length) * Components > this.vtxData.length) {
            this.expandVertexBuffer(this.numVtx + vertices.length)
        }
        for (let i = 0; i < vertices.length; ++i) {
            this.set(this.numVtx++, vertices[i])
        }
        indices && this.addIndices(vtxBeginIndex, indices)

        return vtxBeginIndex
    }

    set(index: number, v: VertexParam) {
        let i = index * Components

        this.vtxData[i++] = v.x
        this.vtxData[i++] = v.y
        this.vtxData[i++] = v.z ?? 0
        this.vtxData[i++] = v.w ?? 0

        this.vtxData[i++] = v.r ?? 0
        this.vtxData[i++] = v.g ?? 0
        this.vtxData[i++] = v.b ?? 0
        this.vtxData[i++] = v.a ?? 0

        this.vtxData[i++] = v.u ?? 0
        this.vtxData[i++] = v.v ?? 0
        this.vtxData[i++] = v.p ?? 0
        this.vtxData[i++] = v.q ?? 0

        this.changed = true
    }

    addIndex(base: number, val: number) {
        if (this.numIdx + 1 > this.idxData.length) {
            this.expandIndexBuffer()
        }
        this.idxData[this.numIdx++] = base + val
        this.changed = true
    }

    addIndices(base: number, vals: ArrayLike<number>) {
        let valsTotal = vals.length

        if (this.numIdx + valsTotal > this.idxData.length) {
            this.expandIndexBuffer(this.numIdx + valsTotal)
        }
        for (let i = 0; i < valsTotal; ++i) {
            this.idxData[this.numIdx++] = base + vals[i]
        }
        this.changed = true
    }

    private expandVertexBuffer(total = 0) {
        let currentSize = this.vtxData.length
        let totalSize = total * Components
        let newSize = totalSize > 0 ? totalSize : currentSize * 2
        let newBuffer = new Float32Array(newSize)
        newBuffer.set(this.vtxData)
        this.vtxData = newBuffer
    }

    private expandIndexBuffer(total = 0) {
        let count = this.idxData.length
        let newCount = total > 0 ? total : count * 2
        let newBuffer = new Uint16Array(newCount)
        newBuffer.set(this.idxData)
        this.idxData = newBuffer
    }
}
