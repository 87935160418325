import {parse as parseYaml} from 'yaml';

import Env from 'env';
import session_ from './session';

export class EndPoint {
    url: string;
    type?: string;

    constructor(_url: string) {
        this.url = _url;
    }
}

export type ApiParams = Record<string, string>;

export type ApiDataTypes = 'json' | 'text' | 'html' | 'yaml';
export type ApiAccess = 'admin' | 'user' | 'public';

export interface RequestOptions {
    group: string;
    command: string;

    access?: ApiAccess;
    method?: string;

    params?: ApiParams;

    contentType?: string;
    body?: any;
    bodyType?: ApiDataTypes;


    // Response handling
    resType?: ApiDataTypes; // response type
}

// export interface FetchParams {
//     limit?: number; // Maximum number of records to retrieve

//     page?: number;

//     orderBy?: string;
//     orderAscending?: boolean;
// }

// export class FetchStatus {
//     code = 0; // status code: 0 - pending, 1 - success, 2 > error code

//     data?: string; // status data: depends on the code
//     message?: string; // status message: usually error or progress

//     count?: number; // Number of recortds returned
//     limit?: number; // limit: 0 - no limit
//     pages?: number; // total pages: 0 - not paged
//     page?: number; // current page
// }

// export class FetchResponse<T> {
//     status?: FetchStatus;
//     data?: Array<T>;

//     getStatus(): FetchStatus {
//         return this.status ?? {code: 2};
//     }
//     getData<DataType>(): DataType {
//         return this.data! as unknown as DataType;
//     }
// }


export function makeEndPoint(...comps: string[]) {
    return comps.join('/');
}

export function makeImageEndpoint(ep: EndPoint, name: string) {
    return fmtNameEndpoint(ep.url, name);
}

//
// Various Formatting functions
//
export function fmtNameEndpoint(s: string, name: string) {
    // We'll use replaceAll once we figure polyfills with TypeScript
    //let url = ep.url.replaceAll("{:name}", name);
    return s.replace(new RegExp('{:name}', 'g'), name);
}

function makeError(res?: Response): Error {
    return new Error(res ? res.statusText : 'Invalid response');
}


function makeApiUrlToken(options: RequestOptions) {
    let url = makeEndPoint(Env.apiRoot, options.group, options.command);

    let first = true;

    if (Env.tokenUrl) {
        url += '?token=' + session_.token;
        first = false;
    }

    if (options.params) {
        let query = new URLSearchParams(options.params).toString();

        if (query.length > 0) {
            if (first) {
                url += '?';
                first = false;
            } else {
                url += '&';
            }

            url += query;
        }
    }
    return url;
}


function makeApiHeaders() {
    let headers = new Headers();
    return headers;
}


export function request<T>(options: RequestOptions): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const access = options.access ?? 'user';

        if (access !== 'public') {
            if (!session_.isLoggedIn) return reject(new Error('User not logged in'));
        }

        if (access === 'admin') {
            if (!session_.isAdmin) return reject(new Error('User does not have admin access'));
        }

        let url = makeApiUrlToken(options);

        //let url = makeApiUrlToken(group, command, session_.token, params)

        let headers = makeApiHeaders();

        const body = options.body;
        const method = options.method ?? body ? 'POST' : 'GET';

        //if (body) headers.append('Content-Type', 'application/json');

        if (options.contentType) {
            headers.append('Content-Type', options.contentType);
        }

        if (!Env.tokenUrl) {
            headers.append('Authorization', `Bearer ${session_.token}`);
        }


        let req = new Request(url, {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body) : undefined,
        });

        // if (body)
        // console.debug(req)

        fetch(req)
            .then((res) => {
                if (!res.ok) return reject(makeError(res));

                let rt = options.resType ?? 'json';

                if (rt === 'json') {
                    res.json()
                        .then((json) => {
                            if (json.error) {
                                //console.debug(json.error);

                                if (json.error.code && +json.error.code === 501) {
                                    session_.authenticate();
                                }
                                return reject(new Error(json.error.message ?? 'Unknown Error'));
                                // return reject(json.error.message ?? 'Unknown Error');
                            }
                            resolve(json as T);
                        })
                        .catch(reject);
                }

                if (rt === 'text') {
                    res.text()
                        .then((text) => resolve(text as unknown as T))
                        .catch(reject);
                }

                if (rt === 'yaml') {
                    res.text()
                        .then((text) => {
                            const yaml = parseYaml(text);
                            //console.debug(text)
                            //console.debug(yaml)
                            const obj = yaml as T;

                            //console.debug(obj.header)

                            resolve(obj);
                        })
                        .catch(reject);
                }
            })
            .catch(reject);
    });

    //return processFetchOpt({url: url, headers: headers}, op)
}

export function apiPublic<T>(group: string, command: string, params?: ApiParams): Promise<T> {

    return request<T>({
        group: group,
        command: command,
        params: params,
        access: 'public',
    });
}

export function requestSession<T = any>(group: string, command: string, params?: ApiParams, body?: any): Promise<T> {
    return request<T>({
        group: group,
        command: command,
        params: params,
        access: 'user',
        body: body,
    });
}

export function requestSessionImage<T>(group: string, command: string, params?: ApiParams, body?: any): Promise<T> {
    return request<T>({
        group: group,
        command: command,
        params: params,
        body: body,
        contentType: 'application/octet-stream',
    });

    /*
    return new Promise<T>((resolve, reject) => {
        if (!body) return reject();

        let url = makeApiUrlToken(group, command, session_.token, params);
        //console.log('url', url);
        // let headers = makeApiHeaders();
        let headers = new Headers();
        headers.append('Content-Type', 'application/octet-stream');
        //processFetch<T>(url, headers, body).then(resolve).catch(reject);

        if (!Env.tokenUrl) {
            headers.append('Authorization', `Bearer ${session_.token}`);
        }

        let req = new Request(url, {
            method: 'POST',
            headers: headers,
            body: body,
        });

        fetch(req)
            .then((res) => {
                if (!res.ok) {
                    return reject(makeError(res));
                }

                res.json()
                    .then((json) => {
                        if (json.error) {
                            //console.debug(json.error);

                            if (json.error.code && +json.error.code === 501) {
                                session_.authenticate();
                            }
                            return reject(new Error(json.error.message ?? 'Unknown Error'));
                            // return reject(json.error.message ?? 'Unknown Error');
                        }
                        resolve(json as T);
                    })
                    .catch(reject);
            })
            .catch(reject);
    });
    */
}

export function requestSessionText<T = any>(
    group: string,
    command: string,
    params?: ApiParams,
    body?: any
): Promise<T> {
    return request<T>({
        group: group,
        command: command,
        params: params,
        body: body,
        //contentType: 'application/octet-stream',
        resType: 'text',
    });

    // return new Promise<T>((resolve, reject) => {
    //     if (!session_.isLoggedIn) return reject(new Error('User not logged in'));

    //     // DEBUG: imitate error
    //     //if (command === 'fetch_notes') return reject(new Error('DEBUG: test error'))

    //     let url = makeApiUrlToken(group, command, session_.token, params);
    //     let headers = makeApiHeaders();
    //     if (body) headers.append('Content-Type', 'application/json');
    //     //if (body) console.debug(body)
    //     processFetch<T>({url: url, headers: headers, body: body, res: 'text'}).then(resolve).catch(reject);
    // });
}

export function requestAdmin<T>(group: string, command: string, params?: ApiParams): Promise<T> {

    return request<T>({
        group: group,
        command: command,
        params: params,
        access: 'admin',
    });


    // return new Promise<T>((resolve, reject) => {
    //     if (!session_.isAdmin) return reject(new Error('User is not administrator'));

    //     let url = makeApiUrlToken(group, command, session_.token, params);
    //     let headers = makeApiHeaders();
    //     processFetch<T>({url: url, headers: headers}).then(resolve).catch(reject);
    // });
}

export function requestBlob(ep: string) {
    let url = makeEndPoint(Env.apiRoot, ep);

    //console.log(url);  // TODO: debug

    let headers = new Headers();

    let req = new Request(url, {
        headers: headers,
    });

    // TODO: remove Blob
    return new Promise<Blob>((resolve, reject) => {
        fetch(req)
            .then((res) => {
                if (!res.ok) return reject(makeError(res));

                res.json()
                    .then((json) => resolve(json))
                    .catch(reject);
            })
            .catch(reject);
    });
}

export function s3UploadFile(url: string, body: any) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/octet-stream');

    let req = new Request(url, {
        method: 'PUT',
        headers: headers,
        body: body,
    });

    return fetch(req);
}
