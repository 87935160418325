import React from "react";
import * as Form from 'tsui/Form';


export interface UserProps {
    show?: boolean;
    onClose: () => void;
}


export default function ChangePasswordDialog(props: UserProps) {
    const form = Form.useInputForm();

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {
        if (form.error)
            return;

        props.onClose();
    }, []);



    return <Form.PageFormDialog title='Change Password' show={props.show} form={form} size='sm' onSubmit={onSubmit} onClose={props.onClose}>
        <Form.InputGroup label='Enter current password' form={form} />
        <Form.InputText label='Username' id='username' ngHidden autocomplete='username' data={false} form={form} xsMax />
        <Form.InputText label='Current Password' id='password' type='password' validate="not-empty" autocomplete='new-password' form={form} xsMax required data={false}/>

        <Form.InputGroup label='Type new password' form={form} />
        <Form.InputText label='New Password' id='newPassword' type='password' validate="not-empty" form={form} xsMax required />
        <Form.InputText label='Confirm Password' id='confirmPassword' type='password' validate="not-empty" form={form} xsMax required  data={false}/>

    </Form.PageFormDialog>;
}


