import React from 'react'

//import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';


import * as D from 'data'
import * as AD from 'api/data'

import * as Api from 'api'

import * as GD from 'tools/GlobalDispatch'
import * as RA from '../ReportTypes/ReportAction'


import PageDataTable, {PageDataTableColumn} from 'tsui/PageDataTable'
import { ReportMacrosAcc, ReportMacrosCategory, ReportMacrosMy } from '../ReportTypes/ReportMacros'
import { ReportEditProps } from '../ReportTypes/ReportEditProps'
import EditMacrosDialog from './ReportMacroEditDialog'

interface Props { // extends ReportEditProps {
    ctx: RA.ReportEditContext
    cat: string //ReportMacrosCategory
}

export default function ReportMacrosTable(props: Props) {
    const mounted = React.useRef(false)
    const [dataRequested, setDataRequested] = React.useState(false)
    const [data, setData] = React.useState<D.ReportMacro[] | null>(null)
    //const [data] = React.useState(props.cat === ReportMacrosMy ? testMacroMy : testMacroAcc)

    const [detailsMacro, setDetailsMacro] = React.useState<D.ReportMacro | null>(null)

    const [error, setError] = React.useState<Error | null>(null)

    React.useEffect(() => {
        //console.debug(props.cat)
        //switch (props.cat)
        if (props.cat === ReportMacrosMy) setData(testMacroMy)
        else if (props.cat === ReportMacrosAcc) setData(testMacroAcc)
        else setData(null)

        // setData(props.cat === ReportMacrosMy ? testMacroMy : testMacroAcc)
        // setData(props.cat === ReportMacrosMy ? testMacroMy : testMacroAcc)
    }, [props.cat])

    const handleInsert = React.useCallback((macro: D.ReportMacro) => {
        GD.pubsub_.dispatch(RA.editorInsertMacro, {macro: macro})
        //alert('inserting: ' + macro.content)
    }, [])



    // React.useEffect(() => {
    //     mounted.current = true

    //     //console.debug(props.scan.notes)

    //     if (!dataRequested) {
    //         setDataRequested(true)

    //         if (props.scan.notes === undefined) {
    //             //console.debug('fetching notes')

    //             Api.requestSession<AD.ApiNoteData[]>('user', 'fetch_notes', {
    //                 scanId: props.scan.scanId,
    //             })
    //                 .then((sc) => {
    //                     if (mounted.current) {
    //                         props.scan.notes = D.processApiNotes(sc)
    //                         setData(props.scan.notes)
    //                     }
    //                 })
    //                 .catch(setError)
    //             return
    //         }
    //     }

    //     return () => {
    //         mounted.current = false
    //     }
    // }, [dataRequested])

    return (
        <>
            <PageDataTable<D.ReportMacro>
                getRowId={(row) => row.macroId}
                columns={columns}
                rows={data}
                error={error}
                onDetails={setDetailsMacro}
                actions={[
                    {
                        type: 'details',
                        getDetailsHandler: setDetailsMacro,
                    },

                    {
                        //type: 'generic',
                        icon: ArrowRightAltOutlinedIcon,
                        getHandler: handleInsert,
                    },
                ]}
            />

            <EditMacrosDialog macro={detailsMacro} onCloseNull={setDetailsMacro} />
        </>
    )

}

const columns: PageDataTableColumn<D.ReportMacro>[] = [
    {
        field: 'name',
        label: 'Macro Name',
        flex: 2,
    },

    {
        field: 'content',
        label: 'Macro Text',
        flex: 5,
    },
]

const testMacroMy: D.ReportMacro[] = [
    {
        macroId: '1',
        name: 'Macro 1',
        content: 'Macro Contents',
        previewText: 'Macro Contents',
    },
    {
        macroId: '2',
        name: 'Macro 2',
        content: 'Macro Contents 2',
        previewText: 'Macro Contents 2',
    },
    {
        macroId: '3',
        name: 'Macro 3',
        content: 'Macro Contents 3',
        previewText: 'Macro Contents 3',
    },
]

const testMacroAcc: D.ReportMacro[] = [
    {
        macroId: 'a1',
        name: 'Acc Macro 1',
        content: 'Acc Macro Contents',
        previewText: 'Acc Macro Contents',
    },

    {
        macroId: 'a2',
        name: 'Acc Macro 2',
        content: 'Acc Macro Contents 2',
        previewText: 'Acc Macro Contents 2',
    },

]
