import React from 'react'

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {Button, TextField, Typography} from '@mui/material'
import {Box, Stack} from '@mui/material'
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress'

import {useTranslation} from 'react-i18next'

import * as Api from 'api/api'
import * as UP from 'api/scan_upload'

import DialogCaption from 'tsui/Form/DialogCaption'
import QuestionDialog from 'tsui/QuestionDialog'
import {assertObject} from 'tslib/assert'
import ShowWrapper from 'tsui/ShowWrapper'

// const uploadChunkSize = 6 * 1024 * 1024
// const debugImitateUpload = true

export interface ScanUploadProcessParams {
    stainTag: string
    stain?: string
    pathogen?: string
    files: File[]
}

interface Props {
    params: ScanUploadProcessParams | null
    onUploadEnd: (count: number) => void
}

export default function ScanUploadProcessModal(props: Props) {
    return (
        <ShowWrapper<Props> show={props.params} impl={ScanUploadProcessModalImpl} props={props} />
    )
}

function ScanUploadProcessModalImpl(props: Props) {
    const [t] = useTranslation()

    const params = props.params!

    const [progress, setProgress] = React.useState(0)
    const [uploadFinished, setUploadFinished] = React.useState(false)
    const [uploadError, setUploadError] = React.useState<Error | null>(null)

    const [currentFileIndex, setCurrentFileIndex] = React.useState(0)
    const [currentFile, setCurrentFile] = React.useState(params.files[0])

    const [cencelDalog, setCencelDalog] = React.useState(false)

    const uploadCtxRef = React.useRef<UP.ScanUploadContext | null>(null)

    // const [uploadCtx] = React.useState(new UP.ScanUploadContext({
    //     stainTag: props.params.stainTag,
    //     files: props.params.files,
    // }, handleUploadStatus))

    React.useEffect(() => {
        //uploadFiles(props.params.files)

        let uploadCtx = UP.scanUploadStart(
            {
                stainTag: params.stainTag,
                files: params.files,
                stain: params.stain,
                pathogen: params.pathogen,
            },
            handleUploadStatus
        )

        uploadCtxRef.current = uploadCtx

        return () => {
            uploadCtx.cleanup()
        }
    }, [])

    const handleUploadStatus = React.useCallback((status: UP.ScanUploadStatus) => {
        //console.debug(status)

        switch (status.reason) {
            case 'started':
                break

            case 'progress':
                setProgress(status.progressCurrent! * 100)
                break

            case 'error':
                setUploadError(status.error!) // TODO: properly display
                break

            case 'finished':
                setUploadFinished(true)
                break
        }
    }, [])

    const handleCencelQuestionDalog = React.useCallback((response: number) => {
        if (response === 0) {
            setCencelDalog(false)
            return
        }

        // TODO: cancel downloading

        handleUploadFinished(0)
    }, [])

    const handleUploadFinished = React.useCallback((count: number) => {
        props.onUploadEnd(count)
    }, [])

    const handleClose = React.useCallback(() => {
        // TODO: question

        // TODO: abortMultipartUpload
        console.log('cenceled')
        setCencelDalog(true)
        // props.onClose()
    }, [])

    // const uploadFiles = React.useCallback((files: File[]) => {
    //     // TODO: multiple files
    //     uploadFile(files[currentFileIndex])
    // }, [])

    if (uploadFinished) {
        return (
            <Dialog
                maxWidth='sm'
                fullWidth={true}
                onClose={handleClose}
                open={true}
                PaperProps={{
                    sx: {
                        pr: 4,
                        pl: 4,
                        pt: 2,
                        pb: 2,
                        // minHeight: '60vh',
                    },
                }}
            >
                <DialogContent sx={{textAlign: 'center'}}>
                    <Typography variant='h5' sx={{textAlign: 'center', pb: 5}}>
                        {' '}
                        {t('Upload finished')}{' '}
                    </Typography>
                    <Stack direction='column' spacing={5}>
                        <Stack direction='row' spacing={2}>
                            <TextField
                                label='File'
                                defaultValue={currentFile.name}
                                InputProps={{readOnly: true}}
                                variant='standard'
                            />
                            <TextField
                                label='Size'
                                defaultValue={currentFile.size}
                                InputProps={{readOnly: true}}
                                variant='standard'
                            />
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <TextField
                                label='Stain'
                                defaultValue={params.stain}
                                InputProps={{readOnly: true}}
                                variant='standard'
                            />
                            <TextField
                                label='Pathogen'
                                defaultValue={params.pathogen}
                                InputProps={{readOnly: true}}
                                variant='standard'
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        onClick={() => handleUploadFinished(currentFileIndex + 1)}
                    >
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    // get values from backend
    return (
        <Dialog
            maxWidth='sm'
            fullWidth={true}
            onClose={handleClose}
            open={true}
            PaperProps={{
                sx: {
                    minHeight: '30vh',
                },
            }}
        >
            <DialogCaption ttitle='Uploading...' onClose={handleClose} />

            <DialogContent dividers>
                <Stack direction='column' spacing={5}>
                    <Stack direction='row' spacing={2}>
                        <TextField
                            label='File'
                            defaultValue={currentFile.name}
                            InputProps={{readOnly: true}}
                            variant='standard'
                        />
                        <TextField
                            label='Size'
                            defaultValue={currentFile.size}
                            InputProps={{readOnly: true}}
                            variant='standard'
                        />
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <TextField
                            label='Stain'
                            defaultValue={params.stain}
                            InputProps={{readOnly: true}}
                            variant='standard'
                        />
                        <TextField
                            label='Pathogen'
                            defaultValue={params.pathogen}
                            InputProps={{readOnly: true}}
                            variant='standard'
                        />
                    </Stack>

                    <LinearProgressWithLabel value={progress} />
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' autoFocus onClick={handleClose}>
                    {t('Cancel Upload')}
                </Button>
            </DialogActions>

            {cencelDalog && (
                <QuestionDialog
                    title='Abort Upload'
                    message='Do you want to abort scan upload?'
                    onResponse={handleCencelQuestionDalog}
                />
            )}
        </Dialog>
    )
}

function LinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant='determinate' {...props} sx={{height: '25px'}} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant='body2' color='text.secondary'>{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

/*
interface ScanUploadRequest {
    file: File
    stain?: string
    pathogen?: string
}

interface MultipartUploadPart {
    partNumber: number
    presignedUrl: string
}

interface MultipartUploadData {
    scanId: string
    uploadId: string
    parts: MultipartUploadPart[]
}

interface MultipartS3PartState {
    ETag: string
    PartNumber: number
}

interface MultipartUploadState {
    currentPart: number
    s3Parts: MultipartS3PartState[]
}
*/

/*
const multipartUploadData = React.useRef<MultipartUploadData | null>(null)
const multipartUploadState = React.useRef<MultipartUploadState | null>(null)



    const uploadFile = React.useCallback((file: File) => {
        if (file.size <= uploadChunkSize) {
            simpleFileUpload(file)
        } else {
            multipartFileUpload(file)
        }
    }, [])

    const simpleFileUpload = React.useCallback((file: File) => {
        if (debugImitateUpload) {
            setProgress(30)
            return
        }

        Api.requestSession('scan', 'upload_request_simple', {
            filename: file.name,
            stain: props.params.stain ?? '',
            pathogen: props.params.pathogen ?? '',
        })
            .then((d) => {
                setProgress(30)

                //let file = props.files[0]
                const reader = new FileReader()

                console.log(d)
                let data = JSON.parse(d.body)

                //reader.read

                // console.log(data.presigned_url)

                //reader.onload = (e: ProgressEvent<FileReader>) => {
                reader.onloadend = (e: ProgressEvent<FileReader>) => {
                    setProgress(70)
                    if (e.target!.readyState === 2) {
                        let body = reader.result
                        Api.s3UploadFile(data.presigned_url, body)
                            .then((response) => {
                                setProgress(100)
                                setUploadFinished(true)
                            })
                            .catch((err) => {
                                alert(err) // TODO: proper error messaghe
                            })

                        // console.log(body)
                        //uploadScan(data.presigned_url, body)
                    }
                }

                reader.readAsArrayBuffer(file)
            })
            .catch((err) => alert(err)) // TODO: properly show error
    }, [])

    const multipartFileUpload = React.useCallback((file: File) => {
        if (debugImitateUpload) {
            setProgress(30)
            return
        }

        //let chunksCount = Math.floor((file.size + uploadChunkSize - 1)/ uploadChunkSize)
        let chunksCount = Math.ceil(file.size / uploadChunkSize)

        Api.requestSession<MultipartUploadData>('scan', 'upload_multipart_begin', {
            filename: file.name,
            stain: props.params.stain ?? '',
            pathogen: props.params.pathogen ?? '',
            chunksCount: chunksCount.toString(),
        })
            .then((d) => {
                multipartUploadData.current = d
                multipartUploadState.current = {currentPart: 0, s3Parts: []}
                multipartChunkUpload(0)
            })
            .catch((err) => alert(err)) // TODO: properly show error
    }, [])

    const multipartChunkUpload = React.useCallback((partIndex: number) => {
        let state = multipartUploadState.current!
        let data = multipartUploadData.current!

        if (partIndex >= data.parts.length) {
            Api.requestSession(
                'scan',
                'upload_multipart_complete',
                {
                    scanId: data.scanId,
                    uploadId: data.uploadId,
                },
                {
                    uploadParts: state.s3Parts,
                }
            )
                .then((result) => {
                    setProgress(100)
                    setUploadFinished(true)
                })
                .catch((err) => alert(err)) // TODO: properly show error

            return
        }

        let part = data.parts[partIndex]

        let file = props.params.files[currentFileIndex]
        let sliceBegin = partIndex * uploadChunkSize
        let sliceEnd = sliceBegin + uploadChunkSize
        let slicedFile = file.slice(sliceBegin, sliceEnd < file.size ? sliceEnd : undefined)

        const reader = new FileReader()

        reader.onloadend = (e: ProgressEvent<FileReader>) => {
            setProgress(((partIndex + 0.5) * 100) / data.parts.length)
            //setProgress(70)
            if (e.target!.readyState === 2) {
                let body = reader.result
                Api.s3UploadFile(part.presignedUrl, body)
                    .then((response) => {
                        let etag = response.headers.get('ETag')
                        etag = assertObject(etag, 'ETag is missing')!

                        state.s3Parts.push({
                            ETag: etag,
                            PartNumber: partIndex + 1,
                        })
                        setProgress(((partIndex + 1) * 100) / data.parts.length)
                        multipartChunkUpload(partIndex + 1)
                        //}).catch(err => alert(err))
                        //setProgress(100)
                        //setUploadFinished(true)
                    })
                    .catch((err) => {
                        alert(err) // TODO: proper error messaghe
                    })

                // console.log(body)
                //uploadScan(data.presigned_url, body)
            }
        }

        reader.readAsArrayBuffer(slicedFile)
    }, [])


*/
