import React from 'react';

import {GridValueGetterParams, GridRenderCellParams, GridRowId} from '@mui/x-data-grid';

import {Typography} from '@mui/material';


import * as D from 'data';

import {Scan} from 'data/Scan';


import ScanThumbnail from 'components/ScanThumbnail';
import {ScansTableProps} from './ScansTableProps';
import PageDataTable, {PageDataTableAction, PageDataTableColumn} from 'tsui/PageDataTable';

type GetRowId = (row: D.Scan) => GridRowId;

export default function ScansTableMobile(props: ScansTableProps) {
    const [columns, setColumns] = React.useState<PageDataTableColumn<Scan>[]>();
    const [actions, setActions] = React.useState<PageDataTableAction<Scan>[]>();
    const [getRowId, setGetRowId] = React.useState<GetRowId>();

    React.useEffect(() => {
        if (props.category === 'uploads') {
            setColumns(uploadsColumns);
            setActions([
                {
                    getDetailsHandler: props.onDetails,
                },
            ]);
            setGetRowId(() => (row: D.Scan) => row.uploadId);

        } else {
            setColumns(scanColumns);
            setActions([
                {
                    getDetailsHandler: props.onDetails,
                },

                {
                    getViewHandler: (scan: Scan) => ({
                        href: D.makeScanViewUrl({scan: scan, fs: false, close: true}),
                        target: D.scanViewTarget,
                    }),
                },
            ]);

            setGetRowId(() => (row: D.Scan) => row.scanId);
        }

        //setColumns(props.category === 'uploads' ? uploadsColumns : scanColumns);
    }, [props.category]);

    if (!columns || !getRowId) return <></>;

    return (
        <PageDataTable<Scan> getRowId={getRowId} columns={columns} rows={props.scans} actions={actions} columnHeaderHeight={0}/>
    );
}

const uploadsColumns: PageDataTableColumn<Scan>[] = [
    {
        field: 'fileName',
        //label: 'Name',
        minWidth: 150,
        flex: 1,
    },
];

const scanColumns: PageDataTableColumn<Scan>[] = [
    {
        field: 'scanName',
        label: 'Name',
        minWidth: 150,
        flex: 1,
    },
];


/*
import {Table, TableBody, TableRow, TableCell} from '@mui/material';
import {Link, Button, Typography, Container} from '@mui/material';

import {ScansTableProps} from './ScansTableProps';
import ImgElement from '../../../tsui/DomElements/ImgElement';

export default function ScansTableMobile(props: ScansTableProps) {
    if (!props.scans) {
        // TODO: loading
        return <></>;
    }

    return (
        <>
            <Table
                sx={{
                    padding: '1',
                    '& th': {
                        textAlign: 'center',
                        fontSize: 'large',
                    },
                }}
            >
                <TableBody>
                    {props.scans.map((scan, index) => (
                        // <TableRow key={index} sx={{padding: 1}}>
                        //     <InfoCell>
                        //         {scan.scanName}
                        //         <StatusCell color={scan.guiStatusInfo!.cssColor}>
                        //             {scan.guiStatusInfo!.text}
                        //         </StatusCell>
                        //         ;
                        //     </InfoCell>

                        //     <ThumbnailCell>
                        //         <ThumbnailImage src={scan.thumbnailUrl!} />
                        //         <br />
                        //         <ActionButton onClick={() => props.onDetails(scan)}>
                        //             Details
                        //         </ActionButton>
                        //     </ThumbnailCell>
                        // </TableRow>

                        <TableRow key={index} sx={{padding: 1}}>
                            <TableCell sx={{width: '50%'}}>
                                {scan.scanName}
                                <Typography color={scan.guiStatusInfo!.cssColor}>{scan.guiStatusInfo!.text}</Typography>
                            </TableCell>

                            <TableCell sx={{width: '50%'}}>
                                <ImgElement src={scan.thumbnailUrl!} sx={{width: '100%', maxHeight: 60}} />
                                <br />
                                <Button onClick={() => props.onDetails(scan)} sx={{margin: 6, width: '40%'}}>
                                    Details
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

*/

// const ActionButton = styled(Button)({
//     margin: 6,
//     width: '40%',
// })

// const ThumbnailImage = styled('img')({
//     maxHeight: 60,
//     maxWidth: '100%',
// })

// const InfoCell = styled(TableCell)({
//     width: '50%',
// })

// const ThumbnailCell = styled(TableCell)({
//     width: '50%',
// })

// const ScansTable = styled(Table)({
//     '& th': {
//         textAlign: 'center',
//         fontSize: 'large',
//     },
// })

// const StatusCell = styled(Typography)((props: { color: string }) => ({
//     color: props.color,
// }))
