import React from 'react';

import { Box, Dialog, DialogContent, LinearProgress, LinearProgressProps, Stack, TextField, Typography } from '@mui/material';

import * as Api from 'api';
import * as F from 'tsui/Form'

import ScanModalTabbed, { ScanDetailsCategory } from './ScanDetailsTabbed'

import { Scan } from 'data/Scan'

import PageDialog from 'tsui/PageDialog'
import ShowWrapper from 'tsui/ShowWrapper'
import { ScanSetCategory } from '../ScansPageComponents/ScansPageContents'
import ScanUploadDialog from '../../home/components/ScanUploadDialog'
import { DialogButton } from 'tsui/Buttons/DialogButton';
import DialogCaption from 'tsui/Form/DialogCaption';

interface ScanDetailsProps {
    scan: Scan | null
    onClose?: () => void
    onCloseDetails?: (scan: null) => void
    onUpdate: (category: ScanDetailsCategory) => void
    category: ScanSetCategory

}

export interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void

}

class ScanDetailsState {
    updated = false
}


class DropZoneState {
    dragging = false
    dragCounter = 0

    cleanup() {
        this.dragging = false
        this.dragCounter = 0
    }
}

const fileNotSelectedText = 'Scan image file is not selected.'
const dropZoneContentsText = 'Drop scan image file Here...'


export default function ScanDetailsModal(props: ScanDetailsProps) {
    return <ShowWrapper<ScanDetailsProps> show={props.scan} impl={ScanDetailsModalImpl} props={props} />
}

function ScanDetailsModalImpl(props: ScanDetailsProps) {

    const form = F.useForm({
        type: 'input',
        //layoutElement: 'none',
    })

    const [dataRequested, setDataRequested] = React.useState(true);
    const mounted = React.useRef(false);

    const [fileLength, setFileLength] = React.useState<number | null>(null)
    const [uploadIntervalTime, setUploadIntervalTime] = React.useState(0)
    const [timeVariant, setTimeVariant] = React.useState<string>('minute')
    const [fileOffset, setFileOffset] = React.useState<number | null>(null)
    const [fileChunkId, setFileChunkId] = React.useState<string | null>(null)

    const [progress, setProgress] = React.useState(0)
    const [linearProgress, setLinearProgess] = React.useState(false)

    const fileInputRef = React.useRef<HTMLInputElement>(null)

    const stateRef = React.useRef(new ScanDetailsState())

    const scan = props.scan!

    const [fileName, setFileName] = React.useState(fileNotSelectedText)
    const filesRef = React.useRef<File[]>([])

    const dropRef = React.useRef<HTMLDivElement>(null)
    const [dropZoneText, setDropZoneText] = React.useState<string>(dropZoneContentsText)

    const dropzoneState = React.useRef(new DropZoneState())
    const st_ = dropzoneState.current




    const handleClose = React.useCallback(() => {
        if (stateRef.current.updated) {
            props.onUpdate('scan') // TODO: proper

            stateRef.current.updated = false
        }

        props.onClose && props.onClose()
        props.onCloseDetails && props.onCloseDetails(null)
    }, [])

    const handleUpdate = React.useCallback((cat) => {
        stateRef.current.updated = true
    }, [])



    const onDragEnter = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
            st_.dragging = true
        }

        st_.dragCounter++

        if (st_.dragCounter > 1) return

        if (!dropRef.current) return

        theme_.dropZoneOverBorder.apply(dropRef)
    }, [])

    const onDragLeave = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        st_.dragCounter--

        if (st_.dragCounter < 0) st_.dragCounter = 0

        if (st_.dragCounter > 0) return

        st_.dragging = false

        theme_.dropZoneBorder.apply(dropRef)
    }, [])

    const onDragOver = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const onDragDrop = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        st_.cleanup()

        if (!e.dataTransfer || e.dataTransfer.items.length === 0) return

        let files: File[] = []

        for (let item of e.dataTransfer.items) {
            let file = item.getAsFile()
            if (file) files.push(file)
        }

        //if (files.length === 0) return

        // let item = e.dataTransfer.items[0]
        // let file = item.getAsFile()!
        theme_.dropZoneBorder.apply(dropRef)

        filesRef.current = files
        processSelectedFiles()

        //props.onDrop(files, props.tag)
    }, [])

    const processSelectedFiles = React.useCallback(() => {
        let files = filesRef.current

        if (files.length === 0) {
            setFileName(fileNotSelectedText)
            setDropZoneText(dropZoneContentsText)
            return
        }

        setDropZoneText(`You selected ${files.length} file(s)`)

        if (files.length === 1) {
            setFileName(files[0].name)
            return
        }

        let fileNames: string[] = []

        files.forEach((f) => fileNames.push(f.name))

        setFileName(fileNames.join(', '))
    }, [])

    const onSelectedFileChange = React.useCallback((e) => {
        const files = e.target.files
        filesRef.current = files
        processSelectedFiles()
    }, [])

    const onBrowseClick = () => {
        fileInputRef.current!.click()
    }



    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            //console.log('I am here')
            if (props.category === 'uploads') {
                if (scan.uploadId) {
                    let files = filesRef.current
                    let file = files[0]
                    if (fileOffset !== null && fileChunkId !== null && fileLength) {


                        let startDate = new Date();
                        const blobChunk = file.slice(fileOffset, fileLength);

                        Api.requestSessionImage<any>(
                            'scan',
                            'upload_chunk_start',
                            {

                                chunkId: fileChunkId,
                                uploadId: scan.uploadId,

                            },
                            blobChunk
                        ).then((d) => {
                            let counter = 0
                            if (d.chunksArray) {
                                if (d.chunksArray.length <= 1) {
                                    setProgress(100)
                                    setLinearProgess(true)
                                    counter++
                                } else {
                                    for (let chunk of d.chunksArray) {
                                        if (chunk.isFinished === false) {
                                            let endDate = new Date();
                                            let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
                                            let num = (d.chunksArray.length * seconds / 60) - (counter * seconds / 60)
                                            if (num < 1) {
                                                num = (d.chunksArray.length * seconds) - (counter * seconds)
                                                setTimeVariant('second')
                                            } else {
                                                setTimeVariant('minute')
                                            }
                                            setUploadIntervalTime(Math.round(num * 100) / 100)

                                            setFileOffset(chunk.offset)
                                            setFileLength(chunk.offset + chunk.length)
                                            setFileChunkId(chunk.chunkId)
                                            let prog = chunk.offset / file.size * 100
                                            if (prog <= 100) {
                                                setProgress(prog)
                                                setLinearProgess(true)
                                            }
                                            setDataRequested(false)
                                            break
                                        }
                                        else {
                                            counter++
                                        }
                                    }
                                }

                                if (counter >= d.chunksArray.length) {

                                    Api.requestSession<any>(
                                        'scan',
                                        'upload_chunk_end',
                                        {
                                            uploadId: scan.uploadId,
                                        },
                                    ).then((d) => {

                                        if (d.processStatus === 1) {

                                            setProgress(100)
                                            setTimeout(() => {
                                                setLinearProgess(false)
                                            }, 1000)
                                        }

                                    }).catch(err => alert(err));

                                }
                            }


                        }).catch(err => alert(err));
                    }
                }


                setDataRequested(true);
                return;
            } else {
                setDataRequested(true);
            }
        }

        return () => { mounted.current = false; }

    }, [dataRequested]);



    const onUpload = React.useCallback((evt: F.InputFormEvent) => {
        //event.preventDefault()

        let files = filesRef.current
        let file = files[0]
        // let stain = evt.data[scanStainFieldId]
        // let pathogen = evt.data[scanPathogenFieldId]



        if (files.length === 0) {
            alert('Please select file(s) to upload')
            return
        }


        async function digestMessage(file: File) {
            // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
            const msgUint8 = await file.arrayBuffer();
            console.log(msgUint8)
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
            return hashHex;
        }

        digestMessage(file)
            .then((digestHex) => {


                // let chunkSize = 6 * 1024 * 1024
                // let chunkSize = 36 * 1024 * 1024
                // let chunkSize = 10 * 1024 * 1024

                // if (file.size <= chunkSize) {
                //     // singleFileUpload(ctx)
                // } else {
                //     // multipartFileUpload(ctx)
                if (digestHex !== null && scan.stain !== undefined && scan.pathogen !== undefined) {

                    let startDate = new Date();

                    Api.requestSession<any>(
                        'user',
                        'get_upload',
                        {
                            uploadId: scan.uploadId,
                            fileDigestHex: digestHex
                        },
                    ).then((d) => {

                        setFileOffset(d.offset)
                        setFileLength(d.length)
                        setFileChunkId(d.chunkId)
                        setDataRequested(false)

                    }).catch(err => alert(err));

                }
            })
            .catch(err => alert(err));
    }, [])

    if (props.category === 'uploads') {
        return (
            <F.PageFormDialog
                title='Upload Scan'
                onClose={handleClose}
                form={form}
                size='md'
                onSubmit={onUpload}
                submitLabel='UPLOAD'
                layoutElement='none'
            >
                <Stack direction='column' spacing={2} sx={{ width: 1 }}>
                    <Stack direction='row' spacing={2} pt={2}>
                        <F.InputText id='scan-stain' label='Stain' value={scan.stain} form={form} />
                        <F.InputText id='scan-pathogen' label='Pathogen' value={scan.pathogen} form={form} />
                    </Stack>

                    {/* {props.files ? (
                        <>
                            <Typography>You are about to upload scan image</Typography>
                        </>
                    ) : (
                        <> */}
                    {/* <DropZone className='dropZone' onDragOver={allowDrop} onDrop={dropHandler}> */}
                    <Box
                        ref={dropRef}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver}
                        onDrop={onDragDrop}
                        sx={[
                            theme_.dropZoneBorder.sx(),
                            {
                                width: 'auto',
                                height: 240,

                                //borderStyle: 'dashed',
                                //color: 'green',
                                color: theme_.dialogTextColor,

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',

                                p: 2,
                                //mt: 2,
                                //my: 4,

                                fontSize: 'x-large',
                            },
                        ]}
                    >
                        {dropZoneText}
                    </Box>

                    <Stack direction='row' alignItems='center' spacing={1} pr={1}>
                        <input
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={onSelectedFileChange}
                            type='file'
                        />

                        <DialogButton label='Browse' onClick={onBrowseClick} />
                        <Typography>{fileName}</Typography>

                    </Stack>
                    {/* </>
                    )} */}
                    {linearProgress && (
                        // <Dialog onClose={props.onClose} onBackdropClick={() => {props.onClose}} open={progIndicator} PaperProps={{ sx: { width: 200, height: 200 } }}>
                        // <Dialog onClose={() => { setLinearProgess(false) }} open={linearProgress} PaperProps={{ sx: { width: 200, height: 'auto' } }}>
                        <Dialog
                            maxWidth='sm'
                            fullWidth={true}
                            onClose={() => { setLinearProgess(false) }}
                            open={linearProgress}
                            PaperProps={{
                                sx: {
                                    minHeight: '30vh',
                                },
                            }}
                        >
                            <DialogCaption ttitle='Uploading...' onClose={() => { setLinearProgess(false) }} />
                            <DialogContent dividers>
                                <Stack direction='column' spacing={5}>
                                    <Stack direction='row' spacing={2}>
                                        <TextField
                                            label='File'
                                            defaultValue={filesRef.current[0].name}
                                            InputProps={{ readOnly: true }}
                                            variant='standard'
                                        />
                                        <TextField
                                            label='Size'
                                            defaultValue={filesRef.current[0].size}
                                            InputProps={{ readOnly: true }}
                                            variant='standard'
                                        />
                                    </Stack>
                                    <Stack direction='row' spacing={2}>
                                        <TextField
                                            label='Stain'
                                            defaultValue={scan.stain}
                                            InputProps={{ readOnly: true }}
                                            variant='standard'
                                        />
                                        <TextField
                                            label='Pathogen'
                                            defaultValue={scan.pathogen}
                                            InputProps={{ readOnly: true }}
                                            variant='standard'
                                        />
                                    </Stack>

                                    <LinearProgressWithLabel value={progress} time={uploadIntervalTime} timename={timeVariant} />
                                    {/* <LinearProgress variant="determinate" value={progress} sx={{ height: '25px' }} /> */}
                                    {/* <Typography>{Math.round(progress)}%</Typography> */}

                                </Stack>
                            </DialogContent>
                        </Dialog>
                    )}
                </Stack>
            </F.PageFormDialog>
        )



        // return (
        //     <PageDialog
        //         type='confirm'
        //         title={{
        //             name: 'Upload',
        //         }}
        //         onClose={handleClose}
        //     >
        //         <Typography>asdasd</Typography>
        //         {/* <ScanUploadDialog
        //             tag={selectedScanTag}
        //             files={droppedFiles}
        //             onClose={clearUploadState}
        //             onUpload={handleUploadBegin}
        //         /> */}
        //     </PageDialog>
        // )
    }

    return (
        <PageDialog
            type='confirm'
            title={{
                name: 'Scan',
                tvalue: scan.scanName,
            }}
            onClose={handleClose}
        >
            <ScanModalTabbed scan={scan} onUpdate={handleUpdate} onClose={handleClose} />
        </PageDialog>
    )
}



function LinearProgressWithLabel(props: LinearProgressProps & { value: number, time: number, timename: string }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant='determinate' {...props} sx={{ height: '25px' }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant='body2' color='text.secondary'>{`${Math.round(
                    props.value
                )}%`}</Typography>
                <Typography variant='body2' color='text.secondary'>
                    {`${props.time} ${props.timename}`}
                </Typography>
            </Box>
        </Box>
    )
}