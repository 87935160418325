import { ScanViewRenderer } from "../ScanViewRenderer";

//
// Important function. Called every time location is changed
//
export function ScanRender_processLocationChanged(this: ScanViewRenderer, isWheel: boolean) 
{
    // Shouldn't be called during easing
    // TODO: test and remove
    if (this.webgl!.easing) {
        this.cancelEasing();
    }


    // Check if we have to switch the scene
    let currLoc = this.nav!.currLoc;
    let currScene = this.currentSceneRef_!;

    //console.debug({zoom});

    // Check if we have to switch the scene
    let isIn = currScene.isInZoom(currLoc.zoom);

    if (isIn) {
        let refresh = currScene.setLocation(currLoc);
        if (refresh)
            this.scheduleSceneUpdateOnData();
        this.updateWebGL();
        return;
    }

    //if (isWheel) {
        //let ren = this;

        //function processWheelChange() {
            currScene = this.scenes.find(scene => scene.isInZoom(currLoc.zoom))!;

            //console.debug("--- changing scene to:", currScene.minZoom, currScene.maxZoom);
            
            // check if the new scene position covers the location
            this.selectScene(currScene);

            currScene.setLocation(currLoc);
            this.scheduleSceneUpdateOnData();
            this.updateWebGL();
        
            //ren.locationChangeTimer_ = undefined;
        // }

        // if (this.locationChangeTimer_ === undefined) {
        //     this.locationChangeTimer_ = setTimeout(() => processWheelChange(), 10);
        // }
        /*
    } else {

        // Find the corresponding scene
        currScene = this.scenes.find(scene => scene.isInZoom(currLoc.zoom))!;

        //console.debug("--- changing scene to:", currScene.minZoom, currScene.maxZoom);

        // check if the new scene position covers the location
        this.selectScene(currScene);

        currScene.setLocation(currLoc);
        this.scheduleSceneUpdateOnData();
        this.updateWebGL();
    }
    */
}
