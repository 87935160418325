import {PubSub} from 'tsfront/pubsub';
import {IScanLocation, ScanLocation} from '../ScanTypes/ScanLocation';

type VoidListener = () => void;
type NumberListener = (v: number) => void;
type LocationListener = (loc: IScanLocation) => void;

export class ScanViewPubSub extends PubSub {
    private readonly renderStartid = 'renderStart';
    onRenderStart(listener: VoidListener) {
        this.addListener(this.renderStartid, listener);
    }
    offRenderStart(listener: VoidListener) {
        this.removeListener(this.renderStartid, listener);
    }
    emitRenderStart() {
        this.dispatch<void>(this.renderStartid);
    }

    //
    // Frequent tracking event implementation
    //
    private readonly scanLocTrackId = 'scanLocTrack';
    // private locTrackEvent = new CustomEvent<ScanLocation>(this.scanLocTrackId, {
    //     detail: new ScanLocation(),
    // })
    private locTrackEventPending = false;
    private locTrackTimeoutPending = false;

    onScanLocationTrack(listener: LocationListener) {
        this.addListener(this.scanLocTrackId, listener);
    }
    offScanLocationTrack(listener: LocationListener) {
        this.removeListener(this.scanLocTrackId, listener);
    }
    emitScanLocationTrack(loc: IScanLocation) {
        //this.locTrackEvent.detail.assign(loc)
        this.locTrackEventPending = true;
        if (this.locTrackTimeoutPending) return;

        let details = new ScanLocation();
        details.assign(loc);

        this.locTrackTimeoutPending = true;
        let this_ = this;
        setTimeout(() => {
            if (this_.locTrackEventPending) {
                //this_.dispatchCustomEvent(this_.locTrackEvent)
                this_.dispatchCustomEvent(
                    new CustomEvent<ScanLocation>(this.scanLocTrackId, {
                        detail: details,
                    })
                );
                this_.locTrackEventPending = false;
                this_.locTrackTimeoutPending = false;
            }
        }, 30);
    }

    private readonly scanLocChangeId = 'scanLocChanged';
    private readonly scanZoomChangeId = 'scanZoomChanged';
    private lastEmitZoom_ = Infinity;

    // private locChangeEvent = new CustomEvent<ScanLocation>(this.scanLocChangeId, {
    //     detail: new ScanLocation(),
    // })
    onScanLocation(listener: LocationListener) {
        this.addListener(this.scanLocChangeId, listener);
    }
    offScanLocation(listener: LocationListener) {
        this.removeListener(this.scanLocChangeId, listener);
    }
    onScanZoom(listener: LocationListener) {
        this.addListener(this.scanZoomChangeId, listener);
    }
    offScanZoom(listener: LocationListener) {
        this.removeListener(this.scanZoomChangeId, listener);
    }
    emitScanLocation(loc: IScanLocation) {
        //this.locChangeEvent.detail.assign(loc)
        this.locTrackEventPending = false;
        this.locTrackTimeoutPending = false;
        //this.dispatchCustomEvent(this.locChangeEvent)
        let detail = new ScanLocation();
        detail.assign(loc);
        this.dispatchCustomEvent(
            new CustomEvent<ScanLocation>(this.scanLocChangeId, {
                detail: detail,
            })
        );

        //this.dispatch(this.scanLocationChangeId, loc);

        // Emit zoom information separately if we have to
        if (loc.zoom !== this.lastEmitZoom_) {
            this.dispatchCustomEvent(
                new CustomEvent<ScanLocation>(this.scanZoomChangeId, {
                    detail: detail,
                })
            );
            this.lastEmitZoom_ = loc.zoom;
        }
    }

    //
    // Busy status
    //
    private readonly busyStatusId = 'busyStatus';
    onBusyStatus(listener: NumberListener) {
        this.addListener(this.busyStatusId, listener);
    }
    removeBusyStatus(listener: NumberListener) {
        this.removeListener(this.busyStatusId, listener);
    }
    emitBusyStatus(count: number) {
        this.dispatch<number>(this.busyStatusId, count);
    }
}
