import {createTheme} from '@mui/material/styles'
import { text } from 'stream/consumers'

import './theme'

declare module '@mui/material/styles' {
    interface Palette {
        fieldName: Palette['text']
        fieldValue: Palette['text']
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        fieldName?: PaletteOptions['text']
        fieldValue?: PaletteOptions['text']
    }
}

declare module '@mui/material/Typography' {
    interface TypograpyPropsColorOverrides {
        neutral: true
    }
}

const muiTheme = createTheme({
    spacing: theme_.muiSpacing,

    palette: {
        mode: theme_.mode, // 'dark' | 'light'

        // text: {
        //     primary: theme_.textPrimaryColor,
        // },

        fieldName: {
            primary: theme_.textPrimaryColor,
            //main: text.primary, //'#ff0000',
        },

        fieldValue: {
            primary: theme_.textPrimaryColor,
            //main: '#ffff00',
        },
    },

    typography: {
        h1: {
            fontSize: '3rem',
            color: theme_.pageTextColor,
        },

        h2: {
            fontSize: '2.6rem',
            color: theme_.pageTextColor,
        },

        h3: {
            fontSize: '2.2rem',
            color: theme_.pageTextColor,
        },

        h4: {
            fontSize: '1.6rem',
            color: theme_.pageTextColor,
        },

        body1: {
            color: theme_.pageTextColor,
        },

        body2: {
            color: theme_.pageTextColor,
        },

        //fontSize: 12,
    },

    components: {
        // MuiFormControl: {
        //     styleOverrides: {
        //         root: {
        //             height: '36px',
        //         },
        //     }
        // },

        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             height: '36px',
        //         },
        //     }
        // },

        // MuiTypography: {
        //     styleOverrides: {
        //         root: {
        //             "& h1": {
        //                 fontSize: 'small',
        //                 color: "blue",
        //             }
        //         },
        //     }
        // },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    //color: theme_.dialogTextColor,
                },
            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 1,
                    //backgroundColor: 'red',
                },
            },
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(2n)': {
                        backgroundColor: theme_.tableOddRowColor,
                    },
                },
            },
        },

        // MuiBox: {
        //     styleOverrides: {
        //         root: {
        //             padding: 0,
        //         },
        //     },
        // },
    },
})

//console.debug(muiTheme)

export default muiTheme
