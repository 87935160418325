import React from "react";

import { Container, Grid, Box, Tabs, Tab } from '@mui/material';

import WipScansPage from "./WipScansPage";

import DevApiTab from "./DevApiTab";
import PageContents from "components/PageContents";
import DevLinksTab from "./DevLinksTab";
import DevDocTab from "./DevDocTab";
//import { AdminTabTitle } from "pages/admin/AdminComponents";

//const startTab = 'links';
const startTab = 'doc';  // Debug

export default function DevPage() {
    const [selectedTab, setSelectedTab] =  React.useState(startTab);

    const onTabChange = React.useCallback((evt, value) => {
        setSelectedTab(value);
    }, []);

    return <PageContents title='Dev' current='dev' type='data'>
        {/* <Container sx={{height: 1}}> */}
            {/* <AdminTabTitle>Developer Resources</AdminTabTitle> */}


        <Grid container spacing={2} sx={{
            height: "92%",
        }}>
            <Grid item xs={2}>
                <Tabs 
                    value={selectedTab}
                    onChange={onTabChange}
                    orientation="vertical"
                    sx={{
                        borderRight: 1, 
                        borderColor: 'divider',
                        height: "92%",
                    }}>

                    <Tab value='doc' label="Doc" />
                    <Tab value='links' label="Links" />
                    <Tab value='api' label="API" />
                    <Tab value='scans' label="Scans" />
                </Tabs>
            </Grid>

            <Grid item xs sx={{
                width: 1,
                height: "92%",
                //maxHeight: 0.9, //'90%',
                //overflow: 'auto',
            }}>
                {selectedTab === 'links' && <DevLinksTab/>}
                {selectedTab === 'api' && <DevApiTab/>}
                {selectedTab === 'doc' && <DevDocTab/>}
                {selectedTab === 'scans' && <WipScansPage/>}
            </Grid>

        </Grid>
        {/* </Container> */}
    </PageContents>;
}

