import * as AD from 'api/data'
import * as Api from 'api'


import {Scan} from './Scan'
import { parseBool } from 'tslib/parse'

export interface PatientReport {
    reportId: string

    userId: string
    userGreeting: string

    scanId: string
    finalized: boolean
    status: string


    beginDate: Date

    lastModified?: Date
    modified?: boolean

    text?: string
    src?: string


    reportUrl?: string
}

export const reportWritePath = 'report/write'
export const reportEditPath = 'report/edit'
export const reportAmmendPath = 'report/ammend'

export const reportViewTarget = 'reportview'
export const reportEditTarget = 'reportedit'
export const reportWriteTarget = '_blank'


export function processApiReport(api: AD.ApiReportData) {

    let finalized = parseBool(api.finalized)
    
    let report: PatientReport = {
        reportId: api.reportId,
        scanId: api.scanId,
        userId: api.userId,
        userGreeting: api.userId,

        finalized: finalized,
        reportUrl: api.reportUrl,
        status: finalized ? 'FINALIZED' : 'PENDING',
        //status: api.finalized ? 'FINALIZED' : 'PENDING',

        beginDate: Api.apiToDate(api.beginDate),
        lastModified: Api.apiToDate(api.lastModified),

        text: api.text,
    }

    return report
}

export function processApiReports(api: AD.ApiReportData[]) {
    return api.map(item => processApiReport(item))
}


export function getScanReportWriteRef(scan: Scan) {
    return {
        href: `/${reportWritePath}/${scan.scanId}`,
        target: reportEditTarget,
    }
}



export function getReportEditRef(report: PatientReport | string) {

    let reportId = typeof report === 'string' ? report : report.reportId

    return {
        href: `/${reportEditPath}/${reportId}`,
        target: reportEditTarget,
    }
}


export function getReportViewRef(report: PatientReport) {
    return {
        href: report.reportUrl!,
        target: reportViewTarget,
    }
}



export function getScanReportViewRef(scan: Scan) {
    return {
        href: scan.reportUrl!,
        target: reportViewTarget,
    }
}

