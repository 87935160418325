import { assertThrow } from 'tslib/assert';
import { ScanViewRenderer } from '../ScanViewRenderer';

export function ScanRender_createCanvas(this: ScanViewRenderer): HTMLCanvasElement
{
    // Detect the screen caps
    this.screenCaps.detect();
    //this.screenCaps.debugDump();


    let canvas = document.createElement('canvas');
    canvas = assertThrow(canvas, 'Cannot create canvas');

    this.canvas = canvas;

    let style = canvas.style;
    

    canvas.id = 'bpfScanRenderCanvas';

    style.position = 'absolute';
    style.left = '0';
    style.right = '0';
    style.top = '0';
    style.bottom = '0';
    style.width = '100%';
    style.height = '100%';
    style.cursor = 'grab';


    let gl = canvas.getContext('webgl', { 
        alpha: false,
        antialias: false,
        depth: false,
        premultipliedAlpha: false 
    });

    gl = assertThrow(gl, "WebGL::Cannot Create Context.");

    this.webgl!.init(gl!);
    
    // First check the graphics caps
    this.webgl!.detectGraphicsCaps();
    //this.webgl!.debugDumpDeviceCaps();


    //buildScanViewScene(this.scanCtxRef.deref()!);
    //buildScanViewScene(this.scanCtx!);

    return canvas;
}

//export function ScanRender_attachCanvas(this: ScanViewRenderer, parentElementId: string) 
export function ScanRender_attachCanvas(this: ScanViewRenderer, parent: HTMLElement) 
{
    //let parent = document.getElementById(parentElementId);
    //if (!parent)
    //    throw new Error(`Create Canvas: Invalid parent element: ${parentElementId}`);
    this.canvasParent = parent;

    let canvas = this.canvas!;

    let found = document.getElementById(canvas.id);
    if (found)
        found.remove();

    parent.insertBefore(canvas, parent.childNodes[0]);
}
